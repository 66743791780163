import axios from "axios";
import routes from "./routes";

export const getUsers = async (
    accessToken: string,
    organisationAndEnvName: string,
    page: number,
    limit: number,
    searchQuery?: string,
    sort?: string,
    order?: string,
) => {
    let users = [];
    let querystring = `?organisation=${organisationAndEnvName}`;

    if (page > 0) {
        querystring = `${querystring}${querystring.includes(`=`) ? `&` : `?`}page=${page}`;
    }

    if (limit && limit > 0) {
        querystring = `${querystring}${querystring.includes(`=`) ? `&` : `?`}limit=${limit}`;
    }

    if (searchQuery && searchQuery.length >= 3) {
        querystring = `${querystring}${querystring.includes(`=`) ? `&` : `?`}q=${searchQuery}`;
    }

    if (sort) {
        querystring = `${querystring}${querystring.includes(`=`) ? `&` : `?`}sort=${sort}`;
    }

    if (order) {
        querystring = `${querystring}${querystring.includes(`=`) ? `&` : `?`}order=${order}`;
    }

    const url = `${routes.get.users.getUsers}${querystring}`;

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        const res = await axios.get(url, {
            headers: headers,
        });
        users = res.data;
    } catch (error) {
        // Silent fail
    }

    return users;
};
