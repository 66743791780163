/*
 * findObjectByProperty
 *
 */
export const findObjectByPropertyValue = (
    array: any[],
    property: string,
    value: string,
) => {
    return array.find(item => item[property] === value);
};
