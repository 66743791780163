import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// Components
import { PrimaryButton } from "@repo/ui";

interface Props {
    className?: string;
    imgSrc?: string;
    imgAlt?: string;
    icon?: JSX.Element;
    header: string;
    copy?: string;
    primaryButton?: JSX.Element;
    backRoute?: string;
    buttonTopMargin?: string;
}

const NotificationPanel: React.FC<Props> = ({
    className,
    imgSrc,
    imgAlt,
    icon,
    header,
    copy,
    primaryButton,
    backRoute,
    buttonTopMargin = "10rem",
}) => {
    const navigate = useNavigate();

    return (
        <div className={className}>
            <div className="image-icon">
                <img src={imgSrc} alt={imgAlt} />
                <span className="icon">{icon}</span>
            </div>
            {header && (
                <h1 dangerouslySetInnerHTML={{ __html: header }}></h1>
            )}
            {copy && (
                <div
                    className="copy"
                    dangerouslySetInnerHTML={{ __html: copy }}
                ></div>
            )}
            <div style={{ marginTop: buttonTopMargin }}>
                {primaryButton}
                {!primaryButton && backRoute ? (
                    <PrimaryButton
                        onClick={() => {
                            navigate(backRoute);
                        }}
                    >
                        Continue
                    </PrimaryButton>
                ) : null}
                {primaryButton && backRoute ? (
                    <Link to={backRoute}>
                        <button className="secondary-button">
                            Back
                        </button>
                    </Link>
                ) : null}
            </div>
        </div>
    );
};

export default styled(NotificationPanel)`
    .image-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;
    }

    .icon svg {
        width: 72px;
        height: 72px;
        color: #00bbcc;
    }

    .copy {
        text-align: center;
        color: #424242;
        font-size: 0.875rem;
        margin-top: 2rem;
    }

    a:link,
    a:visited {
        color: #00bbcc;
        text-decoration: underline;
    }

    button {
        width: 100%;
        font-size: 0.875rem !important;
        height: 58px !important;
        margin-top: 1rem;
    }

    .secondary-button {
        border: 2px solid #d9d9d9;
        border-radius: 8px;
        color: #424242;
    }
`;
