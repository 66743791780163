import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
    useLocation,
    useNavigate,
    useOutletContext,
} from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Types
import { OutletContextType } from "../../../@types/OutletContextType";
import { Version } from "../../../@types/Middleware/Version";

// Data
import { getOpeningHours } from "../../../common/api/middleware/getOpeningHours";
import { addOpeningHours } from "../../../common/api/middleware/addOpeningHours";
import { deleteOpeningHours } from "../../../common/api/middleware/deleteOpeningHours";

// Utils
import { capitaliseFirstLetter } from "../../../common/utils/capitaliseFirstLetter";
import { formatDateToDayMonthYear } from "../../../common/utils/formatDateToDayMonthYear";
import { prependZero } from "../../../common/utils/prependZero";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Components
import {
    Clock20Regular,
    Pen16Regular,
    Delete16Regular,
    DeleteDismiss20Regular,
    Pen20Regular,
} from "@fluentui/react-icons";
import { MessageBar } from "@fluentui/react-components";
import AdminPanel from "../../../components/AdminPanel/AdminPanel";
import OperatingHours from "../../../components/OperatingHours/OperatingHours";

export type FormInputs = {
    openingHoursVersions: Version[];
};

const formSchema = {
    openingHoursVersions: yup
        .array()
        .min(1, "You must have at least one opening hour entry")
        .required("You must add at least one opening hour entry"),
};

interface Props {
    className?: string;
}

const EditTwinPage: React.FC<Props> = ({ className }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const { setIsLoading } = useOutletContext<OutletContextType>();

    const currentPath = location.pathname;
    const { bID } = location.state || {}; // bID is the root Digital Twin Entity (Twin)

    if (!bID) {
        navigate("/something-went-wrong");
    }

    const { authMetadata } = useUserContext();

    const [currentEffectiveDates, setCurrentEffectiveDates] =
        useState<string[]>([]);
    const [markedForDeletion, setMarkedForDeletion] = useState<
        string[]
    >([]);
    const [mode, setMode] = useState<string>("LIST");
    const [editVersion, setEditVersion] = useState<{
        version: Version;
        index: number;
    } | null>(null);

    const {
        handleSubmit,
        getValues,
        setValue,
        reset,
        watch,
        formState: { errors, isDirty },
    } = useForm<FormInputs>({
        resolver: yupResolver(yup.object(formSchema)),
    });

    // Fetch existing opening times
    const fetchOpeningHours = useCallback(async () => {
        setIsLoading(true);
        if (
            authMetadata?.tokens?.accessToken &&
            authMetadata.envName &&
            bID
        ) {
            // Fetch and set existing Opening Times
            const openingHoursData = await getOpeningHours(
                authMetadata?.tokens.accessToken,
                authMetadata.envName,
                bID,
            );
            setValue("openingHoursVersions", openingHoursData);
            setCurrentEffectiveDates(
                openingHoursData.map(ohv => ohv.effectiveDateFrom),
            );
            setIsLoading(false);
        }
    }, [
        authMetadata?.envName,
        authMetadata?.tokens?.accessToken,
        bID,
        setValue,
    ]);

    useEffect(() => {
        fetchOpeningHours();
    }, [fetchOpeningHours]);

    const onSubmit: SubmitHandler<FormInputs> = async formData => {
        if (
            authMetadata?.tokens?.accessToken &&
            authMetadata.envName &&
            bID
        ) {
            setIsLoading(true);

            // First delete any existing versions that are marked for deletion
            if (markedForDeletion.length > 0) {
                try {
                    await deleteMarkedRecords(
                        authMetadata?.tokens?.accessToken,
                        authMetadata.envName,
                        bID,
                    );
                } catch (error) {
                    navigate("/account/failure", {
                        state: {
                            message:
                                "<p>Unable to delete one or more opening time versions. Please try again.</p><p>If the problem persists please contact support.</p>",
                            backRoute: currentPath,
                        },
                    });
                }
            }

            // Iterate through Opening Time Versions and call middleware API
            const recordResult: {
                index: number;
                success: boolean;
            }[] = [];
            const openingHourVersions = getValues(
                "openingHoursVersions",
            );
            let i = 0;

            for (const oh of openingHourVersions) {
                if (
                    !currentEffectiveDates.includes(
                        oh.effectiveDateFrom,
                    ) ||
                    markedForDeletion.includes(oh.effectiveDateFrom)
                ) {
                    recordResult.push({
                        index: i,
                        success: await addOpeningHourVersion(oh),
                    });
                    i++;
                }
            }

            if (
                recordResult.some(result => result.success === false)
            ) {
                navigate("/account/failure", {
                    state: {
                        message:
                            "<p>One or more opening times records failed to update. Please try again.</p><p>If the problem persists please contact support.</p>",
                        backRoute: currentPath,
                    },
                });
            } else {
                navigate("/account/success", {
                    state: {
                        message:
                            "You've successfully updated your opening times.",
                    },
                });
            }

            setIsLoading(false);
        }
    };

    const handleDiscard = () => {
        reset();
        fetchOpeningHours();
    };

    const handleAddOpeningHoursVersion = (
        version: Version,
        index?: number,
    ) => {
        const currentArray = getValues("openingHoursVersions") || [];

        if (index !== undefined) {
            currentArray[index] = version;
            setMarkedForDeletion([
                ...markedForDeletion,
                currentEffectiveDates[index],
            ]);
        } else {
            currentArray.push(version);
        }

        setValue("openingHoursVersions", currentArray, {
            shouldDirty: true,
        });
        setEditVersion(null);
        setMode("LIST");
    };

    const removeVersion = (
        index: number,
        effectiveDateFrom: string,
    ) => {
        if (currentEffectiveDates.includes(effectiveDateFrom)) {
            setMarkedForDeletion([
                ...markedForDeletion,
                effectiveDateFrom,
            ]);
        }

        const currentArray = [...getValues("openingHoursVersions")];
        currentArray.splice(index, 1);
        setValue("openingHoursVersions", currentArray, {
            shouldValidate: true,
        });
    };

    const addOpeningHourVersion = async (
        version: Version,
    ): Promise<boolean> => {
        let result = false;
        if (
            authMetadata?.tokens?.accessToken &&
            authMetadata.envName &&
            bID
        ) {
            result = await addOpeningHours(
                authMetadata?.tokens?.accessToken,
                authMetadata.envName,
                bID,
                version.effectiveDateFrom,
                version.effectiveDateTo,
                version.periods,
            );
        }
        return result;
    };

    const deleteMarkedRecords = async (
        accessToken: string,
        envName: string,
        bID: string,
    ): Promise<boolean> => {
        let result = false;
        for (const effectiveDateFrom of markedForDeletion) {
            result = await deleteOpeningHours(
                accessToken,
                envName,
                bID,
                effectiveDateFrom,
            );
        }
        return result;
    };

    const openingHoursVersions = watch("openingHoursVersions");

    return (
        <div className={className}>
            <AdminPanel
                maxWidth={630}
                header="Update Twin"
                children={
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {mode === "LIST" && (
                                <>
                                    <fieldset>
                                        <h2>Opening Hours</h2>
                                        {!openingHoursVersions ||
                                        openingHoursVersions.length ===
                                            0 ? (
                                            <div className="no-operating-hours-message">
                                                <MessageBar intent="warning">
                                                    No opening times
                                                    have been set.
                                                </MessageBar>
                                            </div>
                                        ) : null}
                                        {typeof errors
                                            .openingHoursVersions
                                            ?.message === "string" &&
                                            (errors
                                                .openingHoursVersions
                                                .message ? (
                                                <MessageBar
                                                    intent="error"
                                                    layout="multiline"
                                                >
                                                    {
                                                        errors
                                                            .openingHoursVersions
                                                            .message
                                                    }
                                                </MessageBar>
                                            ) : null)}
                                        <div className="operating-hours">
                                            {openingHoursVersions &&
                                                openingHoursVersions.map(
                                                    (oh, i) => {
                                                        return (
                                                            <div
                                                                className="version"
                                                                key={
                                                                    i
                                                                }
                                                            >
                                                                <div className="header">
                                                                    <p>
                                                                        Effective
                                                                        Between
                                                                    </p>
                                                                    <p>
                                                                        {formatDateToDayMonthYear(
                                                                            new Date(
                                                                                oh.effectiveDateFrom,
                                                                            ),
                                                                        )}{" "}
                                                                        -{" "}
                                                                        {formatDateToDayMonthYear(
                                                                            new Date(
                                                                                oh.effectiveDateTo,
                                                                            ),
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                {oh.periods &&
                                                                    oh.periods.map(
                                                                        (
                                                                            p,
                                                                            i,
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    key={
                                                                                        i
                                                                                    }
                                                                                    className="day-of-week"
                                                                                >
                                                                                    <h3>
                                                                                        {capitaliseFirstLetter(
                                                                                            p.dayOfWeek,
                                                                                        )}
                                                                                    </h3>
                                                                                    <ul>
                                                                                        {p.ranges &&
                                                                                            p.ranges.map(
                                                                                                (
                                                                                                    p,
                                                                                                    i,
                                                                                                ) => {
                                                                                                    let openingTime =
                                                                                                        p.open.split(
                                                                                                            ":",
                                                                                                        );
                                                                                                    let closingTime =
                                                                                                        p.close.split(
                                                                                                            ":",
                                                                                                        );

                                                                                                    return (
                                                                                                        <li
                                                                                                            key={
                                                                                                                i
                                                                                                            }
                                                                                                        >
                                                                                                            {prependZero(
                                                                                                                Number(
                                                                                                                    openingTime[0],
                                                                                                                ),
                                                                                                            )}

                                                                                                            :
                                                                                                            {prependZero(
                                                                                                                Number(
                                                                                                                    openingTime[1],
                                                                                                                ),
                                                                                                            )}{" "}
                                                                                                            -{" "}
                                                                                                            {prependZero(
                                                                                                                Number(
                                                                                                                    closingTime[0],
                                                                                                                ),
                                                                                                            )}

                                                                                                            :
                                                                                                            {prependZero(
                                                                                                                Number(
                                                                                                                    closingTime[1],
                                                                                                                ),
                                                                                                            )}
                                                                                                        </li>
                                                                                                    );
                                                                                                },
                                                                                            )}
                                                                                    </ul>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                                <div className="options">
                                                                    <div className="btn-action">
                                                                        <button
                                                                            onClick={e => {
                                                                                setMode(
                                                                                    "EDIT",
                                                                                );
                                                                                setEditVersion(
                                                                                    {
                                                                                        version:
                                                                                            oh,
                                                                                        index: i,
                                                                                    },
                                                                                );
                                                                            }}
                                                                        >
                                                                            <Pen16Regular />
                                                                        </button>
                                                                    </div>
                                                                    <div className="btn-action">
                                                                        <button
                                                                            onClick={e => {
                                                                                removeVersion(
                                                                                    i,
                                                                                    oh.effectiveDateFrom,
                                                                                );
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            <Delete16Regular />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    },
                                                )}
                                        </div>
                                        <div className="admin-form-buttons">
                                            <button
                                                className="add-version"
                                                onClick={() => {
                                                    setEditVersion(
                                                        null,
                                                    );
                                                    setMode("ADD");
                                                }}
                                            >
                                                <span className="icon">
                                                    <Clock20Regular />
                                                </span>
                                                Add Opening Hours
                                            </button>
                                        </div>
                                    </fieldset>
                                    <div className="admin-form-buttons">
                                        <button
                                            type="submit"
                                            disabled={!isDirty}
                                            style={{
                                                color: isDirty
                                                    ? "#242424"
                                                    : "#CCCCCC",
                                            }}
                                        >
                                            <span className="icon">
                                                <Pen20Regular />
                                            </span>
                                            Save Changes
                                        </button>
                                        <button
                                            onClick={() => {
                                                handleDiscard();
                                            }}
                                            disabled={!isDirty}
                                            type="button"
                                            style={{
                                                color: isDirty
                                                    ? "#242424"
                                                    : "#CCCCCC",
                                            }}
                                        >
                                            <span className="icon">
                                                <DeleteDismiss20Regular />
                                            </span>
                                            Discard Changes
                                        </button>
                                    </div>
                                </>
                            )}
                        </form>
                        {(mode === "ADD" || mode === "EDIT") && (
                            <OperatingHours
                                setMode={setMode}
                                addOpenHourVersion={
                                    handleAddOpeningHoursVersion
                                }
                                editVersion={editVersion}
                            />
                        )}
                    </>
                }
            />
        </div>
    );
};

export default styled(EditTwinPage)`
    form {
        width: 100%;
    }

    .field {
        margin-bottom: 2rem;
    }

    .field label {
        font-size: 1.125rem;
    }

    .link-field {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    .link-field label {
        width: 180px;
        padding-right: 1rem;
    }

    .link-field p {
        font-weight: bold;
    }

    .link-field a:link,
    .link-field a:visited {
        padding: 0.25rem;
        border-radius: 8px;
        margin-left: auto;
    }

    .link-field a:hover {
        color: #00bbcc;
    }

    .admin-form-buttons {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
    }

    .admin-form-buttons button {
        display: flex;
        justify-content: center;
        width: 264px;
        border-radius: 8px;
        padding: 1rem;
        background-color: #ffffff;
    }

    /* Operating Hours */
    .no-operating-hours-message {
        margin-bottom: 0.5rem;
    }

    .operating-hours {
        display: flex;
        flex-wrap: wrap;
    }

    .operating-hours .options {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
    }

    .operating-hours .options .btn-edit {
        margin-right: 0.5rem;
    }

    .operating-hours .version {
        width: 200px;
        margin: 2rem 0 1rem 0;
    }

    .operating-hours .version .header {
        display: flex;
        border-bottom: 1px solid #cccccc;
    }

    .operating-hours .version .header p {
        min-width: 100px;
        font-size: 0.75rem;
        font-weight: bold;
    }

    .operating-hours .version .header p:first-of-type {
        margin-right: 1rem;
    }

    .operating-hours .version:nth-child(even) {
        margin-left: 5%;
    }

    .day-of-week {
        display: flex;
        padding: 0.25rem 0;
        border-bottom: 1px solid #cccccc;
    }

    .day-of-week:last-of-type {
        border-bottom: none;
    }

    .day-of-week h3 {
        display: inline-block;
        margin-right: 1rem;
        min-width: 100px;
        font-size: 0.75rem;
        font-weight: bold;
    }

    .day-of-week ul li {
        list-style: none;
        font-size: 0.75rem;
    }

    .btn-action {
        display: block;
        margin: 0 0.5rem;
        padding: 0.5rem;
    }

    .btn-action:hover {
        color: #00bbcc;
    }

    .add-version {
        margin: 2rem 0 1rem 0;
        width: 100%;
    }
`;
