/*
 * findEntityById
 *
 */
import { TwinEntity } from "@repo/backend-types";
import { DataMode } from "../../@types/DataMode";

export const findEntityById = (
    dataMode: DataMode,
    array: any[],
    id: string,
) => {
    let foundEntity = null;

    if (array) {
        array.some(obj => {
            if (
                (dataMode === DataMode.LIVE && obj.id === id) ||
                (dataMode === DataMode.TIME_SERIES && obj.bID === id)
            ) {
                foundEntity = obj;
                return true; // Break out of the loop if the object is found
            }
            if (obj.children && Array.isArray(obj.children)) {
                foundEntity = findEntityById(
                    dataMode,
                    obj.children,
                    id,
                ); // Recursively search children arrays
                return foundEntity !== null; // Break out of the loop if object is found in children
            }
            return null;
        });
    }

    return foundEntity;
};

// similar to the aboove but slightly different
export function findEntityWithBID(
    twinEntity: TwinEntity,
    bID: string,
): TwinEntity | undefined {
    let found;

    if (twinEntity.bID === bID) {
        found = twinEntity;
    } else {
        if (twinEntity.children) {
            for (const child of twinEntity.children) {
                const result = findEntityWithBID(child, bID);
                if (result) {
                    found = result;
                }
            }
        }
    }

    return found;
}
