import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    Link,
    useNavigate,
    useOutletContext,
} from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useQuery } from "@tanstack/react-query";

// Types
import { OutletContextType } from "../../../@types/OutletContextType";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Data
import { getUsers } from "../../../common/api/middleware/getUsers";

// Components
import { PersonAdd20Regular } from "@fluentui/react-icons";
import AdminPanel from "../../../components/AdminPanel/AdminPanel";
import Pagination from "../../../components/Pagination/Pagination";
import TLSearch from "../../../components/elements/TLSearch/TLSearch";
import UsersTable from "../../../components/UsersTable/UsersTable";

interface Props {
    className?: string;
}

const UsersPage: React.FC<Props> = ({ className }) => {
    const { authMetadata } = useUserContext();
    const { setIsLoading, setEnableMainScroll } =
        useOutletContext<OutletContextType>();

    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [totalPages] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [debouncedQuery] = useDebounce(searchQuery, 500);

    const navigate = useNavigate();

    // Redirect user to Something went wrong
    if (
        !authMetadata?.tokens?.accessToken ||
        !authMetadata?.organisation
    ) {
        navigate("/something-went-wrong");
    }

    const changeLimit = (newLimit: number) => {
        setLimit(newLimit);
    };

    const switchPage = (newPage: number) => {
        setPage(newPage);
    };

    const fetchUsers = async (
        page: number,
        limit: number,
        debouncedQuery: string,
    ) => {
        if (
            authMetadata?.tokens?.accessToken &&
            authMetadata.organisation &&
            authMetadata.envName
        ) {
            const { users } = await getUsers(
                authMetadata.tokens.accessToken,
                `${authMetadata.organisation}:${authMetadata.envName}`,
                page,
                limit,
                debouncedQuery,
            );
            return users;
        }
    };

    const { data, isLoading } = useQuery({
        queryKey: ["users", page, limit, debouncedQuery],
        queryFn: () => fetchUsers(page, limit, debouncedQuery),
    });

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading, setIsLoading]);

    useEffect(() => {
        setEnableMainScroll(false);
    }, []);

    return (
        <div className={className}>
            <AdminPanel
                header="Users"
                children={
                    <>
                        <div className="controls">
                            <Link
                                className="button"
                                to="/account/users/add-new-user"
                            >
                                <span className="icon">
                                    <PersonAdd20Regular />
                                </span>
                                Add New User
                            </Link>
                            <TLSearch
                                isLoading={isLoading}
                                setSearchQuery={setSearchQuery}
                            />
                        </div>
                        <UsersTable data={data} />
                        <Pagination
                            limit={limit}
                            currentPage={page}
                            totalPages={totalPages}
                            switchPage={switchPage}
                            changeLimit={changeLimit}
                            noMoreRecords={
                                !data ||
                                (data && data.length === 0) ||
                                page + 1 === totalPages
                            }
                        />
                    </>
                }
            />
        </div>
    );
};

export default styled(UsersPage)`
    .controls {
        display: flex;
    }

    .button {
        margin-right: 1rem;
    }
`;
