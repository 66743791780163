import axios from "axios";
import routes from "./routes";

export const updateProfile = async (
    accessToken: string,
    emailAddress: string,
    firstName: string,
    lastName: string,
): Promise<boolean | any> => {
    const url = routes.put.users.updateProfile;
    let success = false;

    const data = {
        firstname: firstName,
        lastname: lastName,
        email: emailAddress,
        name: `${firstName} ${lastName}`,
    };

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        const result = await axios.put(url, data, {
            headers: headers,
        });
        return result;
    } catch (error) {
        success = false;
    }

    return success;
};
