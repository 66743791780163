import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderWithNavigate";
import { BrowserRouter } from "react-router-dom";

// Fluent Provider
import {
    FluentProvider,
    teamsLightTheme,
    PartialTheme,
} from "@fluentui/react-components";

// Theme
import { lightTheme } from "./common/fluent2/lightTheme";

// Context
import { FilterAndModeContextsProvider } from "./common/contexts/FilterAndModeContexts";
import { SettingsContextProvider } from "./common/contexts/SettingsContext";
import { TwinContextProvider } from "./common/contexts/TwinContext";
import { UserContextProvider } from "./common/contexts/UserContext";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

const theme: PartialTheme = {
    ...teamsLightTheme,
    ...lightTheme,
};

root.render(
    <React.StrictMode>
        <FluentProvider theme={theme}>
            <UserContextProvider>
                <SettingsContextProvider>
                    <TwinContextProvider>
                        <FilterAndModeContextsProvider>
                            <BrowserRouter>
                                <Auth0ProviderWithNavigate>
                                    <App />
                                </Auth0ProviderWithNavigate>
                            </BrowserRouter>
                        </FilterAndModeContextsProvider>
                    </TwinContextProvider>
                </SettingsContextProvider>
            </UserContextProvider>
        </FluentProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
