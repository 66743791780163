/*
 * getMaxHeadcount
 *
 */
import { tsRangeQuery } from "../../api/timeseries/tsRangeQuery";

export const getMaxHeadcount = async (
    timeSeriesURL: string,
    startDateTime: string,
    endDateTime: string,
    step: string = "10m",
    organisation: string,
    digitalTwinEntity: string,
): Promise<string> => {
    let value = "N/A";

    if (startDateTime && endDateTime && step && digitalTwinEntity) {
        const maxHeadcounts = await tsRangeQuery(
            timeSeriesURL,
            startDateTime,
            endDateTime,
            "max",
            step,
            organisation,
            "countEntity",
            undefined,
            digitalTwinEntity,
        );

        if (maxHeadcounts && maxHeadcounts.length > 0) {
            value = `${maxHeadcounts[0].values[0][1]}`;
        }
    } else {
        value = "N/A";
    }

    return value;
};
