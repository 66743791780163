/*
 * TwinContext
 * Used to store the model of the Twin
 * The UI also utilises the TwinContext to determine whether to render panels for functional area
 *
 */
import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";

// Types
import { Twin } from "../../@types/Twin";

interface TwinContextValue {
    twin: Twin | null;
    setTwin: (twin: Twin | null) => void;
}

const initialState: TwinContextValue = {
    twin: null,
    setTwin: () => {},
};

export const TwinContext =
    createContext<TwinContextValue>(initialState);

export const useTwinContext = (): TwinContextValue => {
    const context = useContext(TwinContext);
    if (!context) {
        throw new Error(
            "useTwinContext must be used within a TwinContextProvider",
        );
    }
    return context;
};
interface ContextProviderProps {
    children: React.ReactNode;
}

export const TwinContextProvider: React.FC<
    ContextProviderProps
> = props => {
    const [twinState, setTwinState] =
        useState<TwinContextValue>(initialState);

    const setTwin = useCallback((twin: Twin | null) => {
        setTwinState(prevTwinState => ({ ...prevTwinState, twin }));
    }, []);

    // Memoise the context value to prevent unnecessary re-renders
    const contextValue = useMemo(() => {
        return {
            ...twinState,
            setTwin,
        };
    }, [twinState, setTwin]);

    return (
        <TwinContext.Provider value={contextValue}>
            {props.children}
        </TwinContext.Provider>
    );
};
