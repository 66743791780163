import React, { useState } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

// Types
import { AppMode } from "../../@types/Mode";
import { Permission } from "../../@types/Auth0/Roles";

// Context
import {
    useFilterContext,
    useModeContext,
} from "../../common/contexts/FilterAndModeContexts";
import { useUserContext } from "../../common/contexts/UserContext";

// Components
import { ReactComponent as CopilotLineIcon } from "../../common/svg/maria-icon.svg";
import TLSwitchWithIcons from "../elements/TLSwitchWithIcons/TLSwitchWithIcons";
import { FullScreenMaximize16Regular } from "@fluentui/react-icons";
import {
    Toolbar,
    Image,
    Avatar,
    Label,
    ToolbarGroup,
    Button,
    ToolbarProps,
} from "@fluentui/react-components";

interface Props {
    className?: string;
    id?: string;
    size?: "small" | "medium" | "large";
    props?: ToolbarProps;
    fullScreen?: boolean;
    supportCopilotVisible: boolean;
    setFullScreen?: (value: boolean) => void;
    setSupportCopilotVisible: (value: boolean) => void;
}

const MainNav: React.FC<Props> = ({
    className,
    id,
    size,
    props,
    fullScreen,
    supportCopilotVisible,
    setFullScreen,
    setSupportCopilotVisible,
}) => {
    const { user } = useAuth0();
    const navigate = useNavigate();
    const { authMetadata } = useUserContext();
    const { lastUpdatedAt } = useFilterContext();
    const { appMode, setAppModeWithFilterReset } = useModeContext();

    const [logoOpacity, setLogoOpacity] = useState(1);

    return (
        <div className={className}>
            <Toolbar
                id={id}
                className="bg-glass"
                size={size ? size : "medium"}
                aria-label="Default"
                {...props}
            >
                <div
                    style={{
                        height: 32,
                        marginRight: 8,
                        opacity: logoOpacity,
                        cursor: "pointer",
                        transition: "opacity 0.3s ease",
                    }}
                    onClick={() => {
                        navigate("/select-twin");
                    }}
                    onMouseEnter={() => setLogoOpacity(0.6)}
                    onMouseLeave={() => setLogoOpacity(1)}
                    onMouseDown={() => setLogoOpacity(0.8)}
                    onMouseUp={() => setLogoOpacity(0.6)}
                >
                    <Image
                        fit="contain"
                        src="/twinlabs-ai-logo.svg"
                        alt="Twinlabs.ai"
                    />
                </div>
                <Label weight="semibold" className="text-sm">
                    TwinLabs.ai
                </Label>
                <ToolbarGroup className="ml-auto flex">
                    <div className="data-switch">
                        {authMetadata?.permissions?.has(
                            Permission.TWIN_EDITING,
                        ) ? (
                            <select
                                className="mode-select"
                                name="mode"
                                value={appMode}
                                onChange={e =>
                                    setAppModeWithFilterReset(
                                        parseInt(e.target.value),
                                    )
                                }
                            >
                                <option
                                    className="mode-select"
                                    value={AppMode.LIVE}
                                >
                                    Live
                                </option>
                                <option
                                    className="mode-select"
                                    value={AppMode.ANALYSIS}
                                >
                                    Analysis
                                </option>
                                <option
                                    className="mode-select"
                                    value={AppMode.EDIT}
                                >
                                    Edit
                                </option>
                            </select>
                        ) : (
                            <TLSwitchWithIcons
                                isSelected={appMode === AppMode.LIVE}
                                leftLabel="Analysis"
                                rightLabel="Live"
                                onChange={() => {
                                    if (appMode === AppMode.LIVE) {
                                        setAppModeWithFilterReset(
                                            AppMode.ANALYSIS,
                                        );
                                    } else {
                                        setAppModeWithFilterReset(
                                            AppMode.LIVE,
                                        );
                                    }
                                }}
                            />
                        )}
                        {appMode === AppMode.ANALYSIS &&
                            lastUpdatedAt && (
                                <p className="data-last-updated">
                                    Data last updated at{" "}
                                    {lastUpdatedAt}
                                </p>
                            )}
                    </div>
                    {setFullScreen && (
                        <Button
                            size="medium"
                            style={{ marginRight: "8px" }}
                            onClick={() => setFullScreen(!fullScreen)}
                            icon={<FullScreenMaximize16Regular />}
                        >
                            Full Screen
                        </Button>
                    )}
                    <Button
                        size="medium"
                        style={{ marginRight: "8px" }}
                        onClick={() =>
                            setSupportCopilotVisible(
                                !supportCopilotVisible,
                            )
                        }
                        icon={<CopilotLineIcon />}
                    >
                        MAR.I.A
                    </Button>
                    <div
                        style={{
                            width: "32px",
                            height: "32px",
                        }}
                        className="flex justify-center items-center"
                    >
                        <Avatar
                            size={20}
                            image={{
                                src: user?.picture
                                    ? user?.picture
                                    : undefined,
                            }}
                            name={user?.name}
                            aria-label={user?.name}
                        />
                    </div>
                </ToolbarGroup>
            </Toolbar>
        </div>
    );
};

export default styled(MainNav)`
    position: fixed;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 98%;
    z-index: 9;

    .data-switch {
        margin-right: 8px;
    }

    .data-last-updated {
        text-align: right;
        font-size: 9px;
        line-height: 100%;
        margin-top: 4px;
        padding: 0;
    }

    .mode-select {
        border-radius: 5px;
        font-weight: 600;
        margin-top: 1px;
        height: 30px;
        padding-left: 10px;
    }
`;
