/**
 * MapCanvas Component
 *
 * The MapCanvas component integrates Mapbox and Three.js to create interactive 3D elements within the Mapbox canvas context.
 * It utilizes the React-Map-GL library for Mapbox integration and React-Three-Map & React-Three-Fiber for Three.js integration.
 */

import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGl from "mapbox-gl";
import { Map } from "react-map-gl";
import { Canvas } from "react-three-map";
import Config from "../../common/Config";
import { LiveTwinScene, NewTwinScene } from "./TwinScene";
import { useTwinContext } from "../../common/contexts/TwinContext";
import { useEffect, useMemo, useRef, useState } from "react";
import { DebugGlInfoDiv } from "./helpers/DebugThreeGlInfo";
import { Point } from "@repo/backend-types";
import { useUserContext } from "../../common/contexts/UserContext";

type Props = {
    onAllModelsLoaded: () => void;
    allModelsLoaded: boolean; // not used, just triggers a rerender
};

function isEqual(point1: Point, point2: Point) {
    return (
        point1.latitude === point2.latitude &&
        point1.longitude === point2.longitude
    );
}

/**
 * MapCanvas
 *
 * The MapCanvas component integrates Mapbox and Three.js to create interactive 3D elements within the Mapbox canvas context.
 * It utilizes the React-Map-GL library for Mapbox integration and React-Three-Map & React-Three-Fiber for Three.js integration.
 */

const DEFAULT_MAP_ORIGIN = {
    latitude: 51.478091,
    longitude: -0.160382,
}; // in battersea park

const MapCanvas = ({ onAllModelsLoaded }: Props) => {
    // Access the Twin Context to retrieve twin model information.
    const { authMetadata } = useUserContext();
    const { twin } = useTwinContext();
    const infoRef = useRef<HTMLDivElement>(null); // just for debug
    const mapRef = useRef<any>(null); // to access internals of react-map-gl
    const [mapOrigin, setMapOrigin] = useState<Point | undefined>(
        undefined,
    );

    if (!authMetadata)
        throw new Error(
            "this wont happen, the router will navigate to login if authCreds are null",
        );

    // Assign Mapbox Auth token from the configuration.
    const mapboxToken = useMemo(() => {
        return authMetadata.tokens && authMetadata.tokens.mapboxToken
            ? authMetadata.tokens.mapboxToken
            : "";
    }, [authMetadata.tokens]);

    MapboxGl.accessToken = mapboxToken;

    // Detect device and browser capabilities for compatibility and performance optimizations.
    // WebGL2, VRAM, and potential future WebGPU build considerations.
    // const gl2 = WebGL.isWebGL2Available();

    // used to determine whether to render-on-demand or not
    const requiresAnimations =
        twin?.physicalModel.organisation?.name === "Movico";

    useEffect(() => {
        if (twin) {
            if (twin.physicalModel) {
                if (
                    twin.physicalModel.mapOrigin === undefined ||
                    twin.physicalModel.mapOrigin === null
                ) {
                    if (
                        twin.physicalModel.organisation?.name ===
                        "Howler"
                    ) {
                        // this is a temporary dummy location for howler (but it will only be used if mapOrigin is not present)
                        if (!mapOrigin)
                            setMapOrigin({
                                latitude: 41.4639,
                                longitude: 2.26,
                            });
                    } else {
                        throw new Error(
                            `top level TwinEntity with id ${twin.physicalModel.id} is missing mapOrigin`,
                        );
                    }
                } else {
                    if (
                        !mapOrigin ||
                        !isEqual(
                            mapOrigin,
                            twin.physicalModel.mapOrigin,
                        )
                    )
                        setMapOrigin(twin.physicalModel.mapOrigin);
                }
            }
        } else {
            // no twin means we're creating a new one
            if (!mapOrigin || !isEqual(mapOrigin, DEFAULT_MAP_ORIGIN))
                setMapOrigin(DEFAULT_MAP_ORIGIN);
        }
    }, [twin, mapOrigin, DEFAULT_MAP_ORIGIN]);

    if (!mapOrigin) {
        return null;
    } else {
        return (
            <>
                {Config.appProfile === "DEBUG" && (
                    <DebugGlInfoDiv infoRef={infoRef} />
                )}
                <div className="fixed top-0 left-0 w-lvw h-lvh opacity-100 z-[-1] pointer-events-auto">
                    {/* Check for Mapbox token and twin model existence. */}
                    {mapboxToken && (
                        <Map
                            ref={mapRef}
                            antialias
                            /*
                             * useWebGL2 is now the default for mapbox but we may want to use WebGL1 for older device/browser support.
                             * Additionally, consider potential future webGPU build.
                             * https://caniuse.com/webgl2 ( Global Support 95.89% ) 09/03/2024
                             * https://caniuse.com/webgpu ( Global Support 25.09% ) 09/03/2024
                             */
                            // useWebGL2={gl2}

                            /*
                             * Set MapBox style URL for the base map visualization style.
                             */
                            mapStyle="mapbox://styles/l0bster/clutu8ah7003301picweu0wqz"
                        >
                            {/*
                             * Use the React-Three-Map canvas component to render R3F 3D components.
                             */}
                            <Canvas
                                frameloop={
                                    requiresAnimations
                                        ? "always"
                                        : "demand"
                                }
                                latitude={mapOrigin.latitude}
                                longitude={mapOrigin.longitude}
                                overlay={true}
                                flat
                                shadows
                                performance={{ min: 0.3, max: 0.4 }}
                            >
                                {/* <axesHelper args={[100]}/> */}
                                {twin ? (
                                    <LiveTwinScene
                                        infoRef={infoRef}
                                        twin={twin}
                                        onAllModelsLoaded={
                                            onAllModelsLoaded
                                        }
                                        mapOrigin={mapOrigin}
                                    />
                                ) : (
                                    <NewTwinScene
                                        mapOrigin={mapOrigin}
                                    />
                                )}
                            </Canvas>
                        </Map>
                    )}
                </div>
            </>
        );
    }
};

export { MapCanvas };
