import { BoxGeometry, MeshStandardMaterial, Vector3 } from "three";
import { TwinEntity } from "@repo/backend-types";
import { TwinEntityType } from "../../../../@types/TwinEntityType";
import { useMemo } from "react";
import { Operator } from "./Operator";
import { Capacity } from "../../behaviours/Capacity";

type Props = {
    entity: TwinEntity;
    idx: number;
    lineage: TwinEntity[];
    mapDiv: HTMLDivElement;
    boxDepth: number;
    boxWidth: number;
    operatorWidth: number;
    gapRatio: number;
    position: [number, number, number];
};

const material = new MeshStandardMaterial({
    color: "blue",
    transparent: true,
    opacity: 0,
    depthWrite: false,
});

export function Station({
    entity,
    lineage,
    mapDiv,
    boxDepth,
    boxWidth,
    operatorWidth,
    position,
    gapRatio,
}: Props) {
    const positionVector = useMemo(
        () => new Vector3(position[0], position[1], position[2]),
        [position],
    );

    const geometry = useMemo(() => {
        const boxGeometry = new BoxGeometry(
            boxWidth,
            boxDepth,
            boxDepth,
        );
        boxGeometry.computeBoundingBox();
        return boxGeometry;
    }, [boxDepth, boxWidth]);

    return (
        <mesh
            geometry={geometry}
            material={material}
            position={positionVector}
        >
            <Capacity
                mapDiv={mapDiv}
                lineage={lineage}
                entity={entity}
                geometry={geometry}
                entityType={TwinEntityType.STATION}
                labelBoundingBox={geometry.boundingBox!} // N.B. the geometry's bounding box should have been computed already
                topLevelType={TwinEntityType.SITE}
            />
            {entity.children?.map((child, idx) => {
                return (
                    <Operator
                        key={idx}
                        idx={idx}
                        entity={child}
                        lineage={[...lineage, entity]}
                        mapDiv={mapDiv}
                        boxWidth={operatorWidth}
                        parentBoxWidth={boxWidth}
                        gapRatio={gapRatio}
                        containerDepth={boxDepth}
                    />
                );
            })}
        </mesh>
    );
}
