/*
 * createFilterPath
 * Utilises findAncestors to find all the ancestors related to an entityId
 * Then returns them as an array of filterPaths
 *
 */

// Types
import { Entity } from "../../@types/Entity";
import { FilterPath } from "../../@types/FilterPath";
import { FilterType } from "../../@types/FilterType";

// Utils
import { findAncestors } from "./findAncestors";

export const createFilterPath = (entityId: string, model: Entity) => {
    let filterPaths: FilterPath[] = [];
    const ancestors: Entity[] = findAncestors(entityId, model);

    ancestors.forEach(anc => {
        let fp: FilterPath = {
            id: anc.id,
            bId: anc.bID,
            type: FilterType.ENTITY,
            label: anc.name,
            display: true,
        };
        filterPaths.push(fp);
    });

    return filterPaths;
};

export const createFilterPathFromLineage = (lineage: Entity[]) => {
    let filterPaths: FilterPath[] = [];

    lineage.forEach(anc => {
        let fp: FilterPath = {
            id: anc.id,
            bId: anc.bID,
            type: FilterType.ENTITY,
            label: anc.name,
            display: true,
        };
        filterPaths.push(fp);
    });

    return filterPaths;
};
