import {
    Bloom,
    EffectComposer,
    N8AO,
    SMAA,
} from "@react-three/postprocessing";
import { ScreenBlend } from "./ScreenBlendEffect";
import { Suspense } from "react";

export const PostProcesses = () => {
    return (
        <Suspense fallback={null}>
            <EffectComposer multisampling={0}>
                <Bloom
                    luminanceThreshold={1} // N.B. materials with colors that exceed this luminace threshold will glow
                    intensity={0.5}
                    radius={0.5}
                    mipmapBlur
                />
                <N8AO
                    aoRadius={30}
                    intensity={1}
                    screenSpaceRadius
                    halfRes
                    distanceFalloff={0.2}
                    quality="performance"
                />
                <ScreenBlend />
                <SMAA />
            </EffectComposer>
        </Suspense>
    );
};
