/*
 * timeSeriesQuery
 * Time Series queries are similar
 *
 */
import { TIME_SERIES_RANGE_QUERY } from "./gql/timeSeriesRangeQuery";

// Utils
import createTimeseriesApolloClient from "../../utils/createTimeseriesApolloClient";
import axios from "axios";
import Config from "../../Config";

export const timeSeriesRangeQuery = async (
    timeSeriesURL: string,
    query: string,
    startDateTime: string,
    endDateTime: string,
    step: string = "15m",
) => {
    try {
        const { data } = await createTimeseriesApolloClient(
            timeSeriesURL,
        ).query({
            query: TIME_SERIES_RANGE_QUERY,
            variables: {
                query: query,
                startTime: startDateTime,
                endTime: endDateTime,
                step,
            },
        });
        return data.rangeQuery.result;
    } catch (error) {
        return null; // Return null for now. Might wish to log errors here in future
    }
};

export const getCalendarData = async (
    startDateTime: string,
    endDateTime: string,
    aggregation: string,
    step: string = "15m",
    organisation: string,
    metric?: string,
    metricType?: string,
    digitalTwinEntity?: string,
    digitalTwinEntityPath?: string,
    offset?: string,
) => {
    try {
        const { data } = await axios.get(
            `${Config.middlewareApi}api/analytics/calendar`,
            {
                headers: {
                    "Content-Type": "application/json",
                    // 'Authorization': `Bearer ${accessToken}`, // TODO: Pass accessToken or configure axios instance with it
                },
                params: {
                    start_time: startDateTime,
                    end_time: endDateTime,
                    aggregation,
                    step,
                    organisation,
                    metric,
                    metricType,
                    digitalTwinEntity,
                    digitalTwinEntityPath,
                    offset,
                },
            },
        );
        return data.result;
    } catch (error) {
        return null;
    }
};
