import { TwinEntity } from "@repo/backend-types";

type NameMapping = { [key: string]: TwinEntity[] };

// note this sort IN-PLACE
export function sortMappings(mappings: [string, TwinEntity[]][]) {
    mappings.sort((entranceA, entranceB) => {
        const [_A, stationsA] = entranceA;
        const [_B, stationsB] = entranceB;
        return stationsA.length - stationsB.length;
    });

    return mappings;
}

export function allocateEntrances(
    stations: TwinEntity[],
    entrances: string[],
): NameMapping {
    // setup empty object to populate
    const mapping: NameMapping = {};

    // fill out the keys from entrance names
    for (const entrance of entrances) {
        mapping[entrance] = [];
    }

    const unmappedStations: TwinEntity[] = [];

    // lookup entrance mapping in each station and assign in map according
    for (const station of stations) {
        let mappingFound = false;
        const stationTags = station.tags;

        if (
            stationTags !== undefined &&
            stationTags !== null &&
            stationTags.length > 0
        ) {
            const stationEntranceMapping = stationTags.find(tag =>
                tag.includes("entrance-name-"),
            );
            // found an entrance name mapping in tags
            if (stationEntranceMapping) {
                const entranceName =
                    stationEntranceMapping.split("-")[2];
                if (entranceName in mapping) {
                    mappingFound = true;
                    mapping[entranceName].push(station);
                } else {
                    console.log(
                        `invalid entrance name found in tags. Bad name: ${entranceName}`,
                    );
                }
            }
        }

        if (!mappingFound) unmappedStations.push(station);
    }

    // take the unmappedStations and distribute them
    const entrancesLeastFirst = sortMappings(Object.entries(mapping));

    // add unmapped stations to entrances, least first, checking order after each addition
    for (const station of unmappedStations) {
        // put it into the entrance with least stations
        const [_, smallestStationCollection] = entrancesLeastFirst[0];
        smallestStationCollection.push(station);

        // re-sort entrances
        sortMappings(entrancesLeastFirst);
    }

    const finalMapping = Object.fromEntries(entrancesLeastFirst);

    return finalMapping;
}
