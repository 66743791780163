import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Context
import { useSettingsContext } from "../../common/contexts/SettingsContext";
import { useUserContext } from "../../common/contexts/UserContext";

// Utils
import { capitaliseFirstLetter } from "../../common/utils/capitaliseFirstLetter";

// Components
import AI from "../AI/AI";
import DraggablePanel from "../DraggablePanel/DraggablePanel";
import MariaHeader from "./MariaHeader";

interface Props {
    setSupportCopilotVisible: (value: boolean) => void;
}

const Maria: React.FC<Props> = ({ setSupportCopilotVisible }) => {
    const { user } = useAuth0();
    const { settings } = useSettingsContext();
    const { authMetadata } = useUserContext();

    const [isHeaderHovered, setIsHeaderHovered] = useState(false);

    return (
        <DraggablePanel
            headerElement={
                <MariaHeader
                    setIsHeaderHovered={setIsHeaderHovered}
                    setSupportCopilotVisible={
                        setSupportCopilotVisible
                    }
                />
            }
            isHeaderHovered={isHeaderHovered}
        >
            <AI
                apiEndpoint={
                    authMetadata?.aiUrl ? authMetadata?.aiUrl : ""
                }
                apiKey={authMetadata?.tokens?.accessToken}
                openingMessage={{
                    content: `Hello ${user?.name?.split(" ")[0] ? `${capitaliseFirstLetter(user?.name?.split(" ")[0])},` : ","} my name is MAR.I.A, I'm new and I'm still learning. How can I help you today? Please just ask${settings?.maria?.introPrompts && settings?.maria?.introPrompts.length > 0 ? ` or use some of the ideas below to help you get started` : ``}.`,
                    response: true,
                    prompts: settings?.maria?.introPrompts,
                }}
                maxHeight={"calc(100vh - 210px)"}
                dynamicHeight
            />
        </DraggablePanel>
    );
};

export default Maria;
