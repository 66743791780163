/*
 * DataContext
 *
 */
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { FetchResult } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { DateTime } from "luxon";
import Config from "../Config";

// Types
import { DataMode } from "../../@types/DataMode";
import { Metric } from "@repo/backend-types";
import { Entity } from "../../@types/Entity";
import { Filter } from "../../@types/Filter";
import { Data } from "../../@types/Data/Data";
import { TSMetricData } from "../../@types/Data/TSMetricData";
import { SortedDataValue } from "../../@types/Data/SortedDataValue";

// Context
import {
    useFilterContext,
    useModeContext,
} from "./FilterAndModeContexts";
import { useSettingsContext } from "./SettingsContext";
import { useTwinContext } from "./TwinContext";
import { useUserContext } from "./UserContext";

// Data
import { QUERY_METRIC_VALUE } from "../api/live/gql/queryMetricValue"; // Returns Live Metric (Sensor) Data (e.g. Headcount)
import { tsMetricData } from "../api/timeseries/tsMetricData";

// Utils
import { calculateUsage } from "../utils/calculateUsage";
import { getDifferenceBetweenDates } from "../utils/getDifferenceBetweenDates";
import { mapLiveSubscriptionData } from "../utils/mappers/mapLiveSubscriptionData";
import { mapTimeSeriesData } from "../utils/mappers/mapTimeSeriesData";
import { sortDataValuesByType } from "../utils/sortDataByType";
import { findEntityById } from "../utils/findEntityById";
import { getCurrentTime } from "../utils/getCurrentTime";
import { AppMode } from "../../@types/Mode";

interface DataContextValue {
    fetchLiveData: boolean;
    fetchTimeSeriesData: boolean;
    filter: Filter[];
    data: Data; // Can hold Live or Time Series data depending on the app being in a live state
    liveData: FetchResult<any> | null; // Live data populated by subscription/web socket.
    warning: boolean; // A flag to allow us to display warnings about the data integrity
    setData: (data: any[]) => void;
    setLiveData: (data: any[]) => void;
}

const initialState: DataContextValue = {
    fetchLiveData: true,
    fetchTimeSeriesData: false,
    filter: [],
    data: { raw: [], processed: null },
    liveData: null,
    warning: false,
    setData: () => {},
    setLiveData: () => {},
};

export const DataContext =
    createContext<DataContextValue>(initialState);

export const useDataContext = (): DataContextValue => {
    return useContext(DataContext);
};
interface ContextProviderProps {
    children: React.ReactNode;
}

export const DataContextProvider: React.FC<
    ContextProviderProps
> = props => {
    const apolloClient = useApolloClient();
    const { settings, metrics } = useSettingsContext();
    const { twin } = useTwinContext();
    const { authMetadata } = useUserContext();

    const {
        heroMetric,
        filter,
        startDateTime,
        finishDateTime,
        setLastUpdatedAt,
    } = useFilterContext();
    const { appMode } = useModeContext();
    const [dataState, setDataState] =
        useState<DataContextValue>(initialState);
    const timeZone = settings?.timeZone
        ? settings.timeZone
        : Intl.DateTimeFormat().resolvedOptions().timeZone;

    const prevFilterContextRef = useRef({ filter });

    const setData = useCallback((data: Data) => {
        setDataState(prevState => ({ ...prevState, data }));
    }, []);

    const setLiveData = useCallback((liveData: FetchResult<any>) => {
        setDataState(prevState => ({ ...prevState, liveData }));
    }, []);

    const setWarning = useCallback((warning: boolean) => {
        setDataState(prevState => ({ ...prevState, warning }));
    }, []);

    /*
     * Manually add usage metric to time series processedData
     * WARNING/REGRET: Transversing the twin multiple times to get the capacity figure for each entity is quite expensive.
     * I'm also not keen on consuming the TwinContext within the DataContext, as I think this will lead to uncessary re-rendering esp. with Howler.
     * This should be a very temporary measure only.
     *
     */
    const calcUsageMetrics = useCallback(
        (processedData: SortedDataValue, key: string) => {
            let usageMetrics: {
                entityId?: string;
                bID?: string | undefined;
                bIDPath?: string | undefined;
                timestamp: string;
                value: number;
                unit?: string;
            }[] = [];

            if (
                processedData &&
                processedData[key] &&
                processedData[key].length > 0
            ) {
                if (twin?.physicalModel) {
                    processedData[key].forEach(ce => {
                        if (ce.entityId) {
                            // Live
                            let entity: Entity | null =
                                findEntityById(
                                    DataMode.LIVE,
                                    [twin.physicalModel],
                                    ce.entityId,
                                );

                            if (entity) {
                                let capacity = entity["capacity"];

                                usageMetrics.push({
                                    entityId: ce.entityId,
                                    bID: ce.bID,
                                    bIDPath: ce.bIDPath,
                                    timestamp: ce.timestamp,
                                    value:
                                        ce.value > 0
                                            ? calculateUsage(
                                                  ce.value,
                                                  capacity,
                                              )
                                            : 0,
                                    unit: "%",
                                });
                            }
                        } else if (ce.bID) {
                            // Time Series
                            let entity: Entity | null =
                                findEntityById(
                                    DataMode.TIME_SERIES,
                                    [twin.physicalModel],
                                    ce.bID,
                                );

                            if (entity) {
                                let capacity = entity["capacity"];

                                usageMetrics.push({
                                    bID: ce.bID,
                                    bIDPath: ce.bIDPath,
                                    timestamp: ce.timestamp,
                                    value:
                                        ce.value > 0
                                            ? calculateUsage(
                                                  ce.value,
                                                  capacity,
                                              )
                                            : 0,
                                    unit: "%",
                                });
                            }
                        }
                    });
                }
            }
            return usageMetrics;
        },
        [twin?.physicalModel],
    );

    /*
     * Parse the live metric data and map to ensure it's in the right shape
     * to store in the data context
     *
     */
    const parseLiveData = useCallback(
        (liveData: FetchResult<any>, metrics: Metric[]): Data => {
            const mappedData = mapLiveSubscriptionData(
                liveData.data.queryMetricValue,
                metrics,
            );

            // Used to help catch live data issues
            const showWarning = Config.metricDataWarning
                ? Config.metricDataWarning
                : false;
            if (showWarning) {
                setWarning(mappedData.warning);
            }

            // Sort data into 'buckets' of metric data
            let processedData = sortDataValuesByType(
                mappedData.data,
                metrics,
            );

            // Add usage metric to processedData
            let usageMetrics = calcUsageMetrics(
                processedData,
                "countEntity",
            );

            /* @ts-ignore */
            processedData["usage"] = usageMetrics;

            console.log(`Live Processed Data`, processedData);

            return {
                raw: liveData.data.queryMeasure,
                processed: processedData,
            };
        },
        [setWarning, calcUsageMetrics],
    );

    /*
     * Parse the time series metric data and map to ensure it's in the right shape
     * to store in the data context
     *
     */
    const parseTimeSeriesData = useCallback(
        (timeSeriesData: TSMetricData, metrics: Metric[]): Data => {
            // Extract value and set to DataValue.value
            const mappedData = mapTimeSeriesData(
                timeSeriesData,
                metrics,
            );

            // Sort data into 'buckets' of metric data
            let processedData = sortDataValuesByType(
                mappedData,
                metrics,
            );

            // Add usage metric to processedData
            let usageMetrics = calcUsageMetrics(
                processedData,
                "countEntity",
            );

            /* @ts-ignore */
            processedData["usage"] = usageMetrics;

            const currentTime = getCurrentTime();
            setLastUpdatedAt(
                `${currentTime.substring(0, currentTime.lastIndexOf(":"))}`,
            );

            console.log(`Time Series Processed Data`, processedData);
            return { raw: timeSeriesData, processed: processedData };
        },
        [calcUsageMetrics, setLastUpdatedAt],
    );

    /*
     * Fetch Time Series Data and utilise filters from filterContext
     *
     */
    const fetchTimeSeriesData =
        useCallback(async (): Promise<TSMetricData> => {
            let timeSeriesData: TSMetricData = {};

            // Call Time series endpoint and utilise the filter[] prop within filterContext as params
            if (!startDateTime) {
                throw new Error(
                    "fetchTimeSeriesData is missing value for startDateTime",
                );
            }

            if (!finishDateTime) {
                throw new Error(
                    "fetchTimeSeriesData is missing value for finishDateTime",
                );
            }

            const startDateTimeQuery =
                DateTime.fromISO(startDateTime, { zone: timeZone })
                    .toUTC()
                    .toISO() || "";
            const finishDateTimeQuery =
                DateTime.fromISO(finishDateTime, { zone: timeZone })
                    .toUTC()
                    .toISO() || "";

            if (
                settings &&
                settings.organisation &&
                authMetadata &&
                startDateTimeQuery &&
                finishDateTimeQuery
            ) {
                let aggregation = heroMetric?.timeSeries?.aggregation;
                let metric = heroMetric?.metric;
                let digitalTwinEntity = undefined;
                let digitalTwinEntityPath = `${twin?.physicalModel.bID}.*`;

                // WARNING - CLIENT SPECIFIC CODE: We override the USAGE hero metric here as we need to use the countEntity metric
                // SOLUTION: Start calculating the usage in the back-end for time series. We can then stop switching to the countMetric.
                if (metric === "usage") {
                    metric = "countEntity";
                }

                if (!aggregation || !metric) {
                    throw new Error(
                        "Hero metric is missing for DataContext",
                    );
                }

                if (!authMetadata.tsdbUrl) {
                    throw new Error("Missing analytics endpoint");
                }

                let step = getDifferenceBetweenDates(
                    startDateTimeQuery,
                    finishDateTimeQuery,
                    "seconds",
                );
                timeSeriesData = await tsMetricData(
                    authMetadata?.tsdbUrl,
                    startDateTimeQuery,
                    finishDateTimeQuery,
                    aggregation,
                    `${step}s`,
                    settings.organisation,
                    digitalTwinEntity,
                    digitalTwinEntityPath,
                );
            }

            return timeSeriesData;
        }, [
            authMetadata,
            heroMetric,
            startDateTime,
            finishDateTime,
            settings,
            twin?.physicalModel.bID,
        ]);

    /*
     * For Live Mode
     *
     */
    useEffect(() => {
        const getLiveData = async () => {
            let data;
            if (appMode === AppMode.LIVE) {
                if (dataState.liveData) {
                    data = parseLiveData(dataState.liveData, metrics);
                    setData(data);
                }
            }
        };

        // Ensure the metrics data has been fetched
        if (metrics && metrics.length > 0) {
            getLiveData();
        }

        prevFilterContextRef.current.filter = filter;
    }, [
        dataState.liveData,
        filter,
        metrics,
        appMode,
        parseLiveData,
        setData,
    ]);

    /*
     * For Time Series (offline) Mode
     *
     */
    useEffect(() => {
        const getTimeSeriesData = async () => {
            let data;
            if (appMode === AppMode.ANALYSIS) {
                const timeSeriesdata = await fetchTimeSeriesData();
                data = parseTimeSeriesData(timeSeriesdata, metrics);
                setData(data);
            }
        };

        // Ensure the metrics data has been fetched
        if (metrics && metrics.length > 0) {
            getTimeSeriesData();
        }
    }, [
        filter,
        appMode,
        metrics,
        fetchTimeSeriesData,
        parseTimeSeriesData,
        setData,
        setLiveData,
    ]);

    /*
     * LIVE Subscription to GraphQL Headcount Measure Data
     * This continually runs in the background regardless of the Live/Time series state
     *
     */
    useEffect(() => {
        const subscribeToLiveData = () => {
            apolloClient
                .subscribe({ query: QUERY_METRIC_VALUE })
                .subscribe({
                    next(liveData) {
                        setLiveData(liveData);
                    },
                    error(error) {
                        console.error("Subscription error:", error);
                    },
                });
        };
        subscribeToLiveData();
    }, [apolloClient, setLiveData]);

    const contextValue = useMemo(
        () => ({
            ...dataState,
        }),
        [dataState],
    );

    return (
        <DataContext.Provider value={contextValue}>
            {props.children}
        </DataContext.Provider>
    );
};
