/*
 * tsRangeQuery
 * This is a wrapper for the timeSeriesRangeQuery function
 *
 */

// Utils
import { timeSeriesRangeQuery } from "./timeSeriesRangeQuery";

export const tsRangeQuery = async (
    timeSeriesURL: string,
    startDateTime: string,
    endDateTime: string,
    aggregation: string,
    step: string = "15m",
    organisation: string,
    metric?: string,
    metricType?: string,
    digitalTwinEntity?: string,
    digitalTwinEntityPath?: string,
    offset?: string,
) => {
    let by = ``;
    let filterBy = ``;
    let offsetBy = ``;

    if (metric) {
        by = `digitalTwinEntity, digitalTwinEntityPath, metric`;
        filterBy = filterBy + `metric='${metric}'`;
    }

    if (metricType) {
        by = `metricType`;
        filterBy =
            filterBy +
            `${filterBy.length > 0 ? `,` : ``}metricType='${metricType}'`;
    }

    if (organisation) {
        filterBy =
            filterBy +
            `${filterBy.length > 0 ? `,` : ``}organisation='${organisation}'`;
    }

    if (digitalTwinEntity) {
        filterBy =
            filterBy +
            `${filterBy.length > 0 ? `,` : ``}digitalTwinEntity='${digitalTwinEntity}'`;
    }

    if (digitalTwinEntityPath) {
        filterBy =
            filterBy +
            `${filterBy.length > 0 ? `,` : ``}digitalTwinEntityPath=~'${digitalTwinEntityPath}'`;
    }

    if (offset) {
        offsetBy = `offset ${offset}`;
    }

    const query = `${aggregation} by(${by}) (${aggregation}_over_time(metricvalue{${filterBy}}[${step}] ${offsetBy}))`;
    const result = await timeSeriesRangeQuery(
        timeSeriesURL,
        query,
        startDateTime,
        endDateTime,
        step,
    );
    // UNCOMMENT TO USE CALENDAR DATA ENDPOINT
    // ACCESS TOKEN IS NOT PASSED, PLEASE ADD IT (OR CONFIGURE AN AXIOS INSTANCE THAT ALREADY HAS IT)
    // const result = await getCalendarData(startDateTime, endDateTime, aggregation, step, organisation, metric, metricType, digitalTwinEntity, digitalTwinEntityPath, offset);
    return result;
};
