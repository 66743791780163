// Templates
import { eventTwinTemplate } from "../../../twin-templates/eventTwinTemplate";

// Types
import { TwinTemplate } from "../../../../../@types/Settings/TwinTemplate";

const twinPrefs: Partial<TwinTemplate> = {
    organisation: "Howler",
    timeZone: "Europe/London",
};

export const digitalTwinPrefs = {
    ...eventTwinTemplate,
    ...twinPrefs,
};
