export const hexToRgb = (hex: string) => {
    // Remove the '#' if it's there
    hex = hex.replace(/^#/, "");

    // Parse the hex color into RGB components
    let r, g, b;
    if (hex.length === 3) {
        // Convert shorthand hex (e.g., #abc) to full form (e.g., #aabbcc)
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    } else {
        throw new Error("Invalid HEX color.");
    }

    return `${r}, ${g}, ${b}`;
};
