/*
 * generateCalendarMonth
 * Used to create the data required for the CalendarMonthView component
 *
 */
import { formatDateToISO8601 } from "./formatDateToISO8601";
import { getISOWeekNumber } from "./getISOWeekNumber";

export const generateCalendarMonth = (date: Date) => {
    const daysOfWeek = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
    ];
    const result = [];
    const year = date.getFullYear();
    const month = date.getMonth();

    // Get the first day of the month
    const firstDay = new Date(year, month, 1);

    // Get the day of the week of the first day (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
    const firstDayOfWeek = firstDay.getDay();

    // Calculate the offset to Monday (if firstDay is not Monday)
    const offsetToMonday =
        firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

    // Adjust the first day to be Monday
    firstDay.setDate(firstDay.getDate() - offsetToMonday);

    // Get the last day of the month
    const lastDay = new Date(year, month + 1, 0);

    // Calculate the number of days in the month
    const numDays = lastDay.getDate();

    let currentWeekNo = getISOWeekNumber(firstDay);
    let weekDays = [];

    // Loop through each day of the month and potentially some days from the next month
    for (
        let i = -offsetToMonday;
        i < numDays + (7 - lastDay.getDay());
        i++
    ) {
        const currentDate = new Date(year, month, i + 1);
        const currentWeekDay = currentDate.getDay();

        // Format the date as "dd/mm/yyyy"
        const formattedDate = currentDate.toLocaleDateString(
            "en-GB",
            { day: "2-digit", month: "2-digit", year: "numeric" },
        );

        // Push the day object into the weekDays array
        weekDays.push({
            date: currentDate,
            dateString: formatDateToISO8601(currentDate),
            tableString: `${daysOfWeek[currentWeekDay].toLowerCase()}-${formattedDate.replace(/\//g, "-")}`,
            count: 0,
        });

        // If it's Sunday or the last day of the month, push the week into the result
        if (
            currentDate.getMonth() === month &&
            (currentDate.getDay() === 0 || i === numDays - 1) &&
            weekDays.length === 7
        ) {
            result.push({
                weekNo: currentWeekNo,
                days: weekDays,
            });
            weekDays = [];
            currentWeekNo++;
        }

        // If it's the last day of the month and the week hasn't been completed, push the week into the result
        if (i === numDays - 1 && weekDays.length > 0) {
            result.push({ weekNo: currentWeekNo, days: weekDays });
        }
    }

    return result;
};
