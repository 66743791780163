import React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";

interface Props {
    className?: string;
}

const LegalLayout: React.FC<Props> = ({ className }) => {
    return (
        <div
            className={className}
            style={{
                backgroundImage: 'url("/assets/images/atom-bg.png")',
                backgroundSize: "cover",
            }}
        >
            <main className="legal-layout">
                <div className="legal-header">
                    <button className="btn-back">Go back</button>
                </div>
                <div className="legal-panel">
                    <Outlet />
                </div>
            </main>
        </div>
    );
};

export default styled(LegalLayout)`
    .legal-header {
        margin: 0 auto 0.5rem auto;
        width: 60%;
    }

    .legal-header .btn-back {
        background: #ffffff;
        border: 1px #cccccc solid;
        color: #2d2d2d;
        width: 120px;
        padding: 0.25rem;
    }

    .legal-layout {
        display: flex;
        flex-direction: column;
        height: 100vh;
        padding: 2rem;
    }

    .legal-panel {
        margin: 0 auto;
        width: 60%;
        background: #fcfcfb;
        border: 1px #cccccc solid;
        border-radius: 18px; /* Standard */
        -webkit-border-radius: 18px; /* For older Safari and Chrome */
        -moz-border-radius: 18px; /* For older Firefox */
        padding: 4rem;
        height: 90vh;
        overflow-y: scroll;
    }
`;
