// Components
import { MapCanvas } from "../3D/MapCanvas";
import { ErrorBoundary } from "./ErrorBoundary";

interface Props {
    className?: string;
    onAllModelsLoaded: () => void;
    allModelsLoaded: boolean;
}

// N.B. the error boundary sets all models loaded on error so that the
// loading page clears and the FallBackUI can be seen
const The3DScene = ({
    className,
    allModelsLoaded,
    onAllModelsLoaded,
}: Props) => {
    return (
        <div className={className}>
            <ErrorBoundary onError={onAllModelsLoaded}>
                <MapCanvas
                    allModelsLoaded={allModelsLoaded}
                    onAllModelsLoaded={onAllModelsLoaded}
                />
            </ErrorBoundary>
        </div>
    );
};

export default The3DScene;
