import { Roles } from "../../@types/Auth0/Roles";

export const formatRoles = (roles: string[]) => {
    let formattedRoles: string[] = [];
    formattedRoles = roles.map(role => {
        return formatRole(role);
    });
    return formattedRoles;
};

const formatRole = (role: string) => {
    let formattedRole = "";
    switch (role) {
        case Roles.CUSTOMER_ADMIN:
            formattedRole = "Admin";
            break;
        case Roles.CUSTOMER_USER:
            formattedRole = "User";
            break;
        case Roles.PARTNER_USER:
            formattedRole = "Partner";
            break;
        case Roles.TL_ADMIN:
            formattedRole = "TL Admin";
            break;
        default:
            formattedRole = "";
            break;
    }
    return formattedRole;
};
