import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { DateTime } from "luxon";

// Types
import { DataMode } from "../../@types/DataMode";
import { DateTimeSensorCount } from "../../@types/DateTimeSensorCount";
import { FilterType } from "../../@types/FilterType";
import { IndicatorColors } from "../../@types/Settings/IndicatorColors";
import { TSHourlySensorCount } from "../../@types/TSHourlySensorCount";
import { SelectionBoxPosition } from "../../@types/SelectionBoxPosition";
import { SelectedCells } from "../../@types/SelectedCells";
import { SelectedCellValue } from "../../@types/SelectedCellValue";
import { WeekDay } from "../../@types/WeekDay";

// Context
import {
    useFilterContext,
    useModeContext,
} from "../../common/contexts/FilterAndModeContexts";
import { useSettingsContext } from "../../common/contexts/SettingsContext";
import { useTimelineCalendarContext } from "../../common/contexts/TimelineCalendarSync";
import { useTwinContext } from "../../common/contexts/TwinContext";
import { useUserContext } from "../../common/contexts/UserContext";

// Data
import { tsRangeQuery } from "../../common/api/timeseries/tsRangeQuery";

// Utils
import { createHourObjects } from "../../common/utils/createHourObjects";
import { findEntityById } from "../../common/utils/findEntityById";
import { findObjectByPropertyValue } from "../../common/utils/findObjectByPropertyValue";
import { findMinMaxDates } from "../../common/utils/findMinAndMaxDates";
import { formatDateToISO8601 } from "../../common/utils/formatDateToISO8601";
import { getIndicatorColor } from "../../common/utils/getIndicatorColor";
import { generateHourlyTimestamps } from "../../common/utils/generateHourTimestamps";
import { mapTimeseriesSensorDataToDateTime } from "../../common/utils/mappers/mapTimeseriesSensorDataToDateTime";
import { prependZero } from "../../common/utils/prependZero";

// Component
import CalendarSelectionOverlay from "./CalendarSelectionOverlay";
import CalendarWeekChart from "./CalendarWeekChart";
import WeekStepper from "./WeekStepper";
import { AppMode } from "../../@types/Mode";

interface Props {
    className?: string;
}

const CalendarWeekView: React.FC<Props> = ({ className }) => {
    const {
        analysisReset,
        filter,
        heroMetric,
        timeReset,
        setStartDateTime,
        setFinishDateTime,
        setTimeReset,
        setAnalysisReset,
    } = useFilterContext();
    const { setAppModeWithFilterReset } = useModeContext();
    const { setCalendarDateRange } = useTimelineCalendarContext();
    const { settings } = useSettingsContext();
    const { twin } = useTwinContext();
    const { authMetadata } = useUserContext();

    const [renderCalendar, setRenderCalendar] = useState(false);
    const timeZone = settings?.timeZone
        ? settings.timeZone
        : Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [weekViewDateTime, setWeekViewDateTime] = useState<string>(
        DateTime.now().startOf("week").toISO(),
    );
    const [theWeek, setTheWeek] = useState<WeekDay[]>([]);
    const [refreshSelectedCellData, setRefreshSelectedCellData] =
        useState(false);

    const setCalendarDateTime = (
        startDateTimeISO: string,
        finishDateTimeISO: string,
    ) => {
        setWeekViewDateTime(startDateTimeISO);
        setCalendarDateRange(startDateTimeISO, finishDateTimeISO);
        resetSelection();
    };

    // We need the total capacity of the twin/site to enable us
    // to calculate usage for a date or time
    let totalCap = 0;
    if (twin && twin.totalCapacity) {
        totalCap = twin.totalCapacity;
    }

    if (totalCap === 0) {
        throw new Error(
            "Unable to render calendar. Digital Twin is missing total capacity",
        );
    }

    const digitalTwinEntity = useMemo(() => {
        let entity = twin?.physicalModel.bID;

        const physicalEntityFilters = filter.filter(
            item => item.type === FilterType.ENTITY,
        );

        if (
            physicalEntityFilters &&
            physicalEntityFilters.length > 0
        ) {
            entity =
                physicalEntityFilters[
                    physicalEntityFilters.length - 1
                ].bId;
        }

        return entity;
    }, [twin, filter]);

    const entityCapacity = useMemo(() => {
        let capacity = 0;
        if (twin && digitalTwinEntity) {
            let entity = findEntityById(
                DataMode.TIME_SERIES,
                [twin.physicalModel],
                digitalTwinEntity,
            );
            capacity = entity ? entity["capacity"] : 0;
        }
        return capacity;
    }, [digitalTwinEntity, twin]);

    // Decide the length of the calendar week view (i.e. hour range)
    // The default is midnight until 23:00
    let weeklyCalStartTime = "00:00";
    let weeklyCalFinishTime = "23:00";

    if (settings && settings.calendar) {
        weeklyCalStartTime = settings?.calendar.weekView.hourRange
            .startTime
            ? settings?.calendar.weekView.hourRange.startTime
            : weeklyCalStartTime;
        weeklyCalFinishTime = settings?.calendar.weekView.hourRange
            .finishTime
            ? settings?.calendar.weekView.hourRange.finishTime
            : weeklyCalFinishTime;
    }

    const hoursData = createHourObjects(
        weeklyCalStartTime,
        weeklyCalFinishTime,
    );

    const generateCalendarWeek = useCallback(() => {
        const daysOfWeek = [
            "Sun",
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat",
        ];

        let week: WeekDay[] = [];

        for (let i = 0; i < 7; i++) {
            if (weekViewDateTime) {
                let weekDay = DateTime.fromISO(weekViewDateTime)
                    .plus({ days: i })
                    .toJSDate();

                // Format the date
                let cellDate = `${daysOfWeek[weekDay.getDay()].toLowerCase()}-${(weekDay.getDate() < 10 ? "0" : "") + weekDay.getDate()}-${(weekDay.getMonth() + 1 < 10 ? "0" : "") + (weekDay.getMonth() + 1)}-${weekDay.getFullYear()}`;

                let hours: TSHourlySensorCount[] = [];

                // Push the object into the week
                week.push({
                    dateObj: weekDay,
                    weekDay:
                        daysOfWeek[weekDay.getDay()].toLowerCase(),
                    dateISO: formatDateToISO8601(weekDay),
                    date: weekDay.getDate(),
                    cellDate: cellDate,
                    hours: hours,
                    activeHours: 0,
                });
            }
        }
        return week;
    }, [weekViewDateTime]);

    const updateHourlyCount = (
        dateToUpdate: string,
        hours: TSHourlySensorCount[],
        activeHours: number,
    ) => {
        setTheWeek(prevCalendarWeek => {
            return prevCalendarWeek.map(day => {
                if (day.dateISO === dateToUpdate) {
                    return {
                        ...day,
                        hours: hours,
                        activeHours: activeHours,
                    };
                } else {
                    return day; // Keep other days unchanged
                }
            });
        });
    };

    // Build the calendar for the week
    useEffect(() => {
        if (authMetadata?.tsdbUrl && weekViewDateTime) {
            const fetchCalendarData = async (
                startDateTime: string,
                finishDateTime: string,
            ) => {
                // Get week view range using a combination of startDate and finishDate and the time zone the twin is located in
                const queryStartDate =
                    DateTime.fromISO(startDateTime, {
                        zone: timeZone,
                    })
                        .toUTC()
                        .toISO() || "";
                const queryFinishDate =
                    DateTime.fromISO(finishDateTime, {
                        zone: timeZone,
                    })
                        .endOf("day")
                        .toUTC()
                        .toISO() || "";

                if (
                    settings &&
                    settings.organisation &&
                    queryStartDate &&
                    queryFinishDate
                ) {
                    let aggregation =
                        heroMetric?.timeSeries?.aggregation;
                    let metric = heroMetric?.metric;

                    // WARNING - CLIENT SPECIFIC CODE: We override the USAGE hero metric here as we need to use countEntity
                    // SOLUTION: Start calculating the usage in the back-end for time series. We can then stop switching to the countMetric.
                    if (heroMetric?.metric === "usage") {
                        metric = "countEntity";
                    }

                    if (!aggregation || !metric) {
                        throw new Error(
                            "Hero metric is missing for CalendarWeekView",
                        );
                    }

                    if (!authMetadata.tsdbUrl) {
                        throw new Error("Missing analytics endpoint");
                    }

                    let data = await tsRangeQuery(
                        authMetadata?.tsdbUrl,
                        queryStartDate,
                        queryFinishDate,
                        aggregation,
                        "1h",
                        settings.organisation,
                        metric,
                        undefined,
                        digitalTwinEntity,
                        undefined,
                        "-1h",
                    );

                    // Map sensor data to DateTime
                    const dateTimeSensorData: DateTimeSensorCount[] =
                        mapTimeseriesSensorDataToDateTime(
                            data,
                            timeZone,
                            metric === "countEntity" ? true : false,
                        );

                    // Update the hourly count with sensor data
                    dateTimeSensorData.forEach(entry => {
                        updateHourlyCount(
                            entry.dateString,
                            entry.hours,
                            entry.activeHours,
                        );
                    });

                    // Refresh SelectedCellValues
                    setRefreshSelectedCellData(true);
                }
            };

            /*
             * 1. Generate calendar week
             * Creates and empty TSHourlySensorCount array named hours.
             * The intention is to populate this array based on live / time series data
             *
             */
            const calendarWeek = generateCalendarWeek();
            setTheWeek(calendarWeek);

            /*
             * 2. Use the start/finish date from the calendar week to fetch the sensor data
             *
             */
            let startDate;
            let finishDate;

            if (Array.isArray(calendarWeek)) {
                startDate = calendarWeek[0].dateISO;
                finishDate = calendarWeek[6].dateISO;
            }

            if (startDate && finishDate) {
                fetchCalendarData(startDate, finishDate);
            }

            setRenderCalendar(false);
        }
    }, [
        authMetadata?.tsdbUrl,
        digitalTwinEntity,
        filter,
        heroMetric?.timeSeries?.aggregation,
        heroMetric?.metric,
        generateCalendarWeek,
        renderCalendar,
        settings,
        timeZone,
        twin?.physicalModel.bID,
        weekViewDateTime,
    ]);

    const getSensorCountData = useCallback(
        (dateString: string, hourString: string) => {
            const weekDay = theWeek.find(
                day => day.dateISO === dateString,
            );
            let hour;
            if (weekDay) {
                hour = weekDay.hours.find(
                    hr => hr.hourString === hourString,
                );
            }
            return hour ? hour.count : 0;
        },
        [theWeek],
    );

    // Do a full reset of the selected cells and selection box
    const resetSelection = () => {
        setSelectedCells({
            lastModified: DateTime.now().toISO(),
            selectedValues: [],
        });
        setCurrentSelectedValues([]);
        setSelectedDateTimes([]);
        setHideSelectionBox(true);
    };

    // CALENDAR
    const cellWidthModifier = 43;
    const cellHeight = 32;
    const [isDragging, setIsDragging] = useState(false);

    const [currentSelectedValues, setCurrentSelectedValues] =
        useState<SelectedCellValue[]>([]);
    const [selectedCells, setSelectedCells] = useState<SelectedCells>(
        { lastModified: DateTime.now().toISO(), selectedValues: [] },
    );
    const [selectedDateTimes, setSelectedDateTimes] = useState<
        string[]
    >([]);
    const [hideSelectionBox, setHideSelectionBox] =
        useState<boolean>(false);

    const [selectionBox, setSelectionBox] = useState({
        startX: 0,
        startY: 0,
        width: 0,
        height: 0,
        borderStyle: "dashed",
    });

    const [initialMouseY, setInitialMouseY] = useState(0); // Track initial Y position
    const [initialMouseX, setInitialMouseX] = useState(0); // Track initial X position
    const gridRef = useRef<HTMLTableElement>(null);

    const getSelectedCellValue = useCallback(
        (
            dateISO: string,
            hourValue: string,
            dateTimeISO: string,
        ): SelectedCellValue => {
            let hourlyCount = getSensorCountData(dateISO, hourValue);
            let cellValue = hourlyCount;
            let percentage = hourlyCount
                ? (hourlyCount / entityCapacity) * 100
                : 0;

            // WARNING: We override default behaviour here for usage metric
            // SOLUTION: Start calculating the usage in the back-end for time series. We can just output the metric value in the cell
            if (hourlyCount && heroMetric?.metric === "usage") {
                if (twin && digitalTwinEntity) {
                    cellValue = (hourlyCount / entityCapacity) * 100;
                }
            }

            return {
                dateTimeISO: dateTimeISO,
                count: hourlyCount,
                pc: percentage,
                cellValue: cellValue,
            };
        },
        [
            digitalTwinEntity,
            entityCapacity,
            getSensorCountData,
            heroMetric?.metric,
            twin,
        ],
    );

    // AnalysisReset: Triggered when the analysis mode is reset
    useEffect(() => {
        if (analysisReset) {
            resetSelection();
            setAnalysisReset(false);
        }
    }, [analysisReset, setAnalysisReset]);

    // Triggered when the time filter pill is removed (timeReset) or just general when the filter changes and fetchCalendarData refreshes the data
    useEffect(() => {
        const selectedCellValues: SelectedCellValue[] = [];

        if (timeReset) {
            setHideSelectionBox(true);
            const minMaxDates = findMinMaxDates(
                selectedDateTimes,
                timeZone,
            );

            if (minMaxDates.minDate && minMaxDates.maxDate) {
                const timestamps = generateHourlyTimestamps(
                    minMaxDates.minDate,
                    minMaxDates.maxDate,
                    "06:00",
                    "22:00",
                );

                const selectedDateTimes: string[] = [];

                timestamps.forEach(ts => {
                    let dateISO = `${ts.dateString}T${ts.hourString}`;
                    let scv = getSelectedCellValue(
                        ts.dateString,
                        ts.hourString,
                        dateISO,
                    );
                    selectedCellValues.push(scv);
                    selectedDateTimes.push(dateISO);
                });
            }

            setSelectedCells({
                lastModified: DateTime.now().toISO(),
                selectedValues: selectedCellValues,
            });
            setCurrentSelectedValues(selectedCellValues);
            setSelectedDateTimes([]); // If required, this combined with the selectionBox could allow for the selectionBox to be dynamically drawed on the calendar
            setTimeReset(false);

            // If the DataContext filter changes, fetchCalendarData is triggered and we should refresh the selected cells which in turn refreshes the calendar chart and the indicator / tool tip
        } else if (
            !timeReset &&
            refreshSelectedCellData &&
            selectedDateTimes &&
            selectedDateTimes.length > 0
        ) {
            if (selectedDateTimes && selectedDateTimes.length > 0) {
                selectedDateTimes.forEach(sdt => {
                    const [dateISO, hourValue] = sdt.split("T");
                    const scv = getSelectedCellValue(
                        dateISO,
                        hourValue,
                        sdt,
                    );
                    selectedCellValues.push(scv);
                });
            }
            setSelectedCells({
                lastModified: DateTime.now().toISO(),
                selectedValues: selectedCellValues,
            });
            setCurrentSelectedValues(selectedCellValues);
            setRefreshSelectedCellData(false);
        }
    }, [
        getSelectedCellValue,
        refreshSelectedCellData,
        selectedDateTimes,
        setTimeReset,
        timeReset,
        timeZone,
    ]);

    const handleMouseDown = (
        event: React.MouseEvent<HTMLTableCellElement>,
    ) => {
        setHideSelectionBox(false);

        if (!gridRef.current) return;
        const gridRect = gridRef.current.getBoundingClientRect();
        const { clientX, clientY } = event;
        const relativeX = clientX - gridRect.left;
        const relativeY = clientY - gridRect.top;

        setIsDragging(true);
        setInitialMouseY(relativeY); // Set initial Y position
        setInitialMouseX(relativeX); // Set initial X position
        setSelectionBox({
            startX: relativeX,
            startY: relativeY,
            width: 0,
            height: 0,
            borderStyle: "dashed",
        });

        setSelectedCells({
            lastModified: DateTime.now().toISO(),
            selectedValues: [],
        });
    };

    const setSelectedCellValue = (
        scv: SelectedCellValue,
        singleCell: boolean = false,
    ) => {
        if (singleCell) {
            setCurrentSelectedValues([scv]);
        } else {
            const foundObj = findObjectByPropertyValue(
                selectedCells.selectedValues,
                "dateTimeISO",
                scv.dateTimeISO,
            );
            if (!foundObj) {
                setSelectedCells({
                    lastModified: DateTime.now().toISO(),
                    selectedValues: [
                        ...selectedCells.selectedValues,
                        scv,
                    ],
                });
            }
        }
    };

    const handleMouseClick = (event: any) => {
        if (!gridRef.current) return;
        const { clientX, clientY } = event;
        const sbp = calcSelectionBoxPosition(
            gridRef,
            clientX,
            clientY,
        );

        if (sbp) {
            const selectedDateTimes: string[] = [];
            for (
                let row = Math.min(sbp.startRow, sbp.endRow);
                row < Math.max(sbp.startRow, sbp.endRow);
                row++
            ) {
                for (
                    let col = Math.min(sbp.startCol, sbp.endCol);
                    col < Math.max(sbp.startCol, sbp.endCol);
                    col++
                ) {
                    let dateISO = theWeek[col - 1].dateISO;
                    let hourValue = hoursData[row - 1].value;
                    let dateTimeISO = `${dateISO}T${hourValue}`;
                    selectedDateTimes.push(dateTimeISO);

                    const selecteCellValue = getSelectedCellValue(
                        dateISO,
                        hourValue,
                        dateTimeISO,
                    );
                    setSelectedCellValue(selecteCellValue, true);
                }
            }

            setSelectedDateTimes(selectedDateTimes);

            setSelectionBox({
                startX: sbp.snapStartX,
                startY: sbp.snapStartY,
                width: Math.abs(sbp.snapEndX - sbp.snapStartX),
                height: Math.abs(sbp.snapEndY - sbp.snapStartY),
                borderStyle: "solid",
            });
        }
    };

    const calcSelectionBoxPosition = (
        gridRef: React.RefObject<HTMLTableElement>,
        clientX: number,
        clientY: number,
    ): SelectionBoxPosition | void => {
        if (!gridRef.current) return;

        const gridRect = gridRef.current.getBoundingClientRect();
        const relativeX = clientX - gridRect.left;
        const relativeY = clientY - gridRect.top;

        // Calculate adjusted cell width
        const adjustedCellWidth =
            (gridRect.width - cellWidthModifier) / theWeek.length;

        // Calculate adjusted cell height
        const adjustedCellHeight =
            (gridRect.height -
                gridRef.current.querySelector("thead")!
                    .clientHeight) /
            hoursData.length;

        // Calculate the cell indices for the start and end of the selection box
        const startCol = Math.floor(
            initialMouseX / adjustedCellWidth,
        );
        const startRow = Math.floor(
            initialMouseY / adjustedCellHeight,
        );
        const endCol = Math.ceil(relativeX / adjustedCellWidth);
        const endRow = Math.ceil(relativeY / adjustedCellHeight);

        // Calculate snapping positions
        const snapStartX = startCol * adjustedCellWidth;
        const snapStartY = startRow * adjustedCellHeight;
        const snapEndX = endCol * adjustedCellWidth;
        const snapEndY = endRow * adjustedCellHeight;

        return {
            relativeX: relativeX,
            relativeY: relativeY,
            startCol: startCol,
            startRow: startRow,
            endCol: endCol,
            endRow: endRow,
            snapStartX: snapStartX,
            snapStartY: snapStartY,
            snapEndX: snapEndX,
            snapEndY: snapEndY,
        };
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        let finalSelectedValues: SelectedCellValue[] = [];
        selectedCells.selectedValues.forEach(scv => {
            if (selectedDateTimes.includes(scv.dateTimeISO)) {
                finalSelectedValues.push(scv);
            }
        });
        setCurrentSelectedValues(finalSelectedValues);
        setSelectionBox({ ...selectionBox, borderStyle: "solid" });
    };

    const handleMouseMove = (
        event: React.MouseEvent<HTMLTableElement, MouseEvent>,
    ) => {
        if (!isDragging || !gridRef.current) return;
        const { clientX, clientY } = event;
        const sbp = calcSelectionBoxPosition(
            gridRef,
            clientX,
            clientY,
        );

        if (sbp) {
            // Only proceed if the mouse is moving downward and to the right
            if (
                sbp.relativeY > initialMouseY &&
                sbp.relativeX > initialMouseX
            ) {
                const selectedDateTimes: string[] = [];

                for (
                    let row = Math.min(sbp.startRow, sbp.endRow);
                    row < Math.max(sbp.startRow, sbp.endRow);
                    row++
                ) {
                    for (
                        let col = Math.min(sbp.startCol, sbp.endCol);
                        col < Math.max(sbp.startCol, sbp.endCol);
                        col++
                    ) {
                        let dateISO = theWeek[col - 1].dateISO;
                        let hourValue = hoursData[row - 1].value;
                        let dateTimeISO = `${dateISO}T${hourValue}`;
                        selectedDateTimes.push(dateTimeISO);

                        const selecteCellValue = getSelectedCellValue(
                            dateISO,
                            hourValue,
                            dateTimeISO,
                        );
                        setSelectedCellValue(selecteCellValue);
                    }
                }

                setSelectedDateTimes(selectedDateTimes);

                setSelectionBox({
                    startX: sbp.snapStartX,
                    startY: sbp.snapStartY,
                    width: Math.abs(sbp.snapEndX - sbp.snapStartX),
                    height: Math.abs(sbp.snapEndY - sbp.snapStartY),
                    borderStyle: "dashed",
                });
            }
        }
    };

    useEffect(() => {
        if (
            !analysisReset &&
            !timeReset &&
            !isDragging &&
            selectedDateTimes &&
            selectedDateTimes.length > 0
        ) {
            const minMaxDates = findMinMaxDates(
                selectedDateTimes,
                timeZone,
            );

            if (minMaxDates.minDate && minMaxDates.maxDate) {
                let selectedStartDateTime = DateTime.fromISO(
                    minMaxDates.minDate,
                );
                let selectedFinishDateTime = DateTime.fromISO(
                    minMaxDates.maxDate,
                );

                selectedFinishDateTime = selectedFinishDateTime.plus({
                    hours: 1,
                });

                let startDateTimeISO = selectedStartDateTime.toISO({
                    includeOffset: false,
                });
                let finishDateTimeISO = selectedFinishDateTime.toISO({
                    includeOffset: false,
                });

                if (startDateTimeISO && finishDateTimeISO) {
                    setAppModeWithFilterReset(AppMode.ANALYSIS);
                    setStartDateTime(startDateTimeISO);
                    setFinishDateTime(finishDateTimeISO);
                }
            }
        }
    }, [
        analysisReset,
        timeReset,
        isDragging,
        selectedDateTimes,
        timeZone,
        setAppModeWithFilterReset,
        setStartDateTime,
        setFinishDateTime,
    ]);

    return (
        <div className={className}>
            <CalendarWeekChart
                theWeek={theWeek}
                selectedCellValues={currentSelectedValues}
                entityCapacity={entityCapacity}
            />
            {weekViewDateTime && (
                <div>
                    <WeekStepper
                        weekViewDateTime={weekViewDateTime}
                        setCalendarDateTime={setCalendarDateTime}
                    />
                    <div
                        style={{
                            marginTop: "1rem",
                            position: "relative",
                        }}
                        onMouseUp={handleMouseUp}
                    >
                        <table
                            id="duration"
                            ref={gridRef}
                            onMouseMove={handleMouseMove}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: `${cellWidthModifier}px`,
                                            height: `${cellHeight}px`,
                                        }}
                                    ></th>
                                    {theWeek.map((day, index) => (
                                        <th
                                            key={index}
                                            style={{
                                                width: `${cellWidthModifier}px`,
                                                height: `${cellHeight}px`,
                                                textAlign: "center",
                                                fontSize: "10px",
                                                lineHeight: "14px",
                                                fontWeight: "400",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    color: "#7A7A7A",
                                                }}
                                            >
                                                {day.dateObj.getDate()}
                                            </p>
                                            <p>
                                                {day.dateObj
                                                    .toLocaleDateString(
                                                        "en-GB",
                                                        {
                                                            weekday:
                                                                "short",
                                                        },
                                                    )
                                                    .toUpperCase()}
                                            </p>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {hoursData &&
                                    hoursData.map((hour, i) => {
                                        return (
                                            <tr key={i}>
                                                <th
                                                    style={{
                                                        width: `${cellWidthModifier}px`,
                                                        height: `${cellHeight}px`,
                                                        fontSize:
                                                            "10px",
                                                        textAlign:
                                                            "center",
                                                    }}
                                                >
                                                    {hour.text}
                                                </th>
                                                {theWeek.map(
                                                    (weekDay, i) => {
                                                        const cellValue = `${weekDay.dateISO}T${prependZero(hour.hour)}:00`;
                                                        let selected;

                                                        if (
                                                            selectedDateTimes &&
                                                            selectedDateTimes.length ===
                                                                0
                                                        ) {
                                                            selected =
                                                                true;
                                                        } else {
                                                            selected =
                                                                selectedDateTimes.includes(
                                                                    cellValue,
                                                                );
                                                        }

                                                        let hourlyCount =
                                                            getSensorCountData(
                                                                weekDay.dateISO,
                                                                hour.value,
                                                            );
                                                        let displayValue =
                                                            hourlyCount;

                                                        // WARNING: We override default behaviour here for usage metric
                                                        // SOLUTION: Start calculating the usage in the back-end for time series. We can just output the metric value in the cell
                                                        if (
                                                            hourlyCount &&
                                                            heroMetric?.metric ===
                                                                "usage"
                                                        ) {
                                                            if (
                                                                twin &&
                                                                digitalTwinEntity
                                                            ) {
                                                                displayValue =
                                                                    (hourlyCount /
                                                                        entityCapacity) *
                                                                    100;
                                                            }
                                                        }

                                                        // IMPORTANT: If the hero metric is "countEntity" we always use the metric "usage" to work out which indicator colour to display.
                                                        // This allows us to resolve a indicator colour for different levels across the entire twin.
                                                        let indicatorValue =
                                                            hourlyCount &&
                                                            heroMetric?.metric ===
                                                                "countEntity"
                                                                ? (hourlyCount /
                                                                      entityCapacity) *
                                                                  100
                                                                : displayValue;
                                                        let colors: IndicatorColors =
                                                            getIndicatorColor(
                                                                indicatorValue,
                                                                heroMetric?.indicatorConfig,
                                                                selected,
                                                            );

                                                        return (
                                                            <td
                                                                key={
                                                                    i
                                                                }
                                                                data-value={
                                                                    cellValue
                                                                }
                                                                style={{
                                                                    width: `${cellWidthModifier}px`,
                                                                    height: `${cellHeight}px`,
                                                                    color: colors.textColor,
                                                                    backgroundColor:
                                                                        colors.bgColor,
                                                                }}
                                                                onClick={event =>
                                                                    handleMouseClick(
                                                                        event,
                                                                    )
                                                                }
                                                                onMouseDown={event =>
                                                                    handleMouseDown(
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                {displayValue
                                                                    ? `${displayValue.toFixed(1)}`
                                                                    : `-`}
                                                            </td>
                                                        );
                                                    },
                                                )}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        {!hideSelectionBox &&
                            gridRef &&
                            gridRef.current &&
                            selectionBox.width !== 0 &&
                            selectionBox.height !== 0 && (
                                <div
                                    className="selection-box"
                                    onClick={() => {
                                        setAnalysisReset(true);
                                    }}
                                    style={{
                                        position: "absolute",
                                        left: selectionBox.startX,
                                        top: selectionBox.startY,
                                        width: selectionBox.width,
                                        height: selectionBox.height,
                                        borderStyle:
                                            selectionBox.borderStyle,
                                    }}
                                >
                                    {!isDragging && (
                                        <CalendarSelectionOverlay
                                            selectedCellValues={
                                                currentSelectedValues
                                            }
                                        />
                                    )}
                                </div>
                            )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CalendarWeekView;
