// Types
import { DataMode } from "../../../@types/DataMode";
import { DataScope } from "../../../@types/Settings/DataScope";
import { Environment } from "../../../@types/Settings/Environment";
import { IndicatorColor } from "../../../@types/Settings/IndicatorColor";
import { LabelDisplayOn } from "../../../@types/Settings/LabelDisplayOn";
import { TwinTemplate } from "../../../@types/Settings/TwinTemplate";
import { TwinEntityType } from "../../../@types/TwinEntityType";

// Data / Movico Structure Label Configs
import { LabelSetEventTruckStructureLiveBrief } from "./labelSets/eventTruckTwin/live/LabelSetEventTruckStructureLiveBrief";
import { LabelSetEventTruckStructureLiveFull } from "./labelSets/eventTruckTwin/live/LabelSetEventTruckStructureLiveFull";
import { LabelSetEventTruckStructureTimeSeriesBrief } from "./labelSets/eventTruckTwin/timeseries/LabelSetEventTruckStructureTimeSeriesBrief";
import { LabelSetEventTruckStructureTimeSeriesFull } from "./labelSets/eventTruckTwin/timeseries/LabelSetEventTruckStructureTimeSeriesFull";

// Data / Movico Floor Label Configs
import { LabelSetEventTruckAreaTimeSeriesBrief } from "./labelSets/eventTruckTwin/timeseries/LabelSetEventTruckAreaTimeSeriesBrief";
import { LabelSetEventTruckAreaTimeSeriesFull } from "./labelSets/eventTruckTwin/timeseries/LabelSetEventTruckAreaTimeSeriesFull";
import { LabelSetEventTruckFloorLiveBrief } from "./labelSets/eventTruckTwin/live/LabelSetEventTruckAreaLiveBrief";
import { LabelSetEventTruckFloorLiveFull } from "./labelSets/eventTruckTwin/live/LabelSetEventTruckAreaLiveFull";

export const eventTruckTemplate: TwinTemplate = {
    organisation: "Movico",
    heroMetrics: [
        {
            metric: "usage",
            live: {
                showDecimalPlace: true,
                showUnit: true,
            },
            timeSeries: {
                aggregation: "max",
                showDecimalPlace: true,
                showUnit: true,
            },
            indicatorConfig: {
                selected: [
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.INITIAL,
                        op: `value >= 0 && value <= 1`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.CRYSTAL_TINT,
                        op: `value > 1 && value <= 5`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_LIGHT,
                        op: `value > 5 && value <= 10`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM_LIGHT,
                        op: `value > 10 && value <= 15`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM,
                        op: `value > 15 && value <= 20`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_ACCENT,
                        op: `value > 20 && value <= 25`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_ACCENT_LIGHT,
                        op: `value > 25 && value <= 30`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_ACCENT,
                        op: `value > 30 && value <= 40`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_BRIGHT,
                        op: `value > 40 && value <= 50`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_MUTED,
                        op: `value > 50 && value <= 75`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_VIOLET,
                        op: `value > 75 && value <= 100`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_MEDIUM,
                        op: `value > 100 && value <= 125`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_VIBRANT,
                        op: `value > 125`,
                    },
                ],
                nonSelected: [
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.INITIAL_NOT_SELECTED,
                        op: `value >= 0 && value <= 1`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor:
                            IndicatorColor.CRYSTAL_TINT_NOT_SELECTED,
                        op: `value > 1 && value <= 5`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor:
                            IndicatorColor.TEAL_LIGHT_NOT_SELECTED,
                        op: `value > 5 && value <= 10`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor:
                            IndicatorColor.TEAL_MEDIUM_LIGHT_NOT_SELECTED,
                        op: `value > 10 && value <= 15`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor:
                            IndicatorColor.TEAL_MEDIUM_NOT_SELECTED,
                        op: `value > 15 && value <= 20`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor:
                            IndicatorColor.TEAL_ACCENT_NOT_SELECTED,
                        op: `value > 20 && value <= 25`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.BLUE_ACCENT_LIGHT_NOT_SELECTED,
                        op: `value > 25 && value <= 30`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.BLUE_ACCENT_NOT_SELECTED,
                        op: `value > 30 && value <= 40`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.BLUE_BRIGHT_NOT_SELECTED,
                        op: `value > 40 && value <= 50`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.BLUE_MUTED_NOT_SELECTED,
                        op: `value > 50 && value <= 75`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.BLUE_VIOLET_NOT_SELECTED,
                        op: `value > 75 && value <= 100`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.PINK_MEDIUM_NOT_SELECTED,
                        op: `value > 100 && value <= 125`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.PINK_VIBRANT_NOT_SELECTED,
                        op: `value > 125`,
                    },
                ],
            },
        },
        {
            metric: "countEntity",
            live: {
                showDecimalPlace: false,
                showUnit: false,
            },
            timeSeries: {
                aggregation: "max",
                showDecimalPlace: true,
                showUnit: true,
            },
        },
    ],
    startLive: true,
    liveDataWarning: true,
    physicalEntities: ["site", "structure", "area"],
    liveDataInterval: "15s",
    labels: [
        {
            twinEntityType: TwinEntityType.STRUCTURE,
            labelSets: [
                {
                    brief: LabelSetEventTruckStructureTimeSeriesBrief,
                    full: LabelSetEventTruckStructureTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES,
                },
                {
                    brief: LabelSetEventTruckStructureLiveBrief,
                    full: LabelSetEventTruckStructureLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE,
                },
            ],
        },
        {
            twinEntityType: TwinEntityType.FLOOR,
            labelSets: [
                {
                    brief: LabelSetEventTruckAreaTimeSeriesBrief,
                    full: LabelSetEventTruckAreaTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES,
                },
                {
                    brief: LabelSetEventTruckFloorLiveBrief,
                    full: LabelSetEventTruckFloorLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE,
                },
            ],
        },
        {
            twinEntityType: TwinEntityType.AREA,
            labelSets: [
                {
                    brief: LabelSetEventTruckAreaTimeSeriesBrief,
                    full: LabelSetEventTruckAreaTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES,
                },
                {
                    brief: LabelSetEventTruckFloorLiveBrief,
                    full: LabelSetEventTruckFloorLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE,
                },
            ],
        },
    ],
    maria: {
        id: "maria",
        introPrompts: [],
    },
    timeline: {
        id: "timeline",
        visible: true,
        open: true,
        order: 1,
        interval: "15m",
        hourRange: {
            startTime: "07:00",
            finishTime: "19:00",
        },
    },
    calendar: {
        id: "calendar",
        visible: true,
        open: true,
        icon: "",
        order: 2,
        weekView: {
            dataScope: DataScope.ACTIVE_HOURS,
            hourRange: {
                startTime: "07:00",
                finishTime: "19:00",
            },
        },
        monthView: {
            dataScope: DataScope.ACTIVE_DAYS,
        },
    },
    leftFieldMessages: [
        "Listening to On The Road Again by Willie Nelson",
        "Expo Trucks: Transformers in disguise",
        "Connecting the truck utilities",
        "Trying to find where we parked the truck",
        "Dude, where is my truck!",
        "Looking for the toilets",
    ],
    pollTwin: false,
};
