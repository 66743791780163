/*
 * formatCopilotMessage
 * A series of regex replace functions to format the copilot response
 *
 */
export const formatCopilotMessage = (response: string) => {
    // 1. Convert ** to <strong> HTML element
    response = response.replace(
        /\*\*(.*?)\*\*/g,
        "<strong>$1</strong>",
    );
    return response;
};
