import axios from "axios";
import routes from "./routes";

export const deleteOpeningHours = async (
    accessToken: string,
    envName: string,
    bID: string,
    effectiveDateFrom: string,
): Promise<boolean> => {
    const url = `${routes.delete.twins.deleteOpeningHours(envName, bID, effectiveDateFrom)}`;
    let success = false;

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        await axios.delete(url, {
            headers: headers,
        });
        success = true;
    } catch (error) {
        success = false;
    }

    return success;
};
