import React, { useEffect, useState } from "react";

// Types
import { AppMode } from "../../@types/Mode";
import { Entity } from "../../@types/Entity";
import { FilterType } from "../../@types/FilterType";

// Context
import {
    useFilterContext,
    useModeContext,
} from "../../common/contexts/FilterAndModeContexts";
import { useTwinContext } from "../../common/contexts/TwinContext";

// Utils
import { createFilterPath } from "../../common/utils/createFilterPath";

// Components
import {
    Accordion,
    AccordionToggleData,
} from "@fluentui/react-components";
import AssetAccordion from "../EntityAccordion2/EntityAccordion";

interface Props {
    className?: string;
}

const Assets: React.FC<Props> = ({ className }) => {
    const { twin } = useTwinContext();

    let renderTwin: { children: Entity[] | null } = { children: [] };
    if (twin && twin.physicalModel) {
        renderTwin.children?.push(twin.physicalModel);
    }

    const { filter, setFilter, removeFilterByType } =
        useFilterContext();
    const { appMode } = useModeContext();
    const [openItems, setOpenItems] = useState<string[]>([]);

    useEffect(() => {
        let openedFilterItems: string[] = [];
        filter.forEach(f => {
            f.id && openedFilterItems.push(f.id);
        });
        setOpenItems(openedFilterItems);
    }, [filter]);

    const handleToggle = (
        isCollapsed: AccordionToggleData<string>,
    ) => {
        let pathId = isCollapsed.value;

        const lastPathId =
            isCollapsed.openItems[isCollapsed.openItems.length - 1];
        let firstItemClosed = isCollapsed.openItems.length === 0;

        if (
            !isCollapsed.openItems.includes(pathId) &&
            filter[0].id === lastPathId
        ) {
            firstItemClosed = true;
        }

        if (!isCollapsed.openItems.includes(isCollapsed.value)) {
            pathId = lastPathId;
        }

        // Clear all TAG and ASSET Filter Pills
        removeFilterByType(FilterType.ENTITY);
        removeFilterByType(FilterType.ASSET);

        if (twin) {
            // Create the filter path
            const filter = createFilterPath(
                pathId,
                twin.physicalModel,
            );
            // Update filter in filterContext
            setFilter(filter);
        }
    };

    return (
        <Accordion<string>
            onToggle={(event, isCollapsed) =>
                handleToggle(isCollapsed)
            }
            openItems={openItems}
            collapsible={true}
            multiple={true}
        >
            <AssetAccordion
                entities={
                    renderTwin.children ? renderTwin.children : []
                }
                handleToggle={handleToggle}
                live={appMode === AppMode.LIVE}
            />
        </Accordion>
    );
};

export default Assets;
