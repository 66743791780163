// Types
import { NavItem } from "../../@types/NavItem";
import { Roles } from "../../@types/Auth0/Roles";

// Components
import {
    Building32Filled,
    Building32Regular,
    Guest32Filled,
    Guest32Regular,
    Person32Filled,
    Person32Regular,
    PersonAdd32Filled,
    PersonAdd32Regular,
} from "@fluentui/react-icons";

const allUsers: NavItem[] = [
    {
        text: "Dashboard",
        href: "/account/dashboard",
        icon: Guest32Regular,
        iconSelected: Guest32Filled,
        showOnQuickLinks: false,
    },
    {
        text: "Profile",
        href: "/account/profile",
        icon: Person32Regular,
        iconSelected: Person32Filled,
        showOnQuickLinks: true,
        state: { menuIndex: 1 },
    },
];

const customerAdmin: NavItem[] = [
    {
        text: "Add New User",
        href: "/account/users/add-new-user",
        icon: PersonAdd32Regular,
        iconSelected: PersonAdd32Filled,
        showOnQuickLinks: true,
        state: { menuIndex: 4 },
    },
];

const partnerUser: NavItem[] = [
    {
        text: "Select Organisation",
        href: "/select-organisation",
        icon: Building32Regular,
        iconSelected: Building32Filled,
        showOnQuickLinks: true,
    },
];

const tlAdmin: NavItem[] = [
    {
        text: "Select Organisation",
        href: "/select-organisation",
        icon: Building32Regular,
        iconSelected: Building32Filled,
        showOnQuickLinks: true,
    },
];

export const getProtectedAccountMenu = (role: string) => {
    let accountMenu = [...allUsers];

    switch (role) {
        case Roles.CUSTOMER_ADMIN:
            accountMenu = [...accountMenu, ...customerAdmin];
            break;
        case Roles.PARTNER_USER:
            accountMenu = [...accountMenu, ...partnerUser];
            break;
        case Roles.TL_ADMIN:
            accountMenu = [...accountMenu, ...tlAdmin];
            break;
    }
    return accountMenu;
};
