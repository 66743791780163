// Types
import { Label } from "../../../../../../@types/Settings/Label";
import { LabelValueType } from "../../../../../../@types/Settings/LabelValueType";

// Utils
import { calcMaxUtilisation } from "../../../../../utils/func-metrics/calcMaxUtilisation";
import { getMaxHeadcount } from "../../../../../utils/func-metrics/getMaxHeadcount";

export const LabelSetEventTruckAreaTimeSeriesFull: Label[] = [
    {
        name: "Max Utilisation",
        valueName: "calcMaxUtilisation",
        valueType: LabelValueType.FUNC_METRIC,
        value: calcMaxUtilisation,
    },
    {
        name: "Capacity",
        valueType: LabelValueType.ENTITY_PROPERTY,
        value: "capacity",
    },
    {
        name: "Max Headcount",
        valueName: "getMaxHeadcount",
        valueType: LabelValueType.FUNC_METRIC,
        value: getMaxHeadcount,
    },
];
