/*
 * DialogContext
 *
 */
import {
    createContext,
    useState,
    ReactNode,
    useMemo,
    useCallback,
    useContext,
} from "react";

// Components
import TLDialog from "../../components/elements/TLDialog/TLDialog";

interface DialogProps {
    title: string;
    content: JSX.Element;
    onClose?: () => void;
}
interface DialogContextValue {
    showDialog: (props: DialogProps) => void;
    hideDialog: () => void;
}

const initialState: DialogContextValue = {
    showDialog: () => {},
    hideDialog: () => {},
};

export const DialogContext =
    createContext<DialogContextValue>(initialState);

export const useDialogContext = (): DialogContextValue => {
    return useContext(DialogContext);
};

export const DialogContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [dialogProps, setDialogProps] =
        useState<DialogProps | null>(null);

    const showDialog = useCallback((props: DialogProps) => {
        setDialogProps(props);
    }, []);

    const hideDialog = useCallback(() => {
        setDialogProps(null);
    }, []);

    // Memoise the context value to prevent unnecessary re-renders
    const contextValue = useMemo(() => {
        return {
            showDialog,
            hideDialog,
        };
    }, [showDialog, hideDialog]);

    return (
        <DialogContext.Provider value={contextValue}>
            {children}
            {dialogProps && (
                <>
                    <TLDialog
                        defaultOpen={true}
                        title={dialogProps.title}
                        dialogContent={dialogProps.content}
                    />
                </>
            )}
        </DialogContext.Provider>
    );
};
