/*
 * Maps string values defined in settings/preferences to @fluentui/react-icons components
 * IMPORTANT: @fluentui/react-icons must be imported below in order for the string values to be referenced correctly
 *
 */
import {
    Dumbbell20Regular,
    Food20Regular,
    VehicleBicycle20Regular,
    VehicleTruckProfile20Regular,
    Ribbon20Regular,
    Crown20Regular,
    PeopleCommunity20Regular,
    Headset20Regular,
    Accessibility20Regular,
    Line20Regular,
    DrinkCoffee20Regular,
    ArrowSort20Regular,
    ScanPerson20Regular,
    PersonBoard20Regular,
    WeatherSqualls20Regular,
    TicketDiagonal20Regular,
} from "@fluentui/react-icons";

interface IconMapping {
    [key: string]: React.ComponentType<any>;
}

export const assetIconComponents: IconMapping = {
    Dumbbell20Regular,
    Food20Regular,
    VehicleBicycle20Regular,
    VehicleTruckProfile20Regular,
    Ribbon20Regular,
    Crown20Regular,
    PeopleCommunity20Regular,
    Headset20Regular,
    Accessibility20Regular,
    Line20Regular,
    DrinkCoffee20Regular,
    ArrowSort20Regular,
    ScanPerson20Regular,
    PersonBoard20Regular,
    TicketDiagonal20Regular,
    WeatherSqualls20Regular,
};
