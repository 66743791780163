import { FloorPlannerCustomizationError } from "./floorplanner";

export function ImportErrorsList({
    errors,
}: {
    errors: FloorPlannerCustomizationError[];
}) {
    return (
        <>
            <h3>Errors</h3>
            <p>
                <ul>
                    {errors.map((error, index) => {
                        return (
                            <li style={{ margin: "20px" }}>
                                <ImportError
                                    key={index}
                                    error={error}
                                />
                            </li>
                        );
                    })}
                </ul>
            </p>
        </>
    );
}

function ImportError({
    error,
}: {
    error: FloorPlannerCustomizationError;
}) {
    if ("id" in error) {
        const [firstEntity, secondEntity] = error.entityInfo;
        return (
            <li>
                <h3>Duplicate ID:</h3>
                <p>{error.id}</p>
                <ul>
                    <li>{firstEntity.name}</li>
                    <li>{secondEntity.name}</li>
                </ul>
            </li>
        );
    } else {
        return (
            <li>
                <h3>Custom Name Format Error:</h3>
                <p>{error.message}</p>
                <p>Custom Name: {error.value}</p>
            </li>
        );
    }
}
