/*
 * LogEvent
 *
 */
import { LogEntry } from "../../@types/LogEntry";
import { LogLevel } from "../../@types/LogLevel";

export const LogEvent = (
    level: LogLevel,
    message: string,
    systemMessage?: string,
    code?: string,
    href?: string,
    broadcast: boolean = false,
): LogEntry => {
    const logEntry: LogEntry = {
        level: level,
        code: code,
        message: message,
        systemMessage: systemMessage,
        href: href,
    };

    // TODO: broadcast (Pushes to 3rd party API)

    return logEntry;
};
