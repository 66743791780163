import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useSecureModelURL } from "../../utils/modelFilePaths";
import {
    Box3,
    BoxGeometry,
    Euler,
    Mesh,
    MeshStandardMaterial,
    Object3D,
    Quaternion,
    Vector3,
} from "three";
import { isMesh } from "../../helpers/threeTypeGuards";
import { degreesToRadians, getCentre } from "../../utils/geometry";
import { Point, TwinEntity } from "@repo/backend-types";
import { Wind } from "../../behaviours/Wind";
import { coordsToVector3 } from "react-three-map";
import { ASSET_COLOR } from "../../theme";
import { Capacity } from "../../behaviours/Capacity";
import { TwinEntityType } from "../../../../@types/TwinEntityType";
import { disposeMaterial } from "../../utils/cleanupGL";

const structureMaterial = new MeshStandardMaterial({
    color: ASSET_COLOR,
    opacity: 0.9,
    roughness: 0.8,
    metalness: 0.2,
});

interface Props {
    entity: TwinEntity;
    modelFile: string;
    childEntities?: TwinEntity[];
    mapDiv: HTMLDivElement;
    loadingList: Map<string, boolean>;
    depth: number; // the depth we're at in the twin heirachy
    lineage: TwinEntity[]; // the acenstry of this entity, oldest first
    mapOrigin: Point;
}

type ModelNode = {
    node: Object3D;
    position: Vector3;
    quaternion: Quaternion;
    entity: TwinEntity;
    hitBoxGeometry: BoxGeometry;
    lineage: TwinEntity[];
};

function getBoundingBox(mesh: Mesh): Box3 {
    if (!mesh.geometry.boundingBox) {
        mesh.geometry.computeBoundingBox();
    }

    return mesh.geometry.boundingBox!; // this is safe because we just computed it
}

const nameMatchingRegex = /_/g;

type EntitySearchResult = {
    entity: TwinEntity;
    lineage: TwinEntity[];
};

function searchEntity(
    entity: TwinEntity,
    name: string,
    lineage: TwinEntity[],
) {
    if (entity.name.toUpperCase() === name.toUpperCase()) {
        return { entity, lineage };
    } else {
        if (entity.children) {
            let result: EntitySearchResult | undefined;

            for (const child of entity.children) {
                const childResult = searchEntity(child, name, [
                    ...lineage,
                    entity,
                ]);

                if (childResult) {
                    result = childResult;
                }
            }

            return result;
        }
    }
}

const Structure = ({
    entity,
    childEntities,
    modelFile,
    mapDiv,
    loadingList,
    depth,
    lineage,
    mapOrigin,
}: Props) => {
    const modelURL = useSecureModelURL(modelFile);

    const [hasModel, setHasModel] = useState(false);

    useLayoutEffect(() => {
        const checkModelURL = async () => {
            try {
                const response = await fetch(modelURL);

                if (response.ok) {
                    setHasModel(response.ok);
                } else {
                    loadingList.delete(modelFile);
                }
            } catch (error) {
                console.error(
                    "Error fetching data: ",
                    error,
                    "hasModel?: " + hasModel,
                );
            }
        };

        checkModelURL();
    }, [hasModel, modelURL, loadingList, modelFile]);

    const { nodes, scene } = useGLTF(modelURL);

    // Used by wind
    const structureBoundingBox = Object.entries(nodes)
        .map(entry => {
            const object = entry[1];

            if (isMesh(object)) {
                object.geometry.computeBoundingBox();
                const boundingbox = object.geometry.boundingBox!;
                return boundingbox;
            } else {
                return null;
            }
        })
        .filter((box): box is Box3 => !!box)
        .reduce((box1, box2) => {
            const minx = Math.min(box1.min.x, box2.min.x);
            const miny = Math.min(box1.min.y, box2.min.y);
            const minz = Math.min(box1.min.z, box2.min.z);
            const maxx = Math.min(box1.max.x, box2.max.x);
            const maxy = Math.min(box1.max.y, box2.max.y);
            const maxz = Math.min(box1.max.z, box2.max.z);

            return new Box3(
                new Vector3(minx, miny, minz),
                new Vector3(maxx, maxy, maxz),
            );
        });

    const structureGeometry = new BoxGeometry(1, 1, 1);

    structureGeometry.translate(0, 0.5, 0); // because box geometry is origin (0,0,0) initially
    structureGeometry.scale(
        (structureBoundingBox.max.x - structureBoundingBox.min.x) *
            10, // these 10s are random
        (structureBoundingBox.max.y - structureBoundingBox.min.y) *
            10,
        (structureBoundingBox.max.z - structureBoundingBox.min.z) *
            10,
    );

    const modelNodes = useMemo(() => {
        // const modelNodes: {mesh: Mesh, builtOutEntity?: BuiltOutEntity, structureNodeEntity?: TwinEntity}[] = [];
        const modelNodes: ModelNode[] = [];

        // for Movico we don't need to recurse into the structure nodes but we
        // will need to in order to support arbitrary gltfs
        Object.values(nodes).forEach(node => {
            const structureNodeEntityName = node.name.replace(
                nameMatchingRegex,
                " ",
            );
            const structureNodeEntity:
                | EntitySearchResult
                | undefined = searchEntity(
                entity,
                structureNodeEntityName,
                lineage,
            );

            const nodeIsMesh = isMesh(node);

            if (nodeIsMesh) {
                disposeMaterial(node.material);
                node.material = structureMaterial;
            }

            if (structureNodeEntity) {
                let boundingBox: Box3;
                const hitBoxGeometry = new BoxGeometry(1, 1, 1);

                const scale = new Vector3();
                node.getWorldScale(scale);

                const quaternion = new Quaternion();

                if (nodeIsMesh) {
                    boundingBox = getBoundingBox(node);

                    hitBoxGeometry.translate(0, 0, 0);
                    hitBoxGeometry.scale(
                        -(boundingBox.min.x - boundingBox.max.x) +
                            0.1,
                        -(boundingBox.min.y - boundingBox.max.y) +
                            0.01,
                        -(boundingBox.min.z - boundingBox.max.z) +
                            0.1,
                    );

                    node.getWorldQuaternion(quaternion);
                } else {
                    boundingBox = new Box3();

                    node.traverse(object => {
                        if (isMesh(object)) {
                            const childBoundingBox =
                                getBoundingBox(object);

                            const childBoundingBoxCopy =
                                childBoundingBox.clone();
                            childBoundingBoxCopy.applyMatrix4(
                                object.matrixWorld,
                            );

                            // expand the total bounding box if necessary
                            if (
                                boundingBox.min.x >
                                childBoundingBoxCopy.min.x
                            )
                                boundingBox.min.x =
                                    childBoundingBoxCopy.min.x;
                            if (
                                boundingBox.min.y >
                                childBoundingBoxCopy.min.y
                            )
                                boundingBox.min.y =
                                    childBoundingBoxCopy.min.y;
                            if (
                                boundingBox.min.z >
                                childBoundingBoxCopy.min.z
                            )
                                boundingBox.min.z =
                                    childBoundingBoxCopy.min.z;

                            if (
                                boundingBox.max.x <
                                childBoundingBoxCopy.max.x
                            )
                                boundingBox.max.x =
                                    childBoundingBoxCopy.max.x;
                            if (
                                boundingBox.max.y <
                                childBoundingBoxCopy.max.y
                            )
                                boundingBox.max.y =
                                    childBoundingBoxCopy.max.y;
                            if (
                                boundingBox.max.z <
                                childBoundingBoxCopy.max.z
                            )
                                boundingBox.max.z =
                                    childBoundingBoxCopy.max.z;
                        }
                    });

                    hitBoxGeometry.scale(
                        (boundingBox.max.x - boundingBox.min.x) *
                            1.01,
                        (boundingBox.max.y - boundingBox.min.y) *
                            1.01,
                        (boundingBox.max.z - boundingBox.min.z) *
                            1.01,
                    );
                }

                const position = new Vector3();
                node.getWorldPosition(position);
                hitBoxGeometry.computeBoundingBox();

                modelNodes.push({
                    ...structureNodeEntity,
                    node,
                    quaternion,
                    position,
                    hitBoxGeometry,
                });
            }
        });

        return modelNodes;
    }, [nodes, childEntities]);

    useEffect(() => {
        const alreadyLoaded = loadingList.get(modelFile);
        if (modelNodes && !alreadyLoaded) {
            loadingList.set(modelFile, true);
        }
    }, [modelNodes, loadingList, modelFile]);

    let centre = { latitude: 0, longitude: 0 };

    if (entity.boundaries) {
        centre = getCentre(entity.boundaries.polygons);
    } else {
        throw new Error(`structure with id ${entity.id} is missing boundaries. 
						We need boundaries in order to know where to load it on the map`);
    }

    const position = new Vector3(
        ...coordsToVector3(
            {
                longitude:
                    centre.longitude / 1000 + mapOrigin.longitude,
                latitude: centre.latitude / 1000 + mapOrigin.latitude,
            },
            {
                longitude: mapOrigin.longitude,
                latitude: mapOrigin.latitude,
            },
        ),
    );

    const rotation = new Euler(
        degreesToRadians(entity.rotationX ?? 0),
        degreesToRadians(
            entity.rotationY ? entity.rotationY - 90 : 0,
        ), // TODO adjust this since it's relative to north now
        degreesToRadians(entity.rotationZ ?? 0),
    );

    return (
        <group position={position} rotation={rotation}>
            {/* TODO: Handle Time series */}
            <Wind
                entity={entity}
                geometry={structureGeometry}
                parentWorldRotation={rotation}
            />
            <primitive object={scene} />
            {modelNodes.map((modelNode, meshIndex) => {
                return (
                    <object3D
                        quaternion={modelNode.quaternion}
                        position={modelNode.position}
                    >
                        {
                            <Capacity
                                key={meshIndex}
                                mapDiv={mapDiv}
                                lineage={[...lineage, entity]}
                                entity={modelNode.entity}
                                geometry={modelNode.hitBoxGeometry}
                                entityType={TwinEntityType.FLOOR}
                                labelBoundingBox={
                                    modelNode.hitBoxGeometry
                                        .boundingBox!
                                } // N.B. the geometry's bounding box should have been computed already
                                topLevelType={
                                    TwinEntityType.STRUCTURE
                                }
                            />
                        }
                    </object3D>
                );
            })}
        </group>
    );
};

export { Structure };
