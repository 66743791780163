import { useMemo } from "react";
import { ThreeEvent } from "@react-three/fiber";
import { useFilterContext } from "../../common/contexts/FilterAndModeContexts";
import { BufferGeometry, MeshBasicMaterial } from "three";
import { createFilterPathFromLineage } from "../../common/utils/createFilterPath";
import { Entity } from "../../@types/Entity";
import { TwinEntity } from "@repo/backend-types";
import { useEventContext } from "../../common/contexts/EventContext";
import { EntityHoverSelection } from "../../@types/EntityHoverSelection";

interface Props {
    entity: Entity;
    geometry: BufferGeometry;
    setHover: (hover: boolean) => void;
    setPressed: (hover: boolean) => void;
    mapDiv: HTMLDivElement;
    lineage: TwinEntity[];
}

const dummyMaterial = new MeshBasicMaterial();

function getMaxTwinDepthObject(
    e: ThreeEvent<PointerEvent> | ThreeEvent<MouseEvent>,
) {
    let maxDepthObj = e.intersections[0].object;

    e.intersections.forEach(intersection => {
        let currentDepth = intersection.object.userData.depth || 0;
        let maxDepth = maxDepthObj.userData.depth || 0;

        if (currentDepth > maxDepth) {
            maxDepthObj = intersection.object;
        }
    });

    return maxDepthObj;
}

const setTrueIfMaxDepthEntity = (
    e: ThreeEvent<PointerEvent>,
    mapDiv: HTMLDivElement,
    currentHovered3DEntity: EntityHoverSelection | null,
    setState: (state: boolean) => void,
    set3DState: (state: any) => void,
) => {
    const maxDepthObj = getMaxTwinDepthObject(e);

    const hoveredEntity = e.object.userData as Entity;
    const maxDepthEntity = maxDepthObj.userData as Entity;

    const hoveredDepth = hoveredEntity.depth ?? 0;
    const maxDepth = maxDepthEntity.depth ?? 0;

    if (
        (hoveredDepth > maxDepth ||
            hoveredEntity.id === maxDepthEntity.id) &&
        hoveredEntity.id !== currentHovered3DEntity?.id
    ) {
        // to reduce the number of re-renders
        mapDiv.style.cursor = "pointer";
        setState(true);
        set3DState({ id: hoveredEntity.id });
    } else {
        if (hoveredEntity.id !== maxDepthEntity.id) {
            setState(false);
        }
    }
};

export const Hitbox = ({
    entity,
    geometry,
    mapDiv,
    setHover,
    setPressed,
    lineage,
}: Props) => {
    const { setFilter } = useFilterContext();
    const {
        setCurrentHoveredEntityOn3DScene,
        currentHoveredEntityOn3DScene,
    } = useEventContext();

    const newGeo = useMemo(() => {
        const newGeo = geometry.clone();
        newGeo.scale(1, 1, 1);
        return newGeo;
    }, [geometry]);

    return (
        <mesh
            userData={entity}
            geometry={newGeo}
            visible={false}
            material={dummyMaterial} // this is purely so that lots of separate default materials don't get created
            onPointerDown={e => {
                if (e.button === 0) {
                    setTrueIfMaxDepthEntity(
                        e,
                        mapDiv,
                        currentHoveredEntityOn3DScene,
                        setPressed,
                        setCurrentHoveredEntityOn3DScene,
                    );
                }
            }}
            onPointerUp={() => {
                setPressed(false);
            }}
            onPointerCancel={() => {
                setPressed(false);
            }}
            onClick={e => {
                // N.B. this is twin-heirachy depth, not scene depth
                const maxDepthObj = getMaxTwinDepthObject(e);

                let maxDepthEntity =
                    maxDepthObj.userData as TwinEntity;
                let maxDepthId = maxDepthEntity.id;

                // this test ensures that only one of the clicked-on entities (the one deepest in the twin) calls setState
                if (maxDepthId === entity.id) {
                    const filter = createFilterPathFromLineage([
                        ...lineage,
                        entity,
                    ]);

                    // Update filter in filterContext
                    setFilter(filter);
                }
            }}
            onPointerEnter={e => {
                setTrueIfMaxDepthEntity(
                    e,
                    mapDiv,
                    currentHoveredEntityOn3DScene,
                    setHover,
                    setCurrentHoveredEntityOn3DScene,
                );
            }}
            onPointerMove={e => {
                setTrueIfMaxDepthEntity(
                    e,
                    mapDiv,
                    currentHoveredEntityOn3DScene,
                    setHover,
                    setCurrentHoveredEntityOn3DScene,
                );
            }}
            onPointerLeave={() => {
                setHover(false);
                setCurrentHoveredEntityOn3DScene(null);
                setPressed(false);
                mapDiv.style.cursor = "grab";
            }}
        />
    );
};
