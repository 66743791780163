// Types
import { Roles } from "../../@types/Auth0/Roles";

// Consts
import { customerAdminBottomMenu } from "../const/customerAdminBottomMenu";
import { partnerUserBottomMenu } from "../const/partnerUserBottomMenu";
import { tlAdminBottomMenu } from "../const/tlAdminBottomMenu";
import { allUsersBottomMenu } from "../const/allUsersBottomMenu";

export const getProtectedBottomMenu = (
    role: string,
    currentPath?: string,
) => {
    let bottomMenu = [...allUsersBottomMenu(currentPath)];
    switch (role) {
        case Roles.CUSTOMER_ADMIN:
            bottomMenu = [...customerAdminBottomMenu, ...bottomMenu];
            break;
        case Roles.PARTNER_USER:
            bottomMenu = [...partnerUserBottomMenu, ...bottomMenu];
            break;
        case Roles.TL_ADMIN:
            bottomMenu = [...tlAdminBottomMenu, ...bottomMenu];
            break;
    }
    return bottomMenu;
};
