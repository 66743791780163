// Types
import { Label } from "../../../../../../@types/Settings/Label";
import { LabelValueType } from "../../../../../../@types/Settings/LabelValueType";

// Utils
import { calcUtilisation } from "../../../../../utils/func-metrics/calcUtilisation";
import { getHeadcount } from "../../../../../utils/func-metrics/getHeadcount";

export const LabelSetGymCategoryLiveFull: Label[] = [
    {
        name: "Utilisation",
        valueName: "calcUtilisation",
        valueType: LabelValueType.FUNC_METRIC,
        value: calcUtilisation,
    },
    {
        name: "Capacity",
        valueType: LabelValueType.ENTITY_PROPERTY,
        value: "capacity",
    },
    {
        name: "Current Headcount",
        valueName: "getHeadcount",
        valueType: LabelValueType.FUNC_METRIC,
        value: getHeadcount,
    },
];
