// Types
import { NavItem } from "../../@types/NavItem";

// Utils
import { getProtectedTopMenu } from "./getProtectedTopMenu";
import { getProtectedBottomMenu } from "./getProtectedBottomMenu";

export const getQuickLinks = (role: string, currentPath: string) => {
    const topMenu = getProtectedTopMenu(role).filter(
        navItem => navItem.showOnQuickLinks === true,
    );
    const bottomMenu = getProtectedBottomMenu(
        role,
        currentPath,
    ).filter(navItem => navItem.showOnQuickLinks === true);
    let links: NavItem[] = [...topMenu, ...bottomMenu];
    return links;
};
