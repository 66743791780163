import { BufferGeometry } from "three";
import { NotTrackedMaterial } from "../materials/NotTrackedMaterial";
import {
    DIRECTION_LIGHT_POSITION,
    DIRECTION_LIGHT_TARGET,
} from "../SceneLighting";
import { OutlineSolid } from "../outlines/OutlineSolid";

const material = new NotTrackedMaterial(
    DIRECTION_LIGHT_POSITION,
    DIRECTION_LIGHT_TARGET,
);

type Props = {
    geometry: BufferGeometry;
    visible: boolean;
    showOutline: boolean;
};

const NotTracked = ({ geometry, visible, showOutline }: Props) => {
    return (
        <group visible={visible}>
            <OutlineSolid
                geometry={geometry}
                lineWidth={0.03}
                color={0x808080}
                visible={showOutline}
            />
            <mesh geometry={geometry} material={material} />
        </group>
    );
};

export { NotTracked };
