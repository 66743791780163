import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import styled from "styled-components";

// Types
import { TabOption } from "../../../@types/Tab";

// Components
import {
    Tab,
    TabList,
    TabListProps,
} from "@fluentui/react-components";

interface Props {
    className?: string;
    selectedValue?: string;
    size?: "small" | "medium" | "large";
    options: TabOption[];
    setValue?: Dispatch<SetStateAction<any>>;
}

const TLSwitcher: React.FC<Props> = ({
    className,
    selectedValue,
    size = "medium",
    options,
    setValue,
}) => {
    const [selectedTab, setSelectedTab] = useState<string>(
        selectedValue ? selectedValue : "",
    );

    const handleTabSelect: TabListProps["onTabSelect"] = (
        event,
        data,
    ) => {
        setSelectedTab(String(data.value));
    };

    useEffect(() => {
        if (selectedValue) {
            setSelectedTab(selectedValue);
        }
    }, [selectedValue]);

    return (
        <TabList
            className={className}
            size={size}
            selectedValue={selectedTab}
            onTabSelect={handleTabSelect}
        >
            {options.map((opt, i) => {
                return (
                    <Tab
                        icon={
                            opt.activeIcon &&
                            opt.value === selectedTab
                                ? opt.activeIcon
                                : opt.inactiveIcon
                        }
                        key={i}
                        value={opt.value}
                        onClick={
                            setValue
                                ? () => setValue(opt.value)
                                : () => {}
                        }
                    >
                        {opt.text}
                    </Tab>
                );
            })}
        </TabList>
    );
};

export default styled(TLSwitcher)`
    display: flex;
    button {
        flex: 1;
    }
`;
