/*
 * isSameDate
 * Takes in two date objects and checks they have the same Year, Month and Day
 *
 */
export const isSameDate = (date1: Date, date2: Date): boolean => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
};
