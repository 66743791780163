import * as yup from "yup";

export const passwordValidation = yup
    .string()
    .required("Please enter your new password")
    .test(
        "password-strength",
        "Password must be at least 8 characters long and contain at least 3 of the following: lowercase, uppercase, numbers, special characters (@#$%^&*).",
        value => {
            if (!value) return false;

            const minLength = value.length >= 8;
            const lowercase = /[a-z]/.test(value);
            const uppercase = /[A-Z]/.test(value);
            const numbers = /[0-9]/.test(value);
            const specialChars = /[@#$%^&*]/.test(value);

            // Count how many conditions are met
            const passedConditions = [
                lowercase,
                uppercase,
                numbers,
                specialChars,
            ].filter(Boolean).length;

            return minLength && passedConditions >= 3;
        },
    );
