import axios from "axios";
import routes from "./routes";

export const getOrgUrlsAndMetaData = async (
    envName: string,
    accessToken: string,
) => {
    const url = `${routes.get.organisations.getOrgUrlsAndMetaData}${envName}`;

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };
    return await axios.get(url, {
        headers: headers,
    });
};
