import React from "react";
import styled from "styled-components";

import {
    ChevronLeft20Filled,
    ChevronRight20Filled,
} from "@fluentui/react-icons";

interface Props {
    className?: string;
    limit: number;
    currentPage: number;
    totalPages: number;
    changeLimit: (newLimit: number) => void;
    switchPage: (newPage: number) => void;
    noMoreRecords: boolean;
}

const Pagination: React.FC<Props> = ({
    className,
    limit,
    currentPage,
    totalPages,
    noMoreRecords,
    changeLimit,
    switchPage,
}) => {
    return (
        <div className={className}>
            <div className="pagination-rows">
                <label htmlFor="ddlLimit">Rows per page:</label>
                <select
                    id="ddlLimit"
                    value={limit}
                    onChange={e => {
                        changeLimit(Number(e.target.value));
                    }}
                >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
            </div>
            <div className="pagination-stepper">
                <label htmlFor="stepper">
                    Page {currentPage + 1}{" "}
                    {totalPages ? `of ${totalPages}` : null}
                </label>
                <div id="stepper">
                    <button
                        className={
                            currentPage === 0 ? "disabled" : ""
                        }
                        disabled={currentPage === 0}
                        onClick={() => switchPage(currentPage - 1)}
                    >
                        <span className="icon">
                            <ChevronLeft20Filled />
                        </span>
                    </button>
                    <button
                        className={noMoreRecords ? "disabled" : ""}
                        disabled={noMoreRecords}
                        onClick={() => switchPage(currentPage + 1)}
                    >
                        <span className="icon">
                            <ChevronRight20Filled />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default styled(Pagination)`
    display: flex;
    margin-top: 1rem;

    .pagination-rows {
        display: flex;
    }

    .pagination-rows select {
        margin-left: 0.5rem;
        background: none;
        outline: none;
    }

    .pagination-stepper {
        display: flex;
        margin-left: auto;
    }

    .pagination-stepper label {
        margin-right: 1rem;
    }

    .pagination button {
        cursor: pointer;
    }

    .pagination button.disabled {
        color: #cccccc;
    }
`;
