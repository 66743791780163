import { CSSProperties } from "react";
import styled from "styled-components";

// Types
import { Entity } from "../../@types/Entity";
import { Label } from "../../@types/Settings/Label";

// Components
import LabelValue from "./LabelValue";

interface Props {
    className?: string;
    entity: Entity;
    labels: Label[];
    cssProperties?: CSSProperties;
}

const Labels = ({
    className,
    entity,
    labels,
    cssProperties,
}: Props) => {
    return (
        <ul className={className} style={cssProperties}>
            {labels.map((label, i) => {
                return (
                    <li key={i}>
                        <span>{label.name}</span>{" "}
                        <LabelValue entity={entity} label={label} />
                    </li>
                );
            })}
        </ul>
    );
};

export default styled(Labels)`
    margin: 2rem 0 0.5rem 0;
    list-style: none;
    font-size: 12px;

    li {
        color: #242424;
        display: flex;
        line-height: 100%;
        font-size: inherit;
        font-weight: bold;
        margin-bottom: 8px;
    }

    li span {
        flex: 1;
        font-weight: normal;
    }
`;
