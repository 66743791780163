import React, { forwardRef } from "react";
import styled from "styled-components";

// Components
import { Label, MessageBar } from "@fluentui/react-components";

interface Props {
    className?: string;
    id: string;
    label?: string;
    currentValue?: boolean;
    errorMessage?: string;
}

const TLAdminSwitch: React.FC<Props> = forwardRef<
    HTMLInputElement,
    Props
>(
    (
        {
            className,
            id,
            label,
            currentValue,
            errorMessage,
            ...props
        },
        ref,
    ) => {
        return (
            <>
                <div className={className}>
                    {label && (
                        <Label htmlFor={id} className="label">
                            {label}
                        </Label>
                    )}
                    <label className="switch">
                        <input
                            ref={ref}
                            id={id}
                            type="checkbox"
                            {...props}
                        />
                        <span className="slider"></span>
                    </label>
                </div>
                {errorMessage ? (
                    <MessageBar
                        id={`${id}-error`}
                        intent="error"
                        layout="multiline"
                    >
                        {errorMessage}
                    </MessageBar>
                ) : null}
            </>
        );
    },
);

export default styled(TLAdminSwitch)`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    .label {
        width: 178px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 50px !important;
        height: 26px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: none;
        border: 1px solid #00bbcc;
        transition: 0.4s;
        border-radius: 34px;
    }

    .slider:before {
        content: "";
        position: absolute;
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 3px;
        background-color: #00bbcc;
        transition: 0.4s;
        border-radius: 50%;
    }

    .switch:hover input:not(:checked) + .slider {
        border-color: #5fd8df;
        background-color: none;
    }

    .switch:hover input:not(:checked) + .slider:before {
        background-color: #5fd8df;
    }

    input:checked + .slider {
        background-color: #00bbcc;
    }

    .switch:hover input:checked + .slider {
        background-color: #5fd8df;
    }

    input:checked + .slider:before {
        background: white;
    }

    input:checked + .slider:before {
        transform: translateX(24px);
    }
`;
