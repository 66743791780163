import { useState } from "react";
import {
    FloorPlannerCustomizationError,
    FloorPlannerLayout,
    validateFML,
} from "./floorplanner";
import { ImportErrorsList } from "./ImportErrorsList";

const FloorPlannerImport = () => {
    const [file, setFile] = useState<File | null>(null);
    const [validationResult, setValidationResult] = useState<
        FloorPlannerCustomizationError[] | null
    >(null);

    return (
        <div style={{ margin: "20px" }}>
            <form>
                <input
                    id="file"
                    type="file"
                    onChange={e => {
                        if (e.target.files) {
                            const targetFile = e.target.files[0];
                            setFile(targetFile);
                            if (targetFile.name.endsWith("json")) {
                                targetFile.text().then(text => {
                                    const layout = JSON.parse(
                                        text,
                                    ) as FloorPlannerLayout;
                                    const result =
                                        validateFML(layout);
                                    if ("errors" in result) {
                                        setValidationResult(
                                            result.errors,
                                        );
                                    } else {
                                        setValidationResult([]);
                                    }
                                });
                            }
                        }
                    }}
                />
            </form>
            <p>{file && file.name}</p>
            {validationResult !== null ? (
                validationResult.length > 0 ? (
                    <ImportErrorsList errors={validationResult} />
                ) : (
                    <h3>No Errors Found</h3>
                )
            ) : (
                <></>
            )}
        </div>
    );
};

export default FloorPlannerImport;
