/*
 * getHeadcount
 *
 */
// Types
import { DataMode } from "../../../@types/DataMode";
import { DataValue } from "../../../@types/Data/DataValue";

// Utils
import { getDataValue } from "./getDataValue";
import { roundToOneDecimalPlace } from "../roundToOneDecimalPlace";

export const getHeadcount = (
    dataMode: DataMode,
    data: DataValue[],
    id: string,
): string => {
    if (data && data.length > 0) {
        const value = getDataValue(dataMode, data, id)?.value;
        return isNaN(value)
            ? `0`
            : `${dataMode === DataMode.LIVE ? value : roundToOneDecimalPlace(value).toFixed(1)}`;
    } else {
        return "N/A";
    }
};
