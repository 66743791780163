import { DateTime } from "luxon";

export const isFullDay = (
    startDateTime: string,
    finishDateTime: string,
): boolean => {
    let result = false;
    const sdt = DateTime.fromISO(startDateTime);
    const fdt = DateTime.fromISO(finishDateTime);
    if (
        sdt.hour === 0 &&
        sdt.minute === 0 &&
        sdt.second === 0 &&
        fdt.hour &&
        fdt.minute &&
        fdt.second
    ) {
        result = true;
    }
    return result;
};
