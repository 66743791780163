import React from "react";

// Components
import {
    Accordion,
    AccordionHeader,
    AccordionHeaderSize,
    AccordionItem,
    AccordionPanel,
} from "@fluentui/react-components";
import TLCard from "../elements/TLCard/TLCard";
import LabelHeader from "../LabelHeader/LabelHeader";

interface Props {
    className?: string;
    id: string;
    showId?: boolean;
    icon?: JSX.Element;
    title?: string;
    size?: AccordionHeaderSize;
    defaultOpenItems?: string[];
    children?: JSX.Element;
    showHeader?: boolean;
}

const Panel: React.FC<Props> = ({
    className,
    id,
    showId = false,
    size,
    icon,
    title,
    defaultOpenItems,
    children,
    showHeader = true,
}) => {
    return (
        <div className={className}>
            <Accordion
                multiple={false}
                collapsible={true}
                defaultOpenItems={defaultOpenItems}
            >
                <AccordionItem value={id}>
                    <TLCard className="accordion-item">
                        <>
                            {showHeader && (
                                <AccordionHeader
                                    icon={icon}
                                    size={size ? size : "small"}
                                    expandIconPosition="end"
                                >
                                    <div className="w-full">
                                        <LabelHeader
                                            id={id}
                                            fontSize="14px"
                                            title={`${title}`}
                                        />
                                    </div>
                                </AccordionHeader>
                            )}
                            <AccordionPanel>
                                {children}
                            </AccordionPanel>
                        </>
                    </TLCard>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default Panel;
