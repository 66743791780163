import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { DocumentNode } from "graphql";

// Types
import { AppMode } from "../../../@types/Mode";
import { useModeContext } from "../../../common/contexts/FilterAndModeContexts";

// Context
import { useTwinContext } from "../../../common/contexts/TwinContext";
import { useSettingsContext } from "../../../common/contexts/SettingsContext";

// Data
import { QUERY_TWIN_ENTITY } from "@repo/backend-utils";
import { QUERY_METRIC } from "../../../common/api/live/gql/queryMetric";

// Utils
import Config from "../../../common/Config";
import { parseTwin } from "../../../common/utils/parseTwin";

// Components
import Frame from "../../../components/Frame/Frame";
import The3DScene from "../../../components/3DScene/The3DScene";
import TLSpinnerPage from "../../../components/TLSpinnerPage/TLSpinnerPage";

interface Props {
    className?: string;
}
interface FetchDataResult {
    model: any;
}

const TwinPage: React.FC<Props> = ({ className }) => {
    const { settings, setMetrics } = useSettingsContext();
    const { setAppModeWithFilterReset } = useModeContext();
    const { setTwin } = useTwinContext();
    const apolloClient = useApolloClient();
    const twinPoller = useRef<
        ReturnType<typeof setInterval> | number | null
    >(null);

    const navigate = useNavigate();
    const location = useLocation();

    const [loadingBanterRunning, setLoadingBanterRunning] =
        useState(false);
    const [allModelsLoaded, setAllModelsLoaded] = useState(false);

    const onAllModelsLoaded = useCallback(
        () => setAllModelsLoaded(true),
        [setAllModelsLoaded],
    );

    const twinId = location.state?.twinId;

    /*
     * Triggered when the Main Page loads
     * Fetches Model and Tags for the Twin/Site
     * Passes both these datasets into the TwinParser which preps the data for the 2D and 3D scene
     *
     */
    useEffect(() => {
        // If no twin has been selected redirect back to Twin Selector page
        if (!twinId) {
            setTwin(null);
            setAppModeWithFilterReset(AppMode.EDIT);
        }

        if (twinId) {
            const fetchModel = async (
                query: DocumentNode,
                id: String,
            ): Promise<undefined | FetchDataResult> => {
                try {
                    const { data: model } = await apolloClient.query({
                        query: query,
                        variables: {
                            filter: {
                                bID: {
                                    eq: id,
                                },
                            },
                        },
                    });
                    return { model };
                } catch (error) {
                    console.error("Error:", error);
                }
            };

            const fetchMetrics = async (): Promise<
                undefined | any
            > => {
                try {
                    const { data } = await apolloClient.query({
                        query: QUERY_METRIC,
                    });
                    return data.queryMetric;
                } catch (error) {
                    console.error("Error:", error);
                }
            };

            const loadTwin = () =>
                fetchModel(QUERY_TWIN_ENTITY, twinId).then(
                    (data: undefined | FetchDataResult) => {
                        // Parse/set the Twin using the model
                        let result;

                        if (settings && settings.organisation) {
                            result = parseTwin(
                                settings.organisation,
                                data?.model.queryTwinEntity[0],
                                settings?.physicalEntities
                                    ? settings?.physicalEntities
                                    : [],
                                settings?.filterEntities
                                    ? settings?.filterEntities
                                    : [],
                                false, // TODO: I've simply disabled the preflight check for now. It causes problems on Howler due to how we poll live data.
                                "", // TODO: Part of the preflight check. Disabled for now settings?.apolloExplorerURI
                            );
                        }

                        if (!result) {
                            throw new Error(
                                "Unable to parse twin model",
                            );
                        }

                        setTwin(result.twin);
                    },
                );

            if (!allModelsLoaded) setLoadingBanterRunning(true); // only do this for the first load
            loadTwin();

            // Add artificial delay to initial load to allow loading messages to display
            const delay = Config.initialOnloadDelay
                ? parseInt(Config.initialOnloadDelay)
                : 6000;
            setTimeout(() => {
                setLoadingBanterRunning(false);
                //setAllModelsLoaded(true) // Uncomment this line if you disable the 3D scene
            }, delay);

            if (twinPoller.current) {
                clearInterval(twinPoller.current);
            }

            if (settings?.pollTwin) {
                twinPoller.current = setInterval(loadTwin, 10000); // twice per minute
            } else {
                twinPoller.current = 1;
            }

            // e.g. temperature, speed, countEntity, windSpeed
            fetchMetrics().then(data => {
                if (data) {
                    setMetrics(data);
                }
            });
        }

        return () => {
            if (twinPoller.current) {
                clearInterval(twinPoller.current);
                twinPoller.current = null;
            }
        };
    }, [
        settings,
        apolloClient,
        settings?.entities,
        settings?.pollTwin,
        twinId,
        allModelsLoaded,
        navigate,
        setMetrics,
        setTwin,
        setAppModeWithFilterReset,
    ]);

    return (
        <>
            <div className="relative">
                {twinId &&
                (loadingBanterRunning || !allModelsLoaded) ? (
                    <TLSpinnerPage />
                ) : null}
                <div id="twin-page" className="w-full py-8 relative">
                    <The3DScene
                        className="absolute z-0 w-full h-screen"
                        onAllModelsLoaded={onAllModelsLoaded}
                        allModelsLoaded={allModelsLoaded}
                    />
                    <Frame />
                </div>
            </div>
        </>
    );
};

export default TwinPage;
