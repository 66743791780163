import { Object3D, Vector3 } from "three";

export const DIRECTION_LIGHT_POSITION = new Vector3(7, 7, 10);
export const DIRECTION_LIGHT_TARGET = new Object3D();

const AMBIENT_INTENSITY = 3;
const DIRECTIONAL_INTENSITY = 1;
const DIRECTION_COLOR = 0xffffff;

const LightsAndShadows = () => {
    return (
        <>
            <directionalLight
                castShadow
                shadow-bias={-0.003}
                position={DIRECTION_LIGHT_POSITION}
                color={DIRECTION_COLOR}
                intensity={DIRECTIONAL_INTENSITY}
                shadow-mapSize={1024}
                target={DIRECTION_LIGHT_TARGET}
            >
                <orthographicCamera
                    attach="shadow-camera"
                    args={[0, 45, -10, 20, 0.1, 50]}
                />
            </directionalLight>
            <ambientLight intensity={AMBIENT_INTENSITY} />
        </>
    );
};

export { LightsAndShadows };
