import React from "react";

// Types
import { Entity } from "../../@types/Entity";

// Components
import { AccordionToggleData } from "@fluentui/react-components";
import EntityAccordionItemWithContext from "./EntityAccordionItemWithContext";

interface Props {
    entities: Entity[];
    handleToggle: (isCollapsed: AccordionToggleData<string>) => void;
    live: boolean;
}

const EntityAccordion: React.FC<Props> = React.memo(
    ({ entities, handleToggle, live }) => {
        return (
            <>
                {entities.map((entity, i) => {
                    return (
                        <EntityAccordionItemWithContext
                            key={i}
                            entity={entity}
                            handleToggle={handleToggle}
                            live={live}
                        />
                    );
                })}
            </>
        );
    },
);

export default EntityAccordion;
