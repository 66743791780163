import { func } from "prop-types";

type FloorPlannerPoint = {
    x: number;
    y: number;
};

type FloorPlannerOpening = {
    t: number; // this is how far along the wall's length the opening is
    type: string;
    width: number;
};

type FloorPlannerWall = {
    a: FloorPlannerPoint;
    b: FloorPlannerPoint;
    thickness: number;
    openings: FloorPlannerOpening[];
};

type FloorPlannerArea = {
    name?: string;
    poly: FloorPlannerPoint[];
};

type FloorPlannerSurface = {
    name?: string;
    customName: string;
    refid: string;
    poly: FloorPlannerPoint[];
};

type FloorPlannerItem = {
    refid: string;
    x: number;
    y: number;
    z: number;
    name: string;
    rotation: number;
};

type FloorPlannerDesign = {
    name: string;
    areas: FloorPlannerArea[];
    surfaces: FloorPlannerSurface[];
    items: FloorPlannerItem[];
    walls: FloorPlannerWall[];
};

type FloorPlannerFloor = {
    id: number;
    name: string;
    designs: FloorPlannerDesign[];
};

export type FloorPlannerLayout = {
    floors: FloorPlannerFloor[];
    name: string;
};

//////////////////////////////////

type ParsedCustomNameData = {
    bID: string;
    name: string;
    capacity: number;
    track: boolean;
    parentID: string | null;
};

type DuplicateIdError = {
    id: string;
    entityInfo: [ParsedCustomNameData, ParsedCustomNameData];
};

type CustomNameParsingError = {
    message: string;
    value: string;
};

type CustomNameParsingErrors = {
    errors: CustomNameParsingError[];
};

/////////////////////////////////

function isValidBid(bID: string) {
    return (
        bID.length >= 2 &&
        bID[0].match(/[a-z]/i) &&
        bID[1].match(/[0-9]/i)
    );
}

function findLatestDesign(designs: FloorPlannerDesign[]) {
    let markedCurrent;

    // firstly, look for a design which has been marked current
    for (const design of designs) {
        if (design.name.toLowerCase().includes("current")) {
            markedCurrent = design;
        }
    }

    if (markedCurrent) {
        return markedCurrent;
    } else {
        return designs[0];
    }
}

function parseFloorName(
    name: string,
): string | CustomNameParsingError {
    try {
        const bID = name.split("-")[1];
        return bID;
    } catch {
        return { message: "bID not found for floor", value: name };
    }
}

export type FloorPlannerCustomizationError =
    | CustomNameParsingError
    | DuplicateIdError;

type ValidationResult =
    | FloorPlannerLayout
    | {
          errors: FloorPlannerCustomizationError[];
      };

export function isValidResult(
    validationResult: ValidationResult,
): validationResult is FloorPlannerLayout {
    if ("errors" in validationResult) {
        return false;
    } else {
        return true;
    }
}

export function validateFML(
    rawFML: FloorPlannerLayout,
): ValidationResult {
    let errors: FloorPlannerCustomizationError[] = [];
    const customEntityInfo: ParsedCustomNameData[] = [];
    const floorsData = rawFML.floors;

    for (const floorData of floorsData) {
        const floorLayout = findLatestDesign(floorData.designs);

        const floorNameResult = parseFloorName(floorLayout.name);

        if (typeof floorNameResult !== "string") {
            errors.push(floorNameResult);
        }

        const surfacesData = floorLayout.surfaces;

        for (const surfaceData of surfacesData) {
            const customNameData = surfaceData.customName;

            if (customNameData) {
                const result = parseCustomName(customNameData);
                if ("errors" in result) {
                    errors.push(...result.errors);
                } else {
                    customEntityInfo.push(result);
                }
            }
        }

        const assetsData = floorLayout.items;

        for (const assetData of assetsData) {
            const customNameData = assetData.name;

            if (customNameData) {
                const result = parseCustomName(customNameData);
                if ("errors" in result) {
                    errors.push(...result.errors);
                } else {
                    customEntityInfo.push(result);
                }
            }
        }
    }

    for (let i = 0; i < customEntityInfo.length; i++) {
        const entity = customEntityInfo[i];
        for (let j = 0; j < customEntityInfo.length; j++) {
            if (i == j) {
                continue;
            } else {
                const secondEntity = customEntityInfo[j];
                if (entity.bID == secondEntity.bID) {
                    errors.push({
                        id: entity.bID,
                        entityInfo: [entity, secondEntity],
                    });
                }
            }
        }
    }

    if (errors.length > 0) {
        return { errors };
    } else {
        return rawFML;
    }
}

function parseCustomName(
    customName: string,
): ParsedCustomNameData | CustomNameParsingErrors {
    const errors: CustomNameParsingError[] = [];

    const argumentsProvided = customName
        .split(",")
        .map(param => param.trim());
    const numberOfArgumentsProvided = argumentsProvided.length;

    if (numberOfArgumentsProvided < 3) {
        return {
            errors: [
                {
                    message:
                        "custom name does not have minimum mandatory arguments",
                    value: customName,
                },
            ],
        };
    }

    if (numberOfArgumentsProvided > 6) {
        return {
            errors: [
                {
                    message:
                        "custom name has more than the maximum arguments",
                    value: customName,
                },
            ],
        };
    }

    const bID = argumentsProvided[0];

    if (!isValidBid(bID)) {
        errors.push({
            message:
                "custom name bid is not in the right format: [A-Z]1[0-9]3",
            value: customName,
        });
    }

    const name = argumentsProvided[1];
    const capacityRaw = argumentsProvided[2];
    let track = true; // default
    let parentID = null; // default

    if (!bID) {
        errors.push({
            message: "custom name is missing a valid id",
            value: customName,
        });
    }
    if (!name) {
        errors.push({
            message: "custom name is missing a valid name",
            value: customName,
        });
    }

    if (!capacityRaw) {
        errors.push({
            message: "custom name is missing a valid capacity",
            value: customName,
        });
    }

    const capacity = parseInt(capacityRaw);

    if (numberOfArgumentsProvided >= 4) {
        track = argumentsProvided[3] == "0" ? false : true; // empty string treated as True
    }

    if (numberOfArgumentsProvided === 5) {
        parentID = argumentsProvided[4] ?? null; // empty string treated as None
    }

    if (errors.length > 0) {
        return { errors };
    } else {
        return { bID, name, capacity, track, parentID };
    }
}
