import React, { useState } from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";

// Components
import TLSpinnerPage from "../TLSpinnerPage/TLSpinnerPage";

interface Props {
    className?: string;
    centre: boolean;
}

const SinglePanelLayout: React.FC<Props> = ({
    className,
    centre = false,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <>
            {isLoading && <TLSpinnerPage loadingMessages={[]} />}
            <div
                className={className}
                style={{
                    backgroundImage:
                        'url("/assets/images/atom-bg.png")',
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                }}
            >
                <main className="panel-layout">
                    <div className="panel">
                        <Outlet
                            context={{ isLoading, setIsLoading }}
                        />
                    </div>
                </main>
            </div>
        </>
    );
};

export default styled(SinglePanelLayout)`
    .panel-layout {
        min-height: 100vh;
        padding: 5rem 0;
    }

    .panel {
        margin: 0 auto;
        width: 506px;
        background: #fcfcfb;
        border: 1px #cccccc solid;
        border-radius: 18px; /* Standard */
        -webkit-border-radius: 18px; /* For older Safari and Chrome */
        -moz-border-radius: 18px; /* For older Firefox */
        padding: 4rem;
    }

    .panel h1 {
        font-size: 1.65rem;
        text-align: center;
        line-height: 120%;
    }
`;
