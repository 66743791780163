import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

// Types
import { Legal } from "../../../@types/Auth0/Legal";
import {
    getUserPermissions,
    Roles,
} from "../../../@types/Auth0/Roles";

// Data
import { getOrgUrlsAndMetaData } from "../../../common/api/middleware/getOrgUrlsAndMetadata";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Utils
import { extractClaims } from "../../../common/utils/extractClaims";
import { handleRoleRouting } from "../../../common/utils/handleRoleRouting";

// Components
import TLSpinnerPage from "../../../components/TLSpinnerPage/TLSpinnerPage";

const LoginCallbackPage: React.FC = () => {
    const {
        user,
        isAuthenticated,
        isLoading,
        getAccessTokenSilently,
    } = useAuth0();
    const { setAuthMetadata } = useUserContext();
    const navigate = useNavigate();

    const [accessToken, setAccessToken] = useState<string | null>(
        null,
    );

    const currentLegalVersions = {
        acceptableUseVersion: 1,
        dataProcessingVersion: 1,
        saasStandardTerms: 1,
    };

    useEffect(() => {
        const getAccessToken = async () => {
            setAccessToken(await getAccessTokenSilently());
        };
        getAccessToken();
    }, [isAuthenticated, getAccessTokenSilently]);

    const isLegalValid = (legal: Legal, roles: string[]) => {
        let isValid = false;

        if (!legal) {
            return isValid;
        }

        const acceptableUseVersion =
            currentLegalVersions.acceptableUseVersion;
        let acceptableUseValid = false;

        const dataProcessingVersion =
            currentLegalVersions.dataProcessingVersion;
        let dataProcessingValid = false;

        const saasStandardTerms =
            currentLegalVersions.saasStandardTerms;
        let saasStandardTermsValid = false;

        if (roles.includes(Roles.CUSTOMER_ADMIN)) {
            if (
                legal.acceptableUse?.version ===
                    acceptableUseVersion &&
                legal?.acceptableUse?.acceptedAt
            ) {
                acceptableUseValid = true;
            }
            if (
                legal.dataProcessing?.version ===
                    dataProcessingVersion &&
                legal?.dataProcessing?.acceptedAt
            ) {
                dataProcessingValid = true;
            }
            if (
                legal?.saasStandardTerms?.version ===
                    saasStandardTerms &&
                legal?.saasStandardTerms?.acceptedAt
            ) {
                saasStandardTermsValid = true;
            }

            isValid =
                acceptableUseValid &&
                dataProcessingValid &&
                saasStandardTermsValid;
        } else if (roles.includes(Roles.CUSTOMER_USER)) {
            if (
                legal.acceptableUse?.version ===
                    acceptableUseVersion &&
                legal?.acceptableUse?.acceptedAt
            ) {
                acceptableUseValid = true;
            }

            isValid = acceptableUseValid;
        }
        return isValid;
    };

    const setOrganisation = useCallback(
        async (organisationName: string, envName: string) => {
            if (accessToken) {
                const orgUrlsAndMetaData =
                    await getOrgUrlsAndMetaData(envName, accessToken);
                if (orgUrlsAndMetaData) {
                    setAuthMetadata({
                        organisation: organisationName,
                        aiUrl: orgUrlsAndMetaData.data.aiUrl,
                        dgraphUrl: orgUrlsAndMetaData.data.dgraphUrl,
                        envName: orgUrlsAndMetaData.data.envName,
                        environment:
                            orgUrlsAndMetaData.data.environment,
                        name: user?.name,
                        firstName: user?.given_name,
                        lastName: user?.family_name,
                        email: user?.email,
                        tokens: {
                            accessToken: accessToken,
                        },
                        tsdbUrl: `https://${orgUrlsAndMetaData.data.tsdbUrl}`,
                    });
                }
            }
        },
        [
            accessToken,
            setAuthMetadata,
            user?.name,
            user?.given_name,
            user?.family_name,
            user?.email,
        ],
    );

    useEffect(() => {
        const handleRedirect = async () => {
            if (isLoading) return; // Wait for loading to complete

            if (isAuthenticated && user && accessToken) {
                let redirectPath = "/something-went-wrong";
                let redirectState = { state: {}, replace: true };
                let claims = extractClaims(user);

                // Ensure a user cannot proceed if they haven't been assigned a role
                if (claims.roles && claims.roles.length !== 0) {
                    // Set what data we do have in AuthMetaData
                    setAuthMetadata({
                        roles: claims.roles,
                        permissions: getUserPermissions(claims.roles),
                        tokens: {
                            mapboxToken: claims.mapboxToken,
                            modelUrlKey: claims.modelUrlKey,
                        },
                    });

                    if (
                        claims.organisations &&
                        claims.organisations.length > 0
                    ) {
                        redirectPath = handleRoleRouting(
                            claims.roles,
                        );

                        if (
                            claims.roles.includes(
                                Roles.CUSTOMER_ADMIN,
                            ) ||
                            claims.roles.includes(Roles.CUSTOMER_USER)
                        ) {
                            const auth0OrgMetadata =
                                claims.organisations[0].split(":");
                            setOrganisation(
                                auth0OrgMetadata[0],
                                auth0OrgMetadata[1],
                            );
                        }
                    }

                    if (
                        !(
                            claims.roles.includes(Roles.TL_ADMIN) ||
                            claims.roles.includes(Roles.PARTNER_USER)
                        )
                    ) {
                        if (
                            isLegalValid(claims.legal, claims.roles)
                        ) {
                            redirectPath = "/select-twin";
                        } else {
                            const customerAdmin =
                                claims.roles.includes(
                                    Roles.CUSTOMER_ADMIN,
                                )
                                    ? true
                                    : false;
                            redirectPath = "/account/legal";
                            redirectState = {
                                state: {
                                    currentLegalVersions:
                                        currentLegalVersions,
                                    customerAdmin: customerAdmin,
                                    redirectPath: "/select-twin",
                                },
                                replace: true,
                            };
                        }
                    }
                }

                navigate(redirectPath, redirectState);
            }
        };

        handleRedirect();
    }, [
        accessToken,
        isAuthenticated,
        isLoading,
        user,
        navigate,
        setAuthMetadata,
        setOrganisation,
    ]);

    if (isLoading) {
        return <TLSpinnerPage loadingMessages={[]} />;
    }
};

export default LoginCallbackPage;
