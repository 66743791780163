import { Color, RawShaderMaterial, Texture } from "three";
import { ColorRepresentation } from "three";

// docs on what attributes and unfiforms are passed https://threejs.org/docs/#api/en/renderers/webgl/WebGLProgram
const vertexShader = `

uniform mat4 modelMatrix;
uniform mat4 viewMatrix;
uniform mat4 projectionMatrix;
uniform float time; 

attribute vec3 position;
attribute vec2 uv;
attribute vec3 normal;

varying vec2 texcoord;

float FREQUENCY_FACTOR = 100.0;
float PHASE_FREQUENCY_FACTOR = 10.0;
float SHIFT_SCALE_FACTOR = 100.0;

void main() {

  vec3 shift = sin(-position.z * FREQUENCY_FACTOR + time * PHASE_FREQUENCY_FACTOR) * normal / SHIFT_SCALE_FACTOR;
  vec4 modelPosition = modelMatrix * vec4(position + shift, 1.0);
  vec4 viewPosition = viewMatrix * modelPosition;
  vec4 projectedPosition = projectionMatrix * viewPosition;

  gl_Position = projectedPosition;
  texcoord = uv;

}`;

const fragmentShader = `

precision highp float;

varying vec2 texcoord;

uniform vec3 color;
uniform sampler2D texture;

void main() {

    gl_FragColor = vec4(color, texture2D(texture, texcoord).w);

}`;

export class WindMaterial extends RawShaderMaterial {
    color: Color;
    constructor(
        direction: number,
        color: ColorRepresentation,
        texture: Texture,
    ) {
        const colorUniform = new Color(color);

        const uniforms = {
            direction: { value: direction },
            color: { value: colorUniform },
            texture: { value: texture },
            time: { value: 0 },
        };

        super({ uniforms, vertexShader, fragmentShader });

        this.color = new Color(color);
    }

    updateColor(color: ColorRepresentation) {
        this.uniforms.color.value.set(color);
    }

    addTime(dt: number) {
        this.uniforms.time.value += dt;
    }
}
