export enum Roles {
    CUSTOMER_ADMIN = "CUSTOMER_ADMIN",
    CUSTOMER_USER = "CUSTOMER_USER",
    PARTNER_USER = "PARTNER_USER",
    TL_ADMIN = "TL_ADMIN",
}

export enum Permission {
    TWIN_EDITING,
}

// Add more mappings from roles to permissions here
const permissionsLookup: Record<string, Permission[]> = {
    TL_ADMIN: [Permission.TWIN_EDITING],
};

export function getUserPermissions(roles: string[]) {
    const permissions = new Set<Permission>();

    roles.forEach(role => {
        const foundPermissions = permissionsLookup[role];

        if (foundPermissions) {
            foundPermissions.forEach(permission =>
                permissions.add(permission),
            );
        }
    });

    return permissions;
}
