import { ReactNode } from "react";

// Types
import { Permission } from "../../@types/Auth0/Roles";

// Context
import { useUserContext } from "../../common/contexts/UserContext";

type Props = {
    necessaryPermissions: Permission[]; // a list of all the permissions required. Note this is and AND list not and OR list
    children: ReactNode;
};

// This is just a small utility to wrap components that require permissions in order to be shown
const Permissioned: React.FC<Props> = ({
    necessaryPermissions,
    children,
}) => {
    const { authMetadata } = useUserContext();

    let hasAllPermissions = true;

    for (const permission of necessaryPermissions) {
        if (!authMetadata?.permissions?.has(permission)) {
            hasAllPermissions = false; // A single failure will leave trigger the bool to switch
        }
    }

    if (hasAllPermissions) {
        return children;
    } else {
        return null;
    }
};

export default Permissioned;
