import axios from "axios";
import routes from "./routes";

interface LegalMiddlewareInput {
    saasStandardTerms?: number;
    saasStandardTermsAcceptedAt?: string;
    dataProcessing?: number;
    dataProcessingAcceptedAt?: string;
    acceptableUseVersion: number;
    acceptableUseAcceptedAt: string;
}

export const acceptTerms = async (
    accessToken: string,
    currentLegalVersions: {
        acceptableUseVersion: number;
        dataProcessingVersion: number;
        saasStandardTerms: number;
    },
    customerAdmin: boolean,
) => {
    let success = false;
    const url = routes.post.terms.acceptTerms;
    const now = new Date();
    const timestamp = now.toISOString();

    let data: LegalMiddlewareInput = {
        acceptableUseVersion:
            currentLegalVersions.acceptableUseVersion,
        acceptableUseAcceptedAt: timestamp,
    };

    if (customerAdmin) {
        data = {
            saasStandardTerms: currentLegalVersions.saasStandardTerms,
            saasStandardTermsAcceptedAt: timestamp,
            dataProcessing:
                currentLegalVersions.dataProcessingVersion,
            dataProcessingAcceptedAt: timestamp,
            acceptableUseVersion:
                currentLegalVersions.acceptableUseVersion,
            acceptableUseAcceptedAt: timestamp,
        };
    }

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        await axios.post(url, data, {
            headers: headers,
        });
        success = true;
    } catch (error) {
        success = false;
    }
    return success;
};
