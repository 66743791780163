import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import styled from "styled-components";
import { DebouncedState } from "use-debounce";

// Types
import { CurrentSelection } from "../../@types/CurrentSelection";
import { DataMode } from "../../@types/DataMode";
import { Entity } from "../../@types/Entity";
import { Entity as EntityPref } from "../../@types/Settings/Entity";
import { FilterType } from "../../@types/FilterType";
import { LabelDisplayOn } from "../../@types/Settings/LabelDisplayOn";
import { LabelMode } from "../../@types/LabelMode";
import { LabelSet } from "../../@types/Settings/LabelSet";
import { UIComponentHeader } from "../../@types/Settings/UIComponentHeader";

// Context
import { useFilterContext } from "../../common/contexts/FilterAndModeContexts";
import { useSettingsContext } from "../../common/contexts/SettingsContext";
import { useTwinContext } from "../../common/contexts/TwinContext";

// Data
import { excludedTypes } from "../../common/data/excludedTypes";

// Utils
import { assetIconComponents } from "../../common/utils/assetIconComponents";
import { createFilterPath } from "../../common/utils/createFilterPath";
import { findObjectById } from "../../common/utils/findObjectById";
import { getLabels } from "../../common/utils/getLabels";
import { stringToTwinEntityType } from "../../common/utils/stringToTwinEntityType";

// Components
import {
    AccordionHeader,
    AccordionHeaderSize,
    AccordionItem,
    AccordionPanel,
    AccordionToggleData,
} from "@fluentui/react-components";
import AssetAccordion from "./EntityAccordion";
import EntityIndicator from "../EntityIndicator/EntityIndicator";
import LabelHeader from "../LabelHeader/LabelHeader";
import Labels from "../Labels/Labels";
import TLCard from "../elements/TLCard/TLCard";
import AssetItem from "./AssetItem";

interface Props {
    className?: string;
    handleMouseEnter: DebouncedState<
        (id: string, type: "ASSET" | "ENTITY") => void
    >;
    handleMouseLeave: DebouncedState<() => void>;
    handleToggle: (isCollapsed: AccordionToggleData<string>) => void;
    size: AccordionHeaderSize;
    entity: Entity;
    previousHoveredEntity?: CurrentSelection | null;
    currentHoveredEntity?: CurrentSelection | null;
    currentHovered3DEntity?: CurrentSelection | null;
    labelComp: JSX.Element;
    live: boolean;
    isSelected: boolean;
}

const EntityAccordionItem: React.FC<Props> = React.memo(
    ({
        className,
        size,
        handleToggle,
        handleMouseEnter,
        handleMouseLeave,
        entity,
        currentHoveredEntity,
        currentHovered3DEntity,
        live,
        isSelected,
    }) => {
        const { filter, heroMetric, removeFilterByType, setFilter } =
            useFilterContext();
        const { settings } = useSettingsContext();
        const { twin } = useTwinContext();

        const setFilterPath = useCallback(
            (pathId: string) => {
                if (twin) {
                    // Clear all ENTITY and ASSET filters
                    removeFilterByType(FilterType.ENTITY);
                    removeFilterByType(FilterType.ASSET);

                    // Create the filter path
                    const filter = createFilterPath(
                        pathId,
                        twin.physicalModel,
                    );

                    // Update filter in filterContext
                    setFilter(filter);
                }
            },
            [twin, removeFilterByType, setFilter],
        );

        const isHovered = useMemo(
            () =>
                currentHoveredEntity?.id === entity.id ||
                currentHovered3DEntity?.id === entity.id,
            [currentHoveredEntity, currentHovered3DEntity, entity.id],
        );

        const enableCurrentSelection = true;
        const id = entity.id;
        const title = entity.name;

        let entityPref: EntityPref | null = null;
        let assetHeader: UIComponentHeader | undefined;
        let showWarning = true;

        // Find associated Entity Preferences/Settings related to the Entity ID
        entityPref = useMemo(
            () =>
                findObjectById(
                    settings?.entities?.children
                        ? settings?.entities?.children
                        : [],
                    id,
                ) as EntityPref | null,
            [settings?.entities?.children, id],
        );

        assetHeader = entityPref?.header;
        showWarning = assetHeader?.showWarning
            ? assetHeader?.showWarning
            : true;

        // LabelSet (MEMO)
        let labelSets: LabelSet[] | undefined = useMemo(
            () => entityPref?.content?.labelSets,
            [entityPref?.content],
        );

        // Retrieve the labels required
        const labels = useMemo(() => {
            if (labelSets && labelSets.length > 0) {
                return getLabels(
                    labelSets,
                    stringToTwinEntityType(entity.type.name),
                    settings?.labels ? settings?.labels : [],
                    LabelMode.FULL,
                    LabelDisplayOn.ACCORDION,
                    live ? DataMode.LIVE : DataMode.TIME_SERIES,
                );
            } else {
                return [];
            }
        }, [settings?.labels, live, entity.type.name, labelSets]);

        const entityChildren = entity.children?.filter(
            e => !excludedTypes.includes(e.type.name),
        );
        const hasAssets = entity.children?.filter(
            e => e.type.name === "asset",
        );
        const showAsPanelButton =
            hasAssets &&
            hasAssets?.length === 0 &&
            entityChildren &&
            entityChildren.length === 0;

        return (
            <div className={className}>
                <AccordionItem
                    key={entity.id}
                    id={`entity-${entity.type.name.toLowerCase()}-${entity.id}`}
                    onMouseEnter={() => {
                        if (enableCurrentSelection) {
                            handleMouseEnter(entity.id, "ENTITY");
                        }
                    }}
                    onMouseLeave={() => {
                        if (enableCurrentSelection) {
                            handleMouseLeave();
                        }
                    }}
                    value={entity.id}
                >
                    <TLCard
                        className="accordion-item"
                        padding="8px"
                        hovered={isHovered}
                        selected={isSelected}
                    >
                        <>
                            {/* {showAsPanelButton ? "showAsPanelButton" : ''} */}
                            {assetHeader?.showHeader ||
                            showAsPanelButton === false ? (
                                <AccordionHeader
                                    icon={
                                        entityPref && entityPref.icon
                                            ? React.createElement(
                                                  assetIconComponents[
                                                      entityPref.icon
                                                  ],
                                              )
                                            : undefined
                                    }
                                    size={size ? size : "small"}
                                    expandIconPosition="end"
                                >
                                    <div className="w-full">
                                        <LabelHeader
                                            id={entity.id}
                                            fontSize={`${entity.depth === 0 ? `14px` : `12px`}`}
                                            indicator={
                                                <EntityIndicator
                                                    id={
                                                        live
                                                            ? entity.id
                                                            : entity.bID
                                                    }
                                                    showWarning={
                                                        showWarning
                                                    }
                                                    isSelected={
                                                        isSelected
                                                    }
                                                />
                                            }
                                            title={`${title}`}
                                            showId={
                                                assetHeader?.showId
                                                    ? assetHeader?.showId
                                                    : false
                                            }
                                        />
                                    </div>
                                </AccordionHeader>
                            ) : (
                                <div
                                    className="accordion-button"
                                    onClick={() => {
                                        setFilterPath(entity.id);
                                    }}
                                >
                                    <LabelHeader
                                        id={entity.id}
                                        fontSize={`${entity.depth === 0 ? `14px` : `12px`}`}
                                        indicator={
                                            <EntityIndicator
                                                id={
                                                    live
                                                        ? entity.id
                                                        : entity.bID
                                                }
                                                showWarning={
                                                    showWarning
                                                }
                                                isSelected={
                                                    isSelected
                                                }
                                            />
                                        }
                                        title={`${title}`}
                                        showId={
                                            assetHeader?.showId
                                                ? assetHeader?.showId
                                                : false
                                        }
                                    />
                                </div>
                            )}

                            {!showAsPanelButton && (
                                <AccordionPanel style={{ margin: 0 }}>
                                    {/* Nested Entities */}
                                    {entityChildren &&
                                        entityChildren.length > 0 && (
                                            <AssetAccordion
                                                entities={
                                                    entityChildren
                                                }
                                                handleToggle={
                                                    handleToggle
                                                }
                                                live={live}
                                            />
                                        )}

                                    {hasAssets &&
                                        hasAssets.map((asset, i) => {
                                            return (
                                                <AssetItem
                                                    key={i}
                                                    asset={asset}
                                                />
                                            );
                                        })}

                                    {/* Labels (Optionally displayed and configured within Twin Prefs/Settings) */}
                                    {labels && labels.length > 0 && (
                                        <Labels
                                            entity={entity}
                                            labels={labels}
                                        />
                                    )}
                                </AccordionPanel>
                            )}
                        </>
                    </TLCard>
                </AccordionItem>
            </div>
        );
    },
    (prevProps, nextProps) => {
        // Only re-render if the id matches either current or previous selected values
        // Check if the hover state has changed specifically for the current item (not nested)
        const hasCurrentHoverChanged =
            (prevProps.currentHoveredEntity?.id ===
                prevProps.entity.id) !==
            (nextProps.currentHoveredEntity?.id ===
                nextProps.entity.id);

        // Check hovered state from 3D scene
        const hasCurrent3DHoverChanged =
            (prevProps.currentHovered3DEntity?.id ===
                prevProps.entity.id) !==
            (nextProps.currentHovered3DEntity?.id ===
                nextProps.entity.id);

        // Ensure re-render if the live mode status changes
        const isSelectedChanged =
            nextProps.isSelected !== prevProps.isSelected;

        // Ensure re-render if the live mode status changes
        const hasLiveModeChanged = nextProps.live !== prevProps.live;

        return !(
            hasCurrentHoverChanged ||
            hasCurrent3DHoverChanged ||
            hasLiveModeChanged ||
            isSelectedChanged
        );
    },
);

export default styled(EntityAccordionItem)`
    .accordion-item {
        margin-bottom: 12px;
    }

    .accordion-button {
        min-height: 32px;
        display: flex;
        justify-content: center;
    }

    .accordion-item button {
        padding: 0 !important; /* Override Fluent2 the padding on the accordion buttons */
        border: none !important;
    }
`;
