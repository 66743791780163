export const formatDateToISO8601 = (
    date: Date,
    incTime: boolean = false,
) => {
    if (!(date instanceof Date)) {
        return "Invalid date";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    let isoDate = `${year}-${month}-${day}`;

    if (incTime) {
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        isoDate += `T${hours}:${minutes}:${seconds}`;
    }

    return isoDate;
};
