import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

// Context
import { useDataContext } from "../../../common/contexts/DataContext";
import { useFilterContext } from "../../../common/contexts/FilterAndModeContexts";
import { useSettingsContext } from "../../../common/contexts/SettingsContext";
import { useTwinContext } from "../../../common/contexts/TwinContext";
import { useUserContext } from "../../../common/contexts/UserContext";

// Types
import { Claims } from "../../../@types/Auth0/Claims";
import { SelectorItem } from "../../../@types/SelectorItem";

// Data
import { getOrgUrlsAndMetaData } from "../../../common/api/middleware/getOrgUrlsAndMetadata";

// Utils
import { extractClaims } from "../../../common/utils/extractClaims";

// Components
import { Image } from "@fluentui/react-components";
import SelectorPanel from "../../../components/SelectorPanel/SelectorPanel";

interface Props {
    className?: string;
}

const SelectOrganisationPage: React.FC<Props> = ({ className }) => {
    const { user, isAuthenticated, getAccessTokenSilently } =
        useAuth0();
    const { setAuthMetadata } = useUserContext();
    const navigate = useNavigate();

    const [organisations, setOrganisations] = useState<
        SelectorItem[]
    >([]);
    const [accessToken, setAccessToken] = useState<string | null>(
        null,
    );
    const [auth0Claims, setAuth0Claims] = useState<Claims>();

    const { setTwin } = useTwinContext();
    const { setMetrics } = useSettingsContext();
    const { setData, setLiveData } = useDataContext();
    const { clearFilter } = useFilterContext();

    const clearContext = () => {
        clearFilter();
        setData([]);
        setLiveData([]);
        setMetrics([]);
        setTwin(null);
    };

    useEffect(() => {
        const getAccessToken = async () => {
            setAccessToken(await getAccessTokenSilently());
        };
        getAccessToken();
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        // Redirect the user to the login page if they are not authenticated
        if (!isAuthenticated) {
            navigate("/", { replace: true });
        }

        if (isAuthenticated && user && accessToken) {
            const claims = extractClaims(user);
            if (claims) {
                setAuth0Claims(claims);
                const orgItems: SelectorItem[] = [];
                claims.organisations.forEach(org => {
                    const auth0OrgMetadata = org.split(":");
                    orgItems.push({
                        text: auth0OrgMetadata[0],
                        value: auth0OrgMetadata[1],
                    });
                });
                setOrganisations(orgItems);
            }
        }
    }, [accessToken, isAuthenticated, user]);

    const setOrganisation = async (
        organisationName: string,
        envName: string,
    ) => {
        if (accessToken && auth0Claims) {
            const orgUrlsAndMetaData = await getOrgUrlsAndMetaData(
                envName,
                accessToken,
            );
            if (orgUrlsAndMetaData) {
                clearContext();
                setAuthMetadata({
                    organisation: organisationName,
                    aiUrl: orgUrlsAndMetaData.data.aiUrl,
                    dgraphUrl: orgUrlsAndMetaData.data.dgraphUrl,
                    envName: orgUrlsAndMetaData.data.envName,
                    environment: orgUrlsAndMetaData.data.environment,
                    name: user?.name,
                    firstName: user?.given_name,
                    lastName: user?.family_name,
                    email: user?.email,
                    tokens: {
                        accessToken: accessToken,
                    },
                    tsdbUrl: `https://${orgUrlsAndMetaData.data.tsdbUrl}`,
                });
                navigate("/select-twin", { replace: true });
            }
        }
    };

    return (
        <div className={className}>
            <div
                className="select-organisation-page flex h-screen"
                style={{
                    backgroundImage:
                        'url("/assets/images/atom-bg.png")',
                    backgroundSize: "cover",
                }}
            >
                <div className="flex flex-1 flex-col p-12 lg:flex-none w-2/6">
                    <SelectorPanel
                        header="Select an Organisation"
                        items={organisations}
                        onSelect={setOrganisation}
                        height={600}
                        displayValue={false}
                    />
                </div>

                <div className="relative hidden w-0 flex-1 lg:block">
                    <div
                        className="mt-12 ml-auto mr-12"
                        style={{ width: "100px" }}
                    >
                        <Image
                            fit="contain"
                            src="/twinlabs-atom-dark-logo.svg"
                            alt="Twinlabs.ai"
                        />
                    </div>
                    <div>
                        <img
                            className="object-cover"
                            src={`/assets/images/new.png`}
                            alt="Event Venue"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default styled(SelectOrganisationPage)`
    .twin-selector-panel {
        background-color: rgb(255, 255, 255, 0.4);
        backdrop-filter: "blur(6px)";
        border-color: rgb(255, 255, 255, 0);
        box-shadow:
            0px 0px 11px 1px rgba(228, 255, 254, 0.55),
            0px 3px 11px 0px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        border-width: 2px;
        border-style: solid;
        color: #242424;
        padding: 2rem 1rem 4rem 2rem;
    }

    .select-organisation-page h2 {
        font-size: 2rem !important;
        margin-bottom: 1rem !important;
    }

    .select-organisation-page p {
        font-size: 0.875rem;
        margin: 0;
        color: #707070;
    }

    .select-organisation-page .option {
        display: flex;
        justify-items: center;
        align-items: center;
        padding: 0 0.75rem;
        min-height: 58px;
        margin-bottom: 1rem;
        border: 1px #e8e5e4 solid;
        background-color: #ffffff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        cursor: pointer;
    }

    .select-organisation-page .option:hover {
        background-color: #f5f5f5;
    }

    .select-organisation-page .option .option-icon {
        margin-right: 0.5rem;
    }

    .select-organisation-page .option p.option-title {
        font-size: 1rem;
        padding-bottom: 0;
        margin-bottom: 0;
        line-height: 100%;
        color: #000000;
    }

    .select-organisation-page .option p.option-microcopy {
        font-size: 0.675rem;
        line-height: 100%;
        color: #000000;
    }
`;
