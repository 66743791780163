// Context
import { useFilterContext } from "../../common/contexts/FilterAndModeContexts";
import { useTwinContext } from "../../common/contexts/TwinContext";

// Utils
import { getRelevantFilters } from "../3D/utils/filterUtils";

// Components
import EntityEditEntry from "./EntityEditEntry";
import FloorPlannerImport from "../3D/editing/TwinImport";
import TLCard from "../elements/TLCard/TLCard";
interface Props {
    className?: string;
}

const EditorSideBar = ({ className }: Props) => {
    const { filter } = useFilterContext();
    const { twin } = useTwinContext();

    const entityFilter = getRelevantFilters(filter);

    return (
        <div className={className}>
            <div className="panel left-panel">
                <TLCard size="small">
                    {twin ? (
                        <>
                            <h1>Update Twin Entities</h1>
                            <ul>
                                {entityFilter.map((entity, idx) => {
                                    if (entity.bId) {
                                        return (
                                            <li key={idx}>
                                                <EntityEditEntry
                                                    twinEntityBID={
                                                        entity.bId
                                                    }
                                                    indent={idx * 5}
                                                />
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </>
                    ) : (
                        <>
                            <h1>Create New Twin</h1>
                            <div>
                                Enter Twin Name{" "}
                                <input
                                    style={{ paddingLeft: "5px" }}
                                    type="text"
                                />
                            </div>
                            <FloorPlannerImport />
                        </>
                    )}
                </TLCard>
            </div>
        </div>
    );
};

export default EditorSideBar;
