import React from "react";
import styled from "styled-components";

// Components
import { Dismiss12Regular } from "@fluentui/react-icons";

interface Props {
    className?: string;
    label: string;
    onClick: () => void;
}

const TLFilterPill: React.FC<Props> = ({
    className,
    label,
    onClick,
}) => {
    return (
        <span className={className} onClick={onClick}>
            <p>{label}</p>
            <Dismiss12Regular />
        </span>
    );
};

export default styled(TLFilterPill)`
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 16px;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(50px);
    height: 28px;
    gap: 4px;
    transition:
        transform 0.4s ease,
        background 0.4s ease;
    flex-shrink: 0;
    z-index: 1;

    &:hover {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.6);
        transform: scale(1.1);
        z-index: 10;
    }
`;
