import React, {
    useState,
    useRef,
    useEffect,
    ReactNode,
    ReactElement,
} from "react";
import styled from "styled-components";

interface DraggablePanelProps {
    children: ReactNode;
    headerElement?: ReactElement;
    className?: string;
    isHeaderHovered?: boolean;
}

const DraggablePanel: React.FC<DraggablePanelProps> = ({
    children,
    headerElement,
    className,
    isHeaderHovered,
}) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDraggingState, setIsDraggingState] = useState(false);
    const isDragging = useRef(false);
    const panelRef = useRef<HTMLDivElement>(null);
    const offset = useRef({ x: 0, y: 0 });
    const SCROLLBAR_WIDTH = 25;
    const MARGIN_FROM_RIGHT = 15;
    const MARGIN_FROM_TOP = 48;

    useEffect(() => {
        if (panelRef.current) {
            const { offsetWidth, offsetHeight } = panelRef.current;
            setPosition({
                x:
                    window.innerWidth -
                    offsetWidth -
                    MARGIN_FROM_RIGHT,
                y: MARGIN_FROM_TOP,
            });
        }
    }, []);

    const handleMouseDown = (event: React.MouseEvent) => {
        event.preventDefault();
        isDragging.current = true;
        setIsDraggingState(true);
        offset.current = {
            x: event.clientX - position.x,
            y: event.clientY - position.y,
        };
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (!isDragging.current) return;

        const newX = event.clientX - offset.current.x;
        const newY = event.clientY - offset.current.y;

        const boundedX = Math.max(
            0,
            Math.min(
                window.innerWidth -
                    SCROLLBAR_WIDTH -
                    (panelRef.current?.offsetWidth || 0),
                newX,
            ),
        );
        const boundedY = Math.max(
            0,
            Math.min(
                window.innerHeight -
                    (panelRef.current?.offsetHeight || 0),
                newY,
            ),
        );

        setPosition({ x: boundedX, y: boundedY });
    };

    const handleMouseUp = () => {
        isDragging.current = false;
        setIsDraggingState(false);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };

    return (
        <div
            ref={panelRef}
            className={`${className} ${isDraggingState ? "dragging" : ""} ${isHeaderHovered ? "hovering" : ""}`}
            style={{
                left: position.x,
                top: position.y,
                position: "absolute",
            }}
        >
            {headerElement &&
                React.cloneElement(headerElement, {
                    onMouseDown: handleMouseDown,
                })}
            <div>{children}</div>
        </div>
    );
};

export default styled(DraggablePanel)`
    width: 880px;
    position: fixed; /* fixed positioning to prevent layout shift */
    display: flex;
    flex-direction: column;
    border-radius: var(--X-Large, 8px);
    border: var(--None, 1px) solid
        var(--Neutral-Stroke-1-Glass2, #fff);
    background: var(
        --Neutral-Background-1-Base-Glass,
        rgba(255, 255, 255, 0.4)
    );
    box-shadow:
        3px 4px 24px 0px rgba(255, 255, 255, 0.5) inset,
        0px 3px 11px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(50px);
    z-index: 1000;

    &.hovering::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--X-Large, 8px);
        border: 4px dashed var(--Gradients-Colour-None, #c0f7ff);
        box-shadow:
            0px 4px 4px 0px rgba(0, 0, 0, 0.25),
            3px 4px 24px 0px rgba(255, 255, 255, 0.5) inset,
            0px 3px 11px 0px rgba(0, 0, 0, 0.05);
        pointer-events: none;
        box-sizing: border-box;
    }

    &.dragging::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--X-Large, 8px);
        border: 4px solid var(--Gradients-Colour-None, #c0f7ff);
        pointer-events: none;
        box-sizing: border-box;
        box-shadow:
            0px 4px 15px 0px rgba(0, 0, 0, 0.4),
            3px 4px 24px 0px rgba(255, 255, 255, 0.5) inset,
            0px 3px 11px 0px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(15px);
    }
`;
