import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import {
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";

// Types
import { NavItem } from "../../@types/NavItem";

// Context
import { useUserContext } from "../../common/contexts/UserContext";

// Utils
import { getProtectedTopMenu } from "../../common/utils/getProtectedTopMenu";
import { getProtectedBottomMenu } from "../../common/utils/getProtectedBottomMenu";

// Components
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import MainNav from "../MainNav/MainNav";
import SupportCopilot from "../Maria/Maria";
import TLSpinnerPage from "../TLSpinnerPage/TLSpinnerPage";

interface Props {
    className?: string;
}

const ProtectedLayout: React.FC<Props> = ({ className }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = new QueryClient();

    const { authMetadata } = useUserContext();

    const [supportCopilotVisible, setSupportCopilotVisible] =
        useState<boolean>(false);
    const [selectedNavIndex, setSelectedNavIndex] = useState<number>(
        location.state && location.state.menuIndex
            ? location.state.menuIndex
            : 0,
    );
    const [enableMainScroll, setEnableMainScroll] =
        useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const currentPath = location.pathname;
    const currentYear = new Date().getFullYear();

    // Missing Roles? Redirect user to the "Something went wrong" page
    if (!authMetadata?.roles) {
        navigate("/something-went-wrong");
    }

    // For now, we are just using a single role
    let role = authMetadata?.roles ? authMetadata.roles[0] : "";

    const topMenu: NavItem[] = useMemo(
        () => getProtectedTopMenu(role),
        [role],
    );
    const bottomMenu: NavItem[] = useMemo(
        () => getProtectedBottomMenu(role, currentPath),
        [role, currentPath],
    );

    const outletComponent = (
        <QueryClientProvider client={queryClient}>
            <Outlet
                context={{
                    selectedNavIndex,
                    setSelectedNavIndex,
                    isLoading,
                    setIsLoading,
                    enableMainScroll,
                    setEnableMainScroll,
                }}
            />
        </QueryClientProvider>
    );

    return (
        <div className={className}>
            {isLoading && <TLSpinnerPage loadingMessages={[]} />}
            <div
                className="protected-wrapper"
                style={{
                    backgroundImage:
                        'url("/assets/images/atom-bg.png")',
                    backgroundSize: "cover",
                    backgroundRepeat: "repeat-y",
                }}
            >
                <div className="protected-layout">
                    <MainNav
                        id="main-nav"
                        supportCopilotVisible={supportCopilotVisible}
                        setSupportCopilotVisible={
                            setSupportCopilotVisible
                        }
                    />
                    {/* Main Content */}
                    <div className="protected-layout-content">
                        {/* Sidebar */}
                        <aside className="protected-layout-sidebar">
                            <nav>
                                <ul>
                                    {topMenu.map((item, i) => {
                                        const icon =
                                            i === selectedNavIndex
                                                ? item.iconSelected
                                                : item.icon;
                                        return (
                                            <li
                                                key={i}
                                                className={
                                                    i ===
                                                    selectedNavIndex
                                                        ? "selected"
                                                        : ""
                                                }
                                            >
                                                <Link
                                                    onClick={() => {
                                                        setSelectedNavIndex(
                                                            i,
                                                        );
                                                    }}
                                                    to={item.href}
                                                >
                                                    <span className="icon">
                                                        {React.createElement(
                                                            icon,
                                                        )}
                                                    </span>
                                                    {item.text}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                            <nav>
                                <ul>
                                    {bottomMenu.map((item, i) => {
                                        return (
                                            <li key={i}>
                                                <Link
                                                    to={item.href}
                                                    state={
                                                        item.state
                                                            ? item.state
                                                            : undefined
                                                    }
                                                >
                                                    <span className="icon">
                                                        {React.createElement(
                                                            item.icon,
                                                        )}
                                                    </span>
                                                    {item.text}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </nav>
                            <footer className="protected-layout-footer">
                                <p>
                                    <small>
                                        &copy;{currentYear}{" "}
                                        TwinLabs.ai All rights
                                        reserved.
                                    </small>
                                </p>
                            </footer>
                        </aside>
                        <main className="protected-layout-main">
                            {enableMainScroll ? (
                                <CustomScrollbar>
                                    {outletComponent}
                                </CustomScrollbar>
                            ) : (
                                outletComponent
                            )}
                        </main>
                    </div>
                </div>
                {supportCopilotVisible && (
                    <SupportCopilot
                        setSupportCopilotVisible={
                            setSupportCopilotVisible
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default styled(ProtectedLayout)`
    .protected-wrapper {
        padding: 0 0.5rem;
    }

    .protected-layout #mav-nav {
        width: 100%;
    }

    .protected-layout {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .protected-layout-content {
        display: flex;
        flex: 1;
        margin-top: 4rem;
    }

    .protected-layout-sidebar {
        position: fixed;
        top: 60px;
        bottom: 0;
        left: 0;
        width: 328px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }

    .protected-layout-sidebar nav:nth-child(2) {
        margin-top: auto;
    }

    .protected-layout-main {
        margin-left: 328px;
        padding: 0 10px;
        flex-grow: 1;
        overflow-y: auto;
        height: calc(100vh - 60px);
        flex: 1;
        /* Hide scrollbar for Edge, IE, and Firefox */
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .protected-layout-main::-webkit-scrollbar {
        display: none;
    }

    .protected-layout-footer {
        text-align: center;
        padding: 1rem;
    }

    nav li a,
    .button {
        font-size: 1rem;
        display: flex;
        border: 2px solid #cccccc;
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: rgb(255, 255, 255, 0.4);
        backdrop-filter: "blur(6px)";
        border-color: rgb(255, 255, 255, 0);
        box-shadow:
            0px 0px 11px 1px rgba(228, 255, 254, 0.55),
            0px 3px 11px 0px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        border-width: 2px;
        border-style: solid;
    }

    nav li a:hover,
    .button:hover {
        background-color: #ffffff;
    }

    nav li.selected a,
    .button.selected {
        background-color: #00bbcc;
        color: #ffffff;
        font-weight: bold;
    }

    .icon {
        margin-right: 0.5rem;
        display: block;
    }
`;
