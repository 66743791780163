export const excludedTypes = [
    "asset",
    "Asset",
    "NFArea",
    "wall",
    "Wall",
    "WALL",
    "operator",
    "Operator",
];
