import Config from "../../Config";

const routes = {
    get: {
        organisations: {
            getOrgUrlsAndMetaData: `${Config.middlewareApi}api/organisations/static/`,
        },
        twins: {
            getOpeningHours: (envName: string, bID: string) =>
                `${Config.middlewareApi}api/twins/${bID}/opening-hours`,
        },
        users: {
            getUsers: `${Config.middlewareApi}api/users`,
            getUserById: `${Config.middlewareApi}api/users`,
        },
    },
    post: {
        terms: {
            acceptTerms: `${Config.middlewareApi}api/terms/accept`,
        },
        twins: {
            addOpeningHours: (envName: string, bID: string) =>
                `${Config.middlewareApi}api/twins/${bID}/opening-hours`,
        },
        users: {
            createUser: `${Config.middlewareApi}api/users/`,
        },
    },
    put: {
        users: {
            updateProfile: `${Config.middlewareApi}api/users/me`,
            updateUserById: `${Config.middlewareApi}api/users/`,
        },
    },
    delete: {
        twins: {
            deleteOpeningHours: (
                envName: string,
                bID: string,
                effectiveDate: string,
            ) =>
                `${Config.middlewareApi}api/twins/${bID}/opening-hours/${effectiveDate}`,
        },
        users: `${Config.middlewareApi}api/users/`,
    },
};

export default routes;
