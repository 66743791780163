import React, { useState } from "react";
import styled from "styled-components";
import {
    useLocation,
    useNavigate,
    useOutletContext,
} from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Types
import { OutletContextType } from "../../../@types/OutletContextType";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Data
import { changePassword } from "../../../common/api/middleware/changePassword";

// Utils
import { passwordValidation } from "../../../common/utils/passwordValidation";

// Components
import { MessageBar } from "@fluentui/react-components";
import { Pen20Regular } from "@fluentui/react-icons";
import AdminPanel from "../../../components/AdminPanel/AdminPanel";
import TLAdminInput from "../../../components/elements/TLAdminInput/TLAdminInput";

export type FormInputs = {
    password: string;
    reEnterPassword: string;
};

const formSchema = {
    password: passwordValidation,
    reEnterPassword: yup
        .string()
        .required("Please re-enter your new password")
        .oneOf([yup.ref("password")], "Passwords must match")
        .required("Confirm password is required"),
};
interface Props {
    className?: string;
}

const ChangePasswordPage: React.FC<Props> = ({ className }) => {
    const [serverError] = useState<string>("");
    const location = useLocation();
    const navigate = useNavigate();

    const { authMetadata } = useUserContext();
    const { setIsLoading } = useOutletContext<OutletContextType>();

    const currentPath = location.pathname;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInputs>({
        resolver: yupResolver(yup.object(formSchema)),
    });

    const onSubmit: SubmitHandler<FormInputs> = async formData => {
        if (authMetadata?.tokens?.accessToken) {
            setIsLoading(true);
            const result = await changePassword(
                authMetadata?.tokens.accessToken,
                formData.password,
            );
            if (result) {
                navigate("/account/success", {
                    state: {
                        message:
                            "You've successfully changed your password.",
                    },
                });
            } else {
                navigate("/account/failure", {
                    state: {
                        message:
                            "<p>Unable to change password. Please try again.</p><p>If the problem persists please contact support.</p>",
                        backRoute: currentPath,
                    },
                });
            }
            setIsLoading(false);
        }
    };

    return (
        <div className={className}>
            <AdminPanel
                maxWidth={630}
                header="Change Your Password"
                children={
                    <>
                        <div className="password-requirements">
                            <p>
                                <strong>Password Requirements</strong>
                            </p>
                            <p>At least 8 characters in length</p>
                            <p>
                                Contain at least 3 of the following 4
                                types of characters:
                            </p>
                            <ul>
                                <li>Lower case letters (a-z)</li>
                                <li>Upper case letters (A-Z)</li>
                                <li>Numbers (i.e. 0-9)</li>
                                <li>
                                    Special characters (e.g. @#$%^&*)
                                </li>
                            </ul>
                        </div>
                        <div className="form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <fieldset>
                                    <div className="field">
                                        <TLAdminInput
                                            enableModes={false}
                                            id="txtNewPassword"
                                            label="New Password"
                                            type="password"
                                            {...register("password")}
                                            errorMessage={
                                                errors.password
                                                    ?.message
                                            }
                                            autoFocus={true}
                                        />
                                    </div>
                                    <div className="field">
                                        <TLAdminInput
                                            enableModes={false}
                                            id="txtReEnterPassword"
                                            label="Re-enter password"
                                            type="password"
                                            {...register(
                                                "reEnterPassword",
                                            )}
                                            errorMessage={
                                                errors.reEnterPassword
                                                    ?.message
                                            }
                                        />
                                    </div>
                                </fieldset>
                                <div className="admin-form-buttons">
                                    <button type="submit">
                                        <span className="icon">
                                            <Pen20Regular />
                                        </span>
                                        Change Password
                                    </button>
                                </div>
                                {serverError ? (
                                    <MessageBar
                                        id={`server-error`}
                                        intent="error"
                                        layout="multiline"
                                    >
                                        {serverError}
                                    </MessageBar>
                                ) : null}
                            </form>
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default styled(ChangePasswordPage)`
    form {
        width: 100%;
    }

    .field {
        margin-bottom: 2rem;
    }

    .field label {
        font-size: 1.125rem;
    }

    .link-field {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    .link-field label {
        width: 180px;
        padding-right: 1rem;
    }

    .link-field p {
        font-weight: bold;
    }

    .link-field a:link,
    .link-field a:visited {
        padding: 0.25rem;
        border-radius: 8px;
        margin-left: auto;
    }

    .link-field a:hover {
        color: #00bbcc;
    }

    .admin-form-buttons {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
    }

    .admin-form-buttons button {
        display: flex;
        justify-content: center;
        width: 264px;
        border-radius: 8px;
        padding: 1rem;
        background-color: #ffffff;
    }

    .password-requirements {
        margin-bottom: 2rem;
    }

    .password-requirements p {
        margin-bottom: 1rem;
    }

    .password-requirements ul {
        margin-top: 1rem;
    }
`;
