export enum IndicatorColor {
    BLACK = "#000000", // colors.grey.white
    WHITE = "#FFFFFF", // colors.grey.black

    // DEFAULT SELECTED
    NA = "#FFFFFF", // colors.grey.white
    ZERO = "#FAFAFA", // colors.grey._98
    VERY_LOW = "#E0FFFE", // Brand/New/LightCrystal (new)
    LOW = "#BEF4F2", // colors.brand.160
    MEDIUM_LOW = "#A6EDED", // colors.brand.150
    MEDIUM = "#8FE6E8", // colors.brand.140
    MEDIUM_HIGH = "#5FD8DF", // colors.brand.120
    HIGH = "#3DD6D4", // Brand/Logo/4 (new)
    VERY_HIGH = "#1BB6D9", // Brand/Logo/1 (new)
    EXTREMELY_HIGH = "#C50F1F", // colorStatusDangerBackground3

    // DEFAULT NOT SELECTED
    NA_NOT_SELECTED = "#FFFFFF", // colors.grey.white
    ZERO_NOT_SELECTED = "#FAFAFA", // colors.grey._98
    VERY_LOW_NOT_SELECTED = "#E0E0E0", // colors.grey._88
    LOW_NOT_SELECTED = "#CCCCCC", // colors.grey._80
    MEDIUM_LOW_NOT_SELECTED = "#C2C2C2", // colors.grey._76
    MEDIUM_NOT_SELECTED = "#B8B8B8", // colors.grey._72
    MEDIUM_HIGH_NOT_SELECTED = "#A8A8A8", // colors.grey._66
    HIGH_NOT_SELECTED = "#8F8F8F", // colors.grey._56
    VERY_HIGH_NOT_SELECTED = "#808080", // colors.grey._50
    EXTREMELY_HIGH_NOT_SELECTED = "#707070", // colors.grey._44

    // SELECTED
    INITIAL = "#E4FFFF", // Frosted Crystal | 0-1%
    CRYSTAL_TINT = "#CFF7F7", // Light Crystal Tint | 1-5%
    TEAL_LIGHT = "#A0EBE6", // Vibrant Teal Light | 5-10%
    TEAL_MEDIUM_LIGHT = "#6FDBD2", // Vibrant Teal Medium | 10-15%
    TEAL_MEDIUM = "#42DBD0", // Teal Variant 1 | 15-20%
    TEAL_ACCENT = "#34CED7", // Teal Variant 2 | 20-25%
    BLUE_ACCENT_LIGHT = "#1BB6D9", // Accent Blue | 25-30%
    BLUE_ACCENT = "#0EAAE8", // Bright Blue | 30-40%
    BLUE_BRIGHT = "#5BA8D7", // Muted Blue | 40-50%
    BLUE_MUTED = "#5785D5", // Desaturated Blue | 50-75%
    BLUE_VIOLET = "#8077BE", // Blue-Violet | 75-100%
    PINK_MEDIUM = "#A24489", // Medium Pink | 100-125%
    PINK_VIBRANT = "#D2377A", // Vibrant Pink | 125+

    // NON-SELECTED
    INITIAL_NOT_SELECTED = "#FAFAFA", // Very Light Grey | 0-1%
    CRYSTAL_TINT_NOT_SELECTED = "#E0E0E0", // Light Grey | 1-5%
    TEAL_LIGHT_NOT_SELECTED = "#CFCFCF", // Soft Grey | 5-10%
    TEAL_MEDIUM_LIGHT_NOT_SELECTED = "#BFBFBF", // Light Medium Grey | 10-15%
    TEAL_MEDIUM_NOT_SELECTED = "#AFAFAF", // Medium Grey | 15-20%
    TEAL_ACCENT_NOT_SELECTED = "#9F9F9F", // Mid-Dark Grey | 20-25%
    BLUE_ACCENT_LIGHT_NOT_SELECTED = "#8F8F8F", // Dark Grey | 25-30%
    BLUE_ACCENT_NOT_SELECTED = "#808080", // Standard Grey | 30-40%
    BLUE_BRIGHT_NOT_SELECTED = "#707070", // Darker Grey | 40-50%
    BLUE_MUTED_NOT_SELECTED = "#606060", // Deep Grey | 50-75%
    BLUE_VIOLET_NOT_SELECTED = "#505050", // Very Dark Grey | 75-100%
    PINK_MEDIUM_NOT_SELECTED = "#404040", // Deeper Grey |  100-125%
    PINK_VIBRANT_NOT_SELECTED = "#303030", // Near Black Grey |  125+ (edited)
}
