/*
 * getIndicator
 *
 */

// Types
import { DataValue } from "../../@types/Data/DataValue";
import { Indicator } from "../../@types/Indicator";
import { IndicatorColors } from "../../@types/Settings/IndicatorColors";
import { IndicatorConfig } from "../../@types/Settings/IndicatorConfig";

// Utils
import { getIndicatorColor } from "./getIndicatorColor";
import { roundToOneDecimalPlace } from "./roundToOneDecimalPlace";

export const getIndicator = (
    dataValue: DataValue,
    indicatorValue?: DataValue,
    config?: IndicatorConfig | undefined,
    selected?: boolean,
): Indicator => {
    let value = dataValue.value;
    let unit = uiFriendlyUnit(dataValue.unit);
    let colors: IndicatorColors = getIndicatorColor(
        indicatorValue ? indicatorValue.value : value,
        config,
        selected,
    );

    return {
        value: roundToOneDecimalPlace(value),
        unit: unit,
        colors: colors,
    };
};

// Created this to shorten the unit label in the UI. It's a switch in case we have further units we need to slightly edit
const uiFriendlyUnit = (value: string | undefined): string => {
    let unit = "";
    switch (value) {
        case "tx/minute":
            unit = "tx/min";
            break;
        default:
            unit = value ? value : "";
            break;
    }
    return unit;
};
