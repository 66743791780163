import { ReactNode, useMemo } from "react";
import { Box3 } from "three";
import { TwinEntityType } from "../../../../@types/TwinEntityType";
import { getAreaOutlineShape } from "../../utils/getAreaOutline";
import { Entity } from "../../../../@types/Entity";
import { TwinEntity } from "@repo/backend-types";
import { convertCoordLocalPoint } from "../../utils/coorUtils";

import { Capacity } from "../../behaviours/Capacity";
import { EditView } from "../../behaviours/EditView";
import { useModeContext } from "../../../../common/contexts/FilterAndModeContexts";
import { AppMode } from "../../../../@types/Mode";

/**
 * Area Component
 * For all areas
 */

interface Props {
    entity: Entity; // The backend twin data graphQL configuration needed to generate 3D components.
    mapDiv: HTMLDivElement; //Reference to the Mapbox map container element from React-Three-Map for event triggering css changes to the map cursor style.
    lineage: TwinEntity[]; // the acenstry of this entity, oldest first
    depth: number;
    children: ReactNode;
}

// TODO should selection be different for different types of area?
export const Area = ({
    entity,
    mapDiv,
    depth,
    lineage,
    children,
}: Props) => {
    const { appMode } = useModeContext();
    const { centre, areaBlock } = useMemo(() => {
        if (
            entity.boundaries === undefined ||
            entity.boundaries == null
        ) {
            throw new Error(
                `Area entity with id ${entity.id} has no boundaries. We cannot render it`,
            );
        }

        return getAreaOutlineShape(entity.boundaries.polygons, depth);
    }, [entity, depth]);

    const labelPosition = convertCoordLocalPoint(centre, 1.5);

    // this bounding box assertion is safe because we ensure it is present in getAreaOutLineShape
    const scaledBoundingBox = useMemo(() => {
        return new Box3(
            areaBlock.boundingBox!.min.multiplyScalar(0.01),
            areaBlock.boundingBox!.max.multiplyScalar(0.01),
        );
    }, [areaBlock]);

    return (
        <group position={[0, depth / 4000, 0]}>
            {appMode === AppMode.EDIT ? (
                <EditView
                    mapDiv={mapDiv}
                    lineage={lineage}
                    entity={entity}
                    geometry={areaBlock}
                />
            ) : (
                <Capacity
                    mapDiv={mapDiv}
                    lineage={lineage}
                    entity={entity}
                    geometry={areaBlock}
                    entityType={TwinEntityType.ZONE}
                    labelBoundingBox={scaledBoundingBox}
                    labelPosition={labelPosition}
                    topLevelType={TwinEntityType.SITE}
                />
            )}
            {children}
        </group>
    );
};
