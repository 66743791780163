import React, { useState } from "react";
import styled from "styled-components";

// Components
import {
    ChevronLeft16Filled,
    ChevronRight16Filled,
} from "@fluentui/react-icons";

interface DateTimeFormatOptions {
    year?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
    day?: "numeric" | "2-digit";
}

interface Props {
    className?: string;
    monthViewDateTime: Date;
    setMonth: (monthViewDateTime: Date) => void;
}

const MonthStepper: React.FC<Props> = ({
    className,
    monthViewDateTime,
    setMonth,
}) => {
    const [currentMonth, setCurrentMonth] =
        useState(monthViewDateTime);

    const goBackOneMonth = () => {
        const newDate = new Date(currentMonth);
        newDate.setMonth(currentMonth.getMonth() - 1);
        setMonth(newDate);
        setCurrentMonth(newDate);
    };

    const goForwardOneMonth = () => {
        const newDate = new Date(currentMonth);
        newDate.setMonth(currentMonth.getMonth() + 1);
        setMonth(newDate);
        setCurrentMonth(newDate);
    };

    const formatMonthString = (date: Date) => {
        const options: DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
        };
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <div className={className}>
            <div className="calendar-stepper">
                <button onClick={goBackOneMonth}>
                    <ChevronLeft16Filled />
                </button>
                <p>{formatMonthString(currentMonth)}</p>
                <button onClick={goForwardOneMonth}>
                    <ChevronRight16Filled />
                </button>
            </div>
        </div>
    );
};

export default styled(MonthStepper)`
    .calendar-stepper {
        width: 311px;
        margin: 8px 0 0 38px;
        display: flex;
    }

    .calendar-stepper p {
        flex-grow: 1;
        font-weight: 600;
        text-align: center;
    }
`;
