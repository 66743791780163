import React, { FC, ReactNode } from "react";

interface SubtitleProps {
    children: ReactNode;
    className?: string;
    m?: string;
}

const Subtitle: FC<SubtitleProps> = ({
    children,
    className = "",
    m,
}) => {
    const subtitleStyle = {
        color: "var(--Neutral-Foreground-1-Rest, #242424)",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "22px", // 137.5%
        margin: m,
    };

    return (
        <p className={className} style={subtitleStyle}>
            {children}
        </p>
    );
};

export default Subtitle;
