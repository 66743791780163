import { Roles } from "../../@types/Auth0/Roles";

export const handleRoleRouting = (roles: string[]) => {
    let redirectPath = "/something-went-wrong";
    if (
        roles &&
        (roles.includes(Roles.TL_ADMIN) ||
            roles.includes(Roles.PARTNER_USER))
    ) {
        redirectPath = "/select-organisation";
    } else if (
        roles &&
        (roles.includes("CUSTOMER_ADMIN") ||
            roles.includes("CUSTOMER_USER"))
    ) {
        redirectPath = "/select-twin";
    }
    return redirectPath;
};
