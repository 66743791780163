import { useCallback, useMemo } from "react";
import styled from "styled-components";

// Types
import { TwinEntity } from "@repo/backend-types";
import { AppMode } from "../../@types/Mode";
import { DataMode } from "../../@types/DataMode";
import { DataValue } from "../../@types/Data/DataValue";
import { FilterType } from "../../@types/FilterType";

// Context
import { useDataContext } from "../../common/contexts/DataContext";
import {
    useFilterContext,
    useModeContext,
} from "../../common/contexts/FilterAndModeContexts";
import { useEventContext } from "../../common/contexts/EventContext";
import { useTwinContext } from "../../common/contexts/TwinContext";

// Utils
import { createFilterPath } from "../../common/utils/createFilterPath";
import { findObjectByPropertyValue } from "../../common/utils/findObjectByPropertyValue";
import { getDataValue } from "../../common/utils/func-metrics/getDataValue";
import { getIndicator } from "../../common/utils/getIndicator";
import { hexToRgb } from "../../common/utils/hexToRgb";

// Components
import EntityIndicator from "../EntityIndicator/EntityIndicator";
import LabelHeader from "../LabelHeader/LabelHeader";

interface Props {
    className?: string;
    asset: TwinEntity;
}

const AssetItem: React.FC<Props> = ({ className, asset }) => {
    const { data } = useDataContext();
    const { filter, heroMetric, removeFilterByType, setFilter } =
        useFilterContext();
    const {
        currentHoveredEntity,
        setCurrentHoveredValue,
        currentHoveredEntityOn3DScene,
        selectedEntityIds,
    } = useEventContext();
    const { appMode } = useModeContext();
    const { twin } = useTwinContext();

    const setFilterPath = useCallback(
        (pathId: string) => {
            if (twin) {
                // Clear all ENTITY and ASSET filters
                removeFilterByType(FilterType.ENTITY);
                removeFilterByType(FilterType.ASSET);

                // Create the filter path
                const filter = createFilterPath(
                    pathId,
                    twin.physicalModel,
                );

                // Update filter in filterContext
                setFilter(filter);
            }
        },
        [twin, removeFilterByType, setFilter],
    );

    const enableCurrentSelection = true;
    const id = asset.id;
    let indicator;
    let metricData: DataValue[] = [];
    let indicatorData: DataValue[] = [];

    if (data.processed && heroMetric) {
        const indicatorMetric =
            heroMetric.metric === "countEntity"
                ? "usage"
                : heroMetric.metric;
        metricData = data.processed
            ? data.processed[heroMetric.metric]
            : [];
        indicatorData = data.processed
            ? data.processed[indicatorMetric]
            : [];
    }

    const isSelected = selectedEntityIds.has(asset.id);
    const selected = findObjectByPropertyValue(
        filter,
        "id",
        asset.id,
    );
    const metricValue = getDataValue(
        appMode === AppMode.LIVE
            ? DataMode.LIVE
            : DataMode.TIME_SERIES,
        metricData,
        appMode === AppMode.LIVE ? asset.id : asset.bID,
    );
    const indicatorValue = getDataValue(
        appMode === AppMode.LIVE
            ? DataMode.LIVE
            : DataMode.TIME_SERIES,
        indicatorData,
        appMode === AppMode.LIVE ? asset.id : asset.bID,
    );

    indicator =
        metricValue &&
        indicatorValue &&
        getIndicator(
            metricValue,
            indicatorValue,
            heroMetric?.indicatorConfig,
            isSelected,
        );

    const rgbColor = indicator && hexToRgb(indicator.colors.bgColor);
    const gradientStyle = rgbColor
        ? `linear-gradient(to right, rgba(255,255,255, 0) 0%, rgba(${rgbColor}, 0.2) 100%)`
        : "transparent";

    const isHovered = useMemo(() => {
        return (
            currentHoveredEntity?.id === asset.id ||
            currentHoveredEntityOn3DScene?.id === asset.id
        );
    }, [
        currentHoveredEntity,
        currentHoveredEntityOn3DScene,
        asset.id,
    ]);

    return (
        <div
            className={`${className} ${selected ? "selected" : ""} ${isHovered ? "hovered" : ""}`}
            onMouseEnter={() => {
                if (enableCurrentSelection) {
                    setCurrentHoveredValue({ id, type: "ENTITY" });
                }
            }}
            onMouseLeave={() => {
                if (enableCurrentSelection) {
                    setCurrentHoveredValue(null);
                }
            }}
            onClick={() => {
                setFilterPath(asset.id);
            }}
            style={{
                background: gradientStyle,
            }}
        >
            <LabelHeader
                id={asset.id}
                fontSize="10px"
                indicator={
                    <EntityIndicator
                        id={
                            appMode === AppMode.LIVE
                                ? asset.id
                                : asset.bID
                        }
                        showWarning={true}
                        isSelected={isSelected}
                    />
                }
                title={`${asset.name}`}
                showId={false}
            />
        </div>
    );
};

export default styled(AssetItem)`
    display: flex;
    align-items: center;
    width: 100%;
    height: 28px;
    margin-bottom: 0.25rem;
    padding: 0.25rem;
    border-radius: 8px;

    /* Outer glow on selection + black shadow */
    &.selected {
        border-width: 2px;
        border-style: solid;
        border-radius: 8px;
        border-color: rgba(228, 255, 254, 1);
        box-shadow:
            0px 0px 12px 2px rgba(228, 255, 254, 1),
            0px 2px 5px rgba(0, 0, 0, 0.9);
        background-color: rgba(255, 255, 255, 0.15) !important;
    }

    /* Outer glow on hover + dashed border - HIGHEST PRIORITY */
    /* It is critical that the hover effect works with selected asset items */
    &.hovered,
    &.selected.hovered {
        cursor: pointer;
        border-width: 2px;
        border-style: dashed;
        border-radius: 8px;
        border-color: rgba(228, 255, 254, 1);
        background-image: linear-gradient(
            145deg,
            rgba(255, 255, 255, 0.15) 0%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0.05) 100%
        );
        box-shadow:
            0px 0px 12px 3px rgba(228, 255, 254, 1),
            0px 2px 5px rgba(0, 0, 0, 0.7) !important;
        transition: all 0.3s ease;
    }
`;
