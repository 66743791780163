import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

// Types
import { Message } from "../../@types/Message";

// Utils
import { formatCopilotMessage } from "../../common/utils/formatCopilotMessage";

// Components
import { ReactComponent as CopilotColorIcon } from "../../common/svg/maria-icon.svg";
import { Avatar } from "@fluentui/react-components";

interface Props {
    className?: string;
    messages: Message[];
    awaitingMessage: boolean;
    addMessage: (message: Message) => void;
    clearMessages: () => void;
}

const AIConversation: React.FC<Props> = ({
    className,
    messages,
    awaitingMessage,
    addMessage,
    clearMessages,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useAuth0();
    const copilotConversationRef = useRef<HTMLDivElement>(null);
    const dummyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLoading(awaitingMessage);
    }, [awaitingMessage]);

    useEffect(() => {
        scrollToBottom();
    }, [loading, messages]);

    const scrollToBottom = () => {
        const container = copilotConversationRef.current;

        if (dummyRef.current && container) {
            dummyRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });

            // Offset the scroll position to counter a jump
            const fixedOffset = 50;
            container.scrollTop -= fixedOffset;
        }
    };

    return (
        <div className={className}>
            {messages && messages.length > 0 ? (
                <div
                    className="conversation"
                    ref={copilotConversationRef}
                >
                    {messages.map((msg, i) => {
                        return (
                            <div
                                key={i}
                                className={`message ${msg.content ? "" : "no-message"}`}
                            >
                                {!msg.response ? (
                                    <div className="avatar mr-2">
                                        <Avatar
                                            size={20}
                                            image={{
                                                src: user?.picture
                                                    ? user?.picture
                                                    : undefined,
                                            }}
                                            name={user?.name}
                                            aria-label={user?.name}
                                        />
                                    </div>
                                ) : null}
                                {msg.content && (
                                    <div
                                        className={`chat-bubble ${msg.response === false || msg.chart ? `text-left` : `text-right ml-auto`} ${msg.error ? `error` : ``}`}
                                    >
                                        <div
                                            className="response"
                                            dangerouslySetInnerHTML={{
                                                __html: formatCopilotMessage(
                                                    msg.content,
                                                ),
                                            }}
                                        ></div>
                                        {msg.prompts &&
                                        msg.prompts.length > 0 ? (
                                            <>
                                                {msg.prompts?.map(
                                                    (p, i) => {
                                                        return (
                                                            <div
                                                                key={
                                                                    i
                                                                }
                                                                className="prompt"
                                                            >
                                                                <button
                                                                    onClick={() => {
                                                                        setLoading(
                                                                            true,
                                                                        );
                                                                        addMessage(
                                                                            {
                                                                                content:
                                                                                    p.title,
                                                                                response:
                                                                                    false,
                                                                                prompts:
                                                                                    [],
                                                                                triggerPrompt:
                                                                                    true,
                                                                            },
                                                                        );
                                                                    }}
                                                                    className={`chat-bubble text-left`}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: p.title,
                                                                    }}
                                                                ></button>
                                                            </div>
                                                        );
                                                    },
                                                )}
                                            </>
                                        ) : null}
                                        {msg.chart ? (
                                            <>{msg.chart}</>
                                        ) : null}
                                    </div>
                                )}
                                {msg.response ? (
                                    <div className="avatar ml-2">
                                        <CopilotColorIcon />
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}

                    {loading ? (
                        <div className="message">
                            <div className="chat-bubble text-right ml-auto">
                                Thinking...
                            </div>
                            <div className="avatar ml-2">
                                <CopilotColorIcon />
                            </div>
                            {/* Just a dummy div stick to the bottom to make scroll to bottom work */}
                            <div ref={dummyRef} />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default styled(AIConversation)`
    .conversation {
        width: 100%;
        height: calc(100vh - 210px);
        overflow: visible;
        pointer-events: all;
        margin-right: 30px;
        min-height: 270px;
        height: auto;
        max-height: calc(100vh - 210px);
    }

    p {
        margin-bottom: 1rem;
        color: #424242;
    }

    .message {
        max-width: 880px;
        padding: 0 0 0.25rem 0;
        margin-bottom: 0.5rem;
        display: flex;
    }

    .message .prompt {
        margin-top: 10px;
    }

    .message .avatar {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .message.no-message {
        justify-content: flex-end !important; /* Aligns avatar to the right when there's no message */
    }

    .chat-bubble {
        font-size: 12px;
        padding: 6px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 6px;
        border: 1px solid;
        border-image-source: linear-gradient(
            95.98deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0.1) 53.32%,
            rgba(255, 255, 255, 0.5) 100%
        );
    }

    .chat-bubble.error {
        background-color: rgba(253, 125, 125, 0.4);
    }

    .response {
        max-width: 640px;
    }

    button.chat-bubble:hover {
        background-color: #ffffff;
        cursor: pointer;
    }
`;
