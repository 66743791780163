import React, { useState } from "react";
import styled from "styled-components";

// Types
import { AppMode } from "../../@types/Mode";

// Context
import { TimelineCalendarSyncContextProvider } from "../../common/contexts/TimelineCalendarSync";
import { useModeContext } from "../../common/contexts/FilterAndModeContexts";
import { useSettingsContext } from "../../common/contexts/SettingsContext";

// Components
import TLFilter from "../elements/TLFilter/TLFilter";
import AssetsAndAnalytics from "../AssetsAndAnalytics/AssetsAndAnalytics";
import Calendar from "../Calendar/Calendar";
import DataWarning from "../DataWarningIndicator/DataWarningIndicator";
import EditorSideBar from "../EditSideBar/EditorSideBar";
import Gestures from "../Gestures/Gestures";
import MainNav from "../MainNav/MainNav";
import Maria from "../Maria/Maria";
interface Props {
    className?: string;
}

const Frame: React.FC<Props> = ({ className }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [supportCopilotVisible, setSupportCopilotVisible] =
        useState<boolean>(false);
    const { settings } = useSettingsContext();
    const { appMode } = useModeContext();

    return (
        <div className={className}>
            <MainNav
                id="main-nav"
                fullScreen={fullScreen}
                supportCopilotVisible={supportCopilotVisible}
                setFullScreen={setFullScreen}
                setSupportCopilotVisible={setSupportCopilotVisible}
            />
            {!fullScreen && (
                <div className="frame">
                    <div className="left-column">
                        {appMode === AppMode.EDIT ? (
                            <EditorSideBar className="panel" />
                        ) : (
                            <AssetsAndAnalytics className="panel" />
                        )}
                    </div>
                    <div className="centre-column">
                        <TLFilter />
                    </div>
                    <div className="right-column">
                        <TimelineCalendarSyncContextProvider>
                            {/* <Timeline className="panel" /> */}
                            {settings?.calendar?.visible ? (
                                <Calendar className="calendar panel" />
                            ) : null}
                        </TimelineCalendarSyncContextProvider>
                    </div>
                    <Gestures />
                    <DataWarning />
                </div>
            )}
            {supportCopilotVisible && (
                <Maria
                    setSupportCopilotVisible={
                        setSupportCopilotVisible
                    }
                />
            )}
        </div>
    );
};

export default styled(Frame)`
    width: 98%;
    margin-left: auto;
    margin-right: auto;

    .frame {
        display: flex;
        height: calc(100vh - 120px);
        margin-top: 42px;
        align-items: start;
        pointer-events: none;
        overflow: hidden;
    }

    .left-column {
        flex: 0 0 auto;
        display: block;
        overflow: visible;
        transition: height 0.3s ease;
    }

    .left-column,
    .right-column {
        position: relative;
        padding-right: 8px;
        box-sizing: content-box;
        pointer-events: auto;
    }

    .right-column {
        max-width: calc(100% - 20px);
        width: auto;
        margin-left: auto;
        display: flex;
        overflow-y: auto;
    }

    .calendar {
        min-width: 400px;
        margin-left: 20px;
    }

    .centre-column {
        display: flex;
        justify-content: center;
        flex: 1;
        position: relative;
        pointer-events: all;
    }
`;
