import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Type
import { FieldMode } from "../../../@types/FieldMode";

// Components
import { Pen20Filled, Pen20Regular } from "@fluentui/react-icons";
import {
    Select,
    Label,
    useId,
    MessageBar,
} from "@fluentui/react-components";
import type { SelectProps } from "@fluentui/react-components";

interface Props {
    className?: string;
    currentValue?: string;
    discard?: boolean;
    enableModes: boolean;
    errorMessage?: string;
    id: string;
    label: string;
    options?: { text: string; value: string }[];
    props: SelectProps;
    stacked?: boolean;
}

export const TLAdminSelect: React.FC<Props> = ({
    className,
    stacked,
    currentValue,
    discard,
    errorMessage,
    enableModes,
    id,
    label,
    options,
    props,
}) => {
    const selectId = useId(id);
    const [fieldMode, setFieldMode] = useState<FieldMode>(
        FieldMode.READ,
    );

    useEffect(() => {
        setFieldMode(FieldMode.READ);
    }, [discard]);

    return (
        <>
            <div
                className={`${className} ${stacked ? "stacked" : ""}`}
            >
                <Label htmlFor={selectId}>{label}</Label>
                {!enableModes || fieldMode === FieldMode.EDIT ? (
                    <Select id={selectId} {...props}>
                        {options &&
                            options.map((opt, i) => (
                                <option key={i} value={opt.value}>
                                    {opt.text}
                                </option>
                            ))}
                    </Select>
                ) : (
                    <p>{currentValue}</p>
                )}
                {enableModes && (
                    <button
                        type="button"
                        className={
                            fieldMode === FieldMode.EDIT
                                ? `edit-mode`
                                : ``
                        }
                        onClick={() => {
                            setFieldMode(
                                fieldMode === FieldMode.EDIT
                                    ? FieldMode.READ
                                    : FieldMode.EDIT,
                            );
                        }}
                    >
                        {fieldMode === FieldMode.EDIT ? (
                            <Pen20Filled />
                        ) : (
                            <Pen20Regular />
                        )}
                    </button>
                )}
            </div>
            {(!enableModes || fieldMode === FieldMode.EDIT) &&
            errorMessage ? (
                <MessageBar id={`${id}-error`} intent="error">
                    {errorMessage}
                </MessageBar>
            ) : null}
        </>
    );
};

export default styled(TLAdminSelect)`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    & .stacked {
        flex-direction: column;
        align-items: left;
    }

    label {
        width: 180px;
        padding-right: 1rem;
    }

    select {
        width: 330px;
        height: 40px;
        padding: 0.5rem;
        font-size: 1.125rem;
        border-radius: 8px;
    }

    p {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.125rem;
    }

    button {
        padding: 0.25rem;
        border-radius: 8px;
        margin-left: auto;
    }

    button:hover,
    button.edit-mode {
        color: #00bbcc;
    }
`;
