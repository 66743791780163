import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { getProtectedResource } from "../../../common/api/auth0/messageService";
import AdminPanel from "../../../components/AdminPanel/AdminPanel";

interface Props {}

const BillingPage: React.FC<Props> = () => {
    const [message, setMessage] = useState<string>("");

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        let isMounted = true;

        const getMessage = async () => {
            const accessToken = await getAccessTokenSilently();
            const { data, error } =
                await getProtectedResource(accessToken);

            if (!isMounted) {
                return;
            }

            if (data) {
                setMessage(JSON.stringify(data, null, 2));
            }

            if (error) {
                setMessage(JSON.stringify(error, null, 2));
            }
        };

        getMessage();

        return () => {
            isMounted = false;
        };
    }, [getAccessTokenSilently]);

    return (
        <>
            <AdminPanel
                header="Billing"
                children={
                    <>
                        <p>Role(s): CUSTOMER_ADMIN</p>
                        <p>In Development</p>
                    </>
                }
            />
        </>
    );
};

export default BillingPage;
