/*
 * generateHourlyTimestamps
 * Generates hourly timestamps for a range of dates, constrained by optional start and end times.
 */
export const generateHourlyTimestamps = (
    minDate: string,
    maxDate: string,
    startTime: string = "00:00",
    endTime: string = "23:00",
): { dateString: string; hourString: string }[] => {
    // Extract date parts
    const startDateStr = minDate.split("T")[0]; // e.g., "2024-10-21"
    const endDateStr = maxDate.split("T")[0]; // e.g., "2024-10-23"

    // Initialise the start and end times for each day
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    // Helper function to increment the date and time by one hour
    const incrementHour = (dateTime: string) => {
        let [date, time] = dateTime.split(" ");
        let [year, month, day] = date.split("-").map(Number);
        let [hour, minute] = time.split(":").map(Number);

        // Increment the hour, adjusting day/month/year if needed
        hour += 1;
        if (hour === 24) {
            hour = 0;
            day += 1;
        }

        // Adjust month and year if day exceeds the max days of the month
        const maxDays = new Date(year, month, 0).getDate(); // days in the current month
        if (day > maxDays) {
            day = 1;
            month += 1;
        }
        if (month > 12) {
            month = 1;
            year += 1;
        }

        // Format back to "YYYY-MM-DD HH:mm"
        const formattedDate = [
            year,
            String(month).padStart(2, "0"),
            String(day).padStart(2, "0"),
        ].join("-");
        const formattedTime = [
            String(hour).padStart(2, "0"),
            String(minute).padStart(2, "0"),
        ].join(":");
        return `${formattedDate} ${formattedTime}`;
    };

    const timestamps = [];

    // Generate timestamps for each day
    let currentDate = startDateStr;
    while (currentDate <= endDateStr) {
        let currentDateTime = `${currentDate} ${startTime}`;
        const endOfDayTime = `${currentDate} ${endTime}`;

        // Generate hourly timestamps within the start and end time for the current day
        while (currentDateTime <= endOfDayTime) {
            const [dateString, hourString] =
                currentDateTime.split(" ");
            timestamps.push({ dateString, hourString });
            currentDateTime = incrementHour(currentDateTime); // Increment by one hour
        }

        // Move to the next day
        const [year, month, day] = currentDate.split("-").map(Number);
        const nextDay = new Date(year, month - 1, day + 1);
        currentDate = nextDay.toISOString().split("T")[0];
    }

    return timestamps;
};
