import React, { useState } from "react";
import styled from "styled-components";

// Context
import { useModeContext } from "../../common/contexts/FilterAndModeContexts";

// Types
import { AppMode } from "../../@types/Mode";
import { TimeUnit } from "../../@types/TimeUnit";

// Utils
import { stringToTimeUnit } from "../../common/utils/stringToTimeUnit";

// Components
import {
    Calendar24Filled,
    Calendar24Regular,
    CalendarWeekNumbers24Filled,
    CalendarWeekNumbers24Regular,
} from "@fluentui/react-icons";
import CalendarMonthView from "./CalendarMonthView";
import CalendarWeekView from "./CalendarWeekView";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import Panel from "../Panel/Panel";
import TLSwitcher from "../elements/TLSwitcher/TLSwitcher";
import TLCard from "../elements/TLCard/TLCard";

interface Props {
    className?: string;
}

const Calendar: React.FC<Props> = ({ className }) => {
    const { appMode } = useModeContext();
    const [timeUnit, setTimeUnit] = useState<TimeUnit>(TimeUnit.WEEK);

    const handleTimeUnitSwitcher = (value: string) => {
        setTimeUnit(stringToTimeUnit(value));
    };

    return (
        <>
            {appMode === AppMode.ANALYSIS && (
                <div className={className}>
                    <div className="header">
                        <TLCard size="small">
                            <TLSwitcher
                                size="small"
                                selectedValue={timeUnit.toString()}
                                options={[
                                    {
                                        activeIcon: (
                                            <CalendarWeekNumbers24Filled />
                                        ),
                                        inactiveIcon: (
                                            <CalendarWeekNumbers24Regular />
                                        ),
                                        value: TimeUnit.WEEK.toString(),
                                        text: "Weekly",
                                    },
                                    {
                                        activeIcon: (
                                            <Calendar24Filled />
                                        ),
                                        inactiveIcon: (
                                            <Calendar24Regular />
                                        ),
                                        value: TimeUnit.MONTH.toString(),
                                        text: "Monthly",
                                    },
                                ]}
                                setValue={handleTimeUnitSwitcher}
                            />
                        </TLCard>
                    </div>
                    <CustomScrollbar
                        right={-20}
                        maxHeight={"calc(100vh - 210px)"}
                        className="right-scrollable-container"
                    >
                        <Panel
                            id={"calendar"}
                            // className="calendar-panel"
                            icon={<Calendar24Regular />}
                            title="Calendar"
                            defaultOpenItems={["calendar"]}
                        >
                            <>
                                {timeUnit === TimeUnit.WEEK ? (
                                    <CalendarWeekView />
                                ) : null}
                                {timeUnit === TimeUnit.MONTH ? (
                                    <CalendarMonthView />
                                ) : null}
                            </>
                        </Panel>
                    </CustomScrollbar>
                </div>
            )}
        </>
    );
};

export default styled(Calendar)`
    .header {
        height: 64px;
        position: sticky;
        top: 0;
        max-width: calc(100% - 20px);
    }

    .right-scrollable-container {
        max-width: calc(100% - 20px);
    }

    /* Calendar Table */
    #duration {
        border-collapse: collapse;
    }

    #duration th,
    #duration td {
        padding: 0;
        border: 1px solid rgba(255, 255, 255, 0.4);
        box-sizing: border-box;
        /* Ensure border is included in the element's dimensions */
        text-align: center;
        user-select: none;
    }

    #duration td {
        color: var(--Neutral-Foreground-1-Rest, #242424);
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        position: relative;
    }

    #duration .month-date {
        color: var(--Colors-Grey-28, #474747);
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        position: absolute;
        left: 1px;
        top: -2px;
    }

    #duration td::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0);
        border: 2px solid transparent;
        z-index: 1;
        pointer-events: none;
    }

    #duration th {
        line-height: 0%;
        padding: 0;
        margin: 0;
        border: none;
    }

    .cell-date {
        font-size: 7px;
        position: absolute !important;
        top: -3px;
        left: 1px;
    }

    .selection-box {
        position: absolute;
        border-radius: var(--Medium, 4px);
        border: var(--Small, 2px) solid #e4fffe;
        box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.4);
    }
`;
