import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

// Types
import { FilterPath } from "../../../@types/FilterPath";

// Context
import { useFilterContext } from "../../../common/contexts/FilterAndModeContexts";
import { useTwinContext } from "../../../common/contexts/TwinContext";

// Components
import {
    ChevronLeft16Filled,
    ChevronRight16Filled,
} from "@fluentui/react-icons";
import { ReactComponent as DotsIcon } from "../../../icons/dots.svg";
import TLFilterPill from "../TLFilterPill/TLFilterPill";
import TLFilterClearPill from "../TLFilterClearPill/TLFilterClearPill";

interface Props {
    className?: string;
}

const TLFilter: React.FC<Props> = ({ className }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const { filter, removeFilter, clearFilter } = useFilterContext();
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const { twin } = useTwinContext();

    const checkOverflowAndScrollPosition = () => {
        const container = containerRef.current;
        if (container) {
            const scrollWidth = container.scrollWidth;
            const clientWidth = container.clientWidth;
            const scrollLeft = container.scrollLeft;

            setIsOverflowing(scrollWidth > clientWidth);
            setShowLeftArrow(scrollLeft > 0);

            // Add a buffer to handle floating-point precision
            const buffer = 5;
            setShowRightArrow(
                scrollLeft + clientWidth < scrollWidth - buffer,
            );
        }
    };

    const scrollContainer = (direction: "left" | "right") => {
        const container = containerRef.current;
        if (container) {
            const scrollAmount = 300;
            const firstPill = container.querySelector(".filter-pill"); // First pill element
            const firstPillWidth = firstPill
                ? (firstPill as HTMLElement).offsetWidth
                : 0; // Get its width

            let newScrollPosition;

            if (direction === "left") {
                // Ensure we scroll back to the start and fully reveal the first pill
                if (container.scrollLeft <= firstPillWidth) {
                    newScrollPosition = 0; // Snap to the beginning of the container
                } else {
                    newScrollPosition =
                        container.scrollLeft - scrollAmount;
                }

                container.scrollTo({
                    left: newScrollPosition,
                    behavior: "smooth",
                });
            } else {
                // Scroll right, ensuring we don't exceed the max scrollable width
                newScrollPosition = Math.min(
                    container.scrollWidth - container.clientWidth,
                    container.scrollLeft + scrollAmount,
                );

                container.scrollTo({
                    left: newScrollPosition,
                    behavior: "smooth",
                });
            }

            // Recheck scroll position after the scroll is done
            setTimeout(() => {
                checkOverflowAndScrollPosition();
            }, 400);
        }
    };

    useEffect(() => {
        checkOverflowAndScrollPosition();
        window.addEventListener(
            "resize",
            checkOverflowAndScrollPosition,
        );

        return () => {
            window.removeEventListener(
                "resize",
                checkOverflowAndScrollPosition,
            );
        };
    }, []);

    useEffect(() => {
        checkOverflowAndScrollPosition();
    }, [filter]);

    return (
        <div className={`${className} filter`}>
            {showLeftArrow && (
                <div className="flex">
                    <button
                        className="arrow left"
                        onClick={() => scrollContainer("left")}
                    >
                        <ChevronLeft16Filled />
                    </button>
                    <DotsIcon className="dots left" />
                </div>
            )}
            <div className="filter-pills" ref={containerRef}>
                {filter.map((f: FilterPath, i) => {
                    return (
                        <React.Fragment key={i}>
                            {twin?.physicalModel.id !== f.id ? (
                                <>
                                    {f.display && (
                                        <div className="filter-pill-wrapper">
                                            <TLFilterPill
                                                onClick={() => {
                                                    removeFilter(i);
                                                }}
                                                label={f.label}
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
            {showRightArrow && (
                <div className="flex">
                    <DotsIcon className="dots right" />
                    <button
                        className="arrow right"
                        onClick={() => scrollContainer("right")}
                    >
                        {<ChevronRight16Filled />}
                    </button>
                </div>
            )}
            {filter.length > 1 && (
                <TLFilterClearPill
                    onClick={() => {
                        clearFilter();
                    }}
                    label="Clear all"
                />
            )}
        </div>
    );
};

export default styled(TLFilter)`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 48px; /* Increased height to allow for zoomed pills */
    overflow: hidden;

    .filter-pills {
        display: flex;
        position: relative;
        max-width: 600px;
        overflow-x: auto;
        gap: 10px;
        padding: 10px 20px;
        height: 44px;
    }

    /* For Chrome, Safari, and Opera */
    .filter-pills::-webkit-scrollbar {
        display: none; /* Hide scrollbar for WebKit browsers */
    }

    /* For Firefox */
    .filter-pills {
        scrollbar-width: none; /* Hide scrollbar for Firefox */
    }

    /* For Internet Explorer 10+ and Edge */
    .filter-pills {
        -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer 10+ and Edge */
    }

    .arrow {
        padding: 0 10px;
        transition:
            transform 0.4s ease,
            background 0.4s ease;
    }

    .arrow:hover {
        transform: scale(1.2);
    }

    .dots {
        margin-top: 12px;
    }

    .dots.right {
        margin-left: 8px;
    }

    .dots.left {
        margin-right: 8px;
    }
`;
