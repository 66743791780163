import { gql } from "@apollo/client";

export const QUERY_METRIC = gql`
    query QueryMetric {
        queryMetric {
            id
            name
            unit
        }
    }
`;
