import React from "react";
import styled from "styled-components";

// Components
import { Card } from "@fluentui/react-components";

interface Props {
    className?: string;
    children?: JSX.Element | JSX.Element[];
    size?: "small" | "medium" | "large";
    padding?: string;
    selected?: boolean;
    hovered?: boolean;
}

const TLCard: React.FC<Props> = ({
    className,
    children,
    padding,
    size,
    selected,
    hovered,
}) => {
    return (
        <Card
            className={`${className} ${selected ? "selected" : ""} ${hovered ? "hovered" : ""}`}
            style={{ padding: padding || "8px" }}
            size={size}
        >
            {children}
        </Card>
    );
};

export default styled(TLCard)`
    /* Default card */
    position: relative;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(25px) saturate(75%);
    -webkit-backdrop-filter: blur(25px) saturate(75%);
    border-width: 2px;
    border-radius: 12px !important;
    border-style: solid;
    border-color: rgba(217, 217, 217, 0);

    /* TODO / IMPORTANT: QUESTION THIS WITH ANNA */
    box-shadow:
        inset 0 1px 3px rgba(255, 255, 255, 0.3),
        inset 0 -3px 8px rgba(0, 0, 0, 0.1),
        0 8px 16px rgba(0, 0, 0, 0.05);

    /* Mirror gloss highlight overlay */
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 40%,
            rgba(255, 255, 255, 0) 70%
        );
        opacity: 1;
        pointer-events: none;
    }

    /* Outer glow on selection + black shadow */
    &.selected {
        border-color: rgba(228, 255, 254, 1);
        box-shadow:
            0px 0px 12px 2px rgba(228, 255, 254, 1),
            0px 2px 5px rgba(0, 0, 0, 0.9);
        background-color: rgba(255, 255, 255, 0.4) !important;
    }

    /* Outer glow on hover + dashed border - HIGHEST PRIORITY */
    &.hovered,
    &.selected.hovered {
        /* This is critical - covers hover over selected */
        border-style: dashed;
        border-color: rgba(228, 255, 254, 1);
        background-image: linear-gradient(
            145deg,
            rgba(255, 255, 255, 0.15) 0%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0.05) 100%
        );
        box-shadow:
            0px 0px 12px 3px rgba(228, 255, 254, 1),
            0px 2px 5px rgba(0, 0, 0, 0.7) !important;
        transition: all 0.3s ease;
    }
`;
