// Types
import { DataMode } from "../../@types/DataMode";
import { Label } from "../../@types/Settings/Label";
import { Labels } from "../../@types/Settings/Labels";
import { LabelDisplayOn } from "../../@types/Settings/LabelDisplayOn";
import { LabelMode } from "../../@types/LabelMode";
import { LabelSet } from "../../@types/Settings/LabelSet";
import { TwinEntityType } from "../../@types/TwinEntityType";

export const getLabels = (
    entityLabelSets: LabelSet[] | undefined,
    twinEntityType: TwinEntityType,
    twinLabels: Labels[],
    labelMode: LabelMode,
    labelDisplayOn: LabelDisplayOn,
    dataMode: DataMode,
): Label[] => {
    let labels: Label[] = [];
    let labelSets: LabelSet[] = [];

    // Explictly defined labelSets should be used over any twinLabels
    if (entityLabelSets && entityLabelSets.length > 0) {
        labelSets = entityLabelSets;
    }

    // Otherwise utilise the labels that have been defined against the TwinEntityType (e.g. ASSET, AREA)
    const filteredTwinLabels = twinLabels.filter(
        item => item.twinEntityType === twinEntityType,
    );

    filteredTwinLabels.forEach(labels => {
        labelSets = [...labelSets, ...labels.labelSets];
    });

    // Once we've resolved and retrieved the correct labelSets, we filter them based on the app context (i.e. live, time series or both, displayed on the accordion, info cards or both)
    const filteredLabelSets = labelSets.filter(
        item =>
            (item.displayOn === labelDisplayOn ||
                item.displayOn === LabelDisplayOn.BOTH) &&
            (item.dataMode === dataMode ||
                item.dataMode === DataMode.BOTH),
    );

    // We then take the filtered label sets and extract either the BRIEF or FULL labels
    filteredLabelSets.forEach(labelSet => {
        if (labelMode === LabelMode.BRIEF) {
            if (labelSet.brief && labelSet.brief.length > 0) {
                const briefLabels = labelSet.brief;
                labels = [...labels, ...briefLabels];
            }
        }

        // We fall back to the FULL labels if no labels have been defined for BRIEF
        if (
            labelMode === LabelMode.FULL ||
            (labelMode === LabelMode.BRIEF && labels.length === 0)
        ) {
            if (labelSet.full && labelSet.full.length > 0) {
                const fullLabels = labelSet.full;
                labels = [...labels, ...fullLabels];
            }
        }
    });

    return labels;
};
