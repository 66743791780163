import React from "react";
import NotificationPanel from "../components/NotificationPanel/NotificationPanel";

interface Props {}

const SomethingWentWrongPage: React.FC<Props> = () => {
    return (
        <>
            <NotificationPanel
                imgSrc="/twinlabs-ai-stacked-logo.svg"
                imgAlt="Twinlabs.ai Logo"
                header="Oops! Something Went Wrong"
                copy="Please try again."
                backRoute="/"
            />
        </>
    );
};

export default SomethingWentWrongPage;
