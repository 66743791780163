import { CurrentSelection } from "../../../@types/CurrentSelection";
import { FilterPath } from "../../../@types/FilterPath";
import { FilterType } from "../../../@types/FilterType";
import { TwinEntity } from "@repo/backend-types";

export const getMenuHovered = (
    lineage: TwinEntity[],
    menuHoverSelection: CurrentSelection | null,
): boolean => {
    if (!menuHoverSelection) return false;

    return lineage[lineage.length - 1].id === menuHoverSelection.id;
};

export const getRelevantFilters = (
    filter: FilterPath[],
): FilterPath[] => {
    return filter.filter(filterTags => {
        return (
            filterTags.type === FilterType.ASSET ||
            filterTags.type === FilterType.ENTITY
        );
    });
};

const isSelected = (
    entity: TwinEntity,
    entityFilters: FilterPath[],
): boolean => {
    return entityFilters[entityFilters.length - 1].id === entity.id;
};

const siblingSelected = (
    entity: TwinEntity,
    parent: TwinEntity,
    entityFilters: FilterPath[],
) => {
    return (
        entityFilters.length > 1 &&
        entityFilters[entityFilters.length - 2].id === parent.id &&
        !isSelected(entity, entityFilters)
    );
};

const hasChildren = (entity: TwinEntity): boolean => {
    if (entity.children === null || entity.children === undefined) {
        return false;
    } else {
        return entity.children.length > 0;
    }
};

export const getSelectionCriteria = (
    entity: TwinEntity,
    parent: TwinEntity,
    filter: FilterPath[],
) => {
    const filters = getRelevantFilters(filter);
    const filtersExist = filters.length > 0;
    const areaIsSelected =
        filtersExist && isSelected(entity, filters);
    const parentIsSelected =
        filtersExist && isSelected(parent, filters);
    const siblingIsSelected =
        filtersExist && siblingSelected(entity, parent, filters);

    return {
        filtersExist,
        areaIsSelected,
        parentIsSelected,
        siblingIsSelected,
    };
};

type FilterState = {
    filtersExist: boolean;
    areaIsSelected: boolean;
    parentIsSelected: boolean;
    siblingIsSelected: boolean;
    isTopLevelArea: boolean;
};

export function determineVisibility(
    entity: TwinEntity,
    filterState: FilterState,
) {
    let visible = false;

    const areaHasChildren = hasChildren(entity);

    const {
        filtersExist,
        areaIsSelected,
        parentIsSelected,
        siblingIsSelected,
        isTopLevelArea,
    } = filterState;

    if (filtersExist) {
        if (areaIsSelected) {
            if (!areaHasChildren) {
                visible = true;
            }
        } else if (parentIsSelected) {
            visible = true;
        } else if (siblingIsSelected) {
            visible = true;
        }
    } else {
        if (isTopLevelArea) {
            visible = true;
        }
    }

    return visible;
}
