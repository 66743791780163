import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Types
import { NavItem } from "../../@types/NavItem";

interface Props {
    className?: string;
    header: string;
    menu?: NavItem[];
    children: JSX.Element;
    maxWidth?: number;
}

const AdminPanel: React.FC<Props> = ({
    className,
    header,
    menu,
    children,
    maxWidth,
}) => {
    return (
        <div
            className={className}
            style={{ maxWidth: `${maxWidth}px` }}
        >
            <header className="admin-panel-header">
                <h1>{header}</h1>
                {menu && (
                    <nav className="admin-panel-header-nav">
                        <ul>
                            {menu.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <Link to={item.href}>
                                            <span className="icon">
                                                {React.createElement(
                                                    item.icon,
                                                )}
                                            </span>
                                            {item.text}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                )}
            </header>
            <div className="admin-panel-content">{children}</div>
        </div>
    );
};

export default styled(AdminPanel)`
    padding: 2rem;
    background-color: rgb(255, 255, 255, 0.4);
    backdrop-filter: "blur(6px)";
    border-color: rgb(255, 255, 255, 0);
    box-shadow:
        0px 0px 11px 1px rgba(228, 255, 254, 0.55),
        0px 3px 11px 0px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border-width: 2px;
    border-style: solid;
    color: #242424;

    .admin-panel-header {
        display: flex;
        margin-bottom: 2rem;
    }

    .admin-panel-header h1 {
        font-size: 1.5rem;
    }

    .admin-panel-header-nav {
        margin-left: auto;
    }

    .admin-panel-header-nav li {
        display: flex;
    }

    .admin-panel-header-nav li a {
        font-size: 1rem;
        display: flex;
        border: 2px solid #cccccc;
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: rgb(255, 255, 255, 0.4);
        backdrop-filter: "blur(6px)";
        border-color: rgb(255, 255, 255, 0);
        box-shadow:
            0px 0px 11px 1px rgba(228, 255, 254, 0.55),
            0px 3px 11px 0px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        border-width: 2px;
        border-style: solid;
    }

    .admin-panel-header-nav li a:hover {
        background-color: #ffffff;
    }

    .admin-panel-header-nav li.selected a {
        background-color: #00bbcc;
        color: #ffffff;
        font-weight: bold;
    }
`;
