export const getCurrentTime = () => {
    // Create a new Date object representing the current date and time
    const timestamp = new Date();

    // Get hours, minutes, and seconds
    const hours = timestamp.getHours().toString().padStart(2, "0");
    const minutes = timestamp
        .getMinutes()
        .toString()
        .padStart(2, "0");
    const seconds = timestamp
        .getSeconds()
        .toString()
        .padStart(2, "0");

    // Create the formatted timestamp string
    return `${hours}:${minutes}:${seconds}`;
};
