/*
 * timeSeriesInstantQuery
 * Time Series queries are similar
 *
 */
import { INSTANT_QUERY } from "./gql/instantQuery";

// Utils
import timeseriesApolloClient from "../../utils/createTimeseriesApolloClient";
import axios from "axios";
import Config from "../../Config";

export const timeSeriesInstantQuery = async (
    timeSeriesURL: string,
    query: string,
    time: string,
) => {
    try {
        const { data } = await timeseriesApolloClient(
            timeSeriesURL,
        ).query({
            query: INSTANT_QUERY,
            variables: {
                query: query,
                time: time,
            },
        });
        return data.instantQuery.value;
    } catch (error) {
        throw new Error(
            "timeSeriesInstantQuery: Unable to receive a range query response from the time series",
        );
    }
};

export const getCalendarSelectionData = async (
    startDateTime: string,
    endDateTime: string,
    aggregation: string,
    step: string = "15m",
    organisation: string,
    metric?: string,
    metricType?: string,
    digitalTwinEntity?: string,
    digitalTwinEntityPath?: string,
    offset?: string,
) => {
    try {
        const { data } = await axios.get(
            `${Config.middlewareApi}api/analytics/calendar-selection`,
            {
                headers: {
                    "Content-Type": "application/json",
                    // 'Authorization': `Bearer ${accessToken}`, // TODO: Pass accessToken or configure axios instance with it
                },
                params: {
                    start_time: startDateTime,
                    end_time: endDateTime,
                    aggregation,
                    step,
                    organisation,
                    metric,
                    metricType,
                    digitalTwinEntity,
                    digitalTwinEntityPath,
                    offset,
                },
            },
        );
        return data;
    } catch (error) {
        return null;
    }
};
