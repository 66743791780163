import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useApolloClient } from "@apollo/client";

// Types
import { Organisation } from "@repo/backend-types";
import { OutletContextType } from "../../../@types/OutletContextType";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Data
import { GET_ORGANISATION } from "../../../common/api/live/gql/getOrganisation";

// Components
import AdminPanel from "../../../components/AdminPanel/AdminPanel";
import TwinsTable from "../../../components/TwinsTable/TwinsTable";

interface Props {
    className?: string;
}

const TwinsPage: React.FC<Props> = ({ className }) => {
    const { authMetadata } = useUserContext();
    const navigate = useNavigate();
    const apolloClient = useApolloClient();
    const { setIsLoading } = useOutletContext<OutletContextType>();

    const [data, setData] = useState<any>();
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [debouncedQuery] = useDebounce(searchQuery, 500);

    // Redirect user to Something went wrong
    if (
        !authMetadata?.tokens?.accessToken ||
        !authMetadata?.organisation
    ) {
        navigate("/something-went-wrong");
    }

    useEffect(() => {
        const fetchTwins = async () => {
            if (
                searchQuery &&
                searchQuery.length < 3 &&
                debouncedQuery &&
                debouncedQuery.length < 3
            )
                return; // Ensure at least 3 characters before searching

            try {
                setIsLoading(true);
                if (
                    authMetadata?.tokens?.accessToken &&
                    authMetadata.organisation &&
                    authMetadata.envName
                ) {
                    apolloClient
                        .query<{ getOrganisation: Organisation }>({
                            query: GET_ORGANISATION,
                            variables: {
                                name: authMetadata.organisation,
                            },
                        })
                        .then(response => {
                            if (
                                response.data.getOrganisation.entities
                            ) {
                                setData(
                                    response.data.getOrganisation
                                        .entities,
                                );
                            }
                        });
                    setIsLoading(false);
                }
            } catch (error) {
                console.error(
                    "Error fetching search results:",
                    error,
                );
            } finally {
                setIsLoading(false);
            }
        };

        fetchTwins();
    }, [
        authMetadata?.organisation,
        authMetadata?.tokens?.accessToken,
        limit,
        page,
        debouncedQuery,
    ]);

    const changeLimit = (newLimit: number) => {
        setLimit(newLimit);
    };

    const switchPage = (newPage: number) => {
        setPage(newPage);
    };

    return (
        <div className={className}>
            <AdminPanel
                header="Twins"
                children={
                    <>
                        <TwinsTable data={data} />
                    </>
                }
            />
        </div>
    );
};

export default styled(TwinsPage)`
    .controls {
        display: flex;
    }

    .button {
        margin-right: 1rem;
    }
`;
