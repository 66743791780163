/*
 * TimelineCalendarSync
 *
 */
import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";

// Types
import { DateRange } from "../../@types/DateRange";

interface TimelineCalendarSyncContextValue {
    timelimeDateTime: string | null;
    calendarDateTimeRange: DateRange | null;
    setTimelineDateTime: (startDateTime: string) => void;
    setCalendarDateRange: (
        startDateTime: string,
        finishDateTime: string,
    ) => void;
}

const initialState: TimelineCalendarSyncContextValue = {
    timelimeDateTime: null,
    calendarDateTimeRange: null,
    setTimelineDateTime: () => {},
    setCalendarDateRange: () => {},
};

export const TimelineCalendarSyncContext =
    createContext<TimelineCalendarSyncContextValue>(initialState);

export const useTimelineCalendarContext =
    (): TimelineCalendarSyncContextValue => {
        return useContext(TimelineCalendarSyncContext);
    };

interface ContextProviderProps {
    children: React.ReactNode;
}

const setInitialState = (): TimelineCalendarSyncContextValue => {
    return {
        timelimeDateTime: null,
        calendarDateTimeRange: null,
        setTimelineDateTime: () => {},
        setCalendarDateRange: () => {},
    };
};

export const TimelineCalendarSyncContextProvider: React.FC<
    ContextProviderProps
> = props => {
    const [syncState, setSyncState] =
        useState<TimelineCalendarSyncContextValue>(setInitialState());

    const setTimelineStartDateTime = useCallback(
        (startDateTime: string | null) => {
            setSyncState(prevState => ({
                ...prevState,
                startDateTime,
            }));
        },
        [],
    );

    const setCalendarDateRange = useCallback(
        (startDateTime: string, finishDateTime: string) => {
            setSyncState(prevState => ({
                ...prevState,
                calendarDateTimeRange: {
                    startDateTime,
                    finishDateTime,
                },
            }));
        },
        [],
    );

    const contextValue = useMemo(
        () => ({
            ...syncState,
            setTimelineStartDateTime,
            setCalendarDateRange,
        }),
        [syncState, setTimelineStartDateTime, setCalendarDateRange],
    );

    return (
        <TimelineCalendarSyncContext.Provider value={contextValue}>
            {props.children}
        </TimelineCalendarSyncContext.Provider>
    );
};
