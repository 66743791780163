// Types
import { DataMode } from "../../../@types/DataMode";
import { DataScope } from "../../../@types/Settings/DataScope";
import { IndicatorColor } from "../../../@types/Settings/IndicatorColor";
import { LabelDisplayOn } from "../../../@types/Settings/LabelDisplayOn";
import { TwinEntityType } from "../../../@types/TwinEntityType";
import { TwinTemplate } from "../../../@types/Settings/TwinTemplate";

// Data / Event Operator Label Configs
import { LabelSetEventOperatorLiveBrief } from "./labelSets/eventTwin/live/LabelSetEventOperatorLiveBrief";
import { LabelSetEventOperatorLiveFull } from "./labelSets/eventTwin/live/LabelSetEventOperatorLiveFull";
import { LabelSetEventOperatorTimeSeriesBrief } from "./labelSets/eventTwin/timeseries/LabelSetEventOperatorTimeSeriesBrief";
import { LabelSetEventOperatorTimeSeriesFull } from "./labelSets/eventTwin/timeseries/LabelSetEventOperatorTimeSeriesFull";
import { LabelSetEventStationLiveBrief } from "./labelSets/eventTwin/live/LabelSetEventStationLiveBrief";
import { LabelSetEventStationLiveFull } from "./labelSets/eventTwin/live/LabelSetEventStationLiveFull";
import { LabelSetEventStationTimeSeriesBrief } from "./labelSets/eventTwin/timeseries/LabelSetEventStationTimeSeriesBrief";
import { LabelSetEventStationTimeSeriesFull } from "./labelSets/eventTwin/timeseries/LabelSetEventStationTimeSeriesFull";

export const eventTwinTemplate: TwinTemplate = {
    organisation: "Howler",
    timeZone: "Europe/London",
    heroMetrics: [
        {
            metric: "transactionRate",
            live: {
                showDecimalPlace: true,
                showUnit: true,
            },
            timeSeries: {
                aggregation: "avg",
                showDecimalPlace: true,
                showUnit: true,
            },
            indicatorConfig: {
                selected: [
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_VIBRANT,
                        op: `value >= 0 && value <= 0.5`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor: IndicatorColor.PINK_MEDIUM,
                        op: `value > 0.5 && value <= 0.6`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_VIOLET,
                        op: `value > 0.6 && value <= 0.7`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_MUTED,
                        op: `value > 0.7 && value <= 0.8`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_BRIGHT,
                        op: `value > 0.8 && value <= 0.9`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_ACCENT,
                        op: `value > 0.9 && value <= 1`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.BLUE_ACCENT_LIGHT,
                        op: `value > 1 && value <= 1.5`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_ACCENT,
                        op: `value > 1.5 && value <= 2`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor: IndicatorColor.TEAL_MEDIUM,
                        op: `value > 2`,
                    },
                ],
                nonSelected: [
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.PINK_VIBRANT_NOT_SELECTED,
                        op: `value >= 0 && value <= 0.5`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.PINK_MEDIUM_NOT_SELECTED,
                        op: `value > 0.5 && value <= 0.6`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.BLUE_VIOLET_NOT_SELECTED,
                        op: `value > 0.6 && value <= 0.7`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.BLUE_MUTED_NOT_SELECTED,
                        op: `value > 0.7 && value <= 0.8`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.BLUE_BRIGHT_NOT_SELECTED,
                        op: `value > 0.8 && value <= 0.9`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.BLUE_ACCENT_NOT_SELECTED,
                        op: `value > 0.9 && value <= 1`,
                    },
                    {
                        textColor: IndicatorColor.WHITE,
                        bgColor:
                            IndicatorColor.BLUE_ACCENT_LIGHT_NOT_SELECTED,
                        op: `value > 1 && value <= 1.5`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor:
                            IndicatorColor.TEAL_ACCENT_NOT_SELECTED,
                        op: `value > 1.5 && value <= 2`,
                    },
                    {
                        textColor: IndicatorColor.BLACK,
                        bgColor:
                            IndicatorColor.TEAL_MEDIUM_NOT_SELECTED,
                        op: `value > 2`,
                    },
                ],
            },
        },
        {
            metric: "totalCheckins",
            live: {
                showDecimalPlace: true,
                showUnit: true,
            },
            timeSeries: {
                aggregation: "avg",
                showDecimalPlace: true,
                showUnit: true,
            },
        },
    ],
    startLive: true,
    liveDataWarning: false,
    liveDataInterval: "15s",
    physicalEntities: ["event", "station", "operator"],
    filterEntities: ["event", "ticketCategory", "ticketType"],
    labels: [
        {
            twinEntityType: TwinEntityType.STATION,
            labelSets: [
                {
                    brief: LabelSetEventStationTimeSeriesBrief,
                    full: LabelSetEventStationTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES,
                },
                {
                    brief: LabelSetEventStationLiveBrief,
                    full: LabelSetEventStationLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE,
                },
            ],
        },
        {
            twinEntityType: TwinEntityType.OPERATOR,
            labelSets: [
                {
                    brief: LabelSetEventOperatorTimeSeriesBrief,
                    full: LabelSetEventOperatorTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES,
                },
                {
                    brief: LabelSetEventOperatorLiveBrief,
                    full: LabelSetEventOperatorLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE,
                },
            ],
        },
    ],
    maria: {
        id: "analytics",
        introPrompts: [],
    },
    timeline: {
        id: "timeline",
        visible: true,
        open: true,
        order: 1,
        interval: "15m",
    },
    calendar: {
        id: "calendar",
        visible: true,
        icon: "",
        order: 2,
        weekView: {
            dataScope: DataScope.ACTIVE_HOURS,
            hourRange: {
                startTime: "06:00",
                finishTime: "22:00",
            },
        },
        monthView: {
            dataScope: DataScope.ACTIVE_DAYS,
        },
    },
    leftFieldMessages: [
        "Packing the tent",
        "Queuing to use a portable loo",
        "Firing up the disposable BBQ",
        `Trudging through a muddy field`,
    ],
    pollTwin: true,
};
