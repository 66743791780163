/*
 * convertDateTimeToUTC
 *
 */
import { DateTime } from "luxon";

export const convertDateTimeISOToUTC = (
    dateTimeISO: string | null,
) => {
    let utcDateTime;

    if (dateTimeISO !== null) {
        // Parse the ISO string and automatically detect the timezone
        const dateTimeWithZone = DateTime.fromISO(dateTimeISO);
        // Convert the time to UTC
        utcDateTime = dateTimeWithZone.toUTC().toISO();
    } else {
        utcDateTime = "N/A";
    }

    return utcDateTime;
};
