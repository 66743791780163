import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Types
import { NavItem } from "../../@types/NavItem";

interface Props {
    className?: string;
    links: NavItem[];
    setSelectedNavIndex: (index: number) => void;
}

const QuickLinks: React.FC<Props> = ({
    className,
    links,
    setSelectedNavIndex,
}) => {
    return (
        <div className={className}>
            {links.map((link, i) => {
                return (
                    <React.Fragment key={i}>
                        <Link
                            to={link.href}
                            state={
                                link.state ? link.state : undefined
                            }
                            onClick={() => setSelectedNavIndex(i + 1)}
                        >
                            <div className="link">
                                <div className="icon">
                                    {React.createElement(link.icon)}
                                </div>
                                <h3>{link.text}</h3>
                            </div>
                        </Link>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default styled(QuickLinks)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    .link {
        border: 2px solid #d9d9d9;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 158px;
    }

    .link .icon svg {
        width: 38px;
        height: 38px;
    }

    .link:hover {
        background-color: #ffffff;
    }

    .link h3 {
        margin-top: 0.5rem;
        font-size: 1.125rem;
        line-height: 120%;
        text-align: center;
        font-weight: 500;
    }
`;
