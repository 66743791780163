/*
 * getTransactionRate
 * Function Metric
 *
 */
// Types
import { DataMode } from "../../../@types/DataMode";
import { DataValue } from "../../../@types/Data/DataValue";

// Utils
import { getDataValue } from "./getDataValue";
import { roundToOneDecimalPlace } from "../roundToOneDecimalPlace";

export const getTransactionRate = (
    dataMode: DataMode,
    data: DataValue[],
    id: string,
): string => {
    if (data && data.length > 0) {
        const value = getDataValue(dataMode, data, id)?.value;
        return isNaN(value)
            ? `0`
            : `${roundToOneDecimalPlace(value).toFixed(1)} tx/min`;
    } else {
        return "N/A";
    }
};
