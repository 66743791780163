import React, { useState } from "react";
import styled from "styled-components";
import {
    Link,
    useLocation,
    useNavigate,
    useOutletContext,
} from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Types
import { OutletContextType } from "../../../@types/OutletContextType";
import { Roles } from "../../../@types/Auth0/Roles";

// Data
import { updateProfile } from "../../../common/api/middleware/updateProfile";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Components
import { Label } from "@fluentui/react-components";
import {
    ArrowExit20Regular,
    DeleteDismiss20Regular,
    Pen20Regular,
} from "@fluentui/react-icons";
import AdminPanel from "../../../components/AdminPanel/AdminPanel";
import TLAdminInput from "../../../components/elements/TLAdminInput/TLAdminInput";
import TLAdminSwitch from "../../../components/elements/TLAdminSwitch/TLAdminSwitch";

export type FormInputs = {
    email: string;
    firstName: string;
    lastName: string;
    developerMode?: boolean;
};

const formSchema = {
    email: yup
        .string()
        .email()
        .required("Please enter your e-mail address"),
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter your last name"),
    developerMode: yup.boolean().optional(),
};

interface Props {
    className?: string;
}

const ProfilePage: React.FC<Props> = ({ className }) => {
    const { authMetadata, setAuthMetadata } = useUserContext();
    const { setIsLoading } = useOutletContext<OutletContextType>();

    const [discard, setDiscard] = useState<boolean>(false);

    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    if (!authMetadata) {
        navigate("something-went-wrong", { replace: true });
    }

    // For now, we are just using a single role
    let role = authMetadata?.roles ? authMetadata.roles[0] : "";

    const {
        getValues,
        handleSubmit,
        register,
        reset,
        watch,
        formState: { errors, isDirty },
    } = useForm<FormInputs>({
        resolver: yupResolver(yup.object(formSchema)),
        defaultValues: {
            email: authMetadata?.email,
            firstName: authMetadata?.firstName,
            lastName: authMetadata?.lastName,
            developerMode: authMetadata?.developerMode,
        },
    });

    const onSubmit: SubmitHandler<FormInputs> = async formData => {
        if (authMetadata?.tokens?.accessToken) {
            setIsLoading(true);
            const result = await updateProfile(
                authMetadata?.tokens.accessToken,
                formData.email,
                formData.firstName,
                formData.lastName,
            );
            if (result) {
                setAuthMetadata({
                    name: result.data?.name,
                    firstName: result.data?.given_name,
                    lastName: result.data?.family_name,
                    email: result.data?.email,
                    developerMode: formData.developerMode,
                });
                navigate("/account/success", {
                    state: {
                        message:
                            "You've successfully updated your profile.",
                    },
                });
            } else {
                navigate("/account/failure", {
                    state: {
                        message:
                            "<p>Unable to update profile. Please try again.</p><p>If the problem persists please contact support.</p>",
                        backRoute: currentPath,
                    },
                });
            }
            setIsLoading(false);
        }
    };

    const handleDiscard = () => {
        reset({
            email: authMetadata?.email || "",
            firstName: authMetadata?.firstName || "",
            lastName: authMetadata?.lastName || "",
        });
        setDiscard(!discard);
    };

    const email = watch("email");
    const emailLength = email ? email.length : 0;

    return (
        <div className={className}>
            <AdminPanel
                maxWidth={emailLength > 50 ? 830 : 630}
                header="Profile"
                children={
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <fieldset>
                                <div className="field">
                                    <TLAdminInput
                                        enableModes={true}
                                        discard={discard}
                                        id="txtEmail"
                                        label="E-mail Address"
                                        type="email"
                                        {...register("email")}
                                        currentValue={getValues(
                                            "email",
                                        )}
                                        errorMessage={
                                            errors.email?.message
                                        }
                                    />
                                </div>
                                <div className="field">
                                    <TLAdminInput
                                        enableModes={true}
                                        discard={discard}
                                        id="txtFirstName"
                                        label="First Name"
                                        type="text"
                                        currentValue={getValues(
                                            "firstName",
                                        )}
                                        {...register("firstName")}
                                        errorMessage={
                                            errors.firstName?.message
                                        }
                                    />
                                </div>
                                <div className="field">
                                    <TLAdminInput
                                        enableModes={true}
                                        discard={discard}
                                        id="txtLastname"
                                        label="Last Name"
                                        type="text"
                                        currentValue={getValues(
                                            "lastName",
                                        )}
                                        {...register("lastName")}
                                        errorMessage={
                                            errors.lastName?.message
                                        }
                                    />
                                </div>
                                <div className="field link-field">
                                    <Label>Password</Label>
                                    <p>********</p>
                                    <Link to="/account/change-password">
                                        <ArrowExit20Regular />
                                    </Link>
                                </div>
                                {role === Roles.TL_ADMIN && (
                                    <div className="field">
                                        <TLAdminSwitch
                                            id="ckbDeveloperMode"
                                            label="Developer Mode"
                                            currentValue={getValues(
                                                "developerMode",
                                            )}
                                            {...register(
                                                "developerMode",
                                            )}
                                        />
                                    </div>
                                )}
                            </fieldset>
                            <div className="admin-form-buttons">
                                <button
                                    disabled={!isDirty}
                                    type="submit"
                                    style={{
                                        color: isDirty
                                            ? "#242424"
                                            : "#CCCCCC",
                                    }}
                                >
                                    <span className="icon">
                                        <Pen20Regular />
                                    </span>
                                    Save Changes
                                </button>
                                <button
                                    disabled={!isDirty}
                                    type="button"
                                    style={{
                                        color: isDirty
                                            ? "#242424"
                                            : "#CCCCCC",
                                    }}
                                    onClick={() => {
                                        handleDiscard();
                                    }}
                                >
                                    <span className="icon">
                                        <DeleteDismiss20Regular />
                                    </span>
                                    Discard Changes
                                </button>
                            </div>
                        </form>
                    </>
                }
            />
        </div>
    );
};

export default styled(ProfilePage)`
    form {
        width: 100%;
    }

    .field {
        margin-bottom: 2rem;
    }

    .field label {
        font-size: 1.125rem;
    }

    .link-field {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    .link-field label {
        width: 180px;
        padding-right: 1rem;
    }

    .link-field p {
        font-weight: bold;
    }

    .link-field a:link,
    .link-field a:visited {
        padding: 0.25rem;
        border-radius: 8px;
        margin-left: auto;
    }

    .link-field a:hover {
        color: #00bbcc;
    }

    .admin-form-buttons {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
    }

    .admin-form-buttons button {
        display: flex;
        justify-content: center;
        width: 264px;
        border-radius: 8px;
        padding: 1rem;
        background-color: #ffffff;
    }
`;
