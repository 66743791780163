import { Color } from "three";

export const ASSET_COLOR = 0xffffff;

// this is above the Bloom threshold for emissive intesity, so it will glow, see Post processes bloom effect
// N.B this color is just eyeballed to look the same as #E4FFFE once the glow is applied
export const FROSTED_CRYSTAL_GLOW = new Color(0.8, 2, 1.8);

const defaultColours = {
    hoverBlack: 0x303030,
    staticGray: 0xededed,
    assetStates: {
        default: {
            bounds: {
                color: 0xffffff,
                opacity: 0.4,
            },
            outline: {
                color: 0xffffff,
                opacity: 0.7,
            },
        },
        least: {
            bounds: {
                color: 0xbef4f2,
                // 0xa5ebff
                opacity: 0.35,
            },
            outline: {
                color: 0x5fd8df,
                opacity: 0.7,
            },
        },
        average: {
            bounds: {
                color: 0x8fe6e8,
                opacity: 0.35,
            },
            outline: {
                color: 0x5fd8df,
                opacity: 0.7,
            },
        },
        most: {
            bounds: {
                color: 0x00bbcc,
                // 0x5FD8DF
                opacity: 0.35,
            },
            outline: {
                color: 0x5fd8df,
                opacity: 0.7,
            },
        },
        overused: {
            bounds: {
                color: 0xc50f1f,
                opacity: 0.35,
            },
            outline: {
                color: 0xd01700,
                opacity: 0.7,
            },
        },
    },
};

export { defaultColours };
