/*
 * addOpeningHours
 * Endpoint accepts single JSON param
 */
import axios from "axios";
import routes from "./routes";
import { Period } from "../../../@types/Middleware/Period";

export const addOpeningHours = async (
    accessToken: string,
    envName: string,
    bID: string,
    effectiveDateFrom: string,
    effectiveDateTo: string,
    periods: Period[],
): Promise<boolean> => {
    const url = `${routes.get.twins.getOpeningHours(envName, bID)}`;
    let success = false;

    const data = {
        effectiveDateFrom,
        effectiveDateTo,
        periods,
    };

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        await axios.post(url, data, {
            headers: headers,
        });
        success = true;
    } catch (error) {
        success = false;
    }

    return success;
};
