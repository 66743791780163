import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Hotjar from "@hotjar/browser";

// Data
import Config from "./common/Config";

// Public Pages
import LoginPage from "./pages/LoginPage/LoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import PermissionDeniedPage from "./pages/PermissionDeniedPage";
import SomethingWentWrongPage from "./pages/SomethingWentWrongPage";
import GetStartedPage from "./pages/GetStarted/GetStartedPage";

// Protected Pages
import LoginCallbackPage from "./pages/Protected/LoginCallbackPage/LoginCallbackPage";

import SaasStandardTerms from "./pages/Protected/LegalPage/SaasStandardTerms";
import DataProcessing from "./pages/Protected/LegalPage/DataProcessing";
import AcceptableUse from "./pages/Protected/LegalPage/AcceptableUse";

import SelectOrganisationPage from "./pages/Protected/SelectOrganisationPage/SelectOrganisationPage";
import SelectTwinPage from "./pages/Protected/SelectTwinPage/SelectTwinPage";

import TwinPage from "./pages/Protected/TwinPage/TwinPage";
import DashboardPage from "./pages/Protected/DashboardPage/DashboardPage";
import ProfilePage from "./pages/Protected/ProfilePage/ProfilePage";
import ChangePasswordPage from "./pages/Protected/ChangePasswordPage/ChangePasswordPage";
import LegalPage from "./pages/Protected/LegalPage/LegalPage";
import LegalPromptPage from "./pages/Protected/LegalPromptPage/LegalPromptPage";
import NotificationPage from "./pages/Protected/NotificationPage/NotificationPage";

import TwinsPage from "./pages/Protected/TwinsPage/TwinsPage";
import EditTwinPage from "./pages/Protected/EditTwinPage/EditTwinPage";

import UsersPage from "./pages/Protected/UsersPage/UsersPage";
import AddNewUserPage from "./pages/Protected/AddNewUserPage/AddNewUserPage";
import EditUserPage from "./pages/Protected/EditUserPage/EditUserPage";
import DeleteUserPage from "./pages/Protected/DeleteUserPage/DeleteUserPage";

import BillingPage from "./pages/Protected/BillingPage/BillingPage";

// Components
import { PrimaryButton } from "@repo/ui";
import {
    CheckmarkCircle12Filled,
    DismissCircle12Filled,
} from "@fluentui/react-icons";
import LegalLayout from "./components/LegalLayout/LegalLayout";
import SinglePanelLayout from "./components/SinglePanelLayout/SinglePanelLayout";
import ProtectedLayout from "./components/ProtectedLayout/ProtectedLayout";
import ProtectedRoute from "./components/Auth0/ProtectedRoute";

// CSS (Keep for now)
import "./App.css";

const App: React.FC = () => {
    const hotjarVersion = 6;

    useEffect(() => {
        if (
            Config.hotJarSiteId &&
            window.location.hostname === "app.twinlabs.ai"
        ) {
            Hotjar.init(parseInt(Config.hotJarSiteId), hotjarVersion);
        }
    }, []);

    const { logout } = useAuth0();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    return (
        <Routes>
            {/* Public Routes */}
            <Route path="/" element={<LoginPage />} />
            <Route path="/callback" element={<LoginCallbackPage />} />
            <Route
                path="/select-organisation"
                element={<SelectOrganisationPage />}
            />

            <Route element={<SinglePanelLayout centre={false} />}>
                <Route
                    path="/get-started"
                    element={<GetStartedPage />}
                />
            </Route>

            {/* Protected Routes */}
            <Route
                path="/select-twin"
                element={
                    <ProtectedRoute component={SelectTwinPage} />
                }
            />
            <Route
                path="/twin"
                element={<ProtectedRoute component={TwinPage} />}
            />

            {/* Protected Routes within Protected Route Layout */}
            <Route element={<ProtectedLayout />}>
                <Route
                    path="/account/dashboard"
                    element={
                        <ProtectedRoute component={DashboardPage} />
                    }
                />

                <Route
                    path="/account/profile"
                    element={
                        <ProtectedRoute component={ProfilePage} />
                    }
                />

                <Route
                    path="/account/change-password"
                    element={
                        <ProtectedRoute
                            component={ChangePasswordPage}
                        />
                    }
                />

                <Route
                    path="/account/twins"
                    element={
                        <ProtectedRoute
                            allowedRoles={["CUSTOMER_ADMIN"]}
                            component={TwinsPage}
                        />
                    }
                />

                <Route
                    path="/account/twins/edit-twin"
                    element={
                        <ProtectedRoute
                            allowedRoles={["CUSTOMER_ADMIN"]}
                            component={EditTwinPage}
                        />
                    }
                />

                <Route
                    path="/account/users"
                    element={
                        <ProtectedRoute
                            allowedRoles={["CUSTOMER_ADMIN"]}
                            component={UsersPage}
                        />
                    }
                />

                <Route
                    path="/account/users/add-new-user"
                    element={
                        <ProtectedRoute
                            allowedRoles={["CUSTOMER_ADMIN"]}
                            component={AddNewUserPage}
                        />
                    }
                />

                <Route
                    path="/account/users/edit-user"
                    element={
                        <ProtectedRoute
                            allowedRoles={["CUSTOMER_ADMIN"]}
                            component={EditUserPage}
                        />
                    }
                />

                <Route
                    path="/account/billing"
                    element={
                        <ProtectedRoute
                            allowedRoles={["CUSTOMER_ADMIN"]}
                            component={BillingPage}
                        />
                    }
                />
            </Route>

            {/* Legal Layouts */}
            <Route element={<LegalLayout />}>
                <Route
                    path="/account/saas-standard-terms"
                    element={
                        <ProtectedRoute
                            component={LegalPage}
                            componentProps={{
                                content: SaasStandardTerms,
                            }}
                        />
                    }
                />
                <Route
                    path="/account/data-processing"
                    element={
                        <ProtectedRoute
                            component={LegalPage}
                            componentProps={{
                                content: DataProcessing,
                            }}
                        />
                    }
                />
                <Route
                    path="/account/acceptable-use"
                    element={
                        <ProtectedRoute
                            component={LegalPage}
                            componentProps={{
                                content: AcceptableUse,
                            }}
                        />
                    }
                />
            </Route>

            {/* Single Panel Layouts */}
            <Route element={<SinglePanelLayout centre={true} />}>
                <Route
                    path="/account/legal"
                    element={<LegalPromptPage />}
                />
                <Route
                    path="/account/log-out"
                    element={
                        <ProtectedRoute
                            component={NotificationPage}
                            componentProps={{
                                imgSrc: "/twinlabs-ai-stacked-logo.svg",
                                imgAlt: "Twinlabs.ai Logo",
                                header: "Are you sure you wish<br />to log out?",
                                primaryButton: (
                                    <PrimaryButton
                                        onClick={handleLogout}
                                    >
                                        Yes
                                    </PrimaryButton>
                                ),
                            }}
                        />
                    }
                />
                <Route
                    path="/account/users/delete"
                    element={
                        <ProtectedRoute component={DeleteUserPage} />
                    }
                />
                <Route
                    path="/account/success"
                    element={
                        <ProtectedRoute
                            component={NotificationPage}
                            componentProps={{
                                icon: (
                                    <CheckmarkCircle12Filled
                                        style={{ color: "#00BBCC" }}
                                    />
                                ),
                                header: "Success!",
                                primaryButton: (
                                    <PrimaryButton
                                        onClick={() => {
                                            navigate(
                                                "/account/dashboard",
                                            );
                                        }}
                                    >
                                        Continue
                                    </PrimaryButton>
                                ),
                            }}
                        />
                    }
                />
                <Route
                    path="/account/failure"
                    element={
                        <ProtectedRoute
                            component={NotificationPage}
                            componentProps={{
                                icon: (
                                    <DismissCircle12Filled
                                        style={{ color: "#FF000D" }}
                                    />
                                ),
                                header: "Failure!",
                            }}
                        />
                    }
                />
                <Route
                    path="/permission-denied"
                    element={<PermissionDeniedPage />}
                />
                <Route
                    path="/something-went-wrong"
                    element={<SomethingWentWrongPage />}
                />
                {/* Catch-All (404 Page Not Found) */}
                <Route path="*" element={<NotFoundPage />} />
            </Route>
        </Routes>
    );
};

export default App;
