import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from "react";

// Types
import { DataMode } from "../../@types/DataMode";
import { EntityHoverSelection } from "../../@types/EntityHoverSelection";
import { Entity } from "../../@types/Entity";
import { FilterType } from "../../@types/FilterType";

// Context
import {
    useFilterContext,
    useModeContext,
} from "./FilterAndModeContexts";
import { useTwinContext } from "./TwinContext";

// Utils
import { findEntityById } from "../utils/findEntityById";
import { AppMode } from "../../@types/Mode";

interface EventContextValue {
    currentHoveredEntity: EntityHoverSelection | null;
    currentHoveredEntityOn3DScene: EntityHoverSelection | null;
    selectedEntityIds: Set<string>;
    setCurrentHoveredValue: (
        currentHoveredEntity: EntityHoverSelection | null,
    ) => void;
    setCurrentHoveredEntityOn3DScene: (
        currentHoveredEntityOn3DScene: EntityHoverSelection | null,
    ) => void;
}

const initialState: EventContextValue = {
    currentHoveredEntity: null,
    currentHoveredEntityOn3DScene: null,
    selectedEntityIds: new Set(),
    setCurrentHoveredValue: () => {},
    setCurrentHoveredEntityOn3DScene: () => {},
};

enum ActionType {
    setCurrentHoveredValue,
    setCurrentHoveredEntityOn3DScene,
    setSelectedEntityIds,
}

type Action =
    | {
          type: ActionType.setCurrentHoveredValue;
          payload: EntityHoverSelection | null;
      }
    | {
          type: ActionType.setCurrentHoveredEntityOn3DScene;
          payload: EntityHoverSelection | null;
      }
    | { type: ActionType.setSelectedEntityIds; payload: Set<string> };

const reducer = (
    state: EventContextValue,
    action: Action,
): EventContextValue => {
    switch (action.type) {
        case ActionType.setCurrentHoveredValue:
            return {
                ...state,
                currentHoveredEntity: action.payload,
            };
        case ActionType.setCurrentHoveredEntityOn3DScene:
            return {
                ...state,
                currentHoveredEntityOn3DScene: action.payload,
            };
        case ActionType.setSelectedEntityIds:
            return {
                ...state,
                selectedEntityIds: action.payload,
            };
        default:
            return state;
    }
};

export const EventContext =
    createContext<EventContextValue>(initialState);

export const useEventContext = (): EventContextValue => {
    return useContext(EventContext);
};

interface EventContextProviderProps {
    children: React.ReactNode;
}

// Helper function to collect entity and its children IDs
const collectEntityAndChildrenIds = (
    entity: Entity,
    selectedIds: Set<string>,
) => {
    selectedIds.add(entity.id);
    if (entity.children && entity.children.length > 0) {
        entity.children.forEach(child =>
            collectEntityAndChildrenIds(child, selectedIds),
        );
    }
};

export const EventContextProvider: React.FC<
    EventContextProviderProps
> = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { filter } = useFilterContext();
    const { appMode } = useModeContext();
    const { twin } = useTwinContext();

    const setCurrentHoveredValue = useCallback(
        (currentHoveredEntity: EntityHoverSelection | null) => {
            dispatch({
                type: ActionType.setCurrentHoveredValue,
                payload: currentHoveredEntity,
            });
        },
        [],
    );

    const setCurrentHoveredEntityOn3DScene = useCallback(
        (
            currentHoveredEntityOn3DScene: EntityHoverSelection | null,
        ) => {
            dispatch({
                type: ActionType.setCurrentHoveredEntityOn3DScene,
                payload: currentHoveredEntityOn3DScene,
            });
        },
        [],
    );

    useEffect(() => {
        const selectedIds = new Set<string>();

        if (twin) {
            let lastFilterId =
                appMode === AppMode.LIVE
                    ? twin?.physicalModel.id
                    : twin?.physicalModel.bID; // Default to root Digital Twin Entity

            if (filter.length > 0) {
                let filterEntities = filter.filter(
                    filterItem =>
                        filterItem.type === FilterType.ENTITY,
                );
                const lastFilterItem = filterEntities.pop();

                filterEntities.forEach(filterItem => {
                    filterItem.id && selectedIds.add(filterItem.id);
                });

                if (lastFilterItem) {
                    if (
                        appMode === AppMode.LIVE &&
                        lastFilterItem.id
                    ) {
                        lastFilterId = lastFilterItem.id;
                    } else if (
                        appMode !== AppMode.LIVE &&
                        lastFilterItem.bId
                    ) {
                        lastFilterId = lastFilterItem.bId;
                    }
                }
            }

            const entities = [twin?.physicalModel];
            const matchedEntity = findEntityById(
                appMode === AppMode.LIVE
                    ? DataMode.LIVE
                    : DataMode.TIME_SERIES,
                entities,
                lastFilterId,
            );
            if (matchedEntity) {
                collectEntityAndChildrenIds(
                    matchedEntity,
                    selectedIds,
                );
            }

            dispatch({
                type: ActionType.setSelectedEntityIds,
                payload: selectedIds,
            });
        }
    }, [filter, appMode, twin]);

    const contextValue = useMemo(() => {
        return {
            ...state,
            setCurrentHoveredValue,
            setCurrentHoveredEntityOn3DScene,
        };
    }, [
        state,
        setCurrentHoveredValue,
        setCurrentHoveredEntityOn3DScene,
    ]);

    return (
        <EventContext.Provider value={contextValue}>
            {props.children}
        </EventContext.Provider>
    );
};
