interface Props {
    className?: string;
    backgroundColor: string;
    value: string;
    px?: number;
    marginRight?: number;
}

const Indicator = ({
    className,
    backgroundColor,
    value,
    px = 8,
    marginRight = 5,
}: Props) => {
    return (
        <div className={className}>
            <span
                style={{
                    width: `${px}px`,
                    height: `${px}px`,
                    borderRadius: `${px}px`,
                    display: "inline-block",
                    marginRight: `${marginRight}px`,
                    backgroundColor: backgroundColor,
                }}
            ></span>
            <span className="value">{value}</span>
        </div>
    );
};

export default Indicator;
