import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import { PrimaryButton } from "@repo/ui";
import NotificationPanel from "../components/NotificationPanel/NotificationPanel";

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <NotificationPanel
                imgSrc="/twinlabs-ai-stacked-logo.svg"
                imgAlt="Twinlabs.ai Logo"
                header="Page Not Found"
                primaryButton={
                    <PrimaryButton
                        onClick={() => navigate("/account/dashboard")}
                    >
                        Go to Login
                    </PrimaryButton>
                }
            />
        </>
    );
};

export default NotFoundPage;
