import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// Components
import { Delete24Regular, Pen24Regular } from "@fluentui/react-icons";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";

// Utils
import { formatRoles } from "../../common/utils/formatRoles";
import { formatDateToDayMonthYear } from "../../common/utils/formatDateToDayMonthYear";
import { truncateString } from "../../common/utils/truncateString";

interface Props {
    className?: string;
    data: any[];
}

const UsersTable: React.FC<Props> = ({ className, data }) => {
    const navigate = useNavigate();

    return (
        <div className={className}>
            <div className="thead">
                <div>Name</div>
                <div>Email</div>
                <div>Role(s)</div>
                <div>Sign up date</div>
                <div>Last seen at</div>
            </div>
            <CustomScrollbar maxHeight={630}>
                <table>
                    <tbody>
                        {data && data.length > 0 ? (
                            data.map((r, i) => {
                                let roles = formatRoles(r.roles).join(
                                    ", ",
                                );
                                let signedUpDate = r.created_at
                                    ? formatDateToDayMonthYear(
                                          new Date(r.created_at),
                                      )
                                    : "N/A";
                                let lastSeenDate = r.last_login
                                    ? formatDateToDayMonthYear(
                                          new Date(r.last_login),
                                      )
                                    : "N/A";

                                return (
                                    <tr key={i}>
                                        <td>{r.name}</td>
                                        <td>
                                            <Link
                                                to={`mailto:${r.email}`}
                                            >
                                                {truncateString(
                                                    r.email,
                                                    40,
                                                )}
                                            </Link>
                                        </td>
                                        <td>{roles}</td>
                                        <td>{signedUpDate}</td>
                                        <td>{lastSeenDate}</td>
                                        <td>
                                            <button
                                                onClick={() =>
                                                    navigate(
                                                        "/account/users/delete",
                                                        {
                                                            state: {
                                                                userId: r.user_id,
                                                            },
                                                        },
                                                    )
                                                }
                                            >
                                                <Delete24Regular />
                                            </button>
                                            <Link
                                                to="/account/users/edit-user"
                                                state={{
                                                    userId: r.user_id,
                                                }}
                                            >
                                                <Pen24Regular />
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td
                                    className="no-records"
                                    colSpan={7}
                                >
                                    No user records available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </CustomScrollbar>
        </div>
    );
};

export default styled(UsersTable)`
    .thead {
        display: flex;
        background-color: #d9d9d9;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .thead div {
        font-weight: 600;
    }

    .thead div,
    table tbody td {
        font-size: 1rem;
        padding: 1rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    /* 1st column */
    .thead div:nth-child(1),
    table tbody td:nth-child(1) {
        width: 200px;
    }

    /* 2nd column */
    .thead div:nth-child(2),
    table tbody td:nth-child(2) {
        width: 400px;
    }

    /* 3rd column */
    .thead div:nth-child(3),
    table tbody td:nth-child(3) {
        width: 200px;
    }

    /* 4th column */
    .thead div:nth-child(4),
    table tbody td:nth-child(4) {
        width: 150px;
    }

    /* 5th column */
    .thead div:nth-child(5),
    table tbody td:nth-child(5) {
        width: 150px;
    }

    table tbody td:nth-child(6) {
        text-align: right;
    }

    table tbody td button {
        margin-right: 2rem;
    }

    table tbody td a {
        margin: 0 1rem;
    }

    table {
        width: 100%;
        background-color: #d9d9d9;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    tbody tr {
        background-color: #ffffff;
        border-bottom: 2px #f8f7f6 solid;
    }

    tbody td a:link,
    tbody td a:visited {
        color: #424242;
    }

    tbody td a:hover,
    tbody td a:active,
    tbody td a:focus,
    tbody td button:hover,
    tbody td button:active,
    tbody td button:focus {
        color: #00bbcc;
    }

    tbody td.no-records {
        text-align: center;
    }
`;
