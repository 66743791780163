import React from "react";
import {
    useLocation,
    useNavigate,
    useOutletContext,
} from "react-router-dom";

// Types
import { OutletContextType } from "../../../@types/OutletContextType";

// Data
import { deleteUser } from "../../../common/api/middleware/deleteUser";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Components
import { Warning12Filled } from "@fluentui/react-icons";
import { PrimaryButton } from "@repo/ui";
import NotificationPanel from "../../../components/NotificationPanel/NotificationPanel";

interface Props {}

const DeleteUserPage: React.FC<Props> = () => {
    const { authMetadata } = useUserContext();
    const { setIsLoading } = useOutletContext<OutletContextType>();

    const location = useLocation();
    const navigate = useNavigate();
    const { userId } = location.state || {};

    if (!userId) {
        navigate("/something-went-wrong");
    }

    const handleDeleteUser = async (userId: string) => {
        setIsLoading(true);
        if (authMetadata?.tokens?.accessToken) {
            const result = await deleteUser(
                authMetadata?.tokens.accessToken,
                userId,
            );
            if (result) {
                navigate("/account/success", {
                    state: {
                        message:
                            "You've successfully deleted the user.",
                    },
                });
            } else {
                navigate("/account/failure", {
                    state: {
                        message:
                            "<p>Unable to delete user. Please try again.</p><p>If the problem persists please contact support.</p>",
                        backRoute: "/account/users/delete",
                        userId: userId,
                    },
                });
            }
            setIsLoading(false);
        }
    };

    return (
        <>
            <NotificationPanel
                icon={
                    <Warning12Filled style={{ color: "#FF000D" }} />
                }
                header="Are you sure you want to delete this account permanently?"
                copy="<p>This action cannot be undone. Press 'Delete Account' to confirm or 'Back' to return to the previous page.</p>"
                primaryButton={
                    <PrimaryButton
                        onClick={() => handleDeleteUser(userId)}
                    >
                        Delete Account
                    </PrimaryButton>
                }
                backRoute="/account/users"
            />
        </>
    );
};

export default DeleteUserPage;
