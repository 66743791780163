/*
 * IMPORTANT: This needs removing when we correctly have Twin Preferences stored within the back-end
 *
 */

// Type
import { TwinTemplate } from "../../@types/Settings/TwinTemplate";

// Newport Live
import { digitalTwinPrefs as stationGymTwinPrefs } from "./client/newport-live/station-gym/twinPrefs";

// Movico
import { digitalTwinPrefs as movicoTwinPrefData } from "./client/movico/mu2-pilot/twinPrefs";

// Movico / Sanofi
import { digitalTwinPrefs as sanofiMobileExpo7TwinPrefData } from "./client/movico/sanofi/mobile-expo-7/twinPrefs";

// Howler
import { digitalTwinPrefs as howlerDummyHowlerTwinPrefData } from "./client/howler/dummy-howler/twinPrefs";
import { digitalTwinPrefs as howler30678TwinPrefData } from "./client/howler/30678/twinPrefs";
import { digitalTwinPrefs as howler33054TwinPrefData } from "./client/howler/33054/twinPrefs";
import { digitalTwinPrefs as howler28695TwinPrefData } from "./client/howler/28695/twinPrefs";
import { digitalTwinPrefs as howler32845TwinPrefData } from "./client/howler/32845/twinPrefs";

// BLK BOX / Gym Co
import { digitalTwinPrefs as gymcoFinaghyTwinPrefs } from "./client/blk-box/gym-co/finaghy/twinPrefs";
import { digitalTwinPrefs as gymcoCitysideTwinPrefs } from "./client/blk-box/gym-co/cityside/twinPrefs";

// Total Fitness / Wilmslow
import { digitalTwinPrefs as tfWilmslowMainGymTwinPrefData } from "./client/total-fitness/wilmslow-main-gym/twinPrefs";
import { digitalTwinPrefs as tfWilmslowLadiesGymTwinPrefData } from "./client/total-fitness/wilmslow-ladies-gym/twinPrefs";

type TwinTemplateLookup = Record<
    string,
    Record<string, TwinTemplate>
>;

export const preferencesLookup: TwinTemplateLookup = {
    "Newport Live": {
        "station-gym": stationGymTwinPrefs,
    },
    "Movico": {
        "Mu2-Pilot": movicoTwinPrefData,
    },
    "Sanofi": {
        "SAN-XP7": sanofiMobileExpo7TwinPrefData,
    },
    "Howler": {
        "dummy-howler": howlerDummyHowlerTwinPrefData,
        "30678": howler30678TwinPrefData,
        "33054": howler33054TwinPrefData,
        "32845": howler32845TwinPrefData,
        "28695": howler28695TwinPrefData,
    },
    "Gym Co": {
        "GCO-Fin": gymcoFinaghyTwinPrefs,
        "GCO-CTY": gymcoCitysideTwinPrefs,
    },
    "Total Fitness": {
        "wilmslow-main-gym": tfWilmslowMainGymTwinPrefData,
        "wilmslow-ladies-gym": tfWilmslowLadiesGymTwinPrefData,
        "Total Fitness Gym 1": tfWilmslowMainGymTwinPrefData,
    },
};
