import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    useLocation,
    useNavigate,
    useOutletContext,
} from "react-router-dom";

// Types
import { NavItem } from "../../../@types/NavItem";
import { OutletContextType } from "../../../@types/OutletContextType";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Data
import { getQuickLinks } from "../../../common/utils/getQuickLinks";

// Components
import AdminPanel from "../../../components/AdminPanel/AdminPanel";
import QuickLinks from "../../../components/QuickLinks/QuickLinks";

interface Props {
    className?: string;
}

const DashboardPage: React.FC<Props> = ({ className }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setSelectedNavIndex } =
        useOutletContext<OutletContextType>();
    const { authMetadata } = useUserContext();

    const [quickLinks, setQuickLinks] = useState<NavItem[]>([]);

    const currentPath = location.pathname;

    // Missing authMetadata? Redirect user to the "Something went wrong" page
    if (
        !authMetadata?.tokens?.accessToken ||
        !authMetadata?.organisation ||
        !authMetadata?.roles
    ) {
        navigate("/something-went-wrong");
    }

    // For now, we are just using a single role
    let role = authMetadata?.roles ? authMetadata.roles[0] : "";

    useEffect(() => {
        if (authMetadata?.roles) {
            const links = getQuickLinks(role, currentPath);
            setQuickLinks(links);
        }
    }, [authMetadata?.roles, currentPath, role]);

    return (
        <div className={className}>
            <AdminPanel
                maxWidth={630}
                header="Dashboard"
                children={
                    <>
                        <h2>Quick Links</h2>
                        <QuickLinks
                            links={quickLinks}
                            setSelectedNavIndex={setSelectedNavIndex}
                        />
                    </>
                }
            />
        </div>
    );
};

export default styled(DashboardPage)`
    h2 {
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }
`;
