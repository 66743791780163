/*
 * Config is essentially just a wrapper for the env variables
 *
 */
const Config = {
    authEndpoint: process.env.REACT_APP_AUTH_ENDPOINT,
    liveGraphQLEndpoint: process.env.REACT_APP_LIVE_GRAPHQL_ENDPOINT,
    liveGraphQLSubscriptions:
        process.env.REACT_APP_LIVE_GRAPHQL_SUBSCRIPTIONS,
    middlewareApi: process.env.REACT_APP_API_SERVER_URL,
    initialOnloadDelay: process.env.REACT_APP_INITIAL_ONLOAD_DELAY,
    hotJarSiteId: process.env.REACT_APP_HOTJAR_SITE_ID,
    appProfile: process.env.REACT_APP_PROFILE,
    modelBaseURL: process.env.REACT_APP_MODEL_BASE_URL,
    preflightReport: process.env.REACT_APP_PREFLIGHT_REPORT,
    metricDataWarning: process.env.REACT_APP_METRIC_DATA_WARNING,
    useLocalDgraph: !!parseInt(
        process.env.REACT_APP_USE_LOCAL_DGRAPH || "0",
    ),
    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
    auth0Namespace: process.env.REACT_APP_AUTH0_NAMESPACE,
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    auth0CallbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
    twinLabsAPIUrl: process.env.REACT_APP_API_SERVER_URL,
    auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export default Config;
