/*
 * tsInstantQuery
 * This is a wrapper for the timeSeriesInstantQuery function
 *
 */

// Utils
import { timeSeriesInstantQuery } from "./timeSeriesInstantQuery";

export const tsInstantQuery = async (
    timeSeriesURL: string,
    endDateTime: string,
    aggregation: string,
    step: string = "15m",
    organisation: string,
    metric: string,
    metricType?: string,
    digitalTwinEntity?: string,
    digitalTwinEntityPath?: string,
) => {
    let by = ``;
    let filterBy = ``;

    if (metric) {
        by = `digitalTwinEntity, digitalTwinEntityPath, metric`;
        filterBy = filterBy + `metric='${metric}'`;
    }

    if (organisation) {
        filterBy =
            filterBy +
            `${filterBy.length > 0 ? `,` : ``}organisation='${organisation}'`;
    }

    if (metricType) {
        by = `metricType`;
        filterBy =
            filterBy +
            `${filterBy.length > 0 ? `,` : ``}metricType='${metricType}'`;
    }

    if (digitalTwinEntity) {
        filterBy =
            filterBy +
            `${filterBy.length > 0 ? `,` : ``}digitalTwinEntity='${digitalTwinEntity}'`;
    }

    if (digitalTwinEntityPath) {
        filterBy =
            filterBy +
            `${filterBy.length > 0 ? `,` : ``}digitalTwinEntityPath=~'${digitalTwinEntityPath}'`;
    }

    const query = `${aggregation} by(${by}) (${aggregation}_over_time(metricvalue{${filterBy}}[${step}]))`;
    const result = await timeSeriesInstantQuery(
        timeSeriesURL,
        query,
        endDateTime,
    );
    return result;
};
