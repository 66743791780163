// Templates
import { eventTruckTemplate } from "../../../twin-templates/eventTruckTemplate";

// Types
import { TwinTemplate } from "../../../../../@types/Settings/TwinTemplate";

const twinPrefs: Partial<TwinTemplate> = {
    organisation: "Movico",
    timeZone: "Europe/Paris",
    leftFieldMessages: [
        "Fixing a flat tyre",
        "Checking the bike suspension",
        "Shaving my legs to improve aerodynamics",
        `Using The "N+1" Rule to justify my latest bike purchase`,
    ],
};

export const digitalTwinPrefs = {
    ...eventTruckTemplate,
    ...twinPrefs,
};
