export const calculateTotalHours = (
    startTime: string,
    endTime: string,
): number => {
    let [startHour, startMinute] = startTime.split(":").map(Number);
    let [endHour, endMinute] = endTime.split(":").map(Number);
    const startDate = new Date(0, 0, 0, startHour, startMinute);
    const endDate = new Date(0, 0, 0, endHour, endMinute);
    let diff = endDate.getTime() - startDate.getTime();
    let diffHours = diff / (1000 * 60 * 60);

    if (diffHours < 0) {
        diffHours += 24;
    }

    return diffHours;
};
