import styled from "styled-components";
import { Warning48Filled } from "@fluentui/react-icons";

// Context
import { useDataContext } from "../../common/contexts/DataContext";

interface Props {
    className?: string;
}

const DataWarningIndicator: React.FC<Props> = ({ className }) => {
    const { warning } = useDataContext();
    return (
        <div className={className}>
            {warning ? <Warning48Filled /> : null}
        </div>
    );
};

export default styled(DataWarningIndicator)`
    position: absolute;
    z-index: 99;
    bottom: 0;
    right: 20px;
    color: #000;
    pointer-events: all;
`;
