import React from "react";
import styled from "styled-components";

interface Props {
    className?: string;
    label: string;
    onClick: () => void;
}

const TLFilterClearPill: React.FC<Props> = ({
    className,
    label,
    onClick,
}) => {
    return (
        <span className={className} onClick={onClick}>
            <p className="mr-2">{label}</p>
        </span>
    );
};

export default styled(TLFilterClearPill)`
    cursor: pointer;
    color: #c50f1f;
    font-size: 0.875rem;
    border-radius: 9999px;
    padding: 0.5rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;

    &:hover {
        color: #d64545;
    }
`;
