import { Point, Polygon } from "@repo/backend-types";

const EARTH_RADIUS_METRES = 6378137;

export function shiftLatLongByMetres(
    startingLocation: Point,
    offset: { x: number; y: number },
) {
    const newLatitude =
        startingLocation.latitude +
        (offset.y / EARTH_RADIUS_METRES) * (180 / Math.PI);
    const newLongitude =
        startingLocation.longitude +
        ((offset.x / EARTH_RADIUS_METRES) * (180 / Math.PI)) /
            Math.cos((startingLocation.latitude * Math.PI) / 180);

    return {
        lat: newLatitude,
        lng: newLongitude,
    };
}

export function degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
}

export function radiansToDegrees(radians: number) {
    return (radians * 180) / Math.PI;
}

export function getPolygonCentroid(pts: Point[]) {
    var first = pts[0],
        last = pts[pts.length - 1];
    if (
        first.latitude !== last.latitude ||
        first.longitude !== last.longitude
    )
        pts.push(first);
    var twicearea = 0,
        x = 0,
        y = 0,
        nPts = pts.length,
        p1,
        p2,
        f;
    for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
        p1 = pts[i];
        p2 = pts[j];
        f =
            (p1.longitude - first.longitude) *
                (p2.latitude - first.latitude) -
            (p2.longitude - first.longitude) *
                (p1.latitude - first.latitude);
        twicearea += f;
        x += (p1.latitude + p2.latitude - 2 * first.latitude) * f;
        y += (p1.longitude + p2.longitude - 2 * first.longitude) * f;
    }
    f = twicearea * 3;
    return {
        latitude: x / f + first.latitude,
        longitude: y / f + first.longitude,
    };
}

// take the last polygon centroid, as per https://github.com/TwinLabs-ai/graph/blob/68513dfb36f3690830ce407a1340ff673ba359d0/schema/schema.graphql#L88
export function getCentre(polygons: Polygon[]) {
    const lastPolygon = polygons[polygons.length - 1];
    return getPolygonCentroid(lastPolygon.coordinates[0].points);
}
