/*
 * getIndicatorColor
 *
 */

// Types
import { IndicatorColor } from "../../@types/Settings/IndicatorColor";
import { IndicatorColors } from "../../@types/Settings/IndicatorColors";
import { IndicatorConfig } from "../../@types/Settings/IndicatorConfig";

// Utils
import { evaluateStringAsFunction } from "./evaluateStringAsFunction";

export const getIndicatorColor = (
    value: number | null,
    config?: IndicatorConfig | undefined,
    selected?: boolean,
): IndicatorColors => {
    let textColor = IndicatorColor.BLACK;
    let bgColor = IndicatorColor.NA;

    if (value && config) {
        let rangeMap = selected
            ? config?.selected
            : config.nonSelected;
        for (let i = 0; i < rangeMap.length; i++) {
            // Perform test using the operation stored in the settings
            const opFunc = evaluateStringAsFunction(rangeMap[i].op);
            if (opFunc(value)) {
                textColor = rangeMap[i].textColor;
                bgColor = rangeMap[i].bgColor;
                break;
            }
        }
    } else {
        if (value) {
            if (selected) {
                // Default Selected Indicator Colors
                if (value === 0) {
                    bgColor = IndicatorColor.ZERO;
                } else if (value >= 1 && value <= 10) {
                    bgColor = IndicatorColor.VERY_LOW;
                } else if (value >= 11 && value <= 20) {
                    bgColor = IndicatorColor.LOW;
                } else if (value >= 21 && value <= 30) {
                    bgColor = IndicatorColor.MEDIUM_LOW;
                } else if (value >= 31 && value <= 40) {
                    bgColor = IndicatorColor.MEDIUM;
                } else if (value >= 41 && value <= 50) {
                    bgColor = IndicatorColor.MEDIUM_HIGH;
                } else if (value >= 51 && value <= 75) {
                    bgColor = IndicatorColor.HIGH;
                } else if (value >= 76 && value <= 100) {
                    bgColor = IndicatorColor.VERY_HIGH;
                } else if (value > 100) {
                    bgColor = IndicatorColor.EXTREMELY_HIGH;
                }
            } else {
                // Default Non-Selected Indicator Colors
                if (value === 0) {
                    bgColor = IndicatorColor.ZERO_NOT_SELECTED;
                } else if (value >= 1 && value <= 10) {
                    bgColor = IndicatorColor.VERY_LOW_NOT_SELECTED;
                } else if (value >= 11 && value <= 20) {
                    bgColor = IndicatorColor.LOW_NOT_SELECTED;
                } else if (value >= 21 && value <= 30) {
                    bgColor = IndicatorColor.MEDIUM_LOW_NOT_SELECTED;
                } else if (value >= 31 && value <= 40) {
                    bgColor = IndicatorColor.MEDIUM_NOT_SELECTED;
                } else if (value >= 41 && value <= 50) {
                    bgColor = IndicatorColor.MEDIUM_HIGH_NOT_SELECTED;
                } else if (value >= 51 && value <= 75) {
                    bgColor = IndicatorColor.HIGH_NOT_SELECTED;
                } else if (value >= 76 && value <= 100) {
                    bgColor = IndicatorColor.VERY_HIGH_NOT_SELECTED;
                } else if (value > 100) {
                    bgColor =
                        IndicatorColor.EXTREMELY_HIGH_NOT_SELECTED;
                }
            }
        }
    }

    return { textColor, bgColor };
};
