import { BufferGeometry, Color, DoubleSide, Vector2 } from "three";
import { OutlineSolid } from "../outlines/OutlineSolid";
import { TwinEntity } from "@repo/backend-types";
import { IndicatorColor } from "../../../@types/Settings/IndicatorColor";

type Props = {
    visible: boolean;
    geometry: BufferGeometry;
    color: IndicatorColor;
    showOutline: boolean;
    entity: TwinEntity;
};

const NORMAL_SCALE = new Vector2(0.25, 0.25);

export function Tracked({
    visible,
    geometry,
    color,
    showOutline,
    entity,
}: Props) {
    return (
        <group visible={visible}>
            <OutlineSolid
                geometry={geometry}
                lineWidth={0.03}
                color={color}
                visible={showOutline}
            />
            <mesh userData={entity} geometry={geometry}>
                <meshStandardMaterial
                    color={color}
                    forceSinglePass
                    transparent
                    opacity={0.4}
                    roughness={0.6}
                    metalness={0.25}
                    side={DoubleSide}
                    normalScale={NORMAL_SCALE}
                    depthWrite={false}
                />
            </mesh>
        </group>
    );
}
