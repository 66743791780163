import { Group, Material, Mesh } from "three";
import { isMesh } from "../helpers/threeTypeGuards";

export function disposeMaterial(material: Material | Material[]) {
    if (Array.isArray(material)) {
        material.forEach(individualMaterial =>
            disposeMaterial(individualMaterial),
        );
    } else {
        material.dispose();

        // dispose textures
        for (const [, value] of Object.entries(material)) {
            if (
                value &&
                typeof value === "object" &&
                "minFilter" in value
            ) {
                value.dispose();
            }
        }
    }
}

export function disposeMesh(mesh: Mesh) {
    const material = mesh.material;
    const geometry = mesh.geometry;

    geometry.dispose();

    disposeMaterial(material);
}

export function disposeScene(scene: Group) {
    scene.traverse(object => {
        if (isMesh(object)) {
            disposeMesh(object);
        }
    });
}
