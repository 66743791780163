// Types
import { NavItem } from "../../@types/NavItem";

// Components
import {
    Building20Filled,
    Building20Regular,
} from "@fluentui/react-icons";

export const partnerUserBottomMenu: NavItem[] = [
    {
        text: "Select Organisation",
        href: "/select-organisation",
        icon: Building20Regular,
        iconSelected: Building20Filled,
        showOnQuickLinks: true,
    },
];
