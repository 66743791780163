/*
 * sortDataValuesByType
 *
 */
import { Metric } from "@repo/backend-types";
import { DataValue } from "../../@types/Data/DataValue";
import { SortedDataValue } from "../../@types/Data/SortedDataValue";

export const sortDataValuesByType = (
    dataValues: DataValue[],
    metrics: Metric[],
): SortedDataValue => {
    const sortedObject: SortedDataValue = {};

    metrics.forEach(metric => {
        sortedObject[metric.name] = [];
    });

    dataValues.forEach(dv => {
        if (!sortedObject[dv.metric.name]) {
            sortedObject[dv.metric.name] = [];
        }
        sortedObject[dv.metric.name].push(dv);
    });
    return sortedObject;
};
