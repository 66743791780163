import { DateTime } from "luxon";

export const findMinMaxDates = (
    dates: string[],
    timeZone: string,
) => {
    // Convert selected date strings into Luxon DateTime objects using the timeZone
    let dateObjects = dates.map(date => DateTime.fromISO(date));

    // Get the minimum and maximum dates using Luxon
    const minDate = DateTime.min(...dateObjects);
    const maxDate = DateTime.max(...dateObjects);

    // Convert back to ISO strings
    return {
        minDate: minDate.toISO(),
        maxDate: maxDate.toISO(),
    };
};
