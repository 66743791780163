import { useFrame, useThree } from "@react-three/fiber";
import { RefObject } from "react";

type GlInfoProps = { infoRef: RefObject<HTMLDivElement> };

export const DebugThreeGlInfo = ({ infoRef }: GlInfoProps) => {
    const { gl } = useThree();
    gl.autoClear = false;
    gl.info.autoReset = false;
    useFrame(state => {
        if (infoRef.current) {
            const { calls, lines, points, triangles } =
                gl.info.render;
            infoRef.current.innerHTML = `
            calls: ${calls.toLocaleString()} :-
            lines: ${lines.toLocaleString()} :-
            points: ${points.toLocaleString()} :-
            triangles: ${triangles.toLocaleString()}
            `;
        }

        state.gl.info.reset();
    });

    return <></>;
};

export const DebugGlInfoDiv = ({ infoRef }: GlInfoProps) => {
    return (
        <div
            ref={infoRef}
            style={{
                marginTop: -33,
                top: 0,
                left: 270,
                padding: 5,
                borderStyle: "solid",
                borderWidth: 2,
                borderColor: "#000000",
                position: "absolute",
                zIndex: 1000,
                width: 400,
                height: 40,
                color: "#000000",
                backgroundColor: "#ffffff",
            }}
        ></div>
    );
};
