/*
 * parseTwin
 *
 */

// Types
import { Entity } from "../../@types/Entity";
import { LogEntry } from "../../@types/LogEntry";
import { Twin } from "../../@types/Twin";

// Utils
import { checkEntityDataIntegrity } from "./checkEntityDataIntegrity";

type ModelResult = {
    physicalNode: Entity | null;
    filterNode: Entity | null;
};

export const parseTwin = (
    organisation: string,
    twinEntity: Entity,
    physicalEntities: string[],
    filterEntities: string[],
    preFlightCheckEnabled: boolean = false,
    apolloExplorerURI?: string,
): { twin: Twin; preFlightReport: LogEntry[] } => {
    let preFlightReport: LogEntry[] = [];

    // Recursive helper function to build models based on entity type
    const processEntity = (
        entity: Entity,
        currentDepth: number,
    ): ModelResult => {
        // Initialise objects for possible inclusion in physical and filter models
        let physicalNode: Entity | null = null;
        let filterNode: Entity | null = null;

        if (preFlightCheckEnabled) {
            preFlightReport = preFlightReport.concat(
                checkEntityDataIntegrity(
                    organisation,
                    entity,
                    apolloExplorerURI ? apolloExplorerURI : "",
                    currentDepth === 0,
                ),
            );
        }

        // Check if the current entity matches any type in physicalEntities
        if (physicalEntities.includes(entity.type?.name)) {
            // Copy the whole entity for the physical model
            physicalNode = {
                ...entity,
                children: [],
                depth: currentDepth,
            };
        }

        // Check if the current entity matches any type in filterEntities
        if (filterEntities.includes(entity.type?.name)) {
            // Copy the whole entity for the filter model
            filterNode = {
                ...entity,
                children: [],
                depth: currentDepth,
            };
        }

        // Process children recursively if they exist
        if (
            Array.isArray(entity.children) &&
            entity.children.length > 0
        ) {
            entity.children.forEach(child => {
                const {
                    physicalNode: childPhysical,
                    filterNode: childFilter,
                } = processEntity(child, currentDepth + 1);

                // Add child to physical model if it matches physicalEntities
                if (childPhysical && physicalNode) {
                    physicalNode.children!.push(childPhysical);
                }

                // Add child to filter model if it matches filterEntities
                if (childFilter && filterNode) {
                    filterNode.children!.push(childFilter);
                }
            });
        }

        // Return the nodes, whether populated or null
        return { physicalNode, filterNode };
    };

    // Start processing from the root
    const { physicalNode, filterNode } = processEntity(twinEntity, 0);

    if (physicalNode) {
        const twin = {
            physicalModel: physicalNode,
            filterModel: filterNode,
            totalCapacity:
                physicalNode && physicalNode.capacity
                    ? physicalNode.capacity
                    : 0,
        };
        return {
            twin,
            preFlightReport,
        };
    } else {
        throw new Error("Unable to parse Twin");
    }
};
