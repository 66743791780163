/*
 * getISOWeekNumber
 * Calculates ISO week number
 *
 */
export const getISOWeekNumber = (date: Date) => {
    const tempDate = new Date(date);
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(
        tempDate.getDate() + 4 - (tempDate.getDay() || 7),
    );
    const startOfYear = new Date(tempDate.getFullYear(), 0, 1);
    const weekNo = Math.ceil(
        ((tempDate.getTime() - startOfYear.getTime()) / 86400000 +
            1) /
            7,
    );
    return weekNo;
};
