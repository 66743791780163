/*
 * UserContext
 * Used to store the user account on authenication
 *
 */
import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";

// Types
import { Tokens } from "../../@types/Settings/Tokens";
import { Legal } from "../../@types/Auth0/Legal";
import { Permission } from "../../@types/Auth0/Roles";

type AuthMetadata = {
    name?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    roles?: string[];
    permissions?: Set<Permission>;
    tokens?: Tokens;
    organisation?: string;
    legal?: Legal;
    aiUrl?: string;
    dgraphUrl?: string;
    envName?: string;
    environment?: string;
    tsdbUrl?: string;
    developerMode?: boolean;
};
interface UserContextValue {
    authMetadata: AuthMetadata | null;
    setAuthMetadata: (authMetadata: Partial<AuthMetadata>) => void;
}

const initialState: UserContextValue = {
    authMetadata: null,
    setAuthMetadata: () => {},
};

export const UserContext =
    createContext<UserContextValue>(initialState);

export const useUserContext = (): UserContextValue => {
    return useContext(UserContext);
};

interface ContextProviderProps {
    children: React.ReactNode;
}

export const UserContextProvider: React.FC<
    ContextProviderProps
> = props => {
    const [userState, setUserState] =
        useState<UserContextValue>(initialState);

    const setAuthMetadata = useCallback(
        (authMetadata: Partial<AuthMetadata>) => {
            setUserState(prevState => ({
                ...prevState,
                authMetadata: {
                    ...prevState.authMetadata,
                    ...authMetadata,
                    tokens: {
                        ...prevState.authMetadata?.tokens,
                        ...authMetadata.tokens,
                    },
                },
            }));
        },
        [],
    );

    // Memoise the context value to prevent unnecessary re-renders
    const contextValue = useMemo(() => {
        return {
            ...userState,
            setAuthMetadata,
        };
    }, [userState, setAuthMetadata]);

    return (
        <UserContext.Provider value={contextValue}>
            {props.children}
        </UserContext.Provider>
    );
};
