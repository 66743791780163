import { BufferGeometry, DoubleSide } from "three";
import { OutlineSolid } from "../outlines/OutlineSolid";
import { TwinEntity } from "@repo/backend-types";
import { Hitbox } from "../Hitbox";
import { useState } from "react";

type Props = {
    geometry: BufferGeometry;
    entity: TwinEntity;
    mapDiv: HTMLDivElement;
    lineage: TwinEntity[];
};

export function EditView({
    geometry,
    entity,
    mapDiv,
    lineage,
}: Props) {
    const [pressed, setPressed] = useState(false);
    const [hover, setHover] = useState(false);

    return (
        <group>
            <OutlineSolid
                geometry={geometry}
                lineWidth={0.03}
                color={0x111111}
            />
            <Hitbox
                entity={entity}
                geometry={geometry}
                setHover={setHover}
                setPressed={setPressed}
                mapDiv={mapDiv}
                lineage={lineage}
            />
            <mesh userData={entity} geometry={geometry}>
                <meshStandardMaterial
                    color={0xeeeeee}
                    forceSinglePass
                    transparent
                    opacity={0.1}
                    roughness={0.6}
                    metalness={0.25}
                    side={DoubleSide}
                    depthWrite={false}
                />
            </mesh>
        </group>
    );
}
