import { User } from "@auth0/auth0-spa-js";
import { Claims } from "../../@types/Auth0/Claims";
import Config from "../Config";

export const extractClaims = (user: User): Claims => {
    let organisations;
    let roles;
    let mapboxToken;
    let modelUrlKey;
    let legal;

    organisations =
        user[`${Config.auth0Namespace}/jwt/claims/user_metadata`]
            .organisations;
    roles = user && user[`${Config.auth0Namespace}/jwt/claims/roles`];
    mapboxToken =
        user[`${Config.auth0Namespace}/jwt/claims`].mapboxToken;
    modelUrlKey =
        user[`${Config.auth0Namespace}/jwt/claims`].modelUrlKey;
    legal =
        user &&
        user[`${Config.auth0Namespace}/jwt/claims/user_metadata`]
            .legal;

    return { organisations, roles, mapboxToken, modelUrlKey, legal };
};
