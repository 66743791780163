import { useEffect, useState } from "react";
import styled from "styled-components";

// Types
import { FilterPath } from "../../@types/FilterPath";
import { Message } from "../../@types/Message";
import { PredefinedPrompt } from "../../@types/PredefinedPrompt";

// Data
import { entitySelectionPrompts } from "../../common/data/entitySelectionPrompts";

// Components
import AIPrompt from "./AIPrompt";
import AIConversation from "./AIConversation";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";

interface Props {
    className?: string;
    openingMessage?: Message;
    filter?: FilterPath[];
    apiEndpoint: string;
    apiKey?: string;
    dynamicHeight?: boolean;
    maxHeight?: number | string;
}

const AI: React.FC<Props> = ({
    className,
    openingMessage,
    filter,
    apiEndpoint,
    apiKey,
    dynamicHeight,
    maxHeight,
}) => {
    const [sessionId, setSessionId] = useState<string>("");
    const [awaitingMessage, setAwaitingMessage] =
        useState<boolean>(false);
    const [triggerPrompt, setTriggerPrompt] = useState<
        string | undefined
    >();
    const [messages, setMessages] = useState<Message[]>(
        openingMessage ? [openingMessage] : [],
    );

    const addMessage = (newMessage: Message) => {
        setTriggerPrompt(undefined);
        setMessages(prevMessages => [...prevMessages, newMessage]);
        if (newMessage.triggerPrompt) {
            setTriggerPrompt(newMessage.content);
        }
    };

    const clearMessages = () => {
        setMessages([]);
    };

    useEffect(() => {
        setSessionId(crypto.randomUUID());

        if (filter && filter.length > 0) {
            let lastEntity: string = "";
            const entities = filter.filter(fp => fp.type === 0);

            if (entities && entities.length > 0) {
                entities.forEach(f => {
                    lastEntity = f.label;
                });

                let prompts: PredefinedPrompt[] = [];
                entitySelectionPrompts.forEach(pgp => {
                    const content = pgp.content.replaceAll(
                        `{ENTITY}`,
                        lastEntity,
                    );
                    prompts.push({ title: content, prompt: content });
                });

                addMessage({
                    content: `I've spotted you've selected the <strong>${lastEntity}</strong>; here are some options:`,
                    response: true,
                    prompts: prompts,
                });
            }
        }
    }, [filter]);

    return (
        <div className={className}>
            <CustomScrollbar
                maxHeight={maxHeight || "calc(100vh - 300px)"}
                dynamicHeight={dynamicHeight}
                right={-30}
                contentUpdated={messages.length}
            >
                <AIConversation
                    messages={messages}
                    awaitingMessage={awaitingMessage}
                    addMessage={addMessage}
                    clearMessages={clearMessages}
                />
            </CustomScrollbar>
            <AIPrompt
                setAwaitingMessage={setAwaitingMessage}
                addMessage={addMessage}
                sessionId={sessionId}
                triggerPrompt={triggerPrompt}
                apiEndpoint={apiEndpoint}
                apiKey={apiKey}
            />
        </div>
    );
};

export default styled(AI)`
    padding: 0 12px 12px 12px;
    width: 880px;
    pointer-events: all;
`;
