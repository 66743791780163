import React from "react";
import styled from "styled-components";
import {
    useLocation,
    useNavigate,
    useOutletContext,
} from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Types
import { OutletContextType } from "../../../@types/OutletContextType";
import { Roles } from "../../../@types/Auth0/Roles";

// Data
import { createUser } from "../../../common/api/middleware/createUser";

// Utils
import { passwordValidation } from "../../../common/utils/passwordValidation";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Components
import {
    DeleteDismiss20Regular,
    Pen20Regular,
} from "@fluentui/react-icons";
import AdminPanel from "../../../components/AdminPanel/AdminPanel";
import TLAdminInput from "../../../components/elements/TLAdminInput/TLAdminInput";
import TLAdminSelect from "../../../components/elements/TLAdminSelect/TLAdminSelect";

export type FormInputs = {
    email: string;
    password: string;
    reEnterPassword: string;
    firstName: string;
    lastName: string;
    role: string;
};

const formSchema = {
    email: yup
        .string()
        .email()
        .required("Please enter an e-mail address"),
    password: passwordValidation,
    reEnterPassword: yup
        .string()
        .required("Please re-enter the password"),
    firstName: yup.string().required("Please enter a first name"),
    lastName: yup.string().required("Please enter a last name"),
    role: yup
        .string()
        .typeError("You must select an option")
        .notOneOf([""], "Please select an option")
        .required("You must select an option"),
};

interface Props {
    className?: string;
}

const AddNewUserPage: React.FC<Props> = ({ className }) => {
    const { authMetadata } = useUserContext();
    const { setIsLoading } = useOutletContext<OutletContextType>();

    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    // Redirect user to Something went wrong
    if (
        !authMetadata?.tokens?.accessToken ||
        (!authMetadata?.organisation &&
            !authMetadata?.envName &&
            !authMetadata?.roles)
    ) {
        navigate("/something-went-wrong");
    }

    // For now, we are just using a single role
    let role = authMetadata?.roles ? authMetadata.roles[0] : "";

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormInputs>({
        resolver: yupResolver(yup.object(formSchema)),
        defaultValues: {
            role: "",
        },
    });

    const onSubmit: SubmitHandler<FormInputs> = async formData => {
        if (
            authMetadata?.tokens?.accessToken &&
            authMetadata.organisation
        ) {
            setIsLoading(true);
            const result = await createUser(
                authMetadata?.tokens.accessToken,
                `${authMetadata.organisation}:${authMetadata.envName}`,
                formData.firstName,
                formData.lastName,
                formData.email,
                formData.password,
                formData.role,
            );
            if (result) {
                navigate("/account/success", {
                    state: {
                        message:
                            "You've successfully created a new user.",
                    },
                });
            } else {
                navigate("/account/failure", {
                    state: {
                        message:
                            "<p>Unable to update user. Please try again.</p><p>If the problem persists please contact support.</p>",
                        backRoute: currentPath,
                    },
                });
            }
            setIsLoading(false);
        }
    };

    const handleDiscard = () => {
        reset({
            role: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            reEnterPassword: "",
        });
    };

    const getRoleOptions = (role: string) => {
        let options = [
            {
                text: "Please select",
                value: "",
            },
        ];

        let customerAdminOptions = [
            {
                text: "Admin",
                value: Roles.CUSTOMER_ADMIN,
            },
            {
                text: "User",
                value: Roles.CUSTOMER_USER,
            },
        ];

        if (role === Roles.TL_ADMIN) {
            options = [
                ...options,
                ...[
                    {
                        text: "TL Admin",
                        value: Roles.TL_ADMIN,
                    },
                ],
                ...customerAdminOptions,
            ];
        } else {
            options = [...options, ...customerAdminOptions];
        }
        return options;
    };

    return (
        <div className={className}>
            <AdminPanel
                maxWidth={630}
                header="Add New User"
                children={
                    <>
                        <div className="form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <fieldset>
                                    <div className="field">
                                        <TLAdminSelect
                                            enableModes={false}
                                            id="ddlRole"
                                            label="Role"
                                            options={getRoleOptions(
                                                role,
                                            )}
                                            props={{
                                                ...register("role"),
                                            }}
                                            errorMessage={
                                                errors.role?.message
                                            }
                                        />
                                    </div>
                                    <div className="field">
                                        <TLAdminInput
                                            enableModes={false}
                                            id="txtFirstName"
                                            label="First Name"
                                            {...register("firstName")}
                                            errorMessage={
                                                errors.firstName
                                                    ?.message
                                            }
                                        />
                                    </div>
                                    <div className="field">
                                        <TLAdminInput
                                            enableModes={false}
                                            id="txtLastname"
                                            label="Last Name"
                                            {...register("lastName")}
                                            errorMessage={
                                                errors.lastName
                                                    ?.message
                                            }
                                        />
                                    </div>
                                    <div className="field">
                                        <TLAdminInput
                                            enableModes={false}
                                            id="txtEmail"
                                            label="E-mail Address"
                                            type="email"
                                            {...register("email")}
                                            errorMessage={
                                                errors.email?.message
                                            }
                                        />
                                    </div>

                                    <div className="password-requirements">
                                        <p>
                                            <strong>
                                                Password Requirements
                                            </strong>
                                        </p>
                                        <p>
                                            At least 8 characters in
                                            length
                                        </p>
                                        <p>
                                            Contain at least 3 of the
                                            following 4 types of
                                            characters:
                                        </p>
                                        <ul>
                                            <li>
                                                Lower case letters
                                                (a-z)
                                            </li>
                                            <li>
                                                Upper case letters
                                                (A-Z)
                                            </li>
                                            <li>
                                                Numbers (i.e. 0-9)
                                            </li>
                                            <li>
                                                Special characters
                                                (e.g. @#$%^&*)
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="field">
                                        <TLAdminInput
                                            enableModes={false}
                                            id="txtPassword"
                                            label="Password"
                                            type="password"
                                            {...register("password")}
                                            errorMessage={
                                                errors.password
                                                    ?.message
                                            }
                                        />
                                    </div>
                                    <div className="field">
                                        <TLAdminInput
                                            enableModes={false}
                                            id="txtReEnterPassword"
                                            label="Re-enter Password"
                                            type="password"
                                            {...register(
                                                "reEnterPassword",
                                            )}
                                            errorMessage={
                                                errors.reEnterPassword
                                                    ?.message
                                            }
                                        />
                                    </div>
                                </fieldset>
                                <div className="admin-form-buttons">
                                    <button type="submit">
                                        <span className="icon">
                                            <Pen20Regular />
                                        </span>
                                        Add User
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            handleDiscard()
                                        }
                                    >
                                        <span className="icon">
                                            <DeleteDismiss20Regular />
                                        </span>
                                        Discard Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default styled(AddNewUserPage)`
    form {
        width: 100%;
    }

    .field {
        margin-bottom: 2rem;
    }

    .field label {
        font-size: 1.125rem;
    }

    .link-field {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    .link-field label {
        width: 180px;
        padding-right: 1rem;
    }

    .link-field p {
        font-weight: bold;
    }

    .link-field a:link,
    .link-field a:visited {
        padding: 0.25rem;
        border-radius: 8px;
        margin-left: auto;
    }

    .link-field a:hover {
        color: #00bbcc;
    }

    .admin-form-buttons {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
    }

    .admin-form-buttons button {
        display: flex;
        justify-content: center;
        width: 264px;
        border-radius: 8px;
        padding: 1rem;
        background-color: #ffffff;
    }

    .password-requirements {
        margin-bottom: 2rem;
    }

    .password-requirements p {
        margin-bottom: 1rem;
    }

    .password-requirements ul {
        margin-top: 1rem;
    }
`;
