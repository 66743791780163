import React from "react";
import styled from "styled-components";

// Types
import { AppMode } from "../../@types/Mode";
import { Permission } from "../../@types/Auth0/Roles";
import { TwinEntity } from "@repo/backend-types";

// Data
import { preferencesLookup } from "../../common/data/preferencesLookup";

// Context
import { useNavigate } from "react-router-dom";
import { useDataContext } from "../../common/contexts/DataContext";
import {
    useFilterContext,
    useModeContext,
} from "../../common/contexts/FilterAndModeContexts";
import { useSettingsContext } from "../../common/contexts/SettingsContext";
import { useTwinContext } from "../../common/contexts/TwinContext";

// Components
import { Image } from "@fluentui/react-components";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import Permissioned from "../Permissioned/Permissioned";

interface Props {
    className?: string;
    availableTwins: TwinEntity[] | undefined;
    organisationName?: string | null;
    onTwinHover: (previewImage: string | undefined) => void;
}

export const TwinSelector: React.FC<Props> = ({
    className,
    availableTwins,
    organisationName,
    onTwinHover,
}) => {
    const navigate = useNavigate();
    const { setData, setLiveData } = useDataContext();
    const { clearFilter } = useFilterContext();
    const { setAppModeWithFilterReset } = useModeContext();
    const { setMetrics, setSettings } = useSettingsContext();
    const { setTwin } = useTwinContext();

    const clearContext = () => {
        clearFilter();
        setData([]);
        setLiveData([]);
        setMetrics([]);
        setTwin(null);
    };

    const setSitePref = (
        organisationName: string,
        bID: string | null,
    ): boolean => {
        let settings = null;
        if (bID) {
            settings = preferencesLookup[organisationName][bID];
        }
        clearContext();
        setSettings(settings);
        return true;
    };

    return (
        <div className={className}>
            {organisationName &&
            availableTwins &&
            availableTwins.length > 0 ? (
                <div className="available-twins-container">
                    <p>Select the twin you want to visualise.</p>
                    <CustomScrollbar fadeOutEnabled={true}>
                        <div className="available-twins">
                            <ul>
                                {availableTwins.map((twin, i) => {
                                    return (
                                        <li
                                            key={i}
                                            onMouseEnter={() =>
                                                onTwinHover(
                                                    `./twin-preview/twin.bID`,
                                                )
                                            }
                                            onMouseLeave={() =>
                                                onTwinHover(undefined)
                                            }
                                        >
                                            <div
                                                className={`option digital-twin-${i}`}
                                                onClick={() => {
                                                    if (
                                                        setSitePref(
                                                            organisationName,
                                                            twin.bID,
                                                        )
                                                    ) {
                                                        setAppModeWithFilterReset(
                                                            AppMode.LIVE,
                                                        );
                                                        navigate(
                                                            "/twin",
                                                            {
                                                                state: {
                                                                    twinId: twin.bID,
                                                                },
                                                            },
                                                        );
                                                    }
                                                }}
                                            >
                                                <div className="option-icon">
                                                    <Image
                                                        width={50}
                                                        height={36}
                                                        src="/twinlabs-atom-dark-logo.svg"
                                                        alt="Twinlabs.ai"
                                                    />
                                                </div>
                                                <div className="flex-1 ml-2">
                                                    <p className="option-title">
                                                        {twin.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                                <Permissioned
                                    necessaryPermissions={[
                                        Permission.TWIN_EDITING,
                                    ]}
                                >
                                    <li>
                                        <div
                                            className={`option digital-twin-${10000}`}
                                            onClick={() => {
                                                if (
                                                    setSitePref(
                                                        organisationName,
                                                        null,
                                                    )
                                                ) {
                                                    navigate(
                                                        "/twin",
                                                        {
                                                            state: {
                                                                twinId: null,
                                                            },
                                                        },
                                                    );
                                                }
                                            }}
                                        >
                                            <div className="option-icon">
                                                <Image
                                                    width={50}
                                                    height={36}
                                                    src="/twinlabs-atom-dark-logo.svg"
                                                    alt="Twinlabs.ai"
                                                />
                                            </div>
                                            <div className="flex-1 ml-2">
                                                <p className="option-title">
                                                    Create New Twin
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </Permissioned>
                            </ul>
                        </div>
                    </CustomScrollbar>
                </div>
            ) : (
                <p>Loading</p>
            )}
        </div>
    );
};

export default styled(TwinSelector)`
    .available-twins-container {
        height: 200px;
    }

    .available-twins-container p {
        padding-bottom: 1rem;
    }

    .available-twins {
        padding-right: 2rem;
    }
`;
