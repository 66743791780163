import React, { useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

// Data
import Config from "../../common/Config";

// Utils
import createGraphQLApolloClient from "../../common/utils/createGraphQLApolloClient";
import { resolveEndpoints } from "@repo/backend-utils";

// Context
import { DataContextProvider } from "../../common/contexts/DataContext";
import { EventContextProvider } from "../../common/contexts/EventContext";
import { DialogContextProvider } from "../../common/contexts/DialogContext";
import { useUserContext } from "../../common/contexts/UserContext";

// Components
import TLSpinnerPage from "../TLSpinnerPage/TLSpinnerPage";

interface Props {
    component: React.ComponentType<any>;
    componentProps?: any;
    allowedRoles?: string[];
}

const ProtectedRoute: React.FC<Props> = ({
    component: Component,
    allowedRoles,
    componentProps,
}) => {
    const {
        isAuthenticated,
        isLoading,
        user,
        getAccessTokenSilently,
    } = useAuth0();

    const [accessToken, setAccessToken] = useState<string | null>(
        null,
    );
    const { authMetadata } = useUserContext();

    const navigate = useNavigate();

    useEffect(() => {
        const getAccessToken = async () => {
            setAccessToken(await getAccessTokenSilently());
        };
        getAccessToken();
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        const fetchData = async () => {
            // Redirect the user to the login page if they are not authenticated
            if (!isAuthenticated) {
                navigate("/", { replace: true });
            }

            if (
                isAuthenticated &&
                user &&
                accessToken &&
                authMetadata
            ) {
                // Check to see if the protected route is only for specific roles
                if (allowedRoles) {
                    if (
                        !authMetadata.roles ||
                        !allowedRoles.some((role: string) =>
                            authMetadata?.roles?.includes(role),
                        )
                    ) {
                        navigate("/permission-denied", {
                            replace: true,
                        });
                    }
                }
            }
        };
        fetchData();
    }, [
        accessToken,
        authMetadata,
        isAuthenticated,
        user,
        allowedRoles,
        getAccessTokenSilently,
        navigate,
    ]);

    if (isLoading || !authMetadata) {
        return <TLSpinnerPage loadingMessages={[]} />;
    }

    if (authMetadata.dgraphUrl) {
        const endpoints = resolveEndpoints(
            authMetadata.dgraphUrl,
            Config,
        );
        const apolloClient = createGraphQLApolloClient(
            endpoints.httpUrl,
            endpoints.wsUrl,
            accessToken ? accessToken : "",
        );

        return (
            <ApolloProvider client={apolloClient}>
                <DataContextProvider>
                    <EventContextProvider>
                        <DialogContextProvider>
                            <Component {...componentProps} />
                        </DialogContextProvider>
                    </EventContextProvider>
                </DataContextProvider>
            </ApolloProvider>
        );
    }
};

export default ProtectedRoute;
