// Consts
import { Roles } from "../../@types/Auth0/Roles";
import { customerAdminTopMenu } from "../const/customerAdminTopMenu";
import { partnerUserTopMenu } from "../const/partnerUserTopMenu";
import { tlAdminTopMenu } from "../const/tlAdminTopMenu";
import { allUsersTopMenu } from "../const/allUsersTopMenu";

export const getProtectedTopMenu = (role: string) => {
    let topMenu = [...allUsersTopMenu];
    switch (role) {
        case Roles.CUSTOMER_ADMIN:
            topMenu = [...topMenu, ...customerAdminTopMenu];
            break;
        case Roles.PARTNER_USER:
            topMenu = [...topMenu, ...partnerUserTopMenu];
            break;
        case Roles.TL_ADMIN:
            topMenu = [...topMenu, ...tlAdminTopMenu];
            break;
    }
    return topMenu;
};
