/*
 * Event Truck - Structure (Truck) Labelset - Brief (Live)
 * Ref: https://www.notion.so/Labels-on-the-Front-End-31066cf1c768489d8c08a8c7bb0c8ad5
 *
 */

// Types
import { Label } from "../../../../../../@types/Settings/Label";

export const LabelSetEventTruckStructureLiveBrief: Label[] = []; // No labelset required
