import React from "react";
import styled from "styled-components";
interface Props {
    content: JSX.Element;
}

const LegalPage: React.FC<Props> = ({ content }) => {
    return (
        <div className="legal-page">
            <main>{content}</main>
        </div>
    );
};

export default styled(LegalPage)`
    .btn-back {
        font-size: 2rem;
    }

    h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 2rem;
    }
`;
