/*
 * checkEntityDataIntegrity
 * We perform a series of tests to ensure each Entity within the Model has
 * all the data required to run the app successfully
 *
 */
import { Entity } from "../../@types/Entity";
import { LogEntry } from "../../@types/LogEntry";
import { LogLevel } from "../../@types/LogLevel";
import { LogEvent } from "./logEvent";
import { TwinEntityType } from "../../@types/TwinEntityType";
import { stringToTwinEntityType } from "./stringToTwinEntityType";

export const checkEntityDataIntegrity = (
    organisation: string,
    entity: Entity,
    apolloExplorerURI: string,
    rootNode: boolean = false,
): LogEntry[] => {
    const log: LogEntry[] = [];
    const nonFunctional = isNonFunctional(entity);

    let msgMissing = `entity is missing`;
    let apolloExplorerURL = "";
    if (apolloExplorerURI) {
        apolloExplorerURL = `https://studio.apollographql.com/graph/${apolloExplorerURI}/variant/current/schema/reference/objects/`;
    }

    if (!entity.name) {
        log.push(
            LogEvent(
                LogLevel.FATAL,
                `${msgMissing} name`,
                undefined,
                "EDI-F01",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#bID`
                    : undefined,
            ),
        );
    } else {
        msgMissing = `${entity.name} ${msgMissing}`;
    }

    const fatalResults = fatalTests(
        organisation,
        entity,
        msgMissing,
        apolloExplorerURL,
        rootNode,
    );
    const criticalResults = criticalTests(
        entity,
        msgMissing,
        apolloExplorerURL,
        rootNode,
        nonFunctional,
    );
    const errorResults = errorTests(
        entity,
        msgMissing,
        apolloExplorerURL,
    );
    const warningResults = warningTests(
        entity,
        msgMissing,
        apolloExplorerURL,
    );
    const infoResults = infoTests(
        entity,
        msgMissing,
        apolloExplorerURL,
    );

    return log.concat(
        fatalResults,
        criticalResults,
        errorResults,
        warningResults,
        infoResults,
    );
};

const fatalTests = (
    organisation: string,
    entity: Entity,
    msgMissing: string,
    apolloExplorerURL: string,
    rootNode: boolean,
): LogEntry[] => {
    const log: LogEntry[] = [];

    if (!entity.id) {
        log.push(
            LogEvent(
                LogLevel.FATAL,
                `${msgMissing} id`,
                undefined,
                "EDI-F02",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#id`
                    : undefined,
            ),
        );
    }

    if (!entity.bID) {
        log.push(
            LogEvent(
                LogLevel.FATAL,
                `${msgMissing} bID`,
                undefined,
                "EDI-F03",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#bID`
                    : undefined,
            ),
        );
    }

    if (rootNode && !entity.capacity && organisation !== "Howler") {
        log.push(
            LogEvent(
                LogLevel.FATAL,
                `${msgMissing} capacity`,
                undefined,
                "EDI-F04",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#capacity`
                    : undefined,
            ),
        );
    }

    return log;
};

const criticalTests = (
    entity: Entity,
    msgMissing: string,
    apolloExplorerURL: string,
    rootNode: boolean,
    nonFunctional: boolean,
) => {
    const log: LogEntry[] = [];

    // Check Entity has Capacity data unless it's an non-functional entity (e.g. NFAREA or WALL)
    // If the type.name does not exist then just check the capacity value
    if (
        !rootNode &&
        entity.type &&
        entity.type.name &&
        nonFunctional === true &&
        !entity.capacity &&
        !entity.capacity
    ) {
        log.push(
            LogEvent(
                LogLevel.CRITICAL,
                `${msgMissing} capacity ${rootNode ? `ROOT` : ``}`,
                undefined,
                "EDI-C01",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#capacity`
                    : undefined,
            ),
        );
    }

    if (!entity.coordinateSystem) {
        log.push(
            LogEvent(
                LogLevel.CRITICAL,
                `${msgMissing} coordinateSystem`,
                undefined,
                "EDI-C02",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#coordinateSystem`
                    : undefined,
            ),
        );
    }

    if (!entity.rotationX && entity.rotationX !== 0) {
        log.push(
            LogEvent(
                LogLevel.CRITICAL,
                `${msgMissing} rotationX`,
                undefined,
                "EDI-C03",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#rotationX`
                    : undefined,
            ),
        );
    }

    if (!entity.rotationY && entity.rotationY !== 0) {
        log.push(
            LogEvent(
                LogLevel.CRITICAL,
                `${msgMissing} rotationY`,
                undefined,
                "EDI-C04",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#rotationY`
                    : undefined,
            ),
        );
    }

    if (!entity.rotationZ && entity.rotationZ !== 0) {
        log.push(
            LogEvent(
                LogLevel.CRITICAL,
                `${msgMissing} rotationZ`,
                undefined,
                "EDI-C05",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#rotationZ`
                    : undefined,
            ),
        );
    }

    if (!entity.altitude && entity.altitude !== 0) {
        log.push(
            LogEvent(
                LogLevel.CRITICAL,
                `${msgMissing} altitude`,
                undefined,
                "EDI-C06",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#altitude`
                    : undefined,
            ),
        );
    }

    if (!entity.geoType) {
        log.push(
            LogEvent(
                LogLevel.CRITICAL,
                `${msgMissing} geoType`,
                undefined,
                "EDI-C07",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#geoType`
                    : undefined,
            ),
        );
    }

    if (entity.geoType && !entity.geoType.name) {
        log.push(
            LogEvent(
                LogLevel.CRITICAL,
                `${msgMissing} geoType.name`,
                undefined,
                "EDI-C08",
                apolloExplorerURL
                    ? `${apolloExplorerURL}GeoType?query=isEntity%3Atrue#name`
                    : undefined,
            ),
        );
    }

    if (!entity.type) {
        log.push(
            LogEvent(
                LogLevel.CRITICAL,
                `${msgMissing} type`,
                undefined,
                "EDI-C09",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#type`
                    : undefined,
            ),
        );
    }

    if (entity.type && !entity.type.name) {
        log.push(
            LogEvent(
                LogLevel.CRITICAL,
                `${msgMissing} type.name`,
                undefined,
                "EDI-C10",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntityType?query=isEntity%3Atrue#name`
                    : undefined,
            ),
        );
    }

    // rootNode Checks
    if (rootNode) {
        // Check mapOrigin exists
        if (!entity.mapOrigin) {
            log.push(
                LogEvent(
                    LogLevel.CRITICAL,
                    `Root node ${msgMissing} mapOrigin`,
                    undefined,
                    "EDI-C12",
                    apolloExplorerURL
                        ? `${apolloExplorerURL}TwinEntityType?query=isEntity%3Atrue#mapOrigin`
                        : undefined,
                ),
            );
        }

        // Check mapOrigin.latitude exists
        if (entity.mapOrigin && !entity.mapOrigin.latitude) {
            log.push(
                LogEvent(
                    LogLevel.CRITICAL,
                    `Root node ${msgMissing} mapOrigin.latitude`,
                    undefined,
                    "EDI-C13",
                    apolloExplorerURL
                        ? `${apolloExplorerURL}Point?query=isEntity%3Atrue#latitude`
                        : undefined,
                ),
            );
        }

        // Check mapOrigin.latitude exists
        if (entity.mapOrigin && !entity.mapOrigin.longitude) {
            log.push(
                LogEvent(
                    LogLevel.CRITICAL,
                    `Root node ${msgMissing} mapOrigin.longitude`,
                    undefined,
                    "EDI-C14",
                    apolloExplorerURL
                        ? `${apolloExplorerURL}Point?query=isEntity%3Atrue#longitude`
                        : undefined,
                ),
            );
        }
    }

    return log;
};

const errorTests = (
    entity: Entity,
    msgMissing: string,
    apolloExplorerURL: string,
): LogEntry[] => {
    const log: LogEntry[] = [];

    if (
        entity.__typename &&
        stringToTwinEntityType(entity.__typename) ===
            TwinEntityType.ASSET &&
        !entity.modelFile
    ) {
        log.push(
            LogEvent(
                LogLevel.ERROR,
                `${msgMissing} modelFile`,
                undefined,
                "EDI-E01",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#modelFile`
                    : undefined,
            ),
        );
    }
    return log;
};

const warningTests = (
    entity: Entity,
    msgMissing: string,
    apolloExplorerURL: string,
): LogEntry[] => {
    const log: LogEntry[] = [];

    if (!entity.shortName) {
        log.push(
            LogEvent(
                LogLevel.WARNING,
                `${msgMissing} shortName`,
                undefined,
                "EDI-E02",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#shortName`
                    : undefined,
            ),
        );
    }

    if (entity.nonFunctional === null) {
        log.push(
            LogEvent(
                LogLevel.WARNING,
                `${msgMissing} nonFunctional`,
                undefined,
                "EDI-E03",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#nonFunctional`
                    : undefined,
            ),
        );
    }

    return log;
};

const infoTests = (
    entity: Entity,
    msgMissing: string,
    apolloExplorerURL: string,
): LogEntry[] => {
    const log: LogEntry[] = [];

    if (!entity.description) {
        log.push(
            LogEvent(
                LogLevel.INFO,
                `${msgMissing} description`,
                undefined,
                "EDI-I01",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#description`
                    : undefined,
            ),
        );
    }

    if (!entity.tags || (entity.tags && entity.tags.length === 0)) {
        log.push(
            LogEvent(
                LogLevel.INFO,
                `${msgMissing} tags`,
                undefined,
                "EDI-I02",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#tags`
                    : undefined,
            ),
        );
    }

    if (!entity.tracked === null) {
        log.push(
            LogEvent(
                LogLevel.INFO,
                `${msgMissing} tracked`,
                undefined,
                "EDI-I03",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#tracked`
                    : undefined,
            ),
        );
    }

    if (!entity.maker) {
        log.push(
            LogEvent(
                LogLevel.INFO,
                `${msgMissing} maker`,
                undefined,
                "EDI-I04",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#maker`
                    : undefined,
            ),
        );
    }

    if (!entity.makerID) {
        log.push(
            LogEvent(
                LogLevel.INFO,
                `${msgMissing} makerID`,
                undefined,
                "EDI-I05",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#makerID`
                    : undefined,
            ),
        );
    }

    if (!entity.modelName) {
        log.push(
            LogEvent(
                LogLevel.INFO,
                `${msgMissing} modelName`,
                undefined,
                "EDI-I06",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#modelName`
                    : undefined,
            ),
        );
    }

    if (!entity.aggregateMetrics) {
        log.push(
            LogEvent(
                LogLevel.INFO,
                `${msgMissing} aggregateMetrics`,
                undefined,
                "EDI-I07",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#aggregateMetrics`
                    : undefined,
            ),
        );
    }

    if (entity.geoType && !entity.geoType.displayName) {
        log.push(
            LogEvent(
                LogLevel.INFO,
                `${msgMissing} geoType.displayName`,
                undefined,
                "EDI-I08",
                apolloExplorerURL
                    ? `${apolloExplorerURL}geoType?query=isEntity%3Atrue#displayName`
                    : undefined,
            ),
        );
    }

    if (entity.geoType && !entity.geoType.description) {
        log.push(
            LogEvent(
                LogLevel.INFO,
                `${msgMissing} geoType.description`,
                undefined,
                "EDI-I09",
                apolloExplorerURL
                    ? `${apolloExplorerURL}geoType?query=isEntity%3Atrue#description`
                    : undefined,
            ),
        );
    }

    if (entity.location === null) {
        log.push(
            LogEvent(
                LogLevel.INFO,
                `${msgMissing} location`,
                undefined,
                "EDI-I10",
                apolloExplorerURL
                    ? `${apolloExplorerURL}TwinEntity?query=isEntity%3Atrue#location`
                    : undefined,
            ),
        );
    }

    return log;
};

const isNonFunctional = (entity: Entity): boolean => {
    let nonFunctional = false;

    if (
        entity.type &&
        entity.type.name &&
        stringToTwinEntityType(entity.type.name) !==
            TwinEntityType.NFAREA &&
        stringToTwinEntityType(entity.type.name) !==
            TwinEntityType.WALL &&
        !entity.nonFunctional
    ) {
        nonFunctional = true;
    }

    return nonFunctional;
};
