import { useEffect, useState } from "react";
import styled from "styled-components";

// Context
import { useFilterContext } from "../../common/contexts/FilterAndModeContexts";

// Types
import { SelectedCellValue } from "../../@types/SelectedCellValue";

// Utils
import { getIndicatorColor } from "../../common/utils/getIndicatorColor";
import { roundToOneDecimalPlace } from "../../common/utils/roundToOneDecimalPlace";

// Components
import Indicator from "./Indicator";
interface Props {
    className?: string;
    selectedCellValues: SelectedCellValue[];
}

const CalendarSelectionOverlay = ({
    className,
    selectedCellValues,
}: Props) => {
    const { heroMetric } = useFilterContext();
    const [indicatorColor, setIndicatorColor] = useState<
        string | null
    >(null);
    const [indicatorValue, setIndicatorValue] = useState<
        string | null
    >(null);

    useEffect(() => {
        let value = 0;
        let totalValue = 0;
        let indicatorValue = 0;
        let totalIndicatorValue = 0;
        let numberOfCells = 0;

        selectedCellValues.forEach(scv => {
            if (scv.cellValue) {
                value = totalValue + scv.cellValue;
                indicatorValue =
                    totalIndicatorValue + (scv.pc ? scv.pc : 0);
            }
            numberOfCells = numberOfCells + 1;
        });

        const displayValue = value / numberOfCells;
        const displayIndicatorValue = indicatorValue / numberOfCells;
        const colors = getIndicatorColor(
            displayIndicatorValue,
            heroMetric?.indicatorConfig,
            true,
        );

        setIndicatorValue(
            `${roundToOneDecimalPlace(displayValue).toFixed(1)}`,
        );
        setIndicatorColor(colors.bgColor);
    }, [selectedCellValues, heroMetric?.indicatorConfig]);

    return (
        <>
            {indicatorValue && indicatorColor ? (
                <div className={className}>
                    <div className="selection-overlay">
                        <div className="selection-overlay-value">
                            <Indicator
                                backgroundColor={
                                    parseFloat(indicatorValue)
                                        ? indicatorColor
                                        : "#FFFFFF"
                                }
                                value={
                                    parseFloat(indicatorValue)
                                        ? indicatorValue
                                        : "-"
                                }
                            />
                        </div>
                        <div className="selection-overlay-arrow"></div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default styled(CalendarSelectionOverlay)`
    .selection-overlay {
        background: #333333;
        color: #ffffff;
        width: 62px;
        height: 28px;
        position: relative;
        z-index: 99;
        top: -18px;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        border: var(--None, 0.706px) solid
            var(
                --Neutral-Stroke-Transparent-Rest,
                rgba(255, 255, 255, 0)
            );
        box-shadow:
            0px 2.824px 5.647px 0px rgba(0, 0, 0, 0.14),
            0px 0px 1.412px 0px rgba(0, 0, 0, 0.12);
    }

    .selection-overlay-value {
        line-height: 100%;
        margin-top: -2px;
    }

    .selection-overlay-value .value {
        font-size: 9px;
        line-height: 100%;
        position: relative;
        top: -1px;
    }

    .selection-overlay-arrow {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #333333;
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
