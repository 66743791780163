/*
 * stringToTimeUnit
 * Used for the Calendar Week/Month View Switcher
 *
 */
import { TimeUnit } from "../../@types/TimeUnit";

export const stringToTimeUnit = (value: string): TimeUnit => {
    switch (value.toUpperCase()) {
        case "0":
            return TimeUnit.WEEK;
        case "1":
            return TimeUnit.MONTH;
        default:
            return TimeUnit.WEEK;
    }
};
