import { useCallback, useMemo, useState } from "react";
import { Box3, BufferGeometry, Vector3 } from "three";

// Types
import { DataMode } from "../../../@types/DataMode";
import { DataValue } from "../../../@types/Data/DataValue";
import { Entity } from "./../../../@types/Entity";

// Context
import { useDataContext } from "../../../common/contexts/DataContext";
import {
    useFilterContext,
    useModeContext,
} from "../../../common/contexts/FilterAndModeContexts";
import { useSettingsContext } from "../../../common/contexts/SettingsContext";

// Utils
import { getDataValue } from "../../../common/utils/func-metrics/getDataValue";
import { getIndicator } from "../../../common/utils/getIndicator";
import { NotTracked } from "./NotTracked";
import { Tracked } from "./Tracked";
import { Hitbox } from "../Hitbox";
import { TwinEntity } from "@repo/backend-types";
import { SelectionOutlines } from "../outlines/SelectionOutline";
import { Label3D } from "../Label3D";
import { TwinEntityType } from "../../../@types/TwinEntityType";
import { LabelMode } from "../../../@types/LabelMode";
import {
    determineVisibility,
    getMenuHovered,
    getSelectionCriteria,
} from "../utils/filterUtils";
import { stringToTwinEntityType } from "../../../common/utils/stringToTwinEntityType";
import { useEventContext } from "../../../common/contexts/EventContext";
import { AppMode } from "../../../@types/Mode";

interface Props {
    entity: Entity;
    geometry: BufferGeometry;
    mapDiv: HTMLDivElement;
    lineage: TwinEntity[];
    labelPosition?: Vector3;
    entityType: TwinEntityType;
    labelBoundingBox: Box3;
    topLevelType: TwinEntityType;
}

const Capacity = ({
    entity,
    geometry,
    mapDiv,
    lineage,
    labelPosition,
    entityType,
    labelBoundingBox,
    topLevelType,
}: Props) => {
    // Access data-related functions and settings from Data Context.
    const { heroMetric, filter } = useFilterContext();
    const { appMode } = useModeContext();
    const { data } = useDataContext();
    const { settings } = useSettingsContext();
    const { currentHoveredEntity, selectedEntityIds } =
        useEventContext();

    const [pressed, setPressed] = useState(false);
    const [hover, setHover] = useState(false);

    const id = appMode === AppMode.LIVE ? entity.id : entity.bID;

    let metricData: DataValue[] = [];
    let indicatorData: DataValue[] = [];

    if (data.processed && heroMetric) {
        const indicatorMetric =
            heroMetric.metric === "countEntity"
                ? "usage"
                : heroMetric.metric;
        metricData = data.processed
            ? data.processed[heroMetric.metric]
            : [];
        indicatorData = data.processed
            ? data.processed[indicatorMetric]
            : [];
    }

    const metricValue = getDataValue(
        appMode === AppMode.LIVE
            ? DataMode.LIVE
            : DataMode.TIME_SERIES,
        metricData,
        id,
    );
    const indicatorValue = getDataValue(
        appMode === AppMode.LIVE
            ? DataMode.LIVE
            : DataMode.TIME_SERIES,
        indicatorData,
        id,
    );

    const parent = lineage[lineage.length - 1];
    const parentType = stringToTwinEntityType(parent.type.name);
    const isTopLevelArea = parentType === topLevelType; // used special case for displaying 'top-level' boxes on load

    const {
        filtersExist,
        areaIsSelected,
        parentIsSelected,
        siblingIsSelected,
    } = getSelectionCriteria(entity, parent, filter);

    const fullLineageIncludingThisEntity = [...lineage, entity];
    const menuHovered = getMenuHovered(
        fullLineageIncludingThisEntity,
        currentHoveredEntity,
    );

    const behavioursVisible = determineVisibility(entity, {
        filtersExist,
        areaIsSelected,
        parentIsSelected,
        siblingIsSelected,
        isTopLevelArea,
    });

    const colorMode =
        filtersExist && !areaIsSelected && siblingIsSelected
            ? "greys"
            : "colors";

    const color = useMemo(
        () =>
            metricValue &&
            indicatorValue &&
            getIndicator(
                metricValue,
                indicatorValue,
                heroMetric?.indicatorConfig,
                colorMode === "colors" ? true : false,
            ).colors.bgColor,
        [metricValue, indicatorValue, settings, colorMode],
    );

    const showSolidOutline = areaIsSelected || pressed;
    const showDashedOutline =
        (hover || menuHovered) && !areaIsSelected;
    const showOutline = !(showSolidOutline || showDashedOutline);

    const Label = useCallback(() => {
        return (
            <Label3D
                entity={entity}
                entityType={entityType}
                labelMode={
                    areaIsSelected ? LabelMode.FULL : LabelMode.BRIEF
                }
                boundingBox={labelBoundingBox}
                visible={hover || menuHovered || areaIsSelected}
                isSelected={selectedEntityIds.has(entity.id)}
            />
        );
    }, [
        entity,
        entityType,
        areaIsSelected,
        labelBoundingBox,
        hover,
        menuHovered,
        selectedEntityIds,
    ]);

    return (
        <>
            <SelectionOutlines
                geometry={geometry}
                showDashedOutline={showDashedOutline}
                showSolidOutline={showSolidOutline}
            />
            <Hitbox
                entity={entity}
                geometry={geometry}
                setHover={setHover}
                setPressed={setPressed}
                mapDiv={mapDiv}
                lineage={lineage}
            />
            {entity.tracked ? (
                <Tracked
                    color={color}
                    geometry={geometry}
                    entity={entity}
                    visible={behavioursVisible}
                    showOutline={showOutline}
                />
            ) : (
                <NotTracked
                    geometry={geometry}
                    visible={behavioursVisible}
                    showOutline={showOutline}
                />
            )}
            {(hover || menuHovered || areaIsSelected) &&
                (labelPosition ? (
                    <object3D position={labelPosition}>
                        <Label />
                    </object3D>
                ) : (
                    <Label />
                ))}
        </>
    );
};

export { Capacity };
