/*
 * Calculates Usage Metric
 *
 */
import { roundToOneDecimalPlace } from "./roundToOneDecimalPlace";

export const calculateUsage = (count: number, capacity: number) => {
    const usage =
        count > 0 && capacity > 0 ? (count / capacity) * 100 : 0;
    return roundToOneDecimalPlace(usage);
};
