import React from "react";
import styled from "styled-components";

// Components
import { Link } from "react-router-dom";
import { Pen24Regular } from "@fluentui/react-icons";

interface Props {
    className?: string;
    data: any[];
}

const TwinsTable: React.FC<Props> = ({ className, data }) => {
    return (
        <div className={className}>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.length > 0 ? (
                        data.map((r, i) => {
                            return (
                                <tr key={i}>
                                    <td>{r.bID}</td>
                                    <td>{r.name}</td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <Link
                                            to="/account/twins/edit-twin"
                                            state={{ bID: r.bID }}
                                        >
                                            <Pen24Regular />
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td className="no-records" colSpan={7}>
                                No twin records available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default styled(TwinsTable)`
    table {
        width: 100%;
        background-color: #d9d9d9;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    tbody tr {
        background-color: #ffffff;
        border-bottom: 2px #f8f7f6 solid;
    }

    thead th {
        font-size: 1rem;
        padding: 1rem 1.5rem;
    }

    tbody td {
        font-size: 1rem;
        padding: 1rem 1.5rem;
    }

    tbody td a:link,
    tbody td a:visited {
        color: #424242;
    }

    tbody td a:hover,
    tbody td a:active,
    tbody td a:focus,
    tbody td button:hover,
    tbody td button:active,
    tbody td button:focus {
        color: #00bbcc;
    }

    tbody td.no-records {
        text-align: center;
    }
`;
