import React from "react";
import styled from "styled-components";

interface Props {
    className?: string;
    id: string;
    indicator?: JSX.Element;
    title: string;
    fontSize?: string;
    showId?: boolean;
}

const LabelHeader: React.FC<Props> = ({
    className,
    id,
    indicator,
    title,
    fontSize,
    showId,
}) => {
    return (
        <div className={className} data-id={id}>
            {title && (
                <div
                    className="label"
                    style={{ fontSize: fontSize ? fontSize : "12px" }}
                >
                    {title}
                    {showId ? <small>({id})</small> : null}
                </div>
            )}
            <span className="indicator">{indicator}</span>
        </div>
    );
};

export default styled(LabelHeader)`
    display: flex;
    width: 100%;
    align-items: center;

    .label {
        color: var(--Neutral-Foreground-1-Rest, #242424);
        font-style: normal;
        font-weight: 350;
        line-height: 100%;
    }

    .label small {
        margin-left: 5px;
    }

    .indicator {
        margin-left: auto;
    }
`;
