/*
 * InternalSite Component
 *
 * The InternalSite component uses the twin model dimensions coordinates points to render the external static walls of an internal site if those points exist".
 * This component is the parent container ( group ) of all internal 3D assets, it takes in a mapOrigin {lat lng} coordinate and orientation values
 * in order to adopt the correct world space map position and orientation within the Mapbox context,
 * any children therein are positioned relatively within this parent in local space coordinates relative to the stored twin site data.
 */

import { useMemo } from "react";
import {
    ExtrudeGeometry,
    Shape,
    ShapeGeometry,
    Color,
    MeshStandardMaterial,
    Vector2,
} from "three";
import { extrudeSettingsInternalSiteBoundaryWall } from "../../utils/extrusionConfig";
import { expandOutline } from "../../utils/outlineUtils";
import { PointList } from "@repo/backend-types";
import { coordLocalOutlineToVector2 } from "../../utils/coorUtils";
import { ASSET_COLOR, defaultColours } from "../../theme";
import { MeshTransmissionMaterial } from "@react-three/drei";

const floorMaterial = new MeshStandardMaterial({
    color: ASSET_COLOR,
    roughness: 0.65,
    metalness: 0.4,
    normalScale: new Vector2(0.11, 0.11),
});

const WallMaterial = () => {
    const material = useMemo(() => {
        return (
            <MeshTransmissionMaterial
                background={new Color(0xffffff)}
                transmissionSampler={false}
                backside={false}
                transparent
                opacity={0.9}
                samples={10}
                resolution={1024}
                transmission={0.9}
                roughness={0.123}
                thickness={0.2314}
                ior={1.35}
                chromaticAberration={0.09}
                anisotropy={0.04}
                distortion={0.0}
                distortionScale={0.1}
                temporalDistortion={0.35}
                clearcoat={0.05}
                attenuationDistance={0.5}
                attenuationColor={"#ffffff"}
                forceSinglePass
                color={defaultColours.staticGray}
            />
        );
    }, []);

    return material;
};

interface Props {
    boundingGeometry: PointList[];
}

/**
 * This component renders a wall and floor, from a boundary of points
 */

const WALL_THICKNESS = 0.55;
const WALL_GAP = 0.05;

export const WallsAndFloor = ({ boundingGeometry }: Props) => {
    const InternalWalls = useMemo(() => {
        const worldSpaceOutline = coordLocalOutlineToVector2(
            boundingGeometry[0].points,
        );
        const externalPoints = expandOutline(
            worldSpaceOutline,
            WALL_THICKNESS + WALL_GAP,
        );
        const internalPoints = expandOutline(
            worldSpaceOutline,
            WALL_GAP,
        );

        const externalShape = new Shape(externalPoints);
        const internalShape = new Shape(internalPoints);

        externalShape.holes.push(internalShape);

        const walls = new ExtrudeGeometry(
            externalShape,
            extrudeSettingsInternalSiteBoundaryWall,
        );
        walls.rotateX(-Math.PI / 2);

        const floorShape = new Shape(internalPoints);

        const floor = new ShapeGeometry(floorShape, 1);
        floor.rotateX(-Math.PI / 2);

        return { walls: walls, floor: floor };
    }, [boundingGeometry]);

    return (
        <group>
            <mesh
                geometry={InternalWalls.walls}
                castShadow
                receiveShadow
            >
                <WallMaterial />
            </mesh>
            <mesh
                geometry={InternalWalls.floor}
                material={floorMaterial}
                receiveShadow
            ></mesh>
        </group>
    );
};
