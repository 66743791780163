import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import ReactECharts from "echarts-for-react";
import { DateTime } from "luxon";

// Types
import { CalendarChartData } from "../../@types/CalendarChartData";
import { DataScope } from "../../@types/Settings/DataScope";
import { SelectedCellValue } from "../../@types/SelectedCellValue";
import { WeekDay } from "../../@types/WeekDay";

//  Context
import { useFilterContext } from "../../common/contexts/FilterAndModeContexts";
import { useSettingsContext } from "../../common/contexts/SettingsContext";

// Utils
import { calculateTotalHours } from "../../common/utils/totalHours";
import { getIndicatorColor } from "../../common/utils/getIndicatorColor";
import { roundToOneDecimalPlace } from "../../common/utils/roundToOneDecimalPlace";

interface Props {
    className?: string;
    theWeek: WeekDay[];
    selectedCellValues: SelectedCellValue[];
    entityCapacity: number;
}

const CalendarWeekChart: React.FC<Props> = ({
    className,
    theWeek,
    selectedCellValues,
    entityCapacity,
}) => {
    const { heroMetric } = useFilterContext();
    const { settings } = useSettingsContext();
    const [chartData, setChartData] = useState<CalendarChartData[]>(
        [],
    );
    const weekDays = useMemo(
        () => ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        [],
    );
    const [dataScope] = useState<DataScope | undefined>(
        settings?.calendar.weekView.dataScope,
    );

    const mapTheWeekToChartData = useCallback(
        (theWeek: WeekDay[], entityCapacity: number) => {
            let totalHours = 0;

            if (dataScope === DataScope.TOTAL_HOURS) {
                let hourRange = settings?.calendar.weekView.hourRange; // e.g. 06:00-22:00
                totalHours = calculateTotalHours(
                    hourRange?.startTime
                        ? hourRange.startTime
                        : "00:00",
                    hourRange?.finishTime
                        ? hourRange.finishTime
                        : "23:00",
                );
            }

            let chartData: CalendarChartData[] = [];

            theWeek.forEach(wd => {
                let usages: number[] = [];
                let value = 0;
                let sum = 0;

                if (heroMetric?.metric === "usage") {
                    wd.hours.forEach(hour => {
                        usages.push(
                            entityCapacity
                                ? ((hour.count ? hour.count : 0) /
                                      entityCapacity) *
                                      100
                                : 0,
                        ); // Calculating usage for each
                    });
                    sum = usages.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue,
                        0,
                    );
                } else {
                    wd.hours.forEach(hour => {
                        sum = sum + (hour.count ? hour.count : 0);
                    });
                }

                value =
                    sum /
                    (totalHours && totalHours > 0
                        ? totalHours
                        : wd.activeHours);

                chartData.push({
                    weekDay: wd.weekDay,
                    weekDayCount: 1,
                    usages: usages,
                    value: value,
                    label: `${value}`,
                });
            });

            return chartData;
        },
        [settings?.calendar?.weekView.hourRange],
    );

    const mapSelectedCellValuesToChartData = useCallback(
        (selectedCellValues: SelectedCellValue[]) => {
            const chartData: CalendarChartData[] = [];

            // First create empty chart data for each week day
            for (let i = 0; i <= 6; i++) {
                chartData[i] = {
                    weekDay: weekDays[i],
                    weekDayCount: 0,
                    usages: [],
                    value: 0,
                    label: "",
                };
            }

            // Remove any entries with null counts if only ACTIVE HOURS should be counted towards chart values
            if (dataScope === DataScope.ACTIVE_HOURS) {
                selectedCellValues = selectedCellValues.filter(
                    item => item.count !== null,
                );
            }

            selectedCellValues.forEach(scv => {
                // Get day of week (Sunday is 0)
                let weekDayIndex =
                    DateTime.fromISO(scv.dateTimeISO).weekday - 1;
                chartData[weekDayIndex].weekDayCount =
                    chartData[weekDayIndex].weekDayCount + 1;
                chartData[weekDayIndex].usages.push(
                    scv.cellValue ? scv.cellValue : 0,
                );
                let usageSum = chartData[weekDayIndex].usages.reduce(
                    (accumulator, currentValue) =>
                        accumulator + currentValue,
                    0,
                );
                let value = 0;
                value =
                    usageSum / chartData[weekDayIndex].weekDayCount;
                chartData[weekDayIndex].value = value;
                chartData[weekDayIndex].label = `${value}%`;
            });

            return chartData;
        },
        [weekDays],
    );

    useEffect(() => {
        let data: CalendarChartData[];

        if (selectedCellValues && selectedCellValues.length > 0) {
            data = mapSelectedCellValuesToChartData(
                selectedCellValues,
            );
        } else {
            data = mapTheWeekToChartData(theWeek, entityCapacity);
        }

        setChartData(data);
    }, [
        theWeek,
        selectedCellValues,
        mapSelectedCellValuesToChartData,
        mapTheWeekToChartData,
        entityCapacity,
    ]);

    const option = {
        grid: {
            show: false, // Hide grid lines
            left: "0", // Adjust the left margin
            right: "0", // Adjust the right margin
            top: "0", // Adjust the top margin
            bottom: "0", // Adjust the bottom margin
        },
        xAxis: {
            type: "category",
            data: chartData.map(item => item.label),
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
        series: [
            {
                data: chartData.map(item =>
                    item.value === 0
                        ? null
                        : roundToOneDecimalPlace(item.value).toFixed(
                              1,
                          ),
                ), // Set value to null if it's 0
                type: "bar",
                barWidth: "43px",
                barGap: 0,
                label: {
                    show: true,
                    position: "inside",
                    formatter: function (params: { value: null }) {
                        // Conditionally show label only if value is not null
                        return params.value !== null
                            ? params.value
                            : "";
                    },
                },
                itemStyle: {
                    color: function (params: { value: number }) {
                        const colors = getIndicatorColor(
                            params.value,
                            heroMetric?.indicatorConfig,
                            true,
                        );
                        return colors.bgColor;
                    },
                },
            },
        ],
    };

    return (
        <div className={className}>
            <div className="flex">
                <div className="flex items-end">
                    <p className="text-xs font-semibold text-center">
                        Total
                    </p>
                </div>
                <div style={{ marginLeft: "14px" }}>
                    <ReactECharts
                        className="border-b-2 border-black ml-auto"
                        style={{ height: "40px", width: "301px" }}
                        option={option}
                    />
                </div>
            </div>
        </div>
    );
};

export default CalendarWeekChart;
