import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

// Data
import { acceptTerms } from "../../../common/api/middleware/acceptLegal";

// Components
import { PrimaryButton } from "@repo/ui";
import { useLocation, useNavigate } from "react-router-dom";
import TLSpinnerPage from "../../../components/TLSpinnerPage/TLSpinnerPage";

interface Props {
    className?: string;
}

const LegalPromptPage: React.FC<Props> = ({ className }) => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } =
        useAuth0();
    const [accessToken, setAccessToken] = useState<string | null>(
        null,
    );
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const getAccessToken = async () => {
            setAccessToken(await getAccessTokenSilently());
        };
        getAccessToken();
    }, [isAuthenticated, getAccessTokenSilently]);

    const [declined, setDeclined] = useState<boolean>();
    const { currentLegalVersions, customerAdmin, redirectPath } =
        location.state || {};

    const acceptLegal = async () => {
        if (
            accessToken &&
            (await acceptTerms(
                accessToken,
                currentLegalVersions,
                customerAdmin,
            ))
        ) {
            navigate(redirectPath, { replace: true });
        } else {
            navigate("/something-went-wrong", { replace: true });
        }
    };

    if (isLoading || !accessToken) {
        return <TLSpinnerPage loadingMessages={[]} />;
    }

    return (
        <>
            {accessToken && (
                <div className={className}>
                    <div className="logo">
                        <img
                            src="/twinlabs-ai-stacked-logo.svg"
                            alt="Twinlabs.ai Logo"
                        />
                    </div>
                    <h1>Terms And Conditions</h1>
                    {!declined ? (
                        <>
                            {customerAdmin ? (
                                <p>
                                    To proceed, please accept our{" "}
                                    <a
                                        href="https://www.twinlabs.ai/twinlabs-ai-saas-standard-terms-and-conditions"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        SAAS Standard Terms
                                    </a>
                                    ,{" "}
                                    <a
                                        href="https://www.twinlabs.ai/data-processing"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Data Processing
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href="https://www.twinlabs.ai/acceptable-use"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Acceptable Use
                                    </a>
                                    .
                                </p>
                            ) : (
                                <p>
                                    To proceed, please accept our{" "}
                                    <a
                                        href="https://www.twinlabs.ai/acceptable-use"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Acceptable Use
                                    </a>
                                    .
                                </p>
                            )}
                            <p>
                                By clicking Accept you are confirming
                                you have read and accepted these terms
                                and conditions.
                            </p>
                            <PrimaryButton
                                onClick={() => {
                                    acceptLegal();
                                }}
                            >
                                Accept
                            </PrimaryButton>
                            <button
                                className="secondary-button"
                                onClick={() => {
                                    setDeclined(true);
                                }}
                            >
                                Decline
                            </button>
                        </>
                    ) : (
                        <>
                            <p>
                                Please contact{" "}
                                <a href="mailto:support@twinlabs.ai">
                                    support@twinlabs.ai
                                </a>{" "}
                                to discuss. We are happy to answer any
                                questions.
                            </p>
                            <PrimaryButton
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                Return To Login
                            </PrimaryButton>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default styled(LegalPromptPage)`
    .logo {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;
    }

    h1 {
        font-size: 1.45rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    p {
        color: #424242;
        font-size: 0.875rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    a:link,
    a:visited {
        color: #00bbcc;
        text-decoration: underline;
    }

    button {
        width: 100%;
        font-size: 0.875rem !important;
        height: 58px !important;
        margin-top: 1rem;
    }

    .secondary-button {
        border: 2px solid #d9d9d9;
        border-radius: 8px;
    }
`;
