import React, {
    forwardRef,
    useEffect,
    useRef,
    useState,
} from "react";
import styled from "styled-components";

// Types
import { FieldMode } from "../../../@types/FieldMode";

// Components
import { Label, MessageBar } from "@fluentui/react-components";
import { Pen20Filled, Pen20Regular } from "@fluentui/react-icons";

interface Props {
    id: string;
    className?: string;
    label?: string;
    type?: string;
    currentValue?: string;
    description?: string;
    errorMessage?: string;
    enableModes?: boolean;
    discard?: boolean;
    autoFocus?: boolean;
    placeholder?: string;
    resetForm?: () => void;
}

const TLAdminInput: React.FC<Props> = forwardRef<
    HTMLInputElement,
    Props
>(
    (
        {
            id,
            className,
            label,
            type = "text",
            currentValue,
            description,
            errorMessage,
            enableModes,
            discard,
            autoFocus,
            resetForm,
            placeholder,
            ...props
        },
        ref,
    ) => {
        const [fieldMode, setFieldMode] = useState<FieldMode>(
            FieldMode.READ,
        );
        const localRef = useRef<HTMLInputElement | null>(null);

        useEffect(() => {
            setFieldMode(FieldMode.READ);
        }, [discard]);

        useEffect(() => {
            if (fieldMode === FieldMode.EDIT && localRef.current) {
                localRef.current.focus();

                // Only set cursor position for supported types
                const supportedTypes = [
                    "text",
                    "search",
                    "tel",
                    "password",
                    "url",
                ];
                if (supportedTypes.includes(type)) {
                    const length = localRef.current.value.length;
                    localRef.current.setSelectionRange(
                        length,
                        length,
                    );
                }
            }
        }, [fieldMode, type]);

        return (
            <>
                <div className={className}>
                    <Label htmlFor={id}>{label}</Label>
                    {!enableModes || fieldMode === FieldMode.EDIT ? (
                        <>
                            <input
                                ref={node => {
                                    if (typeof ref === "function")
                                        ref(node);
                                    localRef.current = node;
                                }}
                                id={id}
                                type={type ? type : "text"}
                                placeholder={
                                    placeholder
                                        ? placeholder
                                        : "Type here"
                                }
                                defaultValue={currentValue}
                                autoFocus={
                                    enableModes &&
                                    fieldMode === FieldMode.EDIT
                                        ? true
                                        : autoFocus
                                          ? true
                                          : false
                                }
                                {...props}
                            />
                        </>
                    ) : (
                        <p>{currentValue}</p>
                    )}
                    {enableModes && (
                        <button
                            type="button"
                            className={
                                fieldMode === FieldMode.EDIT
                                    ? `edit-mode`
                                    : ``
                            }
                            onClick={() => {
                                setFieldMode(
                                    fieldMode === FieldMode.EDIT
                                        ? FieldMode.READ
                                        : FieldMode.EDIT,
                                );
                            }}
                        >
                            {fieldMode === FieldMode.EDIT ? (
                                <Pen20Filled />
                            ) : (
                                <Pen20Regular />
                            )}
                        </button>
                    )}
                </div>
                {errorMessage ? (
                    <MessageBar
                        id={`${id}-error`}
                        intent="error"
                        layout="multiline"
                    >
                        {errorMessage}
                    </MessageBar>
                ) : null}
            </>
        );
    },
);

export default styled(TLAdminInput)`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    label {
        width: 180px;
        padding-right: 1rem;
    }

    input {
        width: 330px;
        padding: 0.5rem;
        border-radius: 8px;
        font-size: 1.125rem;
        outline: 0;
        border-color: #d9d9d9;
        border-width: 2px;
    }

    input:focus,
    input:focus {
        border-color: #00bbcc;
        border-width: 2px;
    }

    p {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.125rem;
    }

    button {
        padding: 0.25rem;
        border-radius: 8px;
        margin-left: auto;
    }

    button:hover,
    button.edit-mode {
        color: #00bbcc;
    }
`;
