/*
 * timeSeriesRangeQuery
 * Time series range query
 *
 */
import { gql } from "@apollo/client";

export const TIME_SERIES_RANGE_QUERY = gql`
    query timeSeriesRangeQuery(
        $query: String!
        $startTime: String!
        $endTime: String!
        $step: String!
    ) {
        rangeQuery(
            query: $query
            startTime: $startTime
            endTime: $endTime
            step: $step
        ) {
            executionTimeMs
            result
            seriesFetched
            status
        }
    }
`;
