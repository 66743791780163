import React, { useState } from "react";
import styled from "styled-components";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useNavigate } from "react-router-dom";
import { MessageBar } from "@fluentui/react-components";
import { PrimaryButton, TLInput } from "@repo/ui";

export type FormInputs = {
    companyName: string;
    addressLineOne: string;
    addressLineTwo?: string;
    addressLineThree?: string;
    townCity: string;
    county: string;
    postcode: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    password: string;
    floorPlannerUrl: string;
    openingTimes: string;
};

const formSchema = {
    companyName: yup
        .string()
        .required("Please enter your company name"),
    addressLineOne: yup
        .string()
        .required("Please enter your address"),
    addressLineTwo: yup.string(), // Optional field
    addressLineThree: yup.string(), // Optional field
    townCity: yup.string().required("Please enter your town/city"),
    county: yup.string().required("Please enter your county"),
    postcode: yup.string().required("Please enter your postcode"),
    contactName: yup
        .string()
        .required("Please enter your first name"),
    contactPhone: yup
        .string()
        .required("Please enter your phone number"),
    contactEmail: yup
        .string()
        .email()
        .required("Please enter your e-mail address"),
    password: yup.string().required("Please enter your password"),
    floorPlannerUrl: yup
        .string()
        .required("Please enter your Floor Planner URL"),
    openingTimes: yup
        .string()
        .required("Please enter your opening times"),
};
interface Props {
    className?: string;
}

const GetStartedPage: React.FC<Props> = ({ className }) => {
    const [serverError] = useState<string>("");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInputs>({
        resolver: yupResolver(yup.object(formSchema)),
    });

    const onSubmit: SubmitHandler<FormInputs> = async formData => {
        console.log(`FORM DATA`, formData);
    };

    return (
        <div className={className}>
            <h1>Get Started</h1>
            <p className="intro">
                Please complete this short form. We are excited to be
                working with you.
            </p>

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-6"
            >
                <fieldset>
                    <h2>Company Details</h2>
                    <TLInput
                        className="mb-4"
                        id="txtCompanyName"
                        label="Company Name"
                        props={{
                            type: "text",
                            ...register("companyName"),
                        }}
                        errorMessage={errors.companyName?.message}
                    />
                    <TLInput
                        className="mb-2"
                        id="txtAddressLineOne"
                        label="Address"
                        props={{
                            type: "text",
                            ...register("addressLineOne"),
                        }}
                    />
                    <TLInput
                        className="mb-2"
                        id="txtAddressLineTwo"
                        label=""
                        props={{
                            type: "text",
                            ...register("addressLineTwo"),
                        }}
                    />
                    <TLInput
                        className="mb-4"
                        id="txtAddressLineThree"
                        label=""
                        props={{
                            type: "text",
                            ...register("addressLineThree"),
                        }}
                        errorMessage={errors.addressLineOne?.message}
                    />
                    <TLInput
                        className="mb-4"
                        id="txtTownCity"
                        label="Town/City"
                        props={{
                            type: "text",
                            ...register("townCity"),
                        }}
                        errorMessage={errors.townCity?.message}
                    />
                    <TLInput
                        className="mb-4"
                        id="txtCounty"
                        label="County"
                        props={{
                            type: "text",
                            ...register("county"),
                        }}
                        errorMessage={errors.county?.message}
                    />
                    <TLInput
                        className="mb-4"
                        id="txtPostcode"
                        label="Postcode"
                        props={{
                            type: "text",
                            ...register("postcode"),
                        }}
                        errorMessage={errors.postcode?.message}
                    />
                </fieldset>

                <fieldset>
                    <h2>Contact Details</h2>
                    <TLInput
                        className="mb-4"
                        id="txtName"
                        label="Your Name"
                        props={{
                            type: "text",
                            ...register("contactName"),
                        }}
                        errorMessage={errors.contactName?.message}
                    />
                    <TLInput
                        className="mb-4"
                        id="txtContactPhone"
                        label="Your Phone Number"
                        props={{
                            type: "text",
                            ...register("contactPhone"),
                        }}
                        errorMessage={errors.contactPhone?.message}
                    />
                    <TLInput
                        className="mb-4"
                        id="txtContactEmail"
                        label="Your E-mail Address"
                        props={{
                            type: "email",
                            ...register("contactEmail"),
                        }}
                        errorMessage={errors.contactEmail?.message}
                    />
                </fieldset>

                <fieldset>
                    <h2>Basic Twin Info</h2>
                    <div className="mt-2">
                        <TLInput
                            className="mb-4"
                            id="txtFloorPlannerUrl"
                            label="Floor Planner URL"
                            props={{
                                type: "text",
                                ...register("floorPlannerUrl"),
                            }}
                            errorMessage={
                                errors.floorPlannerUrl?.message
                            }
                        />
                    </div>
                    <div className="mt-2">
                        <TLInput
                            className="mb-4"
                            id="txtOpeningTimes"
                            label="Opening Times"
                            props={{
                                type: "text",
                                ...register("openingTimes"),
                            }}
                            errorMessage={
                                errors.openingTimes?.message
                            }
                        />
                    </div>
                </fieldset>

                <div className="mt-16">
                    <PrimaryButton type="submit">
                        Get Started
                    </PrimaryButton>
                </div>
                {serverError ? (
                    <MessageBar id={`server-error`} intent="error">
                        {serverError}
                    </MessageBar>
                ) : null}
            </form>
        </div>
    );
};

export default styled(GetStartedPage)`
    p.intro {
        margin: 1rem 0 2rem 0;
    }

    fieldset {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px #dad7d6 solid;
    }

    fieldset h2 {
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
    }
`;
