// Types
import { Label } from "../../../../../../@types/Settings/Label";
import { LabelValueType } from "../../../../../../@types/Settings/LabelValueType";

// Utils
import { getCumulativeCount } from "../../../../../utils/func-metrics/getCumulativeCount";
import { getTransactionRate } from "../../../../../utils/func-metrics/getTransactionRate";

export const LabelSetEventOperatorTimeSeriesFull: Label[] = [
    {
        name: "Transaction Rate",
        valueName: "getAvgTransactionRate",
        valueType: LabelValueType.FUNC_METRIC,
        value: getTransactionRate,
    },
    {
        name: "Cumulative Count",
        valueName: "getAvgCumulativeCount",
        valueType: LabelValueType.FUNC_METRIC,
        value: getCumulativeCount,
    },
];
