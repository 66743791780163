import { BoxGeometry, MeshStandardMaterial, Vector3 } from "three";
import { TwinEntity } from "@repo/backend-types";
import { TwinEntityType } from "../../../../@types/TwinEntityType";
import { useMemo } from "react";
import { Capacity } from "../../behaviours/Capacity";

type Props = {
    idx: number;
    entity: TwinEntity;
    lineage: TwinEntity[];
    mapDiv: HTMLDivElement;
    boxWidth: number;
    parentBoxWidth: number;
    containerDepth: number;
    gapRatio: number;
};

const CONTAINER_DEPTH_RATIO = 0.7;

const material = new MeshStandardMaterial({
    color: "#dfdfdf",
    opacity: 0.3,
});

export function Operator({
    entity,
    lineage,
    mapDiv,
    boxWidth,
    parentBoxWidth,
    containerDepth,
    gapRatio,
    idx,
}: Props) {
    const position = useMemo(
        () =>
            new Vector3(
                boxWidth * (1 + gapRatio) * (idx + 1 / 2) -
                    parentBoxWidth / 2,
                0,
                0,
            ),
        [boxWidth, gapRatio, idx, parentBoxWidth],
    );

    const boxDepth = CONTAINER_DEPTH_RATIO * containerDepth;

    const { boxGeometry, behaviourBoxGeometry } = useMemo(() => {
        const boxGeometry = new BoxGeometry(
            boxWidth,
            boxDepth,
            boxDepth,
        );
        boxGeometry.computeBoundingBox();

        const behaviourBoxGeometry = boxGeometry.clone();
        behaviourBoxGeometry.scale(1.1, 1.1, 1.1);
        behaviourBoxGeometry.computeBoundingBox();
        return { boxGeometry, behaviourBoxGeometry };
    }, [boxDepth, boxWidth]);

    return (
        <mesh
            geometry={boxGeometry}
            material={material}
            position={position}
        >
            <Capacity
                mapDiv={mapDiv}
                lineage={lineage}
                entity={entity}
                geometry={behaviourBoxGeometry}
                entityType={TwinEntityType.OPERATOR}
                labelBoundingBox={boxGeometry.boundingBox!} // N.B. the geometry's bounding box should have been computed already
                topLevelType={TwinEntityType.SITE}
            />
        </mesh>
    );
}
