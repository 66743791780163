import { useEffect, useRef, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import ReactECharts from "echarts-for-react";
import styled from "styled-components";

// Types
import { Error } from "../../@types/AI/Error";
import { Message } from "../../@types/Message";
import { PredefinedPrompt } from "../../@types/PredefinedPrompt";

// Context
import { useSettingsContext } from "../../common/contexts/SettingsContext";
import { useTwinContext } from "../../common/contexts/TwinContext";
import { useUserContext } from "../../common/contexts/UserContext";

// Components
import { ReactComponent as SquareIcon } from "../../icons/square.svg";
import { ReactComponent as ArrowUp } from "../../icons/arrowUp.svg";
import TLPromptInput from "../elements/TLPromptInput/TLPromptInput";

interface Props {
    className?: string;
    sessionId?: string;
    apiEndpoint: string;
    apiKey?: string;
    triggerPrompt?: string;
    addMessage: (message: Message) => void;
    setAwaitingMessage: (value: boolean) => void;
}

export type FormInputs = {
    message: string;
};

const formSchema = {
    message: yup
        .string()
        .required(`Sorry, I didn't catch your message`),
};

const AIPrompt: React.FC<Props> = ({
    className,
    sessionId,
    apiEndpoint,
    apiKey,
    triggerPrompt,
    addMessage,
    setAwaitingMessage,
}) => {
    const { authMetadata } = useUserContext();
    const { settings } = useSettingsContext();
    const { twin } = useTwinContext();

    const { register, handleSubmit, setValue, watch } =
        useForm<FormInputs>({
            resolver: yupResolver(yup.object(formSchema)),
        });

    const [isGenerating, setIsGenerating] = useState(false);
    const abortControllerRef = useRef<AbortController | null>(null);

    const clearPromptInput = () => {
        setValue("message", "");
    };

    const message = watch("message", "");

    const askAIQuestion = async (
        apiEndpoint: string,
        userPrompt: string,
        apiKey?: string,
    ) => {
        setAwaitingMessage(true);
        setIsGenerating(true);

        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        if (apiEndpoint === "") {
            addMessage({
                content:
                    "Sorry, I cannot answer. You need to ensure your API URL is correct.",
                response: true,
                prompts: [],
            });
            return;
        }

        let headers = {
            "Content-Type": "application/json",
            "X-TL-Auth": `${apiKey}`,
        };

        axios
            .post(
                apiEndpoint,
                {
                    question: userPrompt,
                    organisation: settings?.organisation,
                    root_entity_bid: twin?.physicalModel.bID,
                    session_id: sessionId,
                },
                {
                    headers: headers,
                    signal: signal,
                },
            )
            .then(response => {
                let uiResponse = "";
                let chartData = null;
                let chartWidth = null;
                const suggestedPrompts: PredefinedPrompt[] = [];

                // Handle response
                if (response.data.response) {
                    uiResponse = response.data.response;

                    if (response.data.machine.chart_data) {
                        chartData = response.data.machine.chart_data;

                        let data;
                        // Check if data exists on xAxis
                        if ("data" in chartData.xAxis) {
                            data = chartData.xAxis.data;
                        }
                        // Check if data exists on yAxis
                        else if ("data" in chartData.yAxis) {
                            data = chartData.yAxis.data;
                        }
                    }

                    chartWidth = "800px"; // Currently we fix the chart width
                }

                addMessage({
                    content: uiResponse,
                    response: true,
                    chart:
                        chartData && chartWidth ? (
                            <ReactECharts
                                style={{
                                    height: `400px`,
                                    width: chartWidth,
                                }}
                                option={chartData}
                            />
                        ) : undefined,
                    prompts: suggestedPrompts,
                });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    addMessage({
                        content: "",
                        prompts: [],
                        response: true,
                    });
                } else {
                    // Check to see if the user is in Developer Mode
                    let errorContent;

                    if (authMetadata?.developerMode) {
                        if (
                            error.response &&
                            error.response.data &&
                            Array.isArray(error.response.data.errors)
                        ) {
                            const errors = error.response.data.errors;
                            const errorCount = errors.length;

                            if (errorCount > 0) {
                                addMessage({
                                    content: `<strong>${errorCount} error${errorCount > 1 ? `s</strong> have` : `</strong> has`} been reported from the API. <strong>Session ID:</strong> ${sessionId}`,
                                    prompts: [],
                                    response: true,
                                    error: true,
                                });
                            }

                            errors.forEach((e: Error) => {
                                errorContent = `<strong>Code:</strong> ${e.code}<br />`;
                                errorContent =
                                    errorContent +
                                    `<strong>Message:</strong> ${e.message}<br />`;
                                errorContent =
                                    errorContent +
                                    `<strong>Technical Message:</strong> ${e.technicalMessage}`;

                                addMessage({
                                    content: errorContent,
                                    prompts: [],
                                    response: true,
                                    error: true,
                                });
                            });
                        } else if (error.message) {
                            errorContent = error.message;

                            addMessage({
                                content: errorContent,
                                prompts: [],
                                response: true,
                                error: true,
                            });
                        }
                    } else {
                        errorContent = `Something went wrong. Please try asking again.`;

                        addMessage({
                            content: errorContent,
                            prompts: [],
                            response: true,
                        });
                    }
                }
            })
            .finally(() => {
                setAwaitingMessage(false);
                setIsGenerating(false);
            });
    };

    useEffect(() => {
        async function sendMessage(triggerPrompt: string) {
            await askAIQuestion(apiEndpoint, triggerPrompt, apiKey);
        }

        if (triggerPrompt) {
            sendMessage(triggerPrompt);
        }
    }, []);

    const onSubmit: SubmitHandler<FormInputs> = async formData => {
        const userPrompt = formData.message;
        // Do this immediately
        clearPromptInput();
        // Submit message
        submitMessage({
            content: userPrompt,
            response: false,
            prompts: [],
        });
    };

    const submitMessage = async (message: Message) => {
        // Add user message to the conversation
        addMessage(message);
        // Send message to AI
        await askAIQuestion(apiEndpoint, message.content, apiKey);
    };

    const handleCancelRequest = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    };

    return (
        <div className={className}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TLPromptInput
                    props={{
                        placeholder: "Type here. 0/500",
                        type: "text",
                        ...register("message"),
                    }}
                />
                {!isGenerating && (
                    <button
                        className="submit-button"
                        type="submit"
                        disabled={!message}
                    >
                        <ArrowUp />
                    </button>
                )}
                {isGenerating && (
                    <button
                        className="cancel-button"
                        onClick={handleCancelRequest}
                    >
                        <SquareIcon />
                    </button>
                )}
            </form>
        </div>
    );
};

export default styled(AIPrompt)`
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    pointer-events: all;
    border-radius: 6px;
    border: 1px solid var(--Neutral-Stroke-1-Glass2, #fff);
    background: var(
        --Neutral-Background-Transparent-Rest,
        rgba(255, 255, 255, 0)
    );

    .copilot-bubbles {
        color: var(--main-color); /* #00BCCB; */
        margin-right: 0.5rem;
    }

    form {
        display: flex;
        flex-grow: 1;
        align-items: center;
        border-radius: 6px;
        padding: 6px;
    }

    input[type="text"] {
        width: 100%;
        margin-left: 0.5rem;
        font-size: 14px;
        background: none;
        border: none;
        outline: none;
    }

    input[type="submit"] {
        cursor: pointer;
    }

    .submit-button,
    .cancel-button {
        background-color: rgba(255, 255, 255, 0.4);
        width: 20px;
        height: 20px;
        border-radius: 4px;
        cursor: pointer;
    }

    .submit-button svg {
        color: rgba(36, 36, 36, 1);
        width: 20px;
        height: 18px;
    }

    .submit-button:disabled {
        background-color: rgba(255, 255, 255, 0.4);
    }

    .submit-button:disabled path {
        fill: rgba(36, 36, 36, 0.5);
    }

    .submit-button:not([disabled]):hover {
        background-color: rgba(255, 255, 255, 0.8);
    }

    .submit-button:not([disabled]):hover svg {
        color: rgba(36, 36, 36, 0.8);
    }

    .submit-button:not([disabled]):active {
        background-color: rgba(255, 255, 255, 1);
    }

    .submit-button:not([disabled]):active svg {
        color: rgba(36, 36, 36, 1);
        stroke: #424242;
        stroke-width: 1px;
    }

    .submit-button:not([disabled]):active path {
        stroke: #424242;
        stroke-width: 0.5px;
    }

    .cancel-button svg {
        margin-left: 3px;
        width: 12px;
        height: 12px;
    }

    .cancel-button:active {
        background-color: rgba(255, 255, 255, 1) !important;
    }

    .cancel-button:hover {
        background-color: rgba(255, 255, 255, 0.8) !important;
    }

    .cancel-button:active svg {
        color: rgba(36, 36, 36, 1);
        border-radius: 4px;
        width: 14px;
        height: 14px;
        margin-left: 2px;
    }
`;
