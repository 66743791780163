import { Object3D, RawShaderMaterial, Vector3 } from "three";

// docs on what attributes and unfiforms are passed https://threejs.org/docs/#api/en/renderers/webgl/WebGLProgram
const vertexShader = `

uniform mat4 modelMatrix;
uniform mat4 viewMatrix;
uniform mat4 projectionMatrix;
uniform mat3 normalMatrix;

attribute vec3 position;
attribute vec2 uv;
attribute vec3 normal;

varying vec4 worldPosition;
varying vec3 worldNormal; 

void main() {


    worldPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * worldPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    worldNormal = mat3(modelMatrix) * normal;

    gl_Position = projectedPosition;

}`;

const fragmentShader = `

precision highp float;

varying vec4 worldPosition;
varying vec3 worldNormal; 

uniform vec3 color;
uniform vec3 directionalLightDirection;

float AMBIENT_LIGHT = 0.5;
vec3 DIFFUSE_WHITE = vec3(0.6, 0.6, 0.6);
vec3 DIFFUSE_GREY = vec3(0.2, 0.2, 0.2);

void main() {

    vec3 MAT_COLOR = DIFFUSE_WHITE;

    float shift = cos((worldPosition.x + worldPosition.z + worldPosition.y) * 20.0);

    if (shift > 0.8) {
        MAT_COLOR = DIFFUSE_GREY;
    }

    float light = -dot(normalize(directionalLightDirection), normalize(worldNormal)) * 0.5  + AMBIENT_LIGHT;
    gl_FragColor = vec4(MAT_COLOR * light, 0.5);
}`;

export class NotTrackedMaterial extends RawShaderMaterial {
    constructor(
        directionalLightPosition: Vector3,
        directionalLightTarget: Object3D,
    ) {
        const uniforms = {
            directionalLightDirection: {
                value: new Vector3().subVectors(
                    directionalLightTarget.position,
                    directionalLightPosition,
                ),
            },
        };

        super({ uniforms, vertexShader, fragmentShader });

        this.transparent = true;
    }
}
