import React, { useRef, useEffect, useState, ReactNode } from "react";
import styled from "styled-components";

interface CustomScrollbarProps {
    children: ReactNode;
    className?: string;
    maxHeight?: number | string;
    right?: number;
    dynamicHeight?: boolean;
    contentUpdated?: number;
    fadeOutEnabled?: boolean;
    onScrollStatusChange?: (
        status: "top" | "scrolling" | "bottom",
    ) => void;
}

const CustomScrollbar: React.FC<CustomScrollbarProps> = ({
    children,
    className,
    maxHeight,
    right,
    dynamicHeight,
    contentUpdated,
    fadeOutEnabled = false,
    onScrollStatusChange,
}) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const thumbRef = useRef<HTMLDivElement | null>(null);
    const initialYRef = useRef(0);
    const scrollStartRef = useRef(0);
    const [thumbHeight, setThumbHeight] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [maxDynamicHeight, setMaxDynamicHeight] = useState("auto");
    const [scrollStatus, setScrollStatus] = useState<
        "top" | "scrolling" | "bottom"
    >("top");

    const updateThumbHeight = () => {
        if (contentRef.current) {
            const contentHeight = contentRef.current.scrollHeight;
            const containerHeight = contentRef.current.clientHeight;

            if (contentHeight > containerHeight) {
                const calculatedThumbHeight =
                    (containerHeight / contentHeight) *
                    containerHeight;
                setThumbHeight(calculatedThumbHeight);
            } else {
                setThumbHeight(0);
            }
        }
    };

    const updateMaxHeight = () => {
        if (contentRef.current) {
            const rect = contentRef.current.getBoundingClientRect();
            const bottomHeight = 50;
            const availableHeight =
                window.innerHeight - rect.top - bottomHeight;

            if (rect.bottom <= window.innerHeight) {
                setMaxDynamicHeight(`${availableHeight}px`);
            } else {
                setMaxDynamicHeight("auto");
            }
        }
    };

    const updateThumbPosition = () => {
        if (contentRef.current && thumbRef.current) {
            const scrollTop = contentRef.current.scrollTop;
            const containerHeight = contentRef.current.clientHeight;
            const contentHeight = contentRef.current.scrollHeight;
            const thumbPosition =
                (scrollTop / contentHeight) * containerHeight;
            thumbRef.current.style.top = `${thumbPosition}px`;
        }
    };

    const determineScrollStatus = () => {
        if (!contentRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } =
            contentRef.current;
        let newStatus: "top" | "scrolling" | "bottom";

        if (scrollTop === 0) {
            newStatus = "top";
        } else if (scrollTop + clientHeight >= scrollHeight - 1) {
            newStatus = "bottom";
        } else {
            newStatus = "scrolling";
        }

        if (newStatus !== scrollStatus) {
            setScrollStatus(newStatus);
            if (onScrollStatusChange) {
                onScrollStatusChange(newStatus);
            }
        }
    };

    const handleScroll = () => {
        updateThumbPosition();
        determineScrollStatus();
    };

    const handleThumbDrag = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
        initialYRef.current = e.clientY;
        scrollStartRef.current = contentRef.current
            ? contentRef.current.scrollTop
            : 0;

        const onMouseMove = (moveEvent: MouseEvent) => {
            if (contentRef.current && thumbRef.current) {
                const deltaY =
                    moveEvent.clientY - initialYRef.current;
                const containerHeight =
                    contentRef.current.clientHeight;
                const contentHeight = contentRef.current.scrollHeight;
                const scrollAmount =
                    (deltaY / containerHeight) * contentHeight;
                contentRef.current.scrollTop =
                    scrollStartRef.current + scrollAmount;

                requestAnimationFrame(() => {
                    updateThumbPosition();
                    determineScrollStatus();
                });
            }
        };

        const onMouseUp = () => {
            setIsDragging(false);
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
        };

        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
    };

    const handleTrackClick = (
        e: React.MouseEvent<HTMLDivElement>,
    ) => {
        if (thumbRef.current && contentRef.current) {
            const thumbRect =
                thumbRef.current.getBoundingClientRect();

            if (
                e.clientY < thumbRect.top ||
                e.clientY > thumbRect.bottom
            ) {
                const containerRect =
                    contentRef.current.getBoundingClientRect();
                const clickY = e.clientY - containerRect.top;
                const containerHeight =
                    contentRef.current.clientHeight;
                const contentHeight = contentRef.current.scrollHeight;

                const scrollPosition =
                    (clickY / containerHeight) * contentHeight;
                contentRef.current.scrollTop = scrollPosition;

                requestAnimationFrame(() => {
                    updateThumbPosition();
                    determineScrollStatus();
                });
            }
        }
    };

    const handleWheel = (e: React.WheelEvent) => {
        if (contentRef.current) {
            contentRef.current.scrollTop += e.deltaY;
            updateThumbPosition();
            determineScrollStatus();
        }
    };

    useEffect(() => {
        updateThumbHeight();
        updateMaxHeight();

        const handleResize = () => {
            updateThumbHeight();
            updateMaxHeight();
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        updateThumbHeight();

        const mutationObserver = new MutationObserver(() => {
            setTimeout(updateThumbHeight, 50);
        });

        if (contentRef.current) {
            mutationObserver.observe(contentRef.current, {
                childList: true,
                subtree: true,
                attributes: true,
            });
        }

        return () => {
            if (contentRef.current) {
                mutationObserver.disconnect();
            }
        };
    }, [children]);

    const prevContentUpdated = useRef(contentUpdated);

    useEffect(() => {
        if (
            contentUpdated !== prevContentUpdated.current &&
            dynamicHeight
        ) {
            updateMaxHeight();
            prevContentUpdated.current = contentUpdated;
        }
    }, [contentUpdated, dynamicHeight]);

    return (
        <div
            className={`${className} ${fadeOutEnabled && scrollStatus !== "top" ? "fade-top" : ""}`}
            style={{
                position: "relative",
                height: "100%",
                maxHeight,
            }}
        >
            <div
                className={`scroll-content ${fadeOutEnabled && scrollStatus !== "bottom" ? "fade-bottom" : ""}`}
                ref={contentRef}
                onScroll={handleScroll}
                style={{
                    maxHeight: dynamicHeight
                        ? maxDynamicHeight
                        : maxHeight,
                }}
            >
                {children}
            </div>
            {thumbHeight > 0 && (
                <div
                    className="custom-scrollbar"
                    onClick={handleTrackClick}
                    onWheel={handleWheel}
                    style={{ right }}
                >
                    <div
                        className={`custom-scrollbar-thumb ${isDragging ? "active" : ""}`}
                        ref={thumbRef}
                        onMouseDown={handleThumbDrag}
                        style={{
                            height: `${thumbHeight}px`,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default styled(CustomScrollbar)`
    position: relative;

    &.fade-top {
        -webkit-mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 40%
        );
        mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 40%
        );
    }

    .scroll-content {
        overflow-y: scroll;
        height: 100%;
        width: 100%;
        margin-right: 8px;
        scrollbar-width: none; /* Firefox - hides scrollbar */
        -ms-overflow-style: none; /* Internet Explorer and Edge */
    }

    .scroll-content::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Edge */
    }

    .custom-scrollbar {
        position: absolute;
        right: 0;
        top: 0;
        width: 12px; /* Matches the width of the native scrollbar */
        height: 100%;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 7px;
        box-shadow: 0px 4px 4px rgba(228, 255, 254, 0.25);
    }

    .custom-scrollbar:hover {
        background: rgba(255, 255, 255, 0.4);
    }

    .custom-scrollbar-thumb {
        width: 100%;
        position: absolute;
        top: 0;
        background: rgba(228, 255, 254, 0.25);
        border-radius: 7px;
        box-shadow: 0px 4px 4px rgba(228, 255, 254, 0.25);
        cursor: pointer;
        pointer-events: "auto";
        transition: background-color 0.2s ease; /* Smooth color transition */
    }

    .custom-scrollbar-thumb:hover {
        background: rgba(228, 255, 254, 0.8);
        box-shadow: 0px 4px 4px 0px rgba(228, 255, 254, 0.25);
    }

    .custom-scrollbar-thumb.active {
        background: rgba(228, 255, 254, 1);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    }

    .fade-bottom {
        -webkit-mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1) 80%,
            rgba(0, 0, 0, 0) 100%
        );
        mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1) 80%,
            rgba(0, 0, 0, 0) 100%
        );
    }
`;
