import axios from "axios";
import routes from "./routes";

export const updateUserById = async (
    accessToken: string,
    email: string,
    firstName: string,
    lastName: string,
    role: string,
    userId: number,
): Promise<boolean> => {
    const url = `${routes.put.users.updateUserById}${userId}`;
    let success = false;

    const data = {
        email,
        firstname: firstName,
        lastname: lastName,
        name: `${firstName} ${lastName}`,
        roles: [role],
    };

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        await axios.put(url, data, {
            headers: headers,
        });
        success = true;
    } catch (error) {
        success = false;
    }

    return success;
};
