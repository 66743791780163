import React from "react";

// Context
import { useFormContext } from "react-hook-form";

// Components
import {
    Clock24Regular,
    Delete24Regular,
} from "@fluentui/react-icons";

export type RangeProps = {
    range: {
        openHour: string;
        openMinute: string;
        closeHour: string;
        closeMinute: string;
    };
    index: number;
    day: string;
    onRemove: () => void;
};

const RangeForm: React.FC<RangeProps> = ({
    range,
    index,
    day,
    onRemove,
}) => {
    const { register, setValue, watch } = useFormContext();

    const formatValue = (value: number | string) =>
        String(value).padStart(2, "0");

    const openHour = formatValue(
        watch(
            `openingHours.${day}.${index}.openHour`,
            range.openHour,
        ),
    );
    const openMinute = formatValue(
        watch(
            `openingHours.${day}.${index}.openMinute`,
            range.openMinute,
        ),
    );
    const closeHour = formatValue(
        watch(
            `openingHours.${day}.${index}.closeHour`,
            range.closeHour,
        ),
    );
    const closeMinute = formatValue(
        watch(
            `openingHours.${day}.${index}.closeMinute`,
            range.closeMinute,
        ),
    );

    return (
        <div className="form-row">
            <div className="input-row">
                <div className="clock">
                    <Clock24Regular />
                </div>
                <div className="times">
                    <div className="time open">
                        <input
                            type="number"
                            {...register(
                                `openingHours.${day}.${index}.openHour`,
                                { valueAsNumber: true },
                            )}
                            value={formatValue(openHour)}
                            min={0}
                            max={23}
                            onChange={e => {
                                const value = Math.max(
                                    0,
                                    Math.min(
                                        23,
                                        Number(e.target.value),
                                    ),
                                );
                                setValue(
                                    `openingHours.${day}.${index}.openHour`,
                                    value,
                                    { shouldDirty: true },
                                );
                            }}
                        />
                        <p className="colon">:</p>
                        <input
                            type="number"
                            {...register(
                                `openingHours.${day}.${index}.openMinute`,
                                { valueAsNumber: true },
                            )}
                            value={formatValue(openMinute)}
                            min={0}
                            max={59}
                            onChange={e => {
                                const value = Math.max(
                                    0,
                                    Math.min(
                                        59,
                                        Number(e.target.value),
                                    ),
                                );
                                setValue(
                                    `openingHours.${day}.${index}.openMinute`,
                                    value,
                                    { shouldDirty: true },
                                );
                            }}
                        />
                    </div>
                    <div className="until">
                        <p>until</p>
                    </div>
                    <div className="time close">
                        <input
                            type="number"
                            {...register(
                                `openingHours.${day}.${index}.closeHour`,
                                { valueAsNumber: true },
                            )}
                            value={formatValue(closeHour)}
                            min={0}
                            max={23}
                            onChange={e => {
                                const value = Math.max(
                                    0,
                                    Math.min(
                                        23,
                                        Number(e.target.value),
                                    ),
                                );
                                setValue(
                                    `openingHours.${day}.${index}.closeHour`,
                                    value,
                                    { shouldDirty: true },
                                );
                            }}
                        />
                        <p className="colon">:</p>
                        <input
                            type="number"
                            {...register(
                                `openingHours.${day}.${index}.closeMinute`,
                                { valueAsNumber: true },
                            )}
                            value={formatValue(closeMinute)}
                            min={0}
                            max={59}
                            onChange={e => {
                                const value = Math.max(
                                    0,
                                    Math.min(
                                        59,
                                        Number(e.target.value),
                                    ),
                                );
                                setValue(
                                    `openingHours.${day}.${index}.closeMinute`,
                                    value,
                                    { shouldDirty: true },
                                );
                            }}
                        />
                    </div>
                </div>
                <button
                    type="button"
                    className="btn-delete"
                    onClick={onRemove}
                >
                    <Delete24Regular />
                </button>
            </div>
        </div>
    );
};

export default RangeForm;
