import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../../../@types/Auth0/api-response";
import { callExternalApi } from "./callExternalApiService";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getPublicResource = async (): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/messages/public`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    };

    const { data, error } = (await callExternalApi({
        config,
    })) as ApiResponse;

    return {
        data,
        error,
    };
};

export const getProtectedResource = async (
    accessToken: string,
): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/messages/protected`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
        },
    };

    const { data, error } = (await callExternalApi({
        config,
    })) as ApiResponse;

    return {
        data,
        error,
    };
};

export const getAdminResource = async (
    accessToken: string,
): Promise<ApiResponse> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/messages/admin`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
        },
    };

    const { data, error } = (await callExternalApi({
        config,
    })) as ApiResponse;

    return {
        data,
        error,
    };
};
