/*
 * findObjectById
 *
 */
export const findObjectById = (array: any[], id: string) => {
    let foundObject = null;

    if (array) {
        array.some(obj => {
            if (obj.id === id) {
                foundObject = obj;
                return true; // Break out of the loop if the object is found
            }
            if (obj.children && Array.isArray(obj.children)) {
                foundObject = findObjectById(obj.children, id); // Recursively search children arrays
                return foundObject !== null; // Break out of the loop if object is found in children
            }
            return null;
        });
    }

    return foundObject;
};
