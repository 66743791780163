// Types
import { NavItem } from "../../@types/NavItem";

// Components
import {
    Guest20Regular,
    Guest20Filled,
    Key20Filled,
    Key20Regular,
    Person20Filled,
    Person20Regular,
} from "@fluentui/react-icons";

export const allUsersTopMenu: NavItem[] = [
    {
        text: "Dashboard",
        href: "/account/dashboard",
        icon: Guest20Regular,
        iconSelected: Guest20Filled,
        showOnQuickLinks: false,
    },
    {
        text: "Profile",
        href: "/account/profile",
        icon: Person20Regular,
        iconSelected: Person20Filled,
        showOnQuickLinks: true,
    },
    {
        text: "Change Password",
        href: "/account/change-password",
        icon: Key20Regular,
        iconSelected: Key20Filled,
        showOnQuickLinks: true,
    },
];
