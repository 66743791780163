import React from "react";
import styled from "styled-components";

import orbit from "../../icons/Orbit2.png";
import pan from "../../icons/Pan2.png";
import zoomIn from "../../icons/Zoom-in2.png";
import zoomOut from "../../icons/Zoom-out2.png";

interface Props {
    className?: string;
}

const Gestures: React.FC<Props> = ({ className }) => {
    return (
        <>
            <div className={className}>
                <img src={orbit} alt="Orbit" />
                <img src={pan} alt="Pan" />
                <img src={zoomIn} alt="Zoom In" />
                <img src={zoomOut} alt="Zoom Out" />
            </div>
        </>
    );
};

export default styled(Gestures)`
    position: absolute;
    bottom: 0;
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255, 0.4);
    backdrop-filter: "blur(6px)";
    border-color: rgb(255, 255, 255, 0);
    box-shadow:
        0px 0px 11px 1px rgba(228, 255, 254, 0.55),
        0px 3px 11px 0px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    overflow: hidden;
    border-width: 2px;
    border-style: solid;

    img {
        margin: 0 10px;
        height: 50px;
        width: auto;
    }
`;
