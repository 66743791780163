export enum TwinEntityType {
    AREA = "Area",
    ASSET = "Asset",
    CATEGORY = "Category",
    CHUTE = "Chute",
    FLOOR = "Floor",
    NFAREA = "NFArea",
    SITE = "Site",
    STRUCTURE = "Structure",
    UNKNOWN = "Unknown",
    TICKET = "Ticket",
    ZONE = "Zone",
    WALL = "Wall",
    EVENT = "Event",
    STATION = "STATION",
    OPERATOR = "OPERATOR",
}
