import React, { useEffect } from "react";
import styled from "styled-components";

// Types
import { AppMode } from "../../@types/Mode";
import { DataMode } from "../../@types/DataMode";
import { DataValue } from "../../@types/Data/DataValue";

// Context
import { useDataContext } from "../../common/contexts/DataContext";
import {
    useFilterContext,
    useModeContext,
} from "../../common/contexts/FilterAndModeContexts";

// Utils
import { getDataValue } from "../../common/utils/func-metrics/getDataValue";
import { getIndicator } from "../../common/utils/getIndicator";

// Components
import { Warning12Filled } from "@fluentui/react-icons";

interface Props {
    className?: string;
    id: string; // This could be an Entity ID or the Entity's bID (Business ID)
    fontSize?: string | number;
    showWarning: boolean;
    showWarningFontSize?: string | number;
    isSelected: boolean;
}

const EntityIndicator: React.FC<Props> = ({
    className,
    id,
    fontSize,
    showWarning,
    showWarningFontSize,
    isSelected,
}) => {
    const { heroMetric } = useFilterContext();
    const { appMode } = useModeContext();
    const { data } = useDataContext();

    let indicator;
    let metricData: DataValue[] = [];
    let indicatorData: DataValue[] = [];

    if (data.processed && heroMetric) {
        const indicatorMetric =
            heroMetric.metric === "countEntity"
                ? "usage"
                : heroMetric.metric;
        metricData = data.processed
            ? data.processed[heroMetric.metric]
            : [];
        indicatorData = data.processed
            ? data.processed[indicatorMetric]
            : [];
    }

    const metricValue = getDataValue(
        appMode === AppMode.LIVE
            ? DataMode.LIVE
            : DataMode.TIME_SERIES,
        metricData,
        id,
    );
    const indicatorValue = getDataValue(
        appMode === AppMode.LIVE
            ? DataMode.LIVE
            : DataMode.TIME_SERIES,
        indicatorData,
        id,
    );

    indicator =
        metricValue &&
        indicatorValue &&
        getIndicator(
            metricValue,
            indicatorValue,
            heroMetric?.indicatorConfig,
            isSelected,
        );

    let showDecimalPlace = true;
    let showUnit = true;

    if (appMode === AppMode.LIVE) {
        showDecimalPlace =
            heroMetric?.live?.showDecimalPlace !== undefined
                ? heroMetric?.live?.showDecimalPlace
                : showDecimalPlace;
        showUnit =
            heroMetric?.live?.showUnit !== undefined
                ? heroMetric?.live?.showUnit
                : showUnit;
    } else {
        showDecimalPlace =
            heroMetric?.timeSeries?.showDecimalPlace !== undefined
                ? heroMetric?.timeSeries?.showDecimalPlace
                : showDecimalPlace;
        showUnit =
            heroMetric?.timeSeries?.showUnit !== undefined
                ? heroMetric?.timeSeries?.showUnit
                : showUnit;
    }

    let displayWarning =
        showWarning &&
        indicator &&
        indicator.value &&
        indicator.value > 100
            ? true
            : false;

    return (
        <div className={className}>
            {indicator && indicator.value ? (
                <>
                    {displayWarning ? (
                        <span
                            className="over-capacity-indicator"
                            style={{
                                fontSize: Number.isInteger(
                                    showWarningFontSize,
                                )
                                    ? `${showWarningFontSize}px`
                                    : showWarningFontSize,
                            }}
                        >
                            <Warning12Filled />
                        </span>
                    ) : null}
                    <span
                        className="indicator-label"
                        style={{
                            fontSize: fontSize ?? "12px",
                            marginLeft: !displayWarning
                                ? "auto"
                                : "4px",
                        }}
                    >
                        {showDecimalPlace
                            ? indicator.value.toFixed(1)
                            : indicator.value.toFixed(0)}
                        {showUnit && indicator.unit}
                    </span>
                    <span
                        className="indicator-badge"
                        style={{
                            backgroundColor: indicator.colors.bgColor,
                        }}
                    ></span>
                </>
            ) : null}
        </div>
    );
};

export default styled(EntityIndicator)`
    min-width: 70px;
    display: flex;
    align-items: center;
    margin-left: auto;

    .over-capacity-indicator {
        margin-top: -2px;
        margin-left: auto;
        color: #da3b01;
    }

    .indicator-badge {
        width: 8px;
        height: 8px;
        margin-left: 8px;
        border-radius: 8px;
        display: inline-block;
    }

    .indicator-label {
        color: var(--Neutral-Foreground-1-Rest, #242424);
        text-align: right;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
`;
