/*
 * calcMaxUtilisation
 * Utilisation Hero Metric
 *
 */
import { getMaxHeadcount } from "./getMaxHeadcount";

export const calcMaxUtilisation = async (
    timeSeriesURL: string,
    startDateTime: string,
    endDateTime: string,
    organisation: string,
    digitalTwinEntity: string,
    capacity: number,
): Promise<string> => {
    let value = "N/A";
    try {
        const headcount = await getMaxHeadcount(
            timeSeriesURL,
            startDateTime,
            endDateTime,
            "1d",
            organisation,
            digitalTwinEntity,
        );
        value = `${parseInt(headcount) > 0 && capacity > 0 ? Math.ceil((parseInt(headcount) / capacity) * 100) : 0}%`;
    } catch (error) {
        value = "N/A";
    }

    return value;
};
