import React from "react";
import NotificationPanel from "../components/NotificationPanel/NotificationPanel";

const PermissionDeniedPage: React.FC = () => {
    return (
        <>
            <NotificationPanel
                imgSrc="/twinlabs-ai-stacked-logo.svg"
                imgAlt="Twinlabs.ai Logo"
                header="Permission Denied Page"
                copy="Please contact your administrator."
                backRoute="/account/dashboard"
            />
        </>
    );
};

export default PermissionDeniedPage;
