import React, { useState } from "react";
import styled from "styled-components";

// Types
import { TabPanel } from "../../@types/TabPanel";

// Components
import {
    WebAsset24Filled,
    WebAsset24Regular,
} from "@fluentui/react-icons";
import Assets from "../Assets/Assets";
import TLCard from "../elements/TLCard/TLCard";
import TLSwitcher from "../elements/TLSwitcher/TLSwitcher";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";

interface Props {
    className?: string;
}

interface TabOption {
    inactiveIcon: JSX.Element;
    activeIcon: JSX.Element;
    value: string;
    text: string;
}

const AssetsAndAnalytics: React.FC<Props> = ({ className }) => {
    const tabOpts = [
        {
            inactiveIcon: <WebAsset24Regular />,
            activeIcon: <WebAsset24Filled />,
            value: TabPanel.ASSETS.toString(),
            text: "Assets",
        },
    ];

    const [tabPanel, setTabPanel] = useState<TabPanel>(
        TabPanel.ASSETS,
    );
    const [tabOptions] = useState<TabOption[]>(tabOpts);

    return (
        <div className={className}>
            <div className="header">
                <TLCard size="small">
                    <TLSwitcher
                        size="small"
                        selectedValue={tabPanel.toString()}
                        options={tabOptions}
                        setValue={setTabPanel}
                    />
                </TLCard>
            </div>
            {tabPanel === TabPanel.ASSETS && (
                <CustomScrollbar
                    fadeOutEnabled={false}
                    className="scrollable-container"
                    maxHeight={"calc(100vh - 220px)"}
                >
                    <Assets />
                </CustomScrollbar>
            )}
        </div>
    );
};

export default styled(AssetsAndAnalytics)`
    .header {
        max-width: calc(100% - 20px);
        height: 64px;
        position: sticky;
        top: 0;
    }

    .scrollable-container {
        width: 308px;
        padding-right: 20px;
    }
`;
