import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export type FormInputs = {
    email: string;
    password: string;
};

interface Props {}

const LoginPage: React.FC<Props> = ({}) => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            authorizationParams: {
                prompt: "login",
            },
        });
    };

    useEffect(() => {
        handleLogin();
    }, []);

    return null;
};
export default LoginPage;
