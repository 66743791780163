import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useApolloClient } from "@apollo/client";

// Types
import { Organisation, TwinEntity } from "@repo/backend-types";
import { NavItem } from "../../../@types/NavItem";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Data
import { GET_ORGANISATION } from "../../../common/api/live/gql/getOrganisation";

// Utils
import { getProtectedAccountMenu } from "../../../common/utils/getProtectedAccountMenu";

// Components
import { Image } from "@fluentui/react-components";
import TwinSelector from "../../../components/TwinSelector/TwinSelector";
import TLSpinnerPage from "../../../components/TLSpinnerPage/TLSpinnerPage";

interface Props {
    className?: string;
}

const SelectTwinPage: React.FC<Props> = ({ className }) => {
    const { isAuthenticated, user } = useAuth0();
    const apolloClient = useApolloClient();

    const navigate = useNavigate();

    const [availableTwins, setAvailableTwins] =
        useState<TwinEntity[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [twinPreview, setTwinPreview] = useState<string>();
    const { authMetadata } = useUserContext();

    // Missing Roles? Redirect user to the "Something went wrong" page
    if (!authMetadata?.roles) {
        navigate("/something-went-wrong");
    }

    // For now, we are just using a single role
    let role = authMetadata?.roles ? authMetadata.roles[0] : "";
    const accountMenu: NavItem[] = useMemo(
        () => getProtectedAccountMenu(role),
        [role],
    );

    useEffect(() => {
        if (isAuthenticated && user && authMetadata?.organisation) {
            setIsLoading(true);
            apolloClient
                .query<{ getOrganisation: Organisation }>({
                    query: GET_ORGANISATION,
                    variables: { name: authMetadata.organisation },
                })
                .then(response => {
                    if (response.data.getOrganisation.entities) {
                        setAvailableTwins(
                            response.data.getOrganisation.entities,
                        );
                        setIsLoading(false);
                    }
                });
        }
    }, [
        apolloClient,
        authMetadata?.dgraphUrl,
        authMetadata?.organisation,
        isAuthenticated,
        user,
    ]);

    return (
        <div className={className}>
            {isLoading && <TLSpinnerPage loadingMessages={[]} />}
            <div
                className="select-twin-page flex h-screen"
                style={{
                    backgroundImage:
                        'url("/assets/images/atom-bg.png")',
                    backgroundSize: "cover",
                }}
            >
                <div className="flex flex-1 flex-col p-12 lg:flex-none w-2/6">
                    <div className="twin-selector-panel">
                        <h2>Select a Digital Twin</h2>
                        <TwinSelector
                            availableTwins={availableTwins}
                            organisationName={
                                authMetadata?.organisation
                            }
                            onTwinHover={setTwinPreview}
                        />
                    </div>
                    <div className="account-management-panel">
                        <h2>Account Management</h2>
                        <p>
                            Pick an option to access account features.
                        </p>
                        <ul>
                            {accountMenu.map((item, i) => {
                                return (
                                    <li key={i}>
                                        <Link
                                            to={item.href}
                                            state={item.state}
                                        >
                                            <div className="option">
                                                <div className="option-icon">
                                                    <span className="icon">
                                                        {React.createElement(
                                                            item.icon,
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="flex-1 ml-2">
                                                    <p className="option-title">
                                                        {item.text}
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <div
                        className="mt-12 ml-auto mr-12"
                        style={{ width: "100px" }}
                    >
                        <Image
                            fit="contain"
                            src="/twinlabs-atom-dark-logo.svg"
                            alt="Twinlabs.ai"
                        />
                    </div>
                    <div>
                        <img
                            className="object-cover"
                            src={`/assets/images/new.png`}
                            alt="Event Venue"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default styled(SelectTwinPage)`
    .twin-selector-panel,
    .account-management-panel {
        background-color: rgb(255, 255, 255, 0.4);
        backdrop-filter: "blur(6px)";
        border-color: rgb(255, 255, 255, 0);
        box-shadow:
            0px 0px 11px 1px rgba(228, 255, 254, 0.55),
            0px 3px 11px 0px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        border-width: 2px;
        border-style: solid;
        color: #242424;
        padding: 2rem 1rem 4rem 2rem;
    }

    div.account-management-panel {
        padding-right: 2.85rem;
        margin-top: 3rem;
    }

    div.account-management-panel p {
        margin-bottom: 1rem;
    }

    .select-twin-page h2 {
        font-size: 2rem !important;
        margin-bottom: 1rem !important;
    }

    .select-twin-page p {
        font-size: 0.875rem;
        margin: 0;
        color: #707070;
    }

    .select-twin-page .option {
        display: flex;
        justify-items: center;
        align-items: center;
        padding: 0 0.75rem;
        min-height: 58px;
        margin-bottom: 1rem;
        border: 1px #e8e5e4 solid;
        background-color: #ffffff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        cursor: pointer;
    }

    .select-twin-page .option:hover {
        background-color: #f5f5f5;
    }

    .select-twin-page .option .option-icon {
        margin-right: 0.5rem;
    }

    .select-twin-page .option p.option-title {
        font-size: 1rem;
        padding-bottom: 0;
        margin-bottom: 0;
        line-height: 100%;
        color: #000000;
    }

    .select-twin-page .option p.option-microcopy {
        font-size: 0.675rem;
        line-height: 100%;
        color: #000000;
    }
`;
