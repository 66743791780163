import { useEffect, useState } from "react";

// Types
import { TwinEntity } from "@repo/backend-types";

// Context
import { useTwinContext } from "../../common/contexts/TwinContext";

// Utils
import { findEntityWithBID } from "../../common/utils/findEntityById";

type Props = {
    twinEntityBID: string;
    indent: number;
};

const EntityEditEntry: React.FC<Props> = ({
    twinEntityBID,
    indent,
}) => {
    const [fullEntityData, setFullEntityData] =
        useState<TwinEntity | null>(null);
    const { twin } = useTwinContext();

    useEffect(() => {
        if (twin) {
            const entityData = findEntityWithBID(
                twin.physicalModel,
                twinEntityBID,
            );
            console.log(entityData);
            if (entityData) {
                setFullEntityData(entityData);
            }
        }
    }, [twin, twinEntityBID, setFullEntityData]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: indent,
            }}
        >
            <h3>{fullEntityData?.name ?? twinEntityBID}</h3>
            {fullEntityData && (
                <>
                    <span>
                        Model File{" "}
                        <input
                            type="text"
                            value={fullEntityData.modelFile ?? "None"}
                        />
                    </span>
                    <span>
                        capacity{" "}
                        <input
                            type="number"
                            value={fullEntityData.capacity ?? 0}
                        />
                    </span>
                </>
            )}
        </div>
    );
};

export default EntityEditEntry;
