import React from "react";
import styled from "styled-components";
import Subtitle from "../Typography/Subtitle";

import { Dismiss16Regular } from "@fluentui/react-icons";
import { ReactComponent as CopilotColorIcon } from "../../common/svg/maria-icon.svg";

interface Props {
    className?: string;
    setIsHeaderHovered: (value: boolean) => void;
    setSupportCopilotVisible: (value: boolean) => void;
    onMouseDown?: (event: React.MouseEvent) => void;
}

const MariaHeader: React.FC<Props> = ({
    className,
    setIsHeaderHovered,
    setSupportCopilotVisible,
    onMouseDown,
}) => {
    return (
        <div className={className}>
            <div
                className="maria-header"
                onMouseEnter={() => setIsHeaderHovered(true)}
                onMouseLeave={() => setIsHeaderHovered(false)}
                onMouseDown={onMouseDown}
            >
                <div className="maria-header-title">
                    <div className="avatar">
                        <CopilotColorIcon />
                    </div>
                    <Subtitle m="auto">MAR.I.A</Subtitle>
                </div>
                <button
                    className="close-button"
                    onClick={() => setSupportCopilotVisible(false)}
                >
                    <Dismiss16Regular />
                </button>
            </div>
        </div>
    );
};

export default styled(MariaHeader)`
    .maria-header .close-button {
        padding-bottom: 3px;
        background-color: #ffffff66;
        width: 20px;
        height: 20px;
        border-radius: 4px;
    }

    .maria-header .close-button svg {
        color: #424242;
        margin-bottom: 3px;
    }

    .maria-header .close-button:hover {
        background-color: #ffffffcc;
    }

    .maria-header .close-button:active svg {
        background-color: #ffffff;
    }

    .maria-header .close-button:active path {
        stroke: #424242;
        stroke-width: 1px;
    }

    .maria-header {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        position: relative;
    }

    .maria-header:hover {
        cursor: move;
    }

    .maria-header-title {
        display: flex;
        gap: 3px;
    }

    .maria-header .avatar {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
