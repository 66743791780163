import React from "react";
import styled from "styled-components";

// Component
import { Search20Regular } from "@fluentui/react-icons";

interface Props {
    className?: string;
    isLoading: boolean;
    setSearchQuery: (value: string) => void;
}

const TLSearch: React.FC<Props> = ({
    className,
    isLoading,
    setSearchQuery,
}) => {
    // Validate input (only allow alphanumeric, @, ', -)
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const value = e.target.value;
        if (/^[a-zA-Z0-9@'\-]*$/.test(value)) {
            setSearchQuery(value);
        }
    };

    return (
        <div className={className}>
            <div className="icon">
                <Search20Regular />
            </div>
            <input
                disabled={isLoading}
                placeholder="Type to search"
                onChange={handleInputChange}
            />
        </div>
    );
};

export default styled(TLSearch)`
    display: flex;
    flex: 1;
    height: 58px;
    margin-bottom: 1rem;
    background-color: rgb(255, 255, 255, 0.4);
    backdrop-filter: "blur(6px)";
    border-color: rgb(255, 255, 255, 0);
    box-shadow:
        0px 0px 11px 1px rgba(228, 255, 254, 0.55),
        0px 3px 11px 0px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border-width: 2px;
    border-style: solid;

    .icon {
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & input {
        width: 100%;
        background: none;
        outline: none;
        font-size: 1.125rem;
    }
`;
