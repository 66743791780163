// Types
import { NavItem } from "../../@types/NavItem";

// Components
import {
    MoreCircle20Regular,
    SignOut20Regular,
    Dismiss20Regular,
    MoreCircle20Filled,
    SignOut20Filled,
    Dismiss20Filled,
} from "@fluentui/react-icons";

export const allUsersBottomMenu = (
    currentPath?: string,
): NavItem[] => {
    return [
        {
            text: "Select a Digital Twin",
            href: "/select-twin",
            icon: MoreCircle20Regular,
            iconSelected: MoreCircle20Filled,
            showOnQuickLinks: true,
        },
        {
            text: "Log Out",
            href: "/account/log-out",
            state: currentPath
                ? { backRoute: currentPath }
                : undefined,
            icon: SignOut20Regular,
            iconSelected: SignOut20Filled,
            showOnQuickLinks: true,
        },
        {
            text: "Exit Dashboard",
            href: "/select-twin",
            icon: Dismiss20Regular,
            iconSelected: Dismiss20Filled,
            showOnQuickLinks: true,
        },
    ];
};
