import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    useLocation,
    useNavigate,
    useOutletContext,
} from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Types
import { OutletContextType } from "../../../@types/OutletContextType";
import { Roles } from "../../../@types/Auth0/Roles";

// Data
import { getUserById } from "../../../common/api/middleware/getUserById";
import { updateUserById } from "../../../common/api/middleware/updateUserById";

// Context
import { useUserContext } from "../../../common/contexts/UserContext";

// Components
import {
    DeleteDismiss20Regular,
    Pen20Regular,
} from "@fluentui/react-icons";
import AdminPanel from "../../../components/AdminPanel/AdminPanel";
import TLAdminSelect from "../../../components/elements/TLAdminSelect/TLAdminSelect";
import TLAdminInput from "../../../components/elements/TLAdminInput/TLAdminInput";

export type FormInputs = {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
};

const formSchema = {
    email: yup
        .string()
        .email()
        .required("Please enter your e-mail address"),
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter your last name"),
    role: yup
        .string()
        .typeError("You must select an option")
        .notOneOf([""], "Please select an option")
        .required("You must select an option"),
};

interface Props {
    className?: string;
}

const EditUserPage: React.FC<Props> = ({ className }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const currentPath = location.pathname;
    const { userId } = location.state || {};

    if (!userId) {
        navigate("/something-went-wrong");
    }

    const { authMetadata } = useUserContext();
    const { setIsLoading } = useOutletContext<OutletContextType>();

    const [discard, setDiscard] = useState<boolean>(false);
    const [userMetadata, setUserMetadata] = useState<{
        email: string;
        firstName: string;
        lastName: string;
        role: string;
    }>({ email: "", firstName: "", lastName: "", role: "" });

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        reset,
        watch,
        formState: { errors, isDirty },
    } = useForm<FormInputs>({
        resolver: yupResolver(yup.object(formSchema)),
    });

    useEffect(() => {
        // Fetch user record
        const fetchUser = async () => {
            setIsLoading(true);
            if (authMetadata?.tokens?.accessToken) {
                const userData = await getUserById(
                    authMetadata?.tokens.accessToken,
                    userId,
                );
                setValue("email", userData.email);
                setValue("firstName", userData.given_name);
                setValue("lastName", userData.family_name);
                setValue("role", userData.roles[0]);
                setUserMetadata({
                    email: userData.email,
                    firstName: userData.given_name,
                    lastName: userData.family_name,
                    role: userData.roles[0],
                });
                setIsLoading(false);
            }
        };
        fetchUser();
    }, [authMetadata?.tokens?.accessToken, setValue, userId]);

    const onSubmit: SubmitHandler<FormInputs> = async formData => {
        if (authMetadata?.tokens?.accessToken) {
            setIsLoading(true);
            const result = await updateUserById(
                authMetadata?.tokens.accessToken,
                formData.email,
                formData.firstName,
                formData.lastName,
                formData.role,
                userId,
            );
            if (result) {
                navigate("/account/success", {
                    state: {
                        message:
                            "You've successfully updated the user.",
                    },
                });
            } else {
                navigate("/account/failure", {
                    state: {
                        message:
                            "<p>Unable to update user. Please try again.</p><p>If the problem persists please contact support.</p>",
                        backRoute: currentPath,
                    },
                });
            }
            setIsLoading(false);
        }
    };

    const handleDiscard = () => {
        reset({
            email: userMetadata.email,
            firstName: userMetadata.firstName,
            lastName: userMetadata.lastName,
            role: userMetadata.role,
        });
        setDiscard(!discard);
    };

    const formatRole = (role: string) => {
        switch (role) {
            case Roles.CUSTOMER_ADMIN:
                role = "Admin";
                break;
            case Roles.CUSTOMER_USER:
                role = "User";
                break;
            case Roles.PARTNER_USER:
                role = "Partner";
                break;
            case Roles.TL_ADMIN:
                role = "TL Admin";
                break;
            default:
                break;
        }
        return role;
    };

    const email = watch("email");
    const emailLength = email ? email.length : 0;

    return (
        <div className={className}>
            <AdminPanel
                maxWidth={emailLength && emailLength > 50 ? 830 : 630}
                header="Update User"
                children={
                    <>
                        <div className="form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <fieldset>
                                    <div className="field">
                                        <TLAdminSelect
                                            enableModes={true}
                                            id="ddlRole"
                                            label="Role"
                                            options={[
                                                {
                                                    text: "Please select",
                                                    value: "",
                                                },
                                                {
                                                    text: "Admin",
                                                    value: Roles.CUSTOMER_ADMIN,
                                                },
                                                {
                                                    text: "User",
                                                    value: Roles.CUSTOMER_USER,
                                                },
                                            ]}
                                            currentValue={formatRole(
                                                getValues("role"),
                                            )}
                                            props={{
                                                ...register("role"),
                                            }}
                                            errorMessage={
                                                errors.role?.message
                                            }
                                        />
                                    </div>
                                    <div className="field">
                                        <TLAdminInput
                                            enableModes={true}
                                            discard={discard}
                                            id="txtEmail"
                                            label="E-mail Address"
                                            currentValue={getValues(
                                                "email",
                                            )}
                                            type="email"
                                            {...register("email")}
                                            resetForm={reset}
                                            errorMessage={
                                                errors.email?.message
                                            }
                                        />
                                    </div>
                                    <div className="field">
                                        <TLAdminInput
                                            enableModes={true}
                                            discard={discard}
                                            id="txtFirstName"
                                            label="First Name"
                                            currentValue={getValues(
                                                "firstName",
                                            )}
                                            {...register("firstName")}
                                            resetForm={reset}
                                            errorMessage={
                                                errors.firstName
                                                    ?.message
                                            }
                                        />
                                    </div>
                                    <div className="field">
                                        <TLAdminInput
                                            enableModes={true}
                                            discard={discard}
                                            id="txtLastname"
                                            label="Last Name"
                                            currentValue={getValues(
                                                "lastName",
                                            )}
                                            {...register("lastName")}
                                            resetForm={reset}
                                            errorMessage={
                                                errors.lastName
                                                    ?.message
                                            }
                                        />
                                    </div>
                                </fieldset>
                                <div className="admin-form-buttons">
                                    <button
                                        disabled={!isDirty}
                                        type="submit"
                                        style={{
                                            color: isDirty
                                                ? "#242424"
                                                : "#CCCCCC",
                                        }}
                                    >
                                        <span className="icon">
                                            <Pen20Regular />
                                        </span>
                                        Save Changes
                                    </button>
                                    <button
                                        disabled={!isDirty}
                                        type="button"
                                        style={{
                                            color: isDirty
                                                ? "#242424"
                                                : "#CCCCCC",
                                        }}
                                        onClick={() => {
                                            handleDiscard();
                                        }}
                                    >
                                        <span className="icon">
                                            <DeleteDismiss20Regular />
                                        </span>
                                        Discard Changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default styled(EditUserPage)`
    form {
        width: 100%;
    }

    .field {
        margin-bottom: 2rem;
    }

    .field label {
        font-size: 1.125rem;
    }

    .link-field {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    .link-field label {
        width: 180px;
        padding-right: 1rem;
    }

    .link-field p {
        font-weight: bold;
    }

    .link-field a:link,
    .link-field a:visited {
        padding: 0.25rem;
        border-radius: 8px;
        margin-left: auto;
    }

    .link-field a:hover {
        color: #00bbcc;
    }

    .admin-form-buttons {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
    }

    .admin-form-buttons button {
        display: flex;
        justify-content: center;
        width: 264px;
        border-radius: 8px;
        padding: 1rem;
        background-color: #ffffff;
    }
`;
