/*
 * formatTimeRange
 * Used to format Filter Pill time ranges
 *
 */
import { DateTime } from "luxon";

export const formatTimeRange = (
    startDate: string,
    endDate: string,
) => {
    const startDateTime = DateTime.fromISO(startDate);
    const startTime = startDateTime.toFormat("HH:mm:ss");
    const finishDateTime = DateTime.fromISO(endDate);
    const finishTime = finishDateTime.toFormat("HH:mm:ss");
    return `${startTime}-${finishTime}`;
};
