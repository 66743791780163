import { Vector2, Vector3 } from "three";
import { Point } from "@repo/backend-types";

/*
 * take a point defined in COORD_LOCAL space and converts it to react-three-map world space
 * with distances in metres
 *  N.B. since -z is north on the map and +x is east, longitude is x+ and latitude is -z
 *  N.B. COORD_LOCAL is supposed to be in km, so this should be * 1000, but csv for newport is in mm and
 *  csv_converter has been assuming its in cm, so we're 10x out
 */

export function coordLocalToVector2(point: Point) {
    return new Vector2(point.longitude * 100, -point.latitude * 100);
}

// utility function to apply the above to an outline
export function coordLocalOutlineToVector2(outline: Point[]) {
    return outline.map(point => coordLocalToVector2(point));
}

export function Vector2ToVector3(vec2: Vector2, altitude: number) {
    return new Vector3(vec2.x, altitude, vec2.y);
}

export function convertCoordLocalPoint(
    point: Point,
    altitude: number = 0,
) {
    return new Vector3(
        point.longitude * 100,
        altitude,
        -point.latitude * 100,
    );
}
