import { useMemo } from "react";
import {
    BufferGeometry,
    ColorRepresentation,
    DoubleSide,
} from "three";

import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { defaultColours } from "../theme";
import { makeLineGeometry } from "./utils";

interface Props {
    geometry: BufferGeometry;
    visible?: boolean;
    lineWidth: number;
    color: ColorRepresentation;
}

export const OutlineSolid = ({
    geometry,
    color,
    lineWidth,
    visible = true,
}: Props) => {
    const edgepoints = useMemo(() => {
        const lineGeometry = makeLineGeometry(geometry);

        const material = new LineMaterial({
            side: DoubleSide,
            color: color
                ? color
                : defaultColours.assetStates.default.bounds.color,
            linewidth: lineWidth,
            worldUnits: true,
        });

        const line = new LineSegments2(lineGeometry, material);

        return line;
    }, [color, geometry, lineWidth]);

    return (
        <group>
            <primitive visible={visible} object={edgepoints} />
        </group>
    );
};
