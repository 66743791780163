/*
 * fetchTimeSeriesMetricData
 *
 */
import { tsInstantQuery } from "./tsInstantQuery";
import { tsRangeQuery } from "./tsRangeQuery";

export const tsMetricData = async (
    timeSeriesURL: string,
    startDateTime: string,
    endDateTime: string,
    aggregation: "avg" | "max" | "sum",
    step: string = "15m",
    organisation: string,
    digitalTwinEntity?: string,
    digitalTwinEntityPath?: string,
) => {
    let countEntity;
    let windOrientation;
    let windSpeed;
    let transactionRate;
    let totalCheckins;

    try {
        countEntity = await tsInstantQuery(
            timeSeriesURL,
            endDateTime,
            aggregation,
            step,
            organisation,
            "countEntity",
            undefined,
            digitalTwinEntity,
            digitalTwinEntityPath,
        );
    } catch (error) {
        console.error(
            `Error fetching time series data for ${aggregation} countEntity metric`,
        );
    }

    try {
        windOrientation = await tsRangeQuery(
            timeSeriesURL,
            startDateTime,
            endDateTime,
            aggregation,
            step,
            organisation,
            "windOrientation",
            undefined,
            digitalTwinEntity,
            digitalTwinEntityPath,
        );
    } catch (error) {
        console.error(
            `Error fetching time series data for ${aggregation} windOrientation metric`,
        );
    }

    try {
        windSpeed = await tsRangeQuery(
            timeSeriesURL,
            startDateTime,
            endDateTime,
            aggregation,
            step,
            organisation,
            "windSpeed",
            undefined,
            digitalTwinEntity,
            digitalTwinEntityPath,
        );
    } catch (error) {
        console.error(
            `Error fetching time series data for ${aggregation} windSpeed metric`,
        );
    }

    try {
        transactionRate = await tsInstantQuery(
            timeSeriesURL,
            endDateTime,
            aggregation,
            step,
            organisation,
            "transactionRate",
            undefined,
            digitalTwinEntity,
            digitalTwinEntityPath,
        );
    } catch (error) {
        console.error(
            `Error fetching time series data for ${aggregation} transactionRate metric`,
        );
    }

    try {
        totalCheckins = await tsInstantQuery(
            timeSeriesURL,
            endDateTime,
            aggregation,
            step,
            organisation,
            "totalCheckins",
            undefined,
            digitalTwinEntity,
            digitalTwinEntityPath,
        );
    } catch (error) {
        console.error(
            `Error fetching time series data for ${aggregation} totalCheckins metric`,
        );
    }

    return {
        countEntity: countEntity,
        windOrientation: windOrientation,
        windSpeed: windSpeed,
        transactionRate: transactionRate,
        totalCheckins: totalCheckins,
    };
};
