import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

// Context
import { useEventContext } from "../../common/contexts/EventContext";

// Components
import EntityAccordionItem from "./EntityAccordionItem";

const withContext = (Component: any) => {
    /* @ts-ignore */
    return props => {
        const {
            currentHoveredEntity,
            setCurrentHoveredValue,
            currentHoveredEntityOn3DScene,
            selectedEntityIds,
        } = useEventContext();
        const [isHovered, setIsHovered] = useState(false);

        const handleMouseEnter = useDebouncedCallback(
            (id: string, type: "ASSET" | "ENTITY") => {
                if (!isHovered) {
                    setCurrentHoveredValue({ id: id, type: type });
                    setIsHovered(true);
                }
            },
            0,
        );

        const handleMouseLeave = useDebouncedCallback(() => {
            if (isHovered) {
                setCurrentHoveredValue(null);
                setIsHovered(false);
            }
        }, 0);

        const isSelected = selectedEntityIds.has(props.entity.id);

        return (
            <Component
                {...props}
                currentHoveredEntity={currentHoveredEntity}
                currentHovered3DEntity={currentHoveredEntityOn3DScene}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                live={props.live}
                isSelected={isSelected}
            />
        );
    };
};

const EntityAccordionItemWithContext = withContext(
    EntityAccordionItem,
);

export default EntityAccordionItemWithContext;
