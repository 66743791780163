import { figmaVariables } from "./figmaVariables";

export const lightTheme = {
    // - - - - - - - - - - - - - - - -
    // - - - - - - Neutral - - - - - -
    // - - - - - - - - - - - - - - - -
    // Neutral / Background / 1
    colorNeutralBackground1: figmaVariables.colors.grey.white,
    colorNeutralBackground1Hover: figmaVariables.colors.grey[96],
    colorNeutralBackground1Pressed: figmaVariables.colors.grey[88],
    colorNeutralBackground1Selected: figmaVariables.colors.grey[92],
    // Neutral / Background / 2
    colorNeutralBackground2: figmaVariables.colors.grey[98],
    colorNeutralBackground2Hover: figmaVariables.colors.grey[94],
    colorNeutralBackground2Pressed: figmaVariables.colors.grey[86],
    colorNeutralBackground2Selected: figmaVariables.colors.grey[90],
    // Neutral / Background / 3
    colorNeutralBackground3: figmaVariables.colors.grey[96],
    colorNeutralBackground3Hover: figmaVariables.colors.grey[92],
    colorNeutralBackground3Pressed: figmaVariables.colors.grey[84],
    colorNeutralBackground3Selected: figmaVariables.colors.grey[88],
    // Neutral / Background / 4
    colorNeutralBackground4: figmaVariables.colors.grey[94],
    colorNeutralBackground4Hover: figmaVariables.colors.grey[98],
    colorNeutralBackground4Pressed: figmaVariables.colors.grey[96],
    colorNeutralBackground4Selected: figmaVariables.colors.grey.white,
    // Neutral / Background / 5
    colorNeutralBackground5: figmaVariables.colors.grey[92],
    colorNeutralBackground5Hover: figmaVariables.colors.grey[96],
    colorNeutralBackground5Pressed: figmaVariables.colors.grey[94],
    colorNeutralBackground5Selected: figmaVariables.colors.grey[98],
    // Neutral / Background / 5
    colorNeutralBackground6: figmaVariables.colors.grey[90],
    // Neutral / Inverted
    colorNeutralBackgroundInverted: figmaVariables.colors.grey[16],
    // Neutral / Inverted / Disabled
    colorNeutralBackgroundInvertedDisabled:
        figmaVariables.colors.grey[94],
    // Neutral / Background / Alpha 1
    colorNeutralBackgroundAlpha: "rgba(255, 255, 255, 0.5)",
    // Neutral / Background / Alpha 2
    colorNeutralBackgroundAlpha2: "rgba(255, 255, 255, 0.8)",
    // Neutral / Background / Static
    colorNeutralBackgroundStatic: figmaVariables.colors.grey[20],
    // Neutral / Background / Subtle
    colorSubtleBackground: figmaVariables.colors.grey.white,
    colorSubtleBackgroundHover: figmaVariables.colors.grey[96],
    colorSubtleBackgroundPressed: figmaVariables.colors.grey[88],
    colorSubtleBackgroundSelected: figmaVariables.colors.grey[92],
    // Neutral / Background / Subtle / Light alpha
    colorSubtleBackgroundLightAlphaHover: "rgba(255, 255, 255, 0.7)",
    colorSubtleBackgroundLightAlphaPressed:
        "rgba(255, 255, 255, 0.5)",
    colorSubtleBackgroundLightAlphaSelected: "rgba(255, 255, 255, 0)",
    // Neutral / Background / Subtle / Inverted
    colorSubtleBackgroundInverted: "rgba(255, 255, 255, 0)",
    colorSubtleBackgroundInvertedHover: "rgba(0, 0, 0, 0.1)",
    colorSubtleBackgroundInvertedPressed: "rgba(0, 0, 0, 0.3)",
    colorSubtleBackgroundInvertedSelected: "rgba(0, 0, 0, 0.2)",
    // Neutral / Background / Transparent
    colorTransparentBackground: "rgba(255, 255, 255, 0)",
    colorTransparentBackgroundHover: "rgba(255, 255, 255, 0)",
    colorTransparentBackgroundPressed: "rgba(255, 255, 255, 0)",
    colorTransparentBackgroundSelected: "rgba(255, 255, 255, 0)",
    // Neutral / Background / Disabled
    colorNeutralBackgroundDisabled: figmaVariables.colors.grey[94],
    // Neutral / Stencil / 1
    colorNeutralStencil1: figmaVariables.colors.grey[90],
    // Neutral / Stencil / 2
    colorNeutralStencil2: figmaVariables.colors.grey[98],
    // Neutral / Background / Overlay
    colorBackgroundOverlay: "rgba(0, 0, 0, 0.4)",
    // Neutral / Scrollbar / Overlay
    colorScrollbarOverlay: "rgba(0, 0, 0, 0.5)",
    // Neutral / Foreground / 1
    colorNeutralForeground1: figmaVariables.colors.grey[14],
    colorNeutralForeground1Hover: figmaVariables.colors.grey[14],
    colorNeutralForeground1Pressed: figmaVariables.colors.grey[14],
    colorNeutralForeground1Selected: figmaVariables.colors.grey[14],
    // Neutral / Foreground / 2
    colorNeutralForeground2: figmaVariables.colors.grey[26],
    colorNeutralForeground2Hover: figmaVariables.colors.grey[14],
    colorNeutralForeground2Pressed: figmaVariables.colors.grey[14],
    colorNeutralForeground2Selected: figmaVariables.colors.grey[14],
    // Neutral / Foreground / 2 / Brand
    colorNeutralForeground2BrandHover:
        figmaVariables.colors.brand[80],
    colorNeutralForeground2BrandPressed:
        figmaVariables.colors.grey[70],
    colorNeutralForeground2BrandSelected:
        figmaVariables.colors.grey[80],
    // Neutral / Foreground / 2 / Link
    colorNeutralForeground2Link: figmaVariables.colors.grey[26],
    colorNeutralForeground2LinkHover: figmaVariables.colors.grey[14],
    colorNeutralForeground2LinkPressed:
        figmaVariables.colors.grey[14],
    colorNeutralForeground2LinkSelected:
        figmaVariables.colors.grey[14],
    // Neutral / Foreground / 3
    colorNeutralForeground3: figmaVariables.colors.grey[38],
    colorNeutralForeground3Hover: figmaVariables.colors.grey[26],
    colorNeutralForeground3Pressed: figmaVariables.colors.grey[26],
    colorNeutralForeground3Selected: figmaVariables.colors.grey[26],
    // Neutral / Foreground / 2 / Brand
    colorNeutralForeground3BrandHover:
        figmaVariables.colors.brand[80],
    colorNeutralForeground3BrandPressed:
        figmaVariables.colors.grey[70],
    colorNeutralForeground3BrandSelected:
        figmaVariables.colors.grey[80],
    // Neutral / Foreground / 4
    colorNeutralForeground4: figmaVariables.colors.grey[44],
    // Neutral / Foreground / Disabled
    colorNeutralForegroundDisabled: figmaVariables.colors.grey[74],
    // Neutral / Foreground / Static
    colorNeutralForeground1Static: figmaVariables.colors.grey[14],
    // Neutral / Foreground / Static / Inverted
    colorNeutralForegroundStaticInverted:
        figmaVariables.colors.grey.white,
    // Neutral / Foreground / Inverted / Disabled
    colorNeutralForegroundInvertedDisabled:
        "rgba(255, 255, 255, 0.4)",
    // Neutral / Foreground / Inverted / 1
    colorNeutralForegroundInverted: figmaVariables.colors.grey.white,
    colorNeutralForegroundInvertedHover:
        figmaVariables.colors.grey.white,
    colorNeutralForegroundInvertedPressed:
        figmaVariables.colors.grey.white,
    colorNeutralForegroundInvertedSelected:
        figmaVariables.colors.grey.white,
    // Neutral / Foreground / Inverted / 2
    colorNeutralForegroundInverted2: figmaVariables.colors.grey.white,
    // Neutral / Foreground / Inverted / Link
    colorNeutralForegroundInvertedLink:
        figmaVariables.colors.grey.white,
    colorNeutralForegroundInvertedLinkHover:
        figmaVariables.colors.grey.white,
    colorNeutralForegroundInvertedLinkPressed:
        figmaVariables.colors.grey.white,
    colorNeutralForegroundInvertedLinkSelected:
        figmaVariables.colors.grey.white,
    // Neutral / Foreground / OnBrand
    colorNeutralForegroundOnBrand: figmaVariables.colors.grey.white,
    // Neutral / Stroke / 1
    colorNeutralStroke1: figmaVariables.colors.grey[82],
    colorNeutralStroke1Hover: figmaVariables.colors.grey[78],
    colorNeutralStroke1Pressed: figmaVariables.colors.grey[70],
    colorNeutralStroke1Selected: figmaVariables.colors.grey[74],
    // Neutral / Stroke / 2
    colorNeutralStroke2: figmaVariables.colors.grey[88],
    // Neutral / Stroke / 3
    colorNeutralStroke3: figmaVariables.colors.grey[94],
    // Neutral / Stroke / Alpha
    colorNeutralStrokeAlpha: "rgba(0, 0, 0, 0.05)",
    // Neutral / Stroke / Alpha / 2
    colorNeutralStrokeAlpha2: "rgba(255, 255, 255, 0.2)",
    // Neutral / Stroke / Subtle
    colorNeutralStrokeSubtle: figmaVariables.colors.grey[88],
    // Neutral / Stroke / OnBrand
    colorNeutralStrokeOnBrand: figmaVariables.colors.grey.white,
    // Neutral / Stroke / OnBrand / 2
    colorNeutralStrokeOnBrand2: figmaVariables.colors.grey.white,
    colorNeutralStrokeOnBrand2Hover: figmaVariables.colors.grey.white,
    colorNeutralStrokeOnBrand2Pressed:
        figmaVariables.colors.grey.white,
    colorNeutralStrokeOnBrand2Selected:
        figmaVariables.colors.grey.white,
    // Neutral / Stroke / Accessible
    colorNeutralStrokeAccessible: figmaVariables.colors.grey[38],
    colorNeutralStrokeAccessibleHover: figmaVariables.colors.grey[34],
    colorNeutralStrokeAccessiblePressed:
        figmaVariables.colors.grey[30],
    colorNeutralStrokeAccessibleSelected:
        figmaVariables.colors.brand[80],
    // Neutral / Stroke / Focus / 1
    colorStrokeFocus1: figmaVariables.colors.grey.white,
    // Neutral / Stroke / Focus / 2
    colorStrokeFocus2: figmaVariables.colors.brand[80],
    // Neutral / Stroke / Transparent
    colorTransparentStroke: "rgba(255, 255, 255, 0)",
    // Neutral / Stroke / Transparent / Interactive
    colorTransparentStrokeInteractive: "rgba(255, 255, 255, 0)",
    // Neutral / Stroke / Transparent / Disabled
    colorTransparentStrokeDisabled: "rgba(255, 255, 255, 0)",
    // Neutral / Stroke / Disabled
    colorNeutralStrokeDisabled: figmaVariables.colors.grey[88],
    // Neutral / Stroke / Disabled / Inverted
    colorNeutralStrokeDisabledInverted: "rgba(255, 255, 255, 0.4)",
    // - - - - - - - - - - - - - - -
    // - - - - - - Brand - - - - - -
    // - - - - - - - - - - - - - - -
    // Brand / Background / 1
    colorBrandBackground: figmaVariables.colors.brand[80],
    colorBrandBackgroundHover: figmaVariables.colors.brand[70],
    colorBrandBackgroundPressed: figmaVariables.colors.brand[50],
    colorBrandBackgroundSelected: figmaVariables.colors.brand[70],
    // Brand / Background / 2
    colorBrandBackground2: figmaVariables.colors.brand[160],
    colorBrandBackground2Hover: figmaVariables.colors.brand[150],
    colorBrandBackground2Pressed: figmaVariables.colors.brand[130],
    // Brand / Background / Inverted
    colorBrandBackgroundInverted: figmaVariables.colors.grey.white,
    colorBrandBackgroundInvertedHover:
        figmaVariables.colors.brand[160],
    colorBrandBackgroundInvertedPressed:
        figmaVariables.colors.brand[140],
    colorBrandBackgroundInvertedSelected:
        figmaVariables.colors.brand[150],
    // Brand / Background / Static
    colorBrandBackgroundStatic: figmaVariables.colors.brand[80],
    // Brand / Background / Compound
    colorCompoundBrandBackground1: figmaVariables.colors.brand[80],
    colorCompoundBrandBackground1Hover:
        figmaVariables.colors.brand[70],
    colorCompoundBrandBackground1Pressed:
        figmaVariables.colors.brand[60],
    // Brand / Foreground / 1
    colorBrandForeground: figmaVariables.colors.brand[80],
    // Brand / Foreground / 2
    colorBrandForeground2: figmaVariables.colors.brand[70],
    colorBrandForeground2Hover: figmaVariables.colors.brand[60],
    colorBrandForeground2Pressed: figmaVariables.colors.brand[30],
    // Brand / Foreground / Link
    colorBrandForegroundLink: figmaVariables.colors.brand[70],
    colorBrandForegroundLinkHover: figmaVariables.colors.brand[60],
    colorBrandForegroundLinkPressed: figmaVariables.colors.brand[40],
    colorBrandForegroundLinkSelected: figmaVariables.colors.brand[70],
    // Brand / Foreground / Inverted
    colorBrandForegroundInverted: figmaVariables.colors.brand[100],
    colorBrandForegroundInvertedHover:
        figmaVariables.colors.brand[110],
    colorBrandForegroundInvertedPressed:
        figmaVariables.colors.brand[100],
    // Brand / Foreground / OnLight
    colorBrandForegroundOnLight: figmaVariables.colors.brand[80],
    colorBrandForegroundOnLightHover: figmaVariables.colors.brand[70],
    colorBrandForegroundOnLightPressed:
        figmaVariables.colors.brand[50],
    colorBrandForegroundOnLightSelected:
        figmaVariables.colors.brand[60],
    // Brand / Foreground / Compound
    colorCompoundBrandForeground1: figmaVariables.colors.brand[80],
    colorCompoundBrandForeground1Hover:
        figmaVariables.colors.brand[70],
    colorCompoundBrandForeground1Pressed:
        figmaVariables.colors.brand[60],
    // Brand / Stroke / 1
    colorBrandStroke1: figmaVariables.colors.brand[80],
    // Brand / Stroke / 2
    colorBrandStroke2: figmaVariables.colors.brand[140],
    colorBrandStroke2Hover: figmaVariables.colors.brand[120],
    colorBrandStroke2Pressed: figmaVariables.colors.brand[80],
    // Brand / Stroke / 2 / Contrast
    colorBrandStroke2Contrast: figmaVariables.colors.brand[140],
    // Brand / Stroke / Compound
    colorCompoundBrandStroke: figmaVariables.colors.brand[80],
    colorCompoundBrandStrokeHover: figmaVariables.colors.brand[70],
    colorCompoundBrandStrokePressed: figmaVariables.colors.brand[60],
    // - - - - - - - - - - - - - - - -
    // - - - - - - Status - - - - - -
    // - - - - - - - - - - - - - - - -
    // Status / Danger / Background / 1
    colorStatusDangerBackground1: "#FDF3F4",
    // Status / Danger / Background / 3
    colorStatusDangerBackground3: "#C50F1F",
    // Status / Danger / Foreground / 1
    colorStatusDangerForeground1: "#B10E1C",
    // Status / Danger / Foreground / 3
    colorStatusDangerForeground3: "#D13438",
    // Status / Danger / Stroke / 1
    colorStatusDangerBorder1: "#EEACB2",
    // Status / Danger / Stroke / 2
    colorStatusDangerBorder2: "#C50F1F",
    // Status / Success / Background / 1
    colorStatusSuccessBackground1: "#F1FAF1",
    // Status / Success / Background / 3
    colorStatusSuccessBackground3: "#107C10",
    // Status / Success / Foreground / 1
    colorStatusSuccessForeground1: "#0E700E",
    // Status / Success / Foreground / 3
    colorStatusSuccessForeground3: "#107C10",
    // Status / Success / Foreground / Inverted
    colorStatusSuccessForegroundInverted: "#359B35",
    // Status / Success / Stroke / 1
    colorStatusSuccessBorder1: "#9FD89F",
    // Status / Success / Stroke / 2
    colorStatusSuccessBorder2: "#107C10",
    // Status / Warning / Background / 1
    colorStatusWarningBackground1: "#FFF9F5",
    // Status / Warning / Background / 3
    colorStatusWarningBackground3: "#F7630C",
    // Status / Warning / Foreground / 1
    colorStatusWarningForeground1: "#BC4B09",
    // Status / Warning / Foreground / 2
    colorStatusWarningForeground3: "#8A3707",
    // Status / Warning / Stroke / 1
    colorStatusWarningBorder1: "#FDCFB4",
    // - - - - - - - - - - - - - - - -
    // - - - - - - Graph - - - - - - -
    // - - - - - - - - - - - - - - - -
    g10: figmaVariables.colors.graph[10],
    g20: figmaVariables.colors.graph[20],
    g30: figmaVariables.colors.graph[30],
    g40: figmaVariables.colors.graph[40],
    g50: figmaVariables.colors.graph[50],
    g60: figmaVariables.colors.graph[60],
};
