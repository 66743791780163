// Templates
import { gymTwinTemplate } from "../../../twin-templates/gymTwinTemplate";

// Types
import { TwinTemplate } from "../../../../../@types/Settings/TwinTemplate";

const twinPrefs: Partial<TwinTemplate> = {
    organisation: "Newport Live",
    timeZone: "Europe/London",
};

export const digitalTwinPrefs = { ...gymTwinTemplate, ...twinPrefs };
