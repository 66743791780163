import React from "react";
import styled from "styled-components";

// Types
import { SelectorItem } from "../../@types/SelectorItem";

// Components
import { Image } from "@fluentui/react-components";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
interface Props {
    className?: string;
    header: string;
    items: SelectorItem[];
    onSelect: (organisationName: string, envName: string) => void;
    content?: string;
    height?: number;
    displayValue?: boolean;
}

const SelectorPanel: React.FC<Props> = ({
    className,
    header,
    content,
    items,
    onSelect,
    height = 300,
    displayValue = false,
}) => {
    return (
        <div className={className}>
            <h2>{header}</h2>
            {items && items.length > 0 ? (
                <div
                    className="available-items-container"
                    style={{ marginTop: !content ? "2rem" : 0 }}
                >
                    {content ? <p>{content}</p> : null}
                    <CustomScrollbar fadeOutEnabled={true}>
                        <div
                            className="available-twins"
                            style={{ height }}
                        >
                            <ul>
                                {items.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <div
                                                className={`option`}
                                                onClick={() => {
                                                    onSelect(
                                                        item.text,
                                                        item.value,
                                                    );
                                                }}
                                            >
                                                <div className="flex justify-center items-center">
                                                    <div className="option-icon">
                                                        <Image
                                                            width={50}
                                                            height={
                                                                36
                                                            }
                                                            src={
                                                                item.imgSrc
                                                                    ? item.imgSrc
                                                                    : `/twinlabs-atom-dark-logo.svg`
                                                            }
                                                            alt={
                                                                item.imgAlt
                                                                    ? item.imgAlt
                                                                    : item.text
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex-1 ml-2">
                                                        <p className="option-title">
                                                            {
                                                                item.text
                                                            }
                                                        </p>
                                                        {displayValue && (
                                                            <p className="option-microcopy">
                                                                {
                                                                    item.value
                                                                }
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </CustomScrollbar>
                </div>
            ) : null}
        </div>
    );
};

export default styled(SelectorPanel)`
    backdrop-filter: "blur(6px)";
    background-color: rgb(255, 255, 255, 0.4);

    /* Fading effect starting closer to the bottom */
    -webkit-mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 80%,
        /* Fully visible until 80% height */ rgba(0, 0, 0, 0) 100%
            /* Starts fading after 80% */
    );
    mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 80%,
        rgba(0, 0, 0, 0) 100%
    );

    border-color: rgb(255, 255, 255, 0);
    box-shadow:
        0px 0px 11px 1px rgba(228, 255, 254, 0.55),
        0px 3px 11px 0px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    border-width: 2px;
    border-style: solid;
    color: #242424;
    padding: 2rem;
`;
