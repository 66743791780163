import axios from "axios";
import routes from "./routes";

export const deleteUser = async (
    accessToken: string,
    id: string,
): Promise<boolean> => {
    const url = `${routes.delete.users}${id}`;
    let success = false;

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        await axios.delete(url, {
            headers: headers,
        });
        success = true;
    } catch (error) {
        success = false;
    }

    return success;
};
