import axios from "axios";
import routes from "./routes";

export const createUser = async (
    accessToken: string,
    organisation: string,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    role: string,
): Promise<boolean> => {
    const url = routes.post.users.createUser;
    let success = false;

    const data = {
        organisation: organisation,
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        role,
    };

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        await axios.post(url, data, {
            headers: headers,
        });
        success = true;
    } catch (error) {
        success = false;
    }

    return success;
};
