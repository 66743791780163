import { Entity } from "../../@types/Entity";

export const findAncestors = (id: string, data: Entity) => {
    // Recursive helper function to search for the node and build the ancestors array

    /* @ts-ignore */
    const searchNode = (currentNode, id) => {
        // Base case: If the current node's id matches the target id, return this node
        if (currentNode.id === id) {
            return [currentNode];
        }

        // If the current node has children, recursively search in its children
        if (currentNode.children && currentNode.children.length > 0) {
            for (let child of currentNode.children) {
                /* @ts-ignore */
                const result = searchNode(child, id);
                if (result) {
                    // If the target node is found in the subtree, include the current node as an ancestor
                    return [currentNode, ...result];
                }
            }
        }

        // If the target node is not found in this subtree, return null
        return null;
    };

    // Start the search from the root of the tree (data)
    return searchNode(data, id) || [];
};
