import axios from "axios";
import routes from "./routes";
import { Version } from "../../../@types/Middleware/Version";

export const getOpeningHours = async (
    accessToken: string,
    envName: string,
    bID: string,
): Promise<Version[]> => {
    let openingHours: Version[] = [];
    const url = `${routes.get.twins.getOpeningHours(envName, bID)}`;

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        const res = await axios.get(url, {
            headers: headers,
        });
        openingHours = res.data.versions;
    } catch (error) {
        // Silent fail
    }

    return openingHours;
};
