import axios from "axios";
import routes from "./routes";

export const changePassword = async (
    accessToken: string,
    password: string,
): Promise<boolean> => {
    const url = routes.put.users.updateProfile;
    let success = false;

    const data = {
        password,
    };

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        await axios.put(url, data, {
            headers: headers,
        });
        success = true;
    } catch (error) {
        success = false;
    }

    return success;
};
