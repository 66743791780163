import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import ReactECharts from "echarts-for-react";
import { DateTime } from "luxon";

// Types
import { CalendarChartData } from "../../@types/CalendarChartData";
import { SelectedCellValue } from "../../@types/SelectedCellValue";
import { Week } from "../../@types/Week";

// Context
import { useFilterContext } from "../../common/contexts/FilterAndModeContexts";

// Utils
import { getIndicatorColor } from "../../common/utils/getIndicatorColor";
import { roundToOneDecimalPlace } from "../../common/utils/roundToOneDecimalPlace";
import { useSettingsContext } from "../../common/contexts/SettingsContext";
import { DataScope } from "../../@types/Settings/DataScope";

interface Props {
    className?: string;
    monthData: Week[];
    selectedCellValues: SelectedCellValue[];
    entityCapacity: number;
}

const CalendarMonthChart: React.FC<Props> = ({
    className,
    monthData,
    selectedCellValues,
    entityCapacity,
}) => {
    const { heroMetric } = useFilterContext();
    const { settings } = useSettingsContext();
    const [chartData, setChartData] = useState<CalendarChartData[]>(
        [],
    );
    const weekDays = useMemo(
        () => ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        [],
    );

    const mapTheWeekToChartData = useCallback(
        (monthData: Week[], entityCapacity: number) => {
            let chartData: CalendarChartData[] = [];

            // First create empty chart data for each week day
            for (let i = 0; i <= 6; i++) {
                chartData[i] = {
                    weekDay: weekDays[i],
                    weekDayCount: 0,
                    //count: 0,
                    usages: [],
                    value: 0,
                    label: "",
                };
            }

            monthData.forEach(week => {
                week.days.forEach(day => {
                    let weekDayIndex =
                        DateTime.fromJSDate(day.date).weekday - 1;
                    let sum = 0;

                    if (
                        settings?.calendar.monthView.dataScope ===
                        DataScope.TOTAL_DAYS
                    ) {
                        chartData[weekDayIndex].weekDayCount =
                            chartData[weekDayIndex].weekDayCount + 1;
                    } else if (
                        settings?.calendar.monthView.dataScope ===
                        DataScope.ACTIVE_DAYS
                    ) {
                        if (day.count > 0) {
                            chartData[weekDayIndex].weekDayCount =
                                chartData[weekDayIndex].weekDayCount +
                                1;
                        }
                    }

                    if (heroMetric?.metric === "usage") {
                        let usageValue = entityCapacity
                            ? (day.count / entityCapacity) * 100
                            : 0;
                        chartData[weekDayIndex].usages.push(
                            usageValue,
                        );
                        sum = chartData[weekDayIndex].usages.reduce(
                            (accumulator, currentValue) =>
                                accumulator + currentValue,
                            0,
                        );
                    } else {
                        sum = sum + (day.count ? day.count : 0);
                    }

                    if (
                        sum > 0 &&
                        chartData[weekDayIndex].weekDayCount > 0
                    ) {
                        let value =
                            sum /
                            chartData[weekDayIndex].weekDayCount;
                        chartData[weekDayIndex].value = value;
                        chartData[weekDayIndex].label = `${value}`;
                    }
                });
            });

            return chartData;
        },
        [
            heroMetric?.metric,
            settings?.calendar.monthView.dataScope,
            weekDays,
        ],
    );

    const mapSelectedCellValuesToChartData = useCallback(
        (selectedCellValues: SelectedCellValue[]) => {
            const chartData: CalendarChartData[] = [];

            // First create empty chart data
            for (let i = 0; i <= 6; i++) {
                chartData[i] = {
                    weekDay: "",
                    weekDayCount: 0,
                    usages: [],
                    value: 0,
                    label: "",
                };
            }

            selectedCellValues.forEach(scv => {
                // Get day of week (Sunday is 0)
                let weekDayIndex =
                    DateTime.fromISO(scv.dateTimeISO).weekday - 1;
                let sum = 0;
                let value = 0;
                chartData[weekDayIndex].weekDayCount =
                    chartData[weekDayIndex].weekDayCount + 1;

                if (heroMetric?.metric === "usage") {
                    chartData[weekDayIndex].usages.push(
                        scv.cellValue ? scv.cellValue : 0,
                    );
                    sum = chartData[weekDayIndex].usages.reduce(
                        (accumulator, currentValue) =>
                            accumulator + currentValue,
                        0,
                    );
                } else {
                    sum = sum + (scv.count ? scv.count : 0);
                }

                if (
                    sum > 0 &&
                    chartData[weekDayIndex].weekDayCount > 0
                ) {
                    value =
                        sum / chartData[weekDayIndex].weekDayCount;
                }

                chartData[weekDayIndex].value = value;
                chartData[weekDayIndex].label = `${value}`;
            });

            return chartData;
        },
        [heroMetric?.metric],
    );

    useEffect(() => {
        let data: CalendarChartData[];

        if (selectedCellValues && selectedCellValues.length > 0) {
            data = mapSelectedCellValuesToChartData(
                selectedCellValues,
            );
        } else {
            data = mapTheWeekToChartData(monthData, entityCapacity);
        }

        setChartData(data);
    }, [
        monthData,
        selectedCellValues,
        mapSelectedCellValuesToChartData,
        mapTheWeekToChartData,
        entityCapacity,
    ]);

    const option = {
        grid: {
            show: false, // Hide grid lines
            left: "0", // Adjust the left margin
            right: "0", // Adjust the right margin
            top: "0", // Adjust the top margin
            bottom: "0", // Adjust the bottom margin
        },
        xAxis: {
            type: "category",
            data: chartData.map(item => item.label),
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
        yAxis: {
            type: "value",
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
        series: [
            {
                data: chartData.map(item =>
                    item.value === 0
                        ? null
                        : roundToOneDecimalPlace(item.value).toFixed(
                              1,
                          ),
                ), // Set value to null if it's 0
                type: "bar",
                barWidth: "43px",
                barGap: 0,
                label: {
                    show: true,
                    position: "inside",
                    formatter: function (params: { value: null }) {
                        // Conditionally show label only if value is not null
                        return params.value !== null
                            ? params.value
                            : "";
                    },
                },
                itemStyle: {
                    color: function (params: { value: number }) {
                        const colors = getIndicatorColor(
                            params.value,
                            heroMetric?.indicatorConfig,
                            true,
                        );
                        return colors.bgColor;
                    },
                },
            },
        ],
    };

    return (
        <div className={className}>
            <div className="flex">
                <div className="flex items-end">
                    <p className="text-xs font-semibold text-center">
                        Total
                    </p>
                </div>
                <div style={{ marginLeft: "14px" }}>
                    <ReactECharts
                        className="border-b-2 border-black ml-auto"
                        style={{ height: "40px", width: "301px" }}
                        option={option}
                    />
                </div>
            </div>
        </div>
    );
};

export default CalendarMonthChart;
