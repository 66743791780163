import React, { useEffect } from "react";
import styled from "styled-components";
import {
    SubmitHandler,
    useForm,
    Controller,
    FormProvider,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Types
import { Period } from "../../@types/Middleware/Period";
import { Range } from "../../@types/Middleware/Range";
import { Version } from "../../@types/Middleware/Version";

// Consts
import { weekDays } from "../../common/const/weekDays";

// Utils
import { capitaliseFirstLetter } from "../../common/utils/capitaliseFirstLetter";

// Components
import { Pen20Regular } from "@fluentui/react-icons";
import TLAdminInput from "../elements/TLAdminInput/TLAdminInput";
import RangeForm from "./RangeForm";

export type FormInputs = {
    openingHours: {
        [key: string]: {
            openHour: string;
            openMinute: string;
            closeHour: string;
            closeMinute: string;
        }[];
    };
    effectiveDateFrom: string;
    effectiveDateTo: string;
};

interface Props {
    className?: string;
    editVersion: { version: Version; index: number } | null;
    addOpenHourVersion: (version: Version, index?: number) => void;
    setMode: (mode: string) => void;
}

const OperatingHours: React.FC<Props> = ({
    className,
    editVersion,
    addOpenHourVersion,
    setMode,
}) => {
    const formSchema = yup.object().shape({
        openingHours: yup
            .object()
            .test(
                "at-least-one-range",
                "Please add at least one time range for at least one weekday",
                value => {
                    const openingHours =
                        value as FormInputs["openingHours"];
                    return Object.values(openingHours).some(
                        ranges =>
                            Array.isArray(ranges) &&
                            ranges.length > 0,
                    );
                },
            ),
        effectiveDateFrom: yup
            .string()
            .required("Please enter an effective from date")
            .matches(
                /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                "Date must be in DD/MM/YYYY format",
            )
            .test(
                "valid-day-month",
                "Invalid day or month",
                value => {
                    if (!value) return false;
                    const date = value.split("/").map(Number);
                    return (
                        date[0] >= 1 &&
                        date[0] <= 31 &&
                        date[1] >= 1 &&
                        date[1] <= 12
                    );
                },
            )
            .test(
                "valid-year",
                "Year must be the current year",
                value => {
                    if (!value) return false;
                    const date = value.split("/").map(Number);
                    const currentYear = new Date().getFullYear();
                    return date[2] === currentYear;
                },
            ),
        effectiveDateTo: yup
            .string()
            .required("Please enter an effective to date")
            .matches(
                /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                "Date must be in DD/MM/YYYY format",
            )
            .test(
                "valid-day-month",
                "Invalid day or month",
                value => {
                    if (!value) return false;
                    const date = value.split("/").map(Number);
                    return (
                        date[0] >= 1 &&
                        date[0] <= 31 &&
                        date[1] >= 1 &&
                        date[1] <= 12
                    );
                },
            )
            .test(
                "valid-year",
                "Year must be the current year",
                value => {
                    if (!value) return false;
                    const date = value.split("/").map(Number);
                    const currentYear = new Date().getFullYear();
                    return date[2] === currentYear;
                },
            ),
    });

    const methods = useForm<FormInputs>({
        resolver: yupResolver(formSchema),
        defaultValues: {
            openingHours: weekDays.reduce(
                (acc, day) => ({
                    ...acc,
                    [day]: [
                        {
                            openHour: "09",
                            openMinute: "00",
                            closeHour: "17",
                            closeMinute: "00",
                        },
                    ],
                }),
                {},
            ),
        },
    });

    const {
        control,
        handleSubmit,
        getValues,
        register,
        setValue,
        watch,
        formState: { errors },
    } = methods;

    const watchOpeningHours = watch("openingHours");

    const addRange = (day: string) => {
        setValue(
            "openingHours",
            {
                ...watchOpeningHours,
                [day]: [
                    ...watchOpeningHours[day],
                    {
                        openHour: "09",
                        openMinute: "00",
                        closeHour: "17",
                        closeMinute: "00",
                    },
                ],
            },
            { shouldDirty: true },
        );
    };

    const removeRange = (day: string, index: number) => {
        const updatedRanges = watchOpeningHours[day].filter(
            (_, i) => i !== index,
        );

        // Ensure at least one range remains
        setValue(
            "openingHours",
            {
                ...watchOpeningHours,
                [day]: updatedRanges.length
                    ? updatedRanges
                    : [
                          {
                              openHour: "09",
                              openMinute: "00",
                              closeHour: "17",
                              closeMinute: "00",
                          },
                      ],
            },
            { shouldDirty: true },
        );
    };

    const onSubmit: SubmitHandler<FormInputs> = formData => {
        const periods: Period[] = [];

        weekDays.forEach(wd => {
            let ranges: Range[] = [];

            formData.openingHours[wd].forEach(oh => {
                ranges.push({
                    open: `${String(oh.openHour).padStart(2, "0")}:${String(oh.openMinute).padStart(2, "0")}`,
                    close: `${String(oh.closeHour).padStart(2, "0")}:${String(oh.closeMinute).padStart(2, "0")}`,
                });
            });

            periods.push({
                dayOfWeek: wd,
                ranges: ranges,
            });
        });

        const [day, month, year] =
            formData.effectiveDateFrom.split("/");
        const effectiveDateFrom = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;

        const [dayTo, monthTo, yearTo] =
            formData.effectiveDateTo.split("/");
        const effectiveDateTo = `${yearTo}-${monthTo.padStart(2, "0")}-${dayTo.padStart(2, "0")}`;
        const version: Version = {
            effectiveDateFrom: effectiveDateFrom,
            effectiveDateTo: effectiveDateTo,
            periods: periods,
        };

        if (editVersion) {
            addOpenHourVersion(version, editVersion.index);
        } else {
            addOpenHourVersion(version);
        }
    };

    useEffect(() => {
        if (editVersion) {
            const [year, month, day] =
                editVersion.version.effectiveDateFrom.split("-");
            const [yearTo, monthTo, dayTo] =
                editVersion.version.effectiveDateTo.split("-");
            const formattedDate = `${day}/${month}/${year}`;
            const formattedTo = `${dayTo}/${monthTo}/${yearTo}`;
            setValue("effectiveDateFrom", formattedDate);
            setValue("effectiveDateTo", formattedTo);
            const updatedOpeningHours = weekDays.reduce(
                (acc, day) => {
                    const periodsForDay =
                        editVersion.version.periods.find(
                            period => period.dayOfWeek === day,
                        );
                    return {
                        ...acc,
                        [day]: periodsForDay
                            ? periodsForDay.ranges.map(range => ({
                                  // openHour: range.open.split(":")[0],
                                  // openMinute: range.open.split(":")[1],
                                  // closeHour: range.close.split(":")[0],
                                  // closeMinute: range.close.split(":")[1]
                                  openHour: String(
                                      range.open.split(":")[0],
                                  ).padStart(2, "0"),
                                  openMinute: String(
                                      range.open.split(":")[1],
                                  ).padStart(2, "0"),
                                  closeHour: String(
                                      range.close.split(":")[0],
                                  ).padStart(2, "0"),
                                  closeMinute: String(
                                      range.close.split(":")[1],
                                  ).padStart(2, "0"),
                              }))
                            : [],
                    };
                },
                {},
            );
            setValue("openingHours", updatedOpeningHours);
        }
    }, [editVersion, setValue]);

    return (
        <FormProvider {...methods}>
            <div className={className}>
                {/* <div className="operating-hours"> */}
                <h2>Add Opening Hours</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset>
                        <div className="field">
                            <TLAdminInput
                                enableModes={false}
                                id="txtEffectiveDate"
                                label="Effective from"
                                type="text"
                                placeholder="DD/MM/YYYY"
                                currentValue={getValues(
                                    "effectiveDateFrom",
                                )}
                                {...register("effectiveDateFrom")}
                                errorMessage={
                                    errors.effectiveDateFrom?.message
                                }
                            />
                        </div>
                        <div className="field">
                            <TLAdminInput
                                enableModes={false}
                                id="txtEffectiveDateTo"
                                label="Effective to"
                                type="text"
                                placeholder="DD/MM/YYYY"
                                currentValue={getValues(
                                    "effectiveDateTo",
                                )}
                                {...register("effectiveDateTo")}
                                errorMessage={
                                    errors.effectiveDateTo?.message
                                }
                            />
                        </div>
                        {weekDays.map(weekday => (
                            <div key={weekday} className="weekday">
                                <h3>
                                    {capitaliseFirstLetter(weekday)}
                                </h3>
                                <Controller
                                    name={`openingHours.${weekday}`}
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            {field.value.map(
                                                (range, index) => (
                                                    <RangeForm
                                                        key={index}
                                                        range={range}
                                                        index={index}
                                                        day={weekday}
                                                        onRemove={() =>
                                                            removeRange(
                                                                weekday,
                                                                index,
                                                            )
                                                        }
                                                    />
                                                ),
                                            )}
                                        </>
                                    )}
                                />
                                <button
                                    type="button"
                                    className="btn-new-range"
                                    onClick={() => addRange(weekday)}
                                >
                                    Add new range
                                </button>
                            </div>
                        ))}
                        {typeof errors.openingHours?.message ===
                            "string" && (
                            <p className="error">
                                {errors.openingHours.message}
                            </p>
                        )}
                    </fieldset>
                    <div className="admin-form-buttons">
                        <button type="submit" className="btn-submit">
                            <span className="icon">
                                <Pen20Regular />
                            </span>
                            {editVersion
                                ? `Save changes`
                                : `Add Opening Times`}
                        </button>
                        <button
                            onClick={e => {
                                setMode("LIST");
                                e.preventDefault();
                            }}
                        >
                            <span className="icon"></span>Cancel
                        </button>
                    </div>
                </form>
            </div>
        </FormProvider>
    );
};

export default styled(OperatingHours)`
    .weekday h3 {
        font-size: 1.125rem;
        margin-bottom: 1rem;
    }

    .form-row {
        margin-bottom: 1rem;
        padding: 0.5rem;
    }

    .form-row:nth-child(even) {
        background-color: #f5f5f5;
    }

    .input-row {
        display: flex;
    }

    .form-row .error {
        margin-top: 1rem;
        color: red;
    }

    .form-header-row {
        display: flex;
    }

    .form-header-row div {
        flex: 1;
        margin-bottom: 1rem;
    }

    .colon {
        margin: 0 0.5rem;
        font-weight: bold;
    }

    .form-row label {
        text-align: center;
        width: 100%;
        display: block;
    }

    .time {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
    }

    .open {
        margin-right: 2rem;
    }

    .close {
        margin-left: 2rem;
    }

    .times {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .time input {
        width: 50px;
        height: 40px;
        text-align: center;
        font-size: 1.25rem;
    }

    .time input[type="number"]::-webkit-inner-spin-button,
    .time input[type="number"]::-webkit-outer-spin-button {
        opacity: 1; /* Force visibility */
        display: block; /* Ensure it's displayed */
    }

    .operating-hours h2 {
        font-size: 1.25rem;
        margin-bottom: 2rem;
    }

    .btn-new-range {
        display: block;
        width: 140px;
        margin: 1rem 0 1rem auto;
        padding: 0.5rem;
        border-radius: 8px;
        background-color: #ffffff;
    }

    .until {
        display: flex;
        align-items: center;
    }

    .until p {
        font-size: 0.75rem;
        font-weight: bold;
        text-align: center;
        width: 100%;
    }

    .clock {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
    }

    .btn-delete:hover {
        color: #00bbcc;
    }
`;
