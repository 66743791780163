/*
 * SettingsContext
 * Used to store any Site specific settings
 *
 */
import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";

// Types
import { Metric } from "@repo/backend-types";
import { TwinTemplate } from "../../@types/Settings/TwinTemplate";

interface SettingsContextValue {
    twinId: string | null; // bID of top-level entity
    settings: TwinTemplate | null;
    metrics: Metric[];
    setTwinId: (bId: string) => void;
    setSettings: (settings: TwinTemplate | null) => void;
    setMetrics: (metrics: Metric[]) => void;
}

const initialState: SettingsContextValue = {
    twinId: null,
    settings: null,
    metrics: [],
    setTwinId: () => {},
    setSettings: () => {},
    setMetrics: () => {},
};

export const SettingsContext =
    createContext<SettingsContextValue>(initialState);

export const useSettingsContext = (): SettingsContextValue => {
    return useContext(SettingsContext);
};

interface ContextProviderProps {
    children: React.ReactNode;
}

export const SettingsContextProvider: React.FC<
    ContextProviderProps
> = props => {
    const [settingsState, setSettingsState] =
        useState<SettingsContextValue>(initialState);

    const setMetrics = useCallback((metrics: Metric[]) => {
        setSettingsState(prevState => ({ ...prevState, metrics }));
    }, []);

    const setSettings = useCallback(
        (settings: TwinTemplate | null) => {
            setSettingsState(prevState => ({
                ...prevState,
                settings,
            }));
        },
        [],
    );

    const setTwinId = useCallback((bId: string | null) => {
        setSettingsState(prevState => ({
            ...prevState,
            twinId: bId,
        }));
    }, []);

    // Memoise the context value to prevent unnecessary re-renders
    const contextValue = useMemo(() => {
        return {
            ...settingsState,
            setMetrics,
            setSettings,
            setTwinId,
        };
    }, [settingsState, setMetrics, setSettings, setTwinId]);

    return (
        <SettingsContext.Provider value={contextValue}>
            {props.children}
        </SettingsContext.Provider>
    );
};
