import axios from "axios";
import routes from "./routes";

export const getUserById = async (
    accessToken: string,
    userId: string,
) => {
    let user = null;
    const url = `${routes.get.users.getUserById}/${userId}`;

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`,
    };

    try {
        const res = await axios.get(url, {
            headers: headers,
        });
        user = res.data;
    } catch (error) {
        // Silent fail
    }

    return user;
};
