import React from "react";
import { useLocation } from "react-router-dom";

// Components
import NotificationPanel from "../../../components/NotificationPanel/NotificationPanel";

interface Props {
    imgSrc?: string;
    imgAlt?: string;
    icon?: JSX.Element;
    header: string;
    copy?: string;
    primaryButton?: JSX.Element;
}

const NotificationPage: React.FC<Props> = ({
    imgSrc,
    imgAlt,
    icon,
    header,
    copy,
    primaryButton,
}) => {
    const location = useLocation();
    const { message, backRoute } = location.state || {};

    return (
        <>
            <NotificationPanel
                imgSrc={imgSrc}
                imgAlt={imgAlt}
                icon={icon}
                header={header}
                copy={message ? message : copy ? copy : undefined}
                primaryButton={primaryButton}
                backRoute={backRoute}
            />
        </>
    );
};

export default NotificationPage;
