// Types
import { NavItem } from "../../@types/NavItem";

// Components
import {
    Cube20Filled,
    Cube20Regular,
    People20Filled,
    People20Regular,
    PersonAdd20Filled,
    PersonAdd20Regular,
    //Receipt20Regular, Receipt20Filled
} from "@fluentui/react-icons";

export const customerAdminTopMenu: NavItem[] = [
    {
        text: "Twins",
        href: "/account/twins",
        icon: Cube20Regular,
        iconSelected: Cube20Filled,
        showOnQuickLinks: true,
    },
    {
        text: "Users",
        href: "/account/users",
        icon: People20Regular,
        iconSelected: People20Filled,
        showOnQuickLinks: true,
    },
    {
        text: "Add New User",
        href: "/account/users/add-new-user",
        icon: PersonAdd20Regular,
        iconSelected: PersonAdd20Filled,
        showOnQuickLinks: true,
    },
    // {
    //   text: 'Billing',
    //   href: '/account/billing',
    //   icon: Receipt20Regular,
    //   showOnQuickLinks: true,
    // },
];
